var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import styled from "styled-components";
import { Checkbox } from "./Checkbox";
import { RadioInput } from "./RadioInputGroup";
import { Box } from "../component/common/FlexItem";
var logger = require("js-logger").get("Options");
import { PriceWithCurrency } from "./PriceWithCurrency";
import * as _ from "underscore";
import { sortBy } from "underscore";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 5px 0 10px 0;\n    padding: 0 8px;\n"], ["\n    margin: 5px 0 10px 0;\n    padding: 0 8px;\n"])));
// const CheckboxContainer = styled(FlexBox)`
//     display: flex;
//     flex-direction: columns;
//     gap: 2rem;
// `
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 5px 0 10px 0;\n    font-size: 16px;\n    color: #111719;\n"], ["\n    margin: 5px 0 10px 0;\n    font-size: 16px;\n    color: #111719;\n"])));
var Price = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: 14px;\n    color: #858992;\n"], ["\n    font-size: 14px;\n    color: #858992;\n"])));
export var Options = React.memo(function (props) {
    var options = props.options, onSelect = props.onSelect, selected = props.selected, _a = props.size, size = _a === void 0 ? "sm" : _a;
    //logger.info("Render options", options, selected)
    return React.createElement(React.Fragment, null, options.map(function (option) {
        var items = option.items;
        //const selected: MenuItemOptionItem[] = [items[0]];
        return React.createElement(Option, { key: option.id, size: size, option: option, items: _.sortBy(items, "sort"), onSelect: onSelect, selected: selected, RenderComponent: option.type == "single" ? SingleChoiceRendererWithPrice : MultipleChoiceRendererWithPrice });
    }));
});
export var Option = React.memo(function (props) {
    var option = props.option, hideName = props.hideName, className = props.className, items = props.items, onSelect = props.onSelect, RenderComponent = props.RenderComponent, size = props.size;
    var enabledItems = sortBy(items.filter(function (item) { return item.enabled; }), "sort");
    if (!enabledItems.length) {
        return null;
    }
    var selected = [items[0]];
    return React.createElement(Container, null,
        !hideName && React.createElement(Title, null, option.name),
        React.createElement(Box, { flexDirection: "column", gap: "8px", className: className }, enabledItems.map(function (item) {
            var renderProps = {
                item: item,
                items: items,
                selected: selected,
                onSelect: onSelect,
                size: size,
                option: option
            };
            return React.createElement(RenderComponent, __assign({}, renderProps));
        })));
});
var SingleChoiceRenderer = React.memo(function (props) {
    var item = props.item, items = props.items, selected = props.selected, onSelect = props.onSelect, size = props.size, option = props.option;
    var isChecked = selected.find(function (it) { return it.id == item.id; }) !== undefined;
    logger.info("[SingleChoiceRenderer]", selected);
    // const removeItemsFromSelected = () => {
    //     return selected.slice(0).filter(itm => items.find(el => el.id == itm.id) == undefined)
    // }
    return React.createElement(RadioInput, { size: size, value: item.id, checked: isChecked, label: item.info.name, onClick: function (value) {
            //logger.info("Selected", selected, props);
            //const update = removeItemsFromSelected();//selected.slice(0);//removeItemsFromSelected();//selected.filter(it => items.find(item => item.id == it.id) == undefined);
            //update.push(Object.assign({}, item, {
            optionId: option.id;
            //}))
            //logger.info("Updated", update);
            //onSelect(update);
        } });
});
var MultipleChoiseRenderer = React.memo(function (props) {
    var item = props.item, items = props.items, selected = props.selected, onSelect = props.onSelect, size = props.size, option = props.option;
    var addToChecked = function (item) {
        var update = selected.slice(0);
        update.push(Object.assign({}, item, {
            optionId: option.id
        }));
        onSelect(update);
    };
    var removeFromChecked = function (item) {
        var update = selected.slice(0);
        onSelect(update.filter(function (it) { return it.id !== item.id; }));
    };
    var isChecked = selected.find(function (it) { return it.id == item.id; }) !== undefined;
    logger.info("[MultipleChoiseRenderer]", selected, isChecked);
    return React.createElement(Checkbox, { size: size, value: item.id, checked: isChecked, label: item.info.name, onChange: function (checked) {
            if (checked) {
                addToChecked(item);
            }
            else {
                removeFromChecked(item);
            }
        } });
});
var SingleChoiceRendererWithPrice = React.memo(function (props) {
    var item = props.item, items = props.items, selected = props.selected;
    var isSelected = selected.find(function (it) { return it.id == item.id; }) !== undefined;
    var selectedItem = items.find(function (it) { return selected.find(function (sl) { return sl.id == it.id; }) !== undefined; });
    var calculatePriceLabel = function () {
        if (!selectedItem) {
            return React.createElement(PriceWithCurrency, { prefix: "\u00A0+\u00A0", value: item.price });
        }
        if (selectedItem.price > item.price) {
            return React.createElement(PriceWithCurrency, { prefix: "\u00A0-\u00A0", value: selectedItem.price - item.price });
        }
        else if (selectedItem.price == item.price) {
            return "";
        }
        return React.createElement(PriceWithCurrency, { prefix: "\u00A0+\u00A0", value: item.price - selectedItem.price });
    };
    return React.createElement(Box, { flex: 1, align: "center" },
        React.createElement(SingleChoiceRenderer, __assign({}, props)),
        !isSelected && React.createElement(Price, { flex: 1, justify: "flex-end" }, calculatePriceLabel()));
});
var MultipleChoiceRendererWithPrice = React.memo(function (props) {
    var item = props.item, items = props.items, selected = props.selected;
    var isSelected = selected.find(function (it) { return it.id == item.id; }) !== undefined;
    //logger.info("[MultipleChoiceRendererWithPrice]", item, isSelected);    
    var calculatePriceLabel = function () {
        //logger.info("[LabelWithPrice]", props);
        var suffix = "";
        if (item.price > 0) {
            suffix = !isSelected ? React.createElement(PriceWithCurrency, { prefix: "\u00A0+\u00A0", value: item.price }) : "";
        }
        return suffix;
    };
    return React.createElement(Box, { flex: 1, align: "center", style: {
            color: "#858992"
        } },
        React.createElement(MultipleChoiseRenderer, __assign({}, props)),
        !isSelected && React.createElement(Price, { flex: 1, justify: "flex-end" }, calculatePriceLabel()));
});
var RequiredOptionContainer = styled(Container)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    flex: 1;\n"], ["\n    flex: 1;\n"])));
var RequiredContent = styled(Box)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    flex-direction: row;\n    font-weight: bold;\n    & > span {\n        border-right: #858992 solid 1px;\n    }\n    & > span:last-child{\n        border-right: 0;        \n    }\n"], ["\n    flex-direction: row;\n    font-weight: bold;\n    & > span {\n        border-right: #858992 solid 1px;\n    }\n    & > span:last-child{\n        border-right: 0;        \n    }\n"])));
var DetailsText = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 14px;\n    color: #858992;\n    font-weight: normal;\n    margin-top: 3px;\n    margin-left: 16px;\n"], ["\n    font-size: 14px;\n    color: #858992;\n    font-weight: normal;\n    margin-top: 3px;\n    margin-left: 16px;\n"])));
export var RequiredOption = React.memo(function (props) {
    var option = props.option, hideName = props.hideName, className = props.className, items = props.items, selected = props.selected, onSelect = props.onSelect;
    var RenderComponent = option.type == "single" ? SingleChoiceRenderer : MultipleChoiseRenderer;
    var selectedItem = items.find(function (it) { return selected.find(function (sl) { return sl.id == it.id; }) !== undefined; });
    var calculatePriceLabel = function (item) {
        console.log("[MenuItemOptionItem]", item, selectedItem);
        if (!selectedItem) {
            return React.createElement(PriceWithCurrency, { prefix: "\u00A0-\u00A0", value: item.price });
        }
        if (selectedItem.price > item.price) {
            return React.createElement(PriceWithCurrency, { prefix: "\u00A0-\u00A0", value: selectedItem.price - item.price });
        }
        else if (selectedItem.price == item.price) {
            return "";
        }
        return React.createElement(PriceWithCurrency, { prefix: "\u00A0+\u00A0", value: item.price - selectedItem.price });
    };
    return React.createElement(RequiredOptionContainer, null,
        !hideName && React.createElement(Title, null, option.name),
        React.createElement(RequiredContent, { flexDirection: items.length > 2 ? "column" : "row", gap: "5px", className: className, style: {
                fontWeight: "bold"
            } }, sortBy(items, "sort").map(function (item) { return React.createElement(Box, { flex: 1, flexDirection: "column", key: item.id, style: {
                minHeight: "20px"
            } },
            React.createElement(RenderComponent, { option: option, item: item, items: items, selected: selected, onSelect: onSelect }),
            React.createElement(DetailsText, null,
                React.createElement(Box, null,
                    item.info.description,
                    " ",
                    calculatePriceLabel(item),
                    React.createElement("span", { style: { color: "#fbfbfb" } }, "$")))); })));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
