export function loadGoogleFont(fontFamily) {
    if (!fontFamily || fontFamily.toLowerCase() == "rubik") {
        return;
    }
    // const fontId = fontFamily.trim().replace(/ /g,"").toLowerCase();
    // const fontElement = document.getElementById(fontId);
    // if(!fontElement){
    //     const styleEl = document.createElement("style");
    //     styleEl.setAttribute("href", `http://fonts.googleapis.com/css?family="${encodeURIComponent(fontFamily)}"`);
    //     styleEl.setAttribute("rel", "stylesheet");
    //     styleEl.setAttribute("media", "all")
    //     styleEl.id = fontId;
    //     const head = document.head || document.getElementsByTagName('head')[0];
    //     head.appendChild(styleEl);
    // }
    WebFont.load({
        google: {
            families: ['Rubik', fontFamily]
        }
    });
}
