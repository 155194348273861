export var currencies = require("../../assets/json/currencies.json");
export var CurrencySymbolPlacements = {
    AED: '# !',
    AFN: '#!',
    ALL: '# !',
    AMD: '#!',
    ANG: '!#',
    AOA: '!#',
    ARS: '!#',
    AUD: '!#',
    AWG: '!#',
    AZN: '!#',
    BAM: '!#',
    BBD: '!#',
    BDT: '!#',
    BGN: '!#',
    BHD: '!#',
    BIF: '!#',
    BMD: '!#',
    BND: '!#',
    BOB: '!#',
    BRL: '!#',
    BSD: '!#',
    BTC: '!#',
    BTN: '!#',
    BWP: '!#',
    BYR: '!#',
    BYN: '!#',
    BZD: '!#',
    CAD: '!#',
    CDF: '!#',
    CHF: '! #',
    CLP: '!#',
    CNY: '!#',
    COP: '!#',
    CRC: '!#',
    CUC: '!#',
    CUP: '!#',
    CVE: '!#',
    CZK: '# !',
    DJF: '!#',
    DKK: '# !',
    DOP: '!#',
    DZD: '!#',
    EEK: '!#',
    EGP: '!#',
    ERN: '!#',
    ETB: '!#',
    ETH: '!#',
    EUR: '!#',
    FJD: '!#',
    FKP: '!#',
    GBP: '!#',
    GEL: '!#',
    GGP: '!#',
    GHC: '!#',
    GHS: '!#',
    GIP: '!#',
    GMD: '!#',
    GNF: '!#',
    GTQ: '!#',
    GYD: '!#',
    HKD: '!#',
    HNL: '!#',
    HRK: '!#',
    HTG: '!#',
    HUF: '!#',
    IDR: '!#',
    ILS: '!#',
    IMP: '!#',
    INR: '!#',
    IQD: '!#',
    IRR: '!#',
    ISK: '# !',
    JEP: '!#',
    JMD: '!#',
    JOD: '!#',
    JPY: '!#',
    KES: '!#',
    KGS: '!#',
    KHR: '!#',
    KMF: '!#',
    KPW: '!#',
    KRW: '!#',
    KWD: '! #',
    KYD: '!#',
    KZT: '!#',
    LAK: '!#',
    LBP: '!#',
    LKR: '!#',
    LRD: '!#',
    LSL: '!#',
    LTC: '!#',
    LTL: '!#',
    LVL: '!#',
    LYD: '!#',
    MAD: '!#',
    MDL: '!#',
    MGA: '!#',
    MKD: '!#',
    MMK: '!#',
    MNT: '!#',
    MOP: '!#',
    MRO: '!#',
    MRU: '!#',
    MUR: '!#',
    MVR: '!#',
    MWK: '!#',
    MXN: '!#',
    MYR: '!#',
    MZN: '!#',
    NAD: '!#',
    NGN: '!#',
    NIO: '!#',
    NOK: '# !',
    NPR: '!#',
    NZD: '!#',
    OMR: '!#',
    PAB: '!#',
    PEN: '!#',
    PGK: '!#',
    PHP: '!#',
    PKR: '!#',
    PLN: '!#',
    PYG: '!#',
    QAR: '!#',
    RMB: '!#',
    RON: '!#',
    RSD: '!#',
    RUB: '# !',
    RWF: '!#',
    SAR: '!#',
    SBD: '!#',
    SCR: '!#',
    SDG: '!#',
    SEK: '# !',
    SGD: '!#',
    SHP: '!#',
    SLL: '!#',
    SOS: '!#',
    SRD: '!#',
    SSP: '!#',
    STD: '!#',
    STN: '!#',
    SVC: '!#',
    SYP: '!#',
    SZL: '!#',
    THB: '!#',
    TJS: '!#',
    TMT: '!#',
    TND: '!#',
    TOP: '!#',
    TRL: '!#',
    TRY: '# !',
    TTD: '!#',
    TVD: '!#',
    TWD: '! #',
    TZS: '!#',
    UAH: '!#',
    UGX: '!#',
    USD: '!#',
    UYU: '!#',
    UZS: '!#',
    VEF: '!#',
    VND: '!#',
    VUV: '!#',
    WST: '!#',
    XAF: '!#',
    XBT: '!#',
    XCD: '!#',
    XOF: '!#',
    XPF: '!#',
    YER: '!#',
    ZAR: '!#',
    ZMK: '!#',
    ZMW: '!#',
    ZWD: '!#',
    ZWL: '!#'
};
export var getPlacement = function (pattern) {
    var placement = null;
    switch (pattern) {
        case "!#":
            placement = "left";
            break;
        case "! #":
            placement = "left";
            break;
        case "#!":
            placement = "right";
            break;
        case "# !":
            placement = "right";
            break;
    }
    return placement;
};
export function formatCurrency(value, code) {
    var currency = currencies.find(function (item) { return item.code.toLowerCase() == code.toLowerCase(); });
    var placement = getPlacement(CurrencySymbolPlacements[currency.code.toLowerCase()]);
    return placement == "left" ? "".concat(Number(value).toFixed(currency.decimalDigits), " ").concat(currency.symbolNative) : "".concat(currency.symbolNative, " ").concat(Number(value).toFixed(currency.decimalDigits));
}
export function getCurrencyFormat(value, code) {
    var currency = currencies.find(function (item) { return item.code.toLowerCase() == code.toLowerCase(); });
    var placement = getPlacement(CurrencySymbolPlacements[currency.code.toUpperCase()]);
    return {
        currency: currency.symbolNative,
        value: Number(value).toFixed(currency.decimalDigits),
        placement: placement
    };
}
