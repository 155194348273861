var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from "eventemitter3";
export var AppEvents;
(function (AppEvents) {
    AppEvents["REFRESH_OPTIONS"] = "refresh_options";
    AppEvents["REFRESH_MENUS"] = "refresh_menus";
})(AppEvents || (AppEvents = {}));
var AppEventEmitter = /** @class */ (function (_super) {
    __extends(AppEventEmitter, _super);
    function AppEventEmitter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppEventEmitter.prototype.dispatch = function (event, data) {
        this.emit(event, data);
    };
    return AppEventEmitter;
}(EventEmitter));
var appEventEmitter = new AppEventEmitter();
export function useAppEventEmitter() {
    return appEventEmitter;
}
