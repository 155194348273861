import * as React from "react";
import { ReactSortable } from "react-sortablejs";
import * as _ from "underscore";
var logger = require("js-logger").get("Sortable");
export var Sortable = React.memo(function (props) {
    var sortItems = function (items) {
        return sortKey ? _.sortBy(items, sortKey) : sortFn ? sortFn(items) : items;
    };
    var items = props.items, sortKey = props.sortKey, renderItem = props.renderItem, _a = props.handle, handle = _a === void 0 ? ".drag-handle" : _a, onSort = props.onSort, sortFn = props.sortFn;
    var _b = React.useState(sortItems(items)), localItems = _b[0], setLocalItems = _b[1];
    var updateItems = function (items) {
        //logger.info("Sorted items");
        var update = items.map(function (item, index) {
            var _a;
            return Object.assign({}, item, (_a = {},
                _a[sortKey] = index,
                _a));
        });
        //logger.info("Updated sort items", update);
        setLocalItems(update);
    };
    React.useEffect(function () {
        setLocalItems(sortItems(items));
    }, [items]);
    return React.createElement(ReactSortable, { animation: 200, handle: handle, list: localItems, setList: updateItems, onEnd: function (event) {
            setTimeout(function () {
                onSort(localItems, event);
            }, 0);
        } }, localItems.map(function (item, index) {
        return React.createElement(React.Fragment, { key: item.id }, renderItem(item, index));
    }));
});
