var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { CFormTextarea, CFormLabel } from "@coreui/react";
import { useController } from "react-hook-form";
import { randomString } from "../../../utils/StringUtils";
var logger = require("js-logger").get("FormTextInput");
import styled from "styled-components";
var OpenInputContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex: 1;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 103;\n    background-color: #FBFBFB;\n"], ["\n    flex: 1;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 103;\n    background-color: #FBFBFB;\n"])));
var FormTextAreaInput = function (props) {
    var control = props.control, name = props.name, defaultValue = props.defaultValue, label = props.label, type = props.type, rules = props.rules, disabled = props.disabled;
    var _a = React.useState(false), isFocused = _a[0], setIsFocused = _a[1];
    var _b = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _c = _b.field, onChange = _c.onChange, onBlur = _c.onBlur, _d = _c.value, value = _d === void 0 ? defaultValue ? defaultValue : "" : _d, ref = _c.ref, fieldState = _b.fieldState;
    var inputId = props.id ? props.id : randomString(6);
    var className = isFocused ? "form-control-focus" : "";
    //logger.info("Errors", fieldState.error);
    var handleFocus = function (e) {
        setIsFocused(true);
    };
    var handleBlur = function (e) {
        setIsFocused(false);
    };
    return React.createElement(React.Fragment, null,
        label && React.createElement(CFormLabel, { htmlFor: inputId }, label),
        React.createElement(CFormTextarea, { onChange: onChange, onBlur: handleBlur, onFocus: handleFocus, value: value, ref: ref, id: inputId, type: type, disabled: disabled, invalid: fieldState.invalid }));
};
export default FormTextAreaInput;
var templateObject_1;
