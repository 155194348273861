import * as React from "react";
import { CFormLabel } from "@coreui/react";
import { useController } from "react-hook-form";
import { randomString } from "../../../utils/StringUtils";
import { ContentEditor } from "../../../common/ContentEditor";
var logger = require("js-logger").get("FormTextInput");
var FormEditorInput = function (props) {
    var control = props.control, name = props.name, defaultValue = props.defaultValue, label = props.label, type = props.type, rules = props.rules, disabled = props.disabled;
    var _a = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, _c = _b.value, value = _c === void 0 ? defaultValue ? defaultValue : "" : _c, ref = _b.ref, fieldState = _a.fieldState;
    var inputId = props.id ? props.id : randomString(6);
    return React.createElement(React.Fragment, null,
        label && React.createElement(CFormLabel, { htmlFor: inputId }, label),
        React.createElement(ContentEditor, { onChange: onChange, 
            // onBlur={onBlur}
            value: value }));
};
export default FormEditorInput;
