var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import FlexItem, { Box } from "../common/FlexItem";
import { CCard, CCardBody, CFormSwitch } from "@coreui/react";
import { useWatch, useFormContext, useController } from "react-hook-form";
import FormTextInput from "../common/form/FormTextInput";
import FormCheckboxInput from "../common/form/FormCheckboxInput";
import FormEditorInput from "../common/form/FormEditorInput";
import styled from "styled-components";
import { getMenuOptionsRequest } from "../../request/options.request";
import { useHttpRequestHandler } from "../../service/network.service";
import LoadingBox from "../common/LoadingBox";
import { useTranslation } from "react-i18next";
import FormSwitchInput from "../common/form/FormSwitchInput";
var logger = require("js-logger").get("DishForm");
import FormCurrencyInput from "../common/form/FormCurrencyInput";
import * as _ from "underscore";
import { FormDropZoneOrPhoto } from "../common/form/FormDropZoneOrPhoto";
import { sortBy } from "underscore";
import { Button } from "../../common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft } from "@fortawesome/free-solid-svg-icons";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n display: flex;\n padding-left: 10px;\n flex: 1\n"], ["\n display: flex;\n padding-left: 10px;\n flex: 1\n"])));
var ButtonsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    margin-top: 10px;\n    flex: 1\n"], ["\n    display: flex;\n    margin-top: 10px;\n    flex: 1\n"])));
export var defaultDishProperties = [
    {
        name: "prepare_time",
        defaultValue: 0
    },
    {
        name: "alcohol",
        defaultValue: 0
    },
    {
        name: "weight",
        label: "Weight/Volume",
        placeholder: "30 ml, 40g, 1 kg",
        defaultValue: 0
    },
    {
        name: "volume",
        defaultValue: 0
    },
    {
        name: "kcal",
        defaultValue: 0
    },
    {
        name: "vat",
        defaultValue: 0
    },
    {
        name: "pos_id",
        defaultValue: ""
    },
    {
        name: "youtube",
        defaultValue: ""
    }
];
export var defaultDishLabels = [
    {
        name: "vegetarian",
        label: "Vegeterian",
        defaultValue: false
    },
    {
        name: "gluten_free",
        label: "Gluten free",
        defaultValue: false
    },
    {
        name: "spicy",
        label: "Spicy",
        defaultValue: false
    },
    {
        name: "middle_spicy",
        label: "Middle spicy",
        defaultValue: false
    },
    {
        name: "recommended",
        label: "Recommended",
        defaultValue: false
    },
    {
        name: "vegan",
        label: "Vegan",
        defaultValue: false
    },
    {
        name: "new",
        label: "New",
        defaultValue: false
    },
];
export var defaultDishAllergens = [
    {
        name: "cereals_containing_gluten",
        label: "Cereals containig gluten",
        defaultValue: false
    },
    {
        name: "crustaceans",
        label: "Crustceans",
        defaultValue: false
    },
    {
        name: "eggs",
        label: "Eggs",
        defaultValue: false
    },
    {
        name: "fish",
        label: "Fish",
        defaultValue: false
    },
    {
        name: "penuts",
        label: "Peanuts",
        defaultValue: false
    },
    {
        name: "soyabeans",
        label: "Soyabeans",
        defaultValue: false
    },
    {
        name: "milk",
        label: "Milk",
        defaultValue: false
    },
    {
        name: "nuts",
        label: "Nuts",
        defaultValue: false
    },
    {
        name: "celery",
        label: "Celery",
        defaultValue: false
    },
    {
        name: "mustard",
        label: "Mustard",
        defaultValue: false
    },
    {
        name: "sesame_seeds",
        label: "Sesame seeds",
        defaultValue: false
    },
    {
        name: "sulphur_dioxide_and_sulphites",
        label: "Sulphur dioxide and sulphites",
        defaultValue: false
    },
    {
        name: "lupin",
        label: "Lupin",
        defaultValue: false
    },
    {
        name: "mollusks",
        label: "Mollusks",
        defaultValue: false
    },
    {
        name: "honey",
        label: "Honey",
        defaultValue: false
    },
];
export var DishFormInputs = React.memo(function (props) {
    var control = props.control;
    var t = useTranslation().t;
    return React.createElement(React.Fragment, null,
        React.createElement(FlexItem, { flexDirection: "column", flex: 1 },
            React.createElement(FlexItem, { flexDirection: "row", gap: 10 },
                React.createElement(FlexItem, { flex: 1, flexDirection: "column" },
                    React.createElement(FormTextInput, { control: control, label: t("dish.name.input.label"), name: "name", rules: {
                            required: true
                        } })),
                React.createElement(FlexItem, { flex: 1, flexDirection: "column" },
                    React.createElement(FormCurrencyInput, { control: control, label: t("dish.price.input.label"), name: "price", rules: {
                            required: true
                        } })))),
        React.createElement(FlexItem, { flexDirection: "column", flex: 1 },
            React.createElement(FormTextInput, { control: control, label: t("dish.details.input.label"), name: "details" })),
        React.createElement(FlexItem, { flexDirection: "row" },
            React.createElement(FlexItem, { flex: 1, flexDirection: "column" },
                React.createElement(FormEditorInput, { control: control, label: t("dish.description.input.label"), name: "description" }))),
        React.createElement(Box, null,
            React.createElement(FormDropZoneOrPhoto, { control: control, name: "image" })));
});
export var DishFormLabels = React.memo(function (props) {
    var control = props.control;
    var t = useTranslation().t;
    var field = useController({
        control: control,
        name: "labels"
    }).field;
    return React.createElement("div", { style: {
            marginTop: "10px"
        } },
        React.createElement("h6", null, t("dish.labels.text")),
        defaultDishLabels.map(function (label, index) {
            //logger.info("Field", field, "index", index)
            var prop = field;
            return React.createElement("div", { key: label.name, style: {
                    display: "inline-block",
                    width: "48%",
                    marginRight: index % 2 == 0 ? "4%" : 0
                } },
                React.createElement(FormCheckboxInput, { key: label.name, name: "labels.".concat(label.name), label: t("dish.label.".concat(label.name, ".label")), control: control }));
        }));
});
export var DishFormAllergens = React.memo(function (props) {
    var control = props.control;
    var field = useController({
        control: control,
        name: "allergens"
    }).field;
    var t = useTranslation().t;
    return React.createElement("div", { style: {
            marginTop: "10px"
        } },
        React.createElement("h6", null, t("dish.allergens.text")),
        defaultDishAllergens.map(function (allergen, index) {
            return React.createElement("div", { key: allergen.name, style: {
                    display: "inline-block",
                    width: "48%",
                    marginRight: index % 2 == 0 ? "4%" : 0
                } },
                React.createElement(FormCheckboxInput, { name: "allergens.".concat(allergen.name), label: t("dish.allergen.".concat(allergen.name, ".label")), control: control }));
        }));
});
export var DishFormProperties = React.memo(function (props) {
    var control = props.control;
    var t = useTranslation().t;
    var field = useController({
        control: control,
        name: "properties"
    }).field;
    // logger.info("Props", props);
    // logger.info("Peperties", properties);
    return React.createElement("div", { style: {
            marginTop: "10px"
        } }, defaultDishProperties.map(function (prop, index) {
        //logger.info("Field", field, "index", index)
        //const prop = field as Record<string, any>;
        return React.createElement("div", { key: prop.name, style: {
                display: "inline-block",
                width: "48%",
                marginRight: index % 2 == 0 ? "4%" : 0,
                marginTop: "10px"
            } },
            React.createElement(FormTextInput, { name: "properties.".concat(prop.name), label: t("dish.property.".concat(prop.name, ".label")), control: control, placeholder: prop.placeholder, rules: {
                    required: prop.required === true
                } }));
    }));
});
var OptionContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background-color: #fff;\n    padding: 5px 0;\n\n"], ["\n    background-color: #fff;\n    padding: 5px 0;\n\n"])));
var OptionItem = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin: 5px 0;\n"], ["\n    margin: 5px 0;\n"])));
var StyledFormSwitchInput = styled(FormSwitchInput)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["    \n    color: ", ";\n    font-weight: ", ";\n"], ["    \n    color: ", ";\n    font-weight: ", ";\n"])), function (props) { return props.diff ? "#2b4beb" : ""; }, function (props) { return props.diff ? 500 : "inherit"; });
var StyledFormTextInput = styled(FormTextInput)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["    \n    color: ", ";\n    font-weight: ", ";\n"], ["    \n    color: ", ";\n    font-weight: ", ";\n"])), function (props) { return props.diff ? "#2b4beb" : ""; }, function (props) { return props.diff ? 500 : "inherit"; });
var StyledFormCurrencyInput = styled(FormCurrencyInput)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    color: ", ";\n    font-weight: ", ";\n"], ["\n    color: ", ";\n    font-weight: ", ";\n"])), function (props) { return props.diff ? "#2b4beb" : ""; }, function (props) { return props.diff ? 500 : "inherit"; });
var StyledFormCheckboxInput = styled(FormCheckboxInput)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    color: ", ";\n    font-weight: ", ";\n"], ["\n    color: ", ";\n    font-weight: ", ";\n"])), function (props) { return props.diff ? "#2b4beb" : ""; }, function (props) { return props.diff ? 500 : "inherit"; });
var OptionName = styled(Box)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    margin-bottom: 0.5rem\n"], ["\n    margin-bottom: 0.5rem\n"])));
var isDiff = function (origin, override) {
    if (override == undefined || origin == undefined) {
        return false;
    }
    return origin != override;
};
var isDefaultDiff = function (origin, override) {
    return override == true && !origin;
};
export var DishFormOption = React.memo(function (props) {
    var option = props.option, control = props.control, index = props.index, _a = props.options, options = _a === void 0 ? [] : _a, resetOption = props.resetOption;
    var t = useTranslation().t;
    var _b = React.useState(false), hasDiff = _b[0], setHasDiff = _b[1];
    var _c = useFormContext(), getValues = _c.getValues, setValue = _c.setValue;
    var itemEnabled = option.items.map(function (item) { return useWatch({
        control: control,
        name: "optionItem.".concat(item.id, ".enabled")
    }); });
    var formOptionItemValues = useWatch({
        control: control,
        name: "optionItem"
    });
    //logger.info("FormOPtionItemValues", formOptionItemValues);
    var enabled = itemEnabled.includes(true);
    var enableOptionItems = function (e) {
        //logger.info("enableOptionItems")        
        var defaultValue = e.target.checked;
        option.items.forEach(function (item) {
            var name = "optionItem.".concat(item.id, ".enabled");
            //logger.info("name", name, "defaultValue", defaultValue);
            setValue(name, defaultValue, {
                shouldDirty: true,
                shouldTouch: true
            });
        });
        //setValue(`option.${option.id}.enabled`, defaultValue);
    };
    var setItemAsDefault = function (id) { return function (e) {
        var target = e.target;
        if (target.checked) {
            option.items.forEach(function (item) {
                var name = "optionItem.".concat(item.id, ".default");
                var isDefault = item.id == id;
                //logger.info("name", name, "defaultValue", defaultValue);                
                setValue(name, isDefault, {
                    shouldDirty: true,
                    shouldTouch: true
                });
            });
        }
        else {
            var name_1 = "optionItem.".concat(id, ".default");
            setTimeout(function () {
                setValue(name_1, true);
            }, 0);
        }
    }; };
    var checkHasDiff = function () {
        var diffs = [];
        option.items.forEach(function (item) {
            //const exist = options.find((op: any) => op.option_item_id == item.id) || { data: {} };
            var posIdDiff = isDiff(item.posId, formOptionItemValues[item.id].posId);
            var priceIdDiff = isDiff(item.price, formOptionItemValues[item.id].price);
            var defaultDiff = isDefaultDiff(item.default, formOptionItemValues[item.id].default);
            if (posIdDiff || priceIdDiff || defaultDiff) {
                diffs.push(true);
            }
        });
        setHasDiff(diffs.includes(true));
    };
    React.useEffect(function () {
        checkHasDiff();
    }, []);
    React.useEffect(function () {
        checkHasDiff();
    }, [formOptionItemValues]);
    return React.createElement(Box, { display: "block" },
        React.createElement(OptionName, { gap: "1rem", align: "center" },
            React.createElement(CFormSwitch, { label: option.name, onChange: enableOptionItems, checked: enabled }),
            hasDiff && React.createElement(Button, { buttonType: "button", className: "btn-primary", onClick: function () { resetOption(option.id); } },
                React.createElement(FontAwesomeIcon, { icon: faArrowRotateLeft }))),
        enabled && React.createElement(CCard, null,
            React.createElement(CCardBody, null, _.sortBy(option.items, "sort").map(function (item, idx) {
                var exist = options.find(function (op) { return op.option_item_id == item.id; }) || { data: {} };
                //logger.info("Exists 1", item);
                //logger.info("Exists 2", exist);                                        
                return React.createElement(OptionItem, { display: "block", key: item.id },
                    React.createElement(Box, { gap: "1rem" },
                        React.createElement(Box, { flex: 0.3 },
                            React.createElement(StyledFormSwitchInput, { control: control, name: "optionItem.".concat(item.id, ".enabled"), label: item.name })),
                        React.createElement(Box, { flex: 1, justify: "flex-end", gap: "5px" },
                            React.createElement(Box, { display: "inline-block" },
                                React.createElement(StyledFormTextInput, { diff: isDiff(item.posId, formOptionItemValues[item.id].posId), control: control, name: "optionItem.".concat(item.id, ".posId"), placeholder: t("menu.option.posId.placeholder"), size: "sm" })),
                            React.createElement(Box, { display: "inline-block" },
                                React.createElement(StyledFormCurrencyInput, { diff: isDiff(item.price, formOptionItemValues[item.id].price), control: control, name: "optionItem.".concat(item.id, ".price"), placeholder: t("menu.option.price.placeholder"), size: "sm", rules: {
                                        required: true,
                                        minLength: 1
                                    } })),
                            React.createElement(Box, { display: "inline-block" },
                                React.createElement(StyledFormCheckboxInput, { diff: isDefaultDiff(item.default, formOptionItemValues[item.id].default), control: control, label: t("default.text"), name: "optionItem.".concat(item.id, ".default"), onChage: setItemAsDefault(item.id) })))));
            }))));
});
export var DishFormOptions = React.memo(function (props) {
    var menuId = props.menuId, categoryId = props.categoryId, control = props.control;
    var menuOptionsState = useHttpRequestHandler(getMenuOptionsRequest(menuId, categoryId));
    var _a = useFormContext(), setValue = _a.setValue, getValues = _a.getValues;
    var optonItems = {};
    var t = useTranslation().t;
    React.useEffect(function () {
        var options = getValues("options") || [];
        if (menuOptionsState.result) {
            menuOptionsState.result.forEach(function (menuOption, index) {
                menuOption.items.map(function (item) {
                    var exist = options.find(function (op) { return op.option_item_id == item.id; }) || {};
                    //logger.info("optoins", options, "menuOptionItem", item)
                    optonItems[item.id] = Object.assign({}, {
                        enabled: false,
                        posId: item.posId,
                        price: item.price,
                        option_item_id: item.id,
                        default: item.default
                    }, exist.data);
                });
            });
            setValue("optionItem", optonItems);
            logger.info("Options", optonItems);
        }
    }, [menuOptionsState.result]);
    if (menuOptionsState.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    var resetOption = function (option_id) {
        var options = menuOptionsState.result;
        var option = options.find(function (option) { return option.id == option_id; });
        //const [optionItems] = getValues(["optionItem"]);
        var optionItems = {};
        logger.info("Reset options", option_id, option);
        option.items.forEach(function (optionItem) {
            optionItems[optionItem.id] = {
                enabled: optionItem.enabled,
                posId: optionItem.posId,
                price: optionItem.price,
                option_item_id: optionItem.id,
                default: optionItem.default
            };
            setValue("optionItem.".concat(optionItem.id, ".price"), optionItem.price);
            setValue("optionItem.".concat(optionItem.id, ".posId"), optionItem.posId);
            setValue("optionItem.".concat(optionItem.id, ".default"), optionItem.default);
        });
        logger.info("Reset optons", optionItems);
        //setValue("optionItem", optonItems);
    };
    return React.createElement(OptionContainer, null,
        React.createElement("h6", null, t("dish.options.text")),
        menuOptionsState.result && sortBy(menuOptionsState.result, "sort").map(function (option, index) { return React.createElement(DishFormOption, { key: option.id, control: control, option: option, index: index, options: getValues("options") || [], resetOption: resetOption }); }));
});
export var DishTags = React.memo(function (props) {
    var control = props.control, category = props.category;
    var t = useTranslation().t;
    return React.createElement(OptionContainer, null,
        React.createElement("h6", null, t("dish.tags.text")),
        React.createElement(Box, { gap: "1rem" }, category.tags.map(function (tag) { return React.createElement(FormCheckboxInput, { key: tag.id, name: "tags.".concat(tag.id), label: tag.tag, control: control }); })));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
