import { DISH_ENDPOINT } from "../constats/ApiEndpoints";
import { userStore } from "../store/user.store";
import { fromJsonToForm, convertModelToFormData } from "../utils/FormUtils";
import * as _ from "underscore";
import { dataURItoBlob } from "../utils/BlobUtils";
var logger = require("js-logger").get("dish.request");
import { randomString } from "../utils/StringUtils";
export function createDishItemRequest(companyId, data) {
    //console.log("Data", serialize(data));
    //return;
    var token = userStore.getState().token;
    if (_.isString(data.image)) {
        data.image = new File([dataURItoBlob(data.image)], "image_".concat(randomString(5), ".png"));
    }
    var formData = new FormData();
    fromJsonToForm(data, formData);
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(DISH_ENDPOINT, "?token=").concat(token),
        method: "POST",
        data: convertModelToFormData(data)
    };
}
export function updateDishItemRequest(companyId, dishId, data) {
    // console.log("Data", serialize(data, {
    //     dotsForObjectNotation: true
    // }));
    // return;
    if (_.isString(data.image)) {
        data.image = new File([dataURItoBlob(data.image)], "image_".concat(randomString(5), ".png"));
    }
    logger.info("CCCC", data.image);
    var formData = new FormData();
    fromJsonToForm(data, formData);
    var token = userStore.getState().token;
    if (data.id) {
        delete data.id;
    }
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(DISH_ENDPOINT, "/").concat(dishId, "?token=").concat(token),
        method: "PUT",
        data: convertModelToFormData(data)
    };
}
