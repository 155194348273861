var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { CFormInput } from "@coreui/react";
import * as React from "react";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { APP } from "../../constats/Routes";
import { updateMyPhoneRequest } from "../../request/me.request";
import { verifyRequest } from "../../request/user.request";
import { HttpApiRequest, useHttpRequesPrepareHandler } from "../../service/network.service";
import { userStore } from "../../store/user.store";
import { EditableInput } from "../common/EditableInput";
import FlexItem, { Box } from "../common/FlexItem";
import InputCode from "../common/InputCode";
import LoadingBox from "../common/LoadingBox";
import { Button } from "../../common/Button";
var logger = require("js-logger").get("VerifyCodeScreen");
// const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
import { PhoneNumberUtil } from "google-libphonenumber";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { createVerificationRequest } from "../../request/user.request";
import { wrapNotVerified } from "../common/NotVerified";
import styled from "styled-components";
var phoneUtil = PhoneNumberUtil.getInstance();
var formatTime = function (time) { return "".concat(time < 10 ? "0" + time : time); };
var VerifySavePhoneText = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: red;\n    margin: 0.5rem 0;\n"], ["\n    color: red;\n    margin: 0.5rem 0;\n"])));
var VerifyCodeScreen = React.memo(function (props) {
    var _a = React.useState(Date.now()), timer = _a[0], setTimer = _a[1];
    var _b = React.useState("view"), phoneEditStatus = _b[0], setPhoneEditStatus = _b[1];
    var timerApi = React.useRef();
    var navigate = useNavigate();
    var phone = userStore(function (state) { return state.user.phone; });
    var updateUserInfo = userStore(function (state) { return state.updateUserInfo; });
    var t = useTranslation().t;
    var verifyState = useHttpRequesPrepareHandler();
    var createVerifyState = useHttpRequesPrepareHandler();
    var canSendVerifyState = useHttpRequesPrepareHandler();
    var createCode = function () {
        createVerifyState.run(createVerificationRequest())
            .then(function (res) {
            logger.info("Verification Request Resp", res);
            var data = res;
            setTimer(data.expiration);
        })
            .catch(function (err) {
            logger.error(err);
        });
    };
    var verifyCode = function (code) {
        verifyState.run(verifyRequest(code))
            .then(function () {
            navigate(APP);
        });
    };
    var updateUserPhone = function (phone) {
        HttpApiRequest(updateMyPhoneRequest(phone))
            .promise
            .then(updateUserInfo)
            .catch(function (err) {
            logger.error(err);
        });
    };
    React.useEffect(function () {
        createCode();
    }, []);
    React.useEffect(function () {
        if (timerApi.current) {
            timerApi.current.api.start();
        }
    }, [timer]);
    if (createVerifyState.inProgress || verifyState.inProgress || canSendVerifyState.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    //logger.info("createVerifyState", createVerifyState.result)
    var createCodeResult = createVerifyState.result;
    return React.createElement(Box, { flex: 1, justify: "center", align: "center" },
        React.createElement(FlexItem, { flexDirection: "column", gap: "0.5rem" },
            React.createElement(Box, null, (createCodeResult === null || createCodeResult === void 0 ? void 0 : createCodeResult.phone) && t("verify.code.sent.text", {
                "phone": createCodeResult === null || createCodeResult === void 0 ? void 0 : createCodeResult.phone
            })),
            React.createElement(InputCode, { length: 6, label: t("verify.input.title"), loading: false, onComplete: function (code) {
                    logger.info("Code", code);
                    verifyCode(code);
                } }),
            React.createElement(Box, { align: "center", justify: "flex-end" },
                React.createElement(Countdown, { ref: timerApi, date: timer, renderer: function (data) {
                        return React.createElement(React.Fragment, null,
                            data.total > 0 && React.createElement(Button, { color: "link" }, t("resend.message.text", {
                                "in": "".concat(formatTime(data.minutes), ":").concat(formatTime(data.seconds))
                            })),
                            data.total == 0 && React.createElement(Box, { flexDirection: "column", gap: "0.5rem", align: "flex-end" },
                                React.createElement(EditableInput, { Component: PhoneInput, inputProps: {
                                        inputComponent: CFormInput
                                    }, value: phone, onChange: function (phone) {
                                        updateUserPhone(phone);
                                        logger.info("Phone", phone);
                                    }, validate: function (phone) {
                                        try {
                                            var p = phoneUtil.parse(phone);
                                            return phoneUtil.isValidNumber(p);
                                        }
                                        catch (err) {
                                            return false;
                                        }
                                    }, errorMessage: t("error.invalid.phone.number"), onStatusChange: function (status) {
                                        setPhoneEditStatus(status);
                                    } }),
                                phoneEditStatus == "edit" && React.createElement(VerifySavePhoneText, null, t("verify.phone.save.message")),
                                React.createElement(Button, { disabled: phoneEditStatus == "edit", color: "link", onClick: createCode }, t("resend.button.text"))));
                    } },
                    React.createElement(Button, { disabled: phoneEditStatus == "edit", color: "link", onClick: createCode }, t("resend.button.text"))))));
});
export default wrapNotVerified(VerifyCodeScreen);
var templateObject_1;
