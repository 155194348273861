import * as React from "react";
import { getURLParams } from "../../utils/URLUtils";
import cookiesService from "../../service/cookies.service";
import { MO_ADMIN_TOKEN } from "../../constats/Cookies";
import { useNavigate } from "react-router-dom";
import { APP } from "../../constats/Routes";
import { CAlert } from "@coreui/react";
import LoadingBox from "../common/LoadingBox";
import FlexItem from "../common/FlexItem";
import { useTranslation } from "react-i18next";
var SignUpInviteResponseScreen = React.memo(function () {
    var navigate = useNavigate();
    var params = getURLParams();
    var t = useTranslation().t;
    React.useEffect(function () {
        if (params.token) {
            cookiesService.setCookie(MO_ADMIN_TOKEN, params.token);
            navigate(APP);
        }
        //if()       
        //navigate(APP);
    }, []);
    var isError = function () {
        if (params.errorCode) {
            return true;
        }
        return params.error && params.errorMessage;
    };
    var getErrorMessage = function () {
        if (params.errorCode) {
            var errorCode = params.errorCode;
            return t("signup.invite.".concat(errorCode.toLowerCase(), ".error"));
        }
        return params.error && params.errorMessage;
    };
    if (isError()) {
        return React.createElement(FlexItem, { flexDirection: "column", flex: 1, align: "center", justify: "center" },
            React.createElement(FlexItem, { flexDirection: "column" },
                React.createElement(CAlert, { color: "danger" }, getErrorMessage())));
    }
    return React.createElement(LoadingBox, null);
});
export default SignUpInviteResponseScreen;
