var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
var FULL_DASH_ARRAY = 283;
var BaseTimer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    width: 100%;\n    height: 100%;\n"], ["\n    position: relative;\n    width: 100%;\n    height: 100%;\n"])));
var BaseTimerSvg = styled.svg(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    transform: scaleX(-1);\n"], ["\n    transform: scaleX(-1);\n"])));
var BaseTimerCircle = styled.g(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    fill: none;\n    stroke: none;\n"], ["\n    fill: none;\n    stroke: none;\n"])));
var BaseTimerPathElapsed = styled.circle(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    stroke-width: 7px;\n    stroke: #dedede;\n"], ["\n    stroke-width: 7px;\n    stroke: #dedede;\n"])));
var BaseTimerPathRemaining = styled.path(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    stroke-width: 7px;\n    stroke-linecap: round;\n    transform: rotate(90deg);\n    transform-origin: center;\n    transition: 1s linear all;\n    fill-rule: nonzero;\n    stroke: currentColor;\n    color: ", ";\n"], ["\n    stroke-width: 7px;\n    stroke-linecap: round;\n    transform: rotate(90deg);\n    transform-origin: center;\n    transition: 1s linear all;\n    fill-rule: nonzero;\n    stroke: currentColor;\n    color: ", ";\n"])), function (props) { return props.remaining < 50 ? "green" : props.remaining < 75 ? "orange" : "red"; });
var BaseTimerLabel = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 0.6rem;\n    font-weight: 500;\n    color: #000;\n"], ["\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 0.6rem;\n    font-weight: 500;\n    color: #000;\n"])));
// function formatTime(time: number): string {
//     let minutes: string | number = Math.floor(time / 60);
//     let seconds: string | number = Math.floor(time % 60);
//     if (seconds < 10) {
//         seconds = `0${seconds}`;
//     }
//     if (minutes < 10) {
//         minutes = `0${minutes}`;
//     }
//     return `${minutes}:${seconds}`;
// }
function calculateTimeFraction(timeLimit, timeLeft) {
    var rawTimeFraction = timeLeft / timeLimit;
    return rawTimeFraction - (1 / timeLimit) * (1 - rawTimeFraction);
}
export var CountdownView = React.memo(function (props) {
    var timeLeft = props.timeLeft, timeLimit = props.timeLimit, timePassed = props.timePassed, time = props.time;
    // const [timeLimit] = React.useState((expiration - Date.now())/1000)
    // const [timeLeft, setTimeLeft] = React.useState(timeLimit);
    // const [timePassed, setTimePassed] = React.useState(0);
    var _a = React.useState("".concat((calculateTimeFraction(timeLimit, timeLeft) * FULL_DASH_ARRAY).toFixed(0), " 283")), circleDashArray = _a[0], setCircleDashArray = _a[1];
    React.useEffect(function () {
        var circleDasharray = "".concat((calculateTimeFraction(timeLimit, timeLeft) * FULL_DASH_ARRAY).toFixed(0), " 283");
        setCircleDashArray(circleDasharray);
    }, [timeLeft]);
    return React.createElement(BaseTimer, null,
        React.createElement(BaseTimerSvg, { viewBox: "0 0 100 100" },
            React.createElement(BaseTimerCircle, null,
                React.createElement(BaseTimerPathElapsed, { cx: "50", cy: "50", r: "45" }),
                timeLeft > 0 && React.createElement(BaseTimerPathRemaining, { remaining: (timePassed / timeLimit) * 100, strokeDasharray: "".concat(circleDashArray), d: "\n                        M 50, 50\n                        m -45, 0\n                        a 45,45 0 1,0 90,0\n                        a 45,45 0 1,0 -90,0\n                    " }))),
        React.createElement(BaseTimerLabel, null, time));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
