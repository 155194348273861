import * as React from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import reactStringReplace from "react-string-replace";
var keywords = {
    "<br/>": React.createElement("br", null),
    "<br />": React.createElement("br", null)
};
function replaceKeyWords(text) {
    Object.keys(keywords).forEach(function (key) {
        text = reactStringReplace(text, key, function (match, index, offset) { return keywords[key]; });
    });
    return text;
}
function Localize(props) {
    var t = useTranslation().t;
    var langKey = props.langKey, _a = props.langParams, langParams = _a === void 0 ? {} : _a;
    return replaceKeyWords(t(langKey, langParams));
}
Localize.propTypes = {
    langKey: PropTypes.string.isRequired,
    langParams: PropTypes.object
};
export default Localize;
