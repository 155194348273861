var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Modal, ModalBody, ModalHeader, ModalHeaderTitle } from "../component/common/Modal";
import styled from "styled-components";
import { Box } from "../component/common/FlexItem";
import { Button } from "./Button";
import { useTranslation } from "react-i18next";
var Buttons = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 1rem 0 0 0;\n"], ["\n    margin: 1rem 0 0 0;\n"])));
var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var ConfirmDialog = React.memo(function (props) {
    var t = useTranslation().t;
    var title = props.title, children = props.children, onCancel = props.onCancel, onConfirm = props.onConfirm, disabled = props.disabled;
    return React.createElement(Modal, { visible: true },
        React.createElement(ModalHeader, { onClose: onCancel },
            React.createElement(ModalHeaderTitle, null, title)),
        React.createElement(ModalBody, null,
            React.createElement(Box, { flexDirection: "column" },
                React.createElement(Content, null, children),
                React.createElement(Buttons, { flex: "none", justify: "flex-end", gap: "0.5rem" },
                    React.createElement(Button, { color: "secondary", onClick: onCancel }, t("cancel.text")),
                    React.createElement(Button, { color: "primary", disabled: disabled, onClick: onConfirm }, t("confirm.text"))))));
});
var templateObject_1, templateObject_2;
