var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { CFormInput, CFormLabel } from "@coreui/react";
import { randomString } from "../../utils/StringUtils";
var logger = require("js-logger").get("Google Address Input");
var queue = [];
var loaded = false;
var loading = false;
var loadScript = function (apiKey) { return __awaiter(void 0, void 0, void 0, function () {
    var sciptTag;
    return __generator(this, function (_a) {
        if (loaded) {
            return [2 /*return*/];
        }
        sciptTag = document.querySelector("#google-map-script");
        if (sciptTag || loading) {
            return [2 /*return*/, new Promise(function (res, rej) {
                    queue.push({ res: res, rej: rej });
                })];
        }
        loading = true;
        return [2 /*return*/, new Promise(function (res, rej) {
                var script = document.createElement("script");
                script.id = "google-map-script";
                script.src = "https://maps.googleapis.com/maps/api/js?key=".concat(apiKey, "&libraries=places");
                script.addEventListener("load", function () {
                    loaded = true;
                    loading = false;
                    res();
                    queue.forEach(function (pobj) {
                        pobj.res();
                    });
                    queue = [];
                });
                script.addEventListener("error", function () {
                    rej();
                });
                document.head.append(script);
            })];
    });
}); };
var initAutoComplete = function (input, options) {
    return new google.maps.places.Autocomplete(input, options);
};
var GoogleAddressInput = React.memo(function (props) {
    var inputRef = React.createRef();
    var autoCompleteRef = React.useRef();
    var _a = React.useState(false), inited = _a[0], setInited = _a[1];
    var apiKey = props.apiKey, label = props.label, onChange = props.onChange, defaultValue = props.defaultValue, placeholder = props.placeholder, disabled = props.disabled, onFocus = props.onFocus, onBlur = props.onBlur, info = props.info;
    var initScript = function () {
        loadScript(apiKey)
            .then(function () {
            setInited(true);
        });
    };
    React.useEffect(initScript, []);
    React.useEffect(function () {
        //console.log("REF", ref.current);
        if (inited) {
            if (defaultValue) {
                inputRef.current.value = defaultValue;
            }
            autoCompleteRef.current = initAutoComplete(inputRef.current, {
                fields: [
                    "address_components",
                    "geometry.location",
                    "place_id",
                    "formatted_address",
                ],
            });
            autoCompleteRef.current.addListener("place_changed", function () {
                var place = autoCompleteRef.current.getPlace();
                var formatted_address = place.formatted_address, address_components = place.address_components;
                logger.info("On Change", JSON.stringify(address_components, null, 4));
                onChange({
                    address: address_components,
                    address_string: formatted_address
                });
            });
        }
    }, [inited]);
    React.useEffect(function () {
        if (defaultValue && (inputRef.current && !inputRef.current.value)) {
            inputRef.current.value = defaultValue;
        }
    }, [defaultValue]);
    var id = props.id ? props.id : randomString(5);
    return React.createElement(React.Fragment, null,
        label && React.createElement(CFormLabel, { htmlFor: id }, label),
        React.createElement(CFormInput, { onFocus: onFocus, onBlur: onBlur, disabled: disabled, placeholder: placeholder, onChange: function (e) {
                logger.info("On Change", e.target.value);
                if (!e.target.value || e.target.value.length < 5) {
                    onChange(null);
                }
            }, id: id, ref: inputRef }));
});
export default GoogleAddressInput;
