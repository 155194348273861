var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Box } from "../component/common/FlexItem";
import styled from "styled-components";
import { Item } from "./item";
import { Touchable } from "../common/Touchable";
import { GroupType } from ".";
import { filterOrderByStatus, groupByStation, orderName, splitOrdersByItem } from "./utils";
import { Modal, ModalHeader, ModalBody } from "../component/common/Modal";
import { OmDetails } from "./OmDetail";
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex-direction: column;\n"], ["\n    flex-direction: column;\n"])));
var HeaderContainer = styled(Touchable)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100%;\n    background-color: #f2f2f2;\n    padding:0.5rem 1rem;\n    border:#e5e5e5 solid 1px;\n    display: flex;\n"], ["\n    width: 100%;\n    background-color: #f2f2f2;\n    padding:0.5rem 1rem;\n    border:#e5e5e5 solid 1px;\n    display: flex;\n"])));
var Header = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["        \n    color:#7a7a7a;    \n    text-transform: uppercase;    \n"], ["        \n    color:#7a7a7a;    \n    text-transform: uppercase;    \n"])));
var Content = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    flex-direction: column;\n    height: ", ";\n    overflow: ", "\n"], ["\n    flex-direction: column;\n    height: ", ";\n    overflow: ", "\n"])), function (props) { return props.open ? "auto" : 0; }, function (props) { return props.open ? "auto" : "hidden"; });
export var Group = React.memo(function (props) {
    var title = props.title, orders = props.orders, typeFilters = props.typeFilters, groupBy = props.groupBy, status = props.status, stationFilters = props.stationFilters;
    var _a = React.useState(true), open = _a[0], setOpen = _a[1];
    var _b = React.useState(undefined), detail = _b[0], setDetail = _b[1];
    var toggleOpen = function () {
        setOpen(!open);
    };
    var items = splitOrdersByItem(orders, status);
    if (groupBy == GroupType.BY_STATION) {
        items = groupByStation(items);
    }
    items = items.filter(function (item) { return stationFilters[item.stations_id]; }).filter(function (item) { return item.items.length; });
    return React.createElement(Container, null,
        React.createElement(HeaderContainer, { onTap: toggleOpen },
            React.createElement(Box, { flex: 1 },
                React.createElement(Header, null, title)),
            React.createElement(Box, { flex: "none", dangerouslySetInnerHTML: { __html: open ? "&uarr;" : "&darr;" } })),
        React.createElement(Content, { open: open },
            items.length > 0 && status !== "new" && items.filter(function (item) { return typeFilters[item.order.info.type.toLowerCase()]; }).map(function (item, index) { return React.createElement(Item, { key: item.items.map(function (item) { return item.id; }).join("_"), item: item, onClick: function () { return setDetail(item); } }); }),
            items.length > 0 && status == "new" && filterOrderByStatus(orders, status).filter(function (order) { return typeFilters[order.info.type.toLowerCase()]; }).map(function (item) { return React.createElement(Item, { key: item.id, item: item }); })),
        detail && React.createElement(Modal, { visible: true, fullscreen: true, noBorderRadius: true },
            React.createElement(ModalHeader, { onClose: function () {
                    setDetail(undefined);
                } }, orderName(detail.order.name)),
            React.createElement(ModalBody, null,
                React.createElement(OmDetails, { item: detail, onStatusChange: function () { return setDetail(undefined); } }))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
