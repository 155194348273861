import { HttpApiRequest } from "./network.service";
import { AUTH_ENDPOINT } from "../constats/ApiEndpoints";
var AuthService = /** @class */ (function () {
    function AuthService() {
    }
    AuthService.prototype.login = function (username, password) {
        return HttpApiRequest(this.loginConfig(username, password));
    };
    AuthService.prototype.loginConfig = function (username, password) {
        return {
            url: "".concat(AUTH_BASE_URL, "/").concat(AUTH_ENDPOINT, "/admin"),
            method: "POST",
            data: {
                username: username,
                password: password
            }
        };
    };
    return AuthService;
}());
var authService = new AuthService();
export default authService;
