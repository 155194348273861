var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormInput } from "@coreui/react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
var logger = require("js-logger").get("Select");
import { Box } from "./FlexItem";
var MenuContainer = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-height: 400px;\n    flex-direction: column;\n"], ["\n    max-height: 400px;\n    flex-direction: column;\n"])));
var ScrollContainer = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    flex: 1;\n    overflow: auto;\n    flex-direction: column;\n"], ["\n    flex: 1;\n    overflow: auto;\n    flex-direction: column;\n"
    //const Toggle = styled(CDropdownToggle)`
])));
//const Toggle = styled(CDropdownToggle)`
var SelectDropdown = styled(CDropdown)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    list-style: none;\n"], ["\n    list-style: none;\n"])));
var Toggle = styled(CDropdownToggle)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: 0;\n"], ["\n    padding: 0;\n"])));
export var Select = React.memo(function (props) {
    var options = props.options, toggle = props.toggle, text = props.text, onChange = props.onChange, value = props.value, renderOption = props.renderOption, search = props.search, labelKey = props.labelKey, valueKey = props.valueKey;
    var _a = React.useState(""), searchStr = _a[0], setSearchStr = _a[1];
    var t = useTranslation().t;
    var getValue = function (option) {
        return valueKey ? option[valueKey] : option;
    };
    var getLabel = function (option) {
        return labelKey ? option[labelKey] : option;
    };
    var renderOptions = options;
    if (searchStr) {
        var pattern_1 = new RegExp(searchStr, "i");
        renderOptions = options.filter(function (option) {
            var label = getLabel(option);
            return pattern_1.test(label);
        });
    }
    var handleChange = function (item) { return function () {
        if (valueKey) {
            onChange(item[valueKey]);
        }
        else {
            onChange(item);
        }
    }; };
    var selected = options.find(function (option) {
        if (value) {
            return option[valueKey] == value;
        }
        return option == value;
    });
    return React.createElement(SelectDropdown, { variant: "nav-item", autoClose: "outside" },
        toggle && React.createElement(Toggle, { caret: false }, toggle),
        text && React.createElement(CDropdownToggle, null, selected ? getLabel(selected) : text),
        React.createElement(CDropdownMenu, null,
            React.createElement(MenuContainer, null,
                search && React.createElement(CDropdownItem, null,
                    React.createElement(CFormInput, { placeholder: t("search.text"), size: "sm", value: searchStr, onChange: function (e) { return setSearchStr(e.target.value); } })),
                React.createElement(ScrollContainer, null, renderOptions.map(function (option) { return React.createElement(CDropdownItem, { key: getValue(option), active: getValue(option) == value, onClick: handleChange(option) },
                    renderOption && renderOption(option),
                    !renderOption && (labelKey ? option[labelKey] : option)); })))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
