var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useForm } from "react-hook-form";
import { Modal, ModalHeader, ModalHeaderTitle, ModalBody } from "./Modal";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../common/Button";
import styled from "styled-components";
import { Box } from "./FlexItem";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars-2";
var Form = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n "], ["\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n "])));
var Buttons = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-top: 2rem;\n"], ["\n    margin-top: 2rem;\n"])));
export var ModalForm = React.memo(function (props) {
    var children = props.children, formProps = props.formProps, title = props.title, onClose = props.onClose, onSubmit = props.onSubmit;
    var formReturn = useForm(formProps);
    var scrollRef = React.useRef(null);
    var t = useTranslation().t;
    var formState = formReturn.formState, handleSubmit = formReturn.handleSubmit;
    var isValid = formState.isValid;
    var isDirty = formState.isDirty;
    React.useEffect(function () {
        //console.log("ScrollRef", scrollRef.current);
        if (scrollRef.current && scrollRef.current.container) {
            var child = scrollRef.current.container.childNodes.item(0);
            child.style.padding = "0 2rem";
        }
    }, []);
    return React.createElement(Modal, { visible: true },
        React.createElement(Form, { onSubmit: handleSubmit(onSubmit) },
            React.createElement(ModalHeader, { onClose: onClose, rightButton: React.createElement(Button, { type: "submit", color: "primary", disabled: !formState.isDirty || !formState.isValid },
                    React.createElement(FontAwesomeIcon, { icon: faFloppyDisk, size: "lg", color: "#fff" })) },
                typeof title === "string" && React.createElement(ModalHeaderTitle, null, title),
                typeof title !== "string" && title),
            React.createElement(ModalBody, null,
                React.createElement(Scrollbars, { style: { height: "100%" }, ref: scrollRef },
                    children(formReturn),
                    React.createElement(Buttons, { gap: "0.5rem", justify: "flex-end" },
                        React.createElement(Button, { onClick: onClose, color: "secondary" }, t("close.text")),
                        React.createElement(Button, { type: "submit", color: "primary", disabled: !formState.isDirty || !formState.isValid }, t("save.text")))))));
});
var templateObject_1, templateObject_2;
