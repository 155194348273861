var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import FlexItem from "../common/FlexItem";
import { CSidebar, CSidebarNav, CNavItem, CButton, CForm } from "@coreui/react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { SETTINGS, SETTINGS_COMPANY_LEGA_INFO, SETTINGS_COMPANY_STATUS_TIMERS, SETTINGS_TARIFF_PLANS } from "../../constats/Routes";
import { useTranslation } from "react-i18next";
var logger = require("js-logger").get("SettingsPage");
export var SettingsPageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex: 1;\n    flex-direction: column;    \n"], ["\n    display: flex;\n    flex: 1;\n    flex-direction: column;    \n"])));
export var SettingsPageHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 15px 10px;\n    border-bottom: #ccc solid 1px;\n    display: flex;\n    align-items: center;\n"], ["\n    padding: 15px 10px;\n    border-bottom: #ccc solid 1px;\n    display: flex;\n    align-items: center;\n"])));
export var SettingsPageContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 1;\n    padding:0 10px;\n"], ["\n    flex: 1;\n    padding:0 10px;\n"])));
export var SettingsPageTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: 0 10px;\n"], ["\n    padding: 0 10px;\n"])));
export var SettingsPageButtons = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    padding: 0;\n    display: flex;\n    flex: 1;\n    justify-content: flex-end;\n    gap: 5px;\n"], ["\n    padding: 0;\n    display: flex;\n    flex: 1;\n    justify-content: flex-end;\n    gap: 5px;\n"])));
export var FormHorizontalContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    display: flex;\n    margin-top: 20px;\n    flex-wrap: wrap;\n"], ["\n    display: flex;\n    margin-top: 20px;\n    flex-wrap: wrap;\n"])));
export var FormVerticalContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n"], ["\n    display: flex;\n    flex-direction: column;\n"])));
export var FormContainerColumn = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    flex: ", ";\n    padding: 0 5px;    \n"], ["\n    flex: ", ";\n    padding: 0 5px;    \n"])), function (props) { return props.flex ? props.flex : 1; });
export var ColumnTitle = styled.h4(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    font-size: 16px;\n    text-transform: uppercase;\n    margin-bottom: 20px\n\n"], ["\n    font-size: 16px;\n    text-transform: uppercase;\n    margin-bottom: 20px\n\n"])));
export var SettingsPage = React.memo(function (props) {
    var navigate = useNavigate();
    var location = useLocation();
    var t = useTranslation().t;
    var goTo = function (route) { return function (e) {
        e.preventDefault();
        navigate(route);
    }; };
    logger.info("Location", location);
    return React.createElement(FlexItem, { flex: 1, style: {
            width: "100%",
            height: "100%"
        } },
        React.createElement(FlexItem, null,
            React.createElement(CSidebar, null,
                React.createElement(CSidebarNav, null,
                    React.createElement(CNavItem, { active: location.pathname == SETTINGS, href: "#", onClick: goTo(SETTINGS) }, t("settings.text")),
                    React.createElement(CNavItem, { href: "#", active: location.pathname == SETTINGS_TARIFF_PLANS, onClick: goTo(SETTINGS_TARIFF_PLANS) }, t("tarrif.plans.text")),
                    React.createElement(CNavItem, { href: "#" }, t("billing.text")),
                    React.createElement(CNavItem, { href: "#", active: location.pathname == SETTINGS_COMPANY_LEGA_INFO, onClick: goTo(SETTINGS_COMPANY_LEGA_INFO) }, t("company.legal.info.text")),
                    React.createElement(CNavItem, { href: "#", active: location.pathname == SETTINGS_COMPANY_STATUS_TIMERS, onClick: goTo(SETTINGS_COMPANY_STATUS_TIMERS) }, t("company.settings.status.timers.text"))))),
        React.createElement(FlexItem, { flex: 1 },
            React.createElement(Outlet, null)));
});
export var SettingsPageLayout = React.memo(function (props) {
    var title = props.title, children = props.children;
    return React.createElement(SettingsPageContainer, null,
        React.createElement(SettingsPageHeader, null,
            React.createElement(SettingsPageTitle, null, title),
            React.createElement(SettingsPageButtons, null,
                React.createElement(CButton, { color: "seconday" }, "Cancel"),
                React.createElement(CButton, null, "Save"))),
        React.createElement(SettingsPageContent, null, children));
});
export var SettingsFormPageLayout = React.memo(function (props) {
    var title = props.title, children = props.children, formConfig = props.formConfig, onSubmit = props.onSubmit;
    var useFormReturn = useForm(formConfig);
    var t = useTranslation().t;
    var formState = useFormReturn.formState, handleSubmit = useFormReturn.handleSubmit;
    logger.info("IS VALID", formState.isValid, "isDirty", formState.isDirty);
    return React.createElement(SettingsPageContainer, null,
        React.createElement(CForm, { onSubmit: handleSubmit(onSubmit) },
            React.createElement(SettingsPageHeader, null,
                React.createElement(SettingsPageTitle, null, title),
                React.createElement(SettingsPageButtons, null,
                    React.createElement(CButton, { color: "seconday" }, t("cancel.text")),
                    React.createElement(CButton, { type: "submit", disabled: !formState.isDirty || !formState.isValid }, t("save.text")))),
            React.createElement(SettingsPageContent, null, children(useFormReturn))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
