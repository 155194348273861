var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { CFormInput, CFormFeedback } from "@coreui/react";
import { Button } from "../../common/Button";
import { Box } from "./FlexItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
var logger = require("js-logger").get("EditableInput");
export var EditableInput = React.memo((function (props) {
    var onChange = props.onChange, _a = props.inputProps, inputProps = _a === void 0 ? {} : _a, validate = props.validate, errorMessage = props.errorMessage, Component = props.Component, onStatusChange = props.onStatusChange;
    var _b = React.useState(false), edit = _b[0], setEdit = _b[1];
    var _c = React.useState(true), valid = _c[0], setValid = _c[1];
    var _d = React.useState(props.value), value = _d[0], setValue = _d[1];
    var openEdit = React.useCallback(function (e) {
        setEdit(true);
        onStatusChange("edit");
        // logger.info("EDIT");
    }, []);
    var closeEdit = React.useCallback(function () {
        setEdit(false);
        onChange(value);
        onStatusChange("view");
    }, [value]);
    var updateValue = React.useCallback(function (e) {
        var _a;
        var val = (e === null || e === void 0 ? void 0 : e.target) ? (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value : e;
        if (validate) {
            setValid(validate(val));
        }
        setValue(val);
    }, []);
    var RenderComponent = Component ? Component : CFormInput;
    return React.createElement(React.Fragment, null,
        React.createElement(Box, { gap: "0.2rem" },
            React.createElement(RenderComponent, __assign({}, inputProps, { readOnly: !edit, size: "sm", value: value, onChange: updateValue })),
            edit && React.createElement(Button, { color: "primary", onClick: closeEdit, disabled: !valid },
                React.createElement(FontAwesomeIcon, { icon: faFloppyDisk })),
            !edit && React.createElement(Button, { onClick: openEdit },
                React.createElement(FontAwesomeIcon, { icon: faPencil }))),
        (!valid && errorMessage) && React.createElement(CFormFeedback, { invalid: true, style: { display: "block" } }, errorMessage));
}));
