import { userStore } from "../store/user.store";
import { DEVICE_ENDPOINT } from "../constats/ApiEndpoints";
var logger = require("js-logger").get("user.request");
export function getDevicesRequest() {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(DEVICE_ENDPOINT),
        params: {
            token: token
        }
    };
}
export function createDeviceRequest(data) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(DEVICE_ENDPOINT),
        data: data,
        method: "POST",
        params: {
            token: token
        }
    };
}
export function updateDeviceRequest(id, data) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(DEVICE_ENDPOINT, "/").concat(id),
        method: "PUT",
        data: data,
        params: {
            token: token
        }
    };
}
export function deleteDeviceRequest(id) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(DEVICE_ENDPOINT, "/").concat(id),
        method: "DELETE",
        params: {
            token: token
        }
    };
}
export function getDeviceQrRequest(id) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(DEVICE_ENDPOINT, "/code/").concat(id),
        method: "POST",
        params: {
            token: token
        }
    };
}
