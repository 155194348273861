var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import FlexItem from "../common/FlexItem";
import authService from "../../service/auth.service";
var logger = require("js-logger").get("SignInScreen");
import cookiesService from "../../service/cookies.service";
import { useNavigate } from "react-router-dom";
import { APP, SIGN_UP } from "../../constats/Routes";
import { MO_ADMIN_TOKEN } from "../../constats/Cookies";
import { meRequest } from "../../request/user.request";
import { useHttpRequesPrepareHandler } from "../../service/network.service";
import { userStore } from "../../store/user.store";
import { getHostWithProtocol } from "../../utils/URLUtils";
import { CButton } from "@coreui/react";
import SocialButton from "../common/SocialButton";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { wrapNotSignedIn } from "../common/NotSignedIn";
var Buttons = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 200px;\n"], ["\n    width: 200px;\n"])));
var SignInScreen = React.memo(function (props) {
    var navigate = useNavigate();
    var loginState = useHttpRequesPrepareHandler();
    var userState = useHttpRequesPrepareHandler();
    var uStore = userStore();
    var t = useTranslation().t;
    var signIn = function (values) {
        var username = values.username, password = values.password;
        logger.info(values);
        loginState.run(authService.loginConfig(username, password));
    };
    var getUserInfo = function (token) {
        userState.run(meRequest());
    };
    var checkIsLoggedIs = function () {
        var token = cookiesService.getCookie(MO_ADMIN_TOKEN);
        if (!token) {
            return;
        }
        getUserInfo(token);
    };
    // React.useEffect(() => {
    //     logger.info("Referrer", document.referrer);
    // }, [])
    React.useEffect(function () {
        if (loginState.executed && loginState.result) {
            var token = loginState.result;
            cookiesService.setCookie(MO_ADMIN_TOKEN, token);
            uStore.setToken(token);
            getUserInfo(token);
        }
    }, [loginState.executed, loginState.result]);
    React.useEffect(function () {
        if (userState.executed && userState.result) {
            logger.info("UserState", userState);
            var _a = userState.result, verified = _a.verified, rest = __rest(_a, ["verified"]);
            uStore.signIn(cookiesService.getCookie(MO_ADMIN_TOKEN), rest, verified);
            navigate(APP);
        }
    }, [userState.executed, userState.result]);
    React.useEffect(function () {
        //checkIsLoggedIs();
    }, []);
    var googleLogin = function () {
        var baseURL = "".concat(getHostWithProtocol()).concat(BASENAME == "/" ? "" : BASENAME);
        logger.info("baseURL", baseURL);
        var backURL = "".concat(baseURL, "/sign-in/response");
        var url = "".concat(AUTH_BASE_URL, "/google/oauth/sign-in?backURL=").concat(encodeURIComponent(backURL)).concat(DEBUG_COMPANY ? ("&company=" + DEBUG_COMPANY) : "");
        document.location.href = url;
    };
    var facebookLogin = function () {
        var baseURL = "".concat(getHostWithProtocol()).concat(BASENAME == "/" ? "" : BASENAME);
        logger.info("baseURL", baseURL);
        var backURL = "".concat(baseURL, "/sign-in/response");
        var url = "".concat(AUTH_BASE_URL, "/facebook/oauth/sign-in?backURL=").concat(encodeURIComponent(backURL)).concat(DEBUG_COMPANY ? ("&company=" + DEBUG_COMPANY) : "");
        document.location.href = url;
    };
    return React.createElement(FlexItem, { flex: 1, flexDirection: "column", justify: "center", align: "center" },
        React.createElement(Buttons, null,
            React.createElement(SocialButton, { icon: "google", onClick: googleLogin, text: t("sign.in.google.text") }),
            React.createElement(SocialButton, { icon: "facebook", onClick: facebookLogin, text: t("sign.in.facebook.text") })),
        React.createElement(FlexItem, { justify: "flex-end" },
            React.createElement(CButton, { color: "link", onClick: function () {
                    navigate(SIGN_UP);
                } }, "Sign Up")));
});
export default wrapNotSignedIn(SignInScreen);
var templateObject_1;
