import { CFormFeedback, CTooltip } from "@coreui/react";
import * as React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { randomString } from "../../../utils/StringUtils";
import { MobileFormTextInput } from "./MobileFormTextInput";
var logger = require("js-logger").get("FormTextInput");
var FormTextInput = function (props) {
    var control = props.control, name = props.name, defaultValue = props.defaultValue, label = props.label, type = props.type, rules = props.rules, disabled = props.disabled, inputRef = props.inputRef, info = props.info, size = props.size, placeholder = props.placeholder, style = props.style, className = props.className, disableHandleFocus = props.disableHandleFocus, readOnly = props.readOnly;
    var onFocus = props.onFocus, onBlur = props.onBlur;
    var _a = React.useState(false), isFocused = _a[0], setIsFocused = _a[1];
    var textareAreaRef = React.useRef(null);
    var _b = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _c = _b.field, onChange = _c.onChange, _d = _c.value, value = _d === void 0 ? defaultValue ? defaultValue : "" : _d, fieldState = _b.fieldState;
    var t = useTranslation().t;
    var inputId = props.id ? props.id : randomString(6);
    //logger.info("Errors", fieldState.error);
    return React.createElement(React.Fragment, null,
        info && React.createElement(CTooltip, { content: info },
            React.createElement(MobileFormTextInput, { onChange: onChange, onBlur: onBlur, onFocus: onFocus, value: value, inputRef: inputRef, id: inputId, type: type, disabled: disabled, invalid: fieldState.invalid, 
                //valid={fieldState.isDirty && !fieldState.invalid}
                style: style, size: size, placeholder: placeholder, label: label, className: className, disableHandleFocus: disableHandleFocus, readOnly: readOnly })),
        !info && React.createElement(MobileFormTextInput, { onChange: onChange, onBlur: onBlur, onFocus: onFocus, value: value, inputRef: inputRef, id: inputId, type: type, disabled: disabled, invalid: fieldState.invalid, 
            //valid={fieldState.isDirty && !fieldState.invalid}
            style: style, size: size, label: label, placeholder: placeholder, className: className, disableHandleFocus: disableHandleFocus, readOnly: readOnly }),
        fieldState.invalid && React.createElement(CFormFeedback, { invalid: true },
            fieldState.error.message && fieldState.error.message,
            !fieldState.error.message && t("form.error.".concat(fieldState.error.type.toLowerCase(), ".message"))));
};
export default FormTextInput;
