import * as _ from "underscore";
export var ROOT = "/";
export var APP = "/app";
export var SETTINGS = "".concat(APP, "/settings");
export var SETTINGS_SETTINGS = "".concat(SETTINGS, "/settings");
export var SETTINGS_COMPANY_LEGA_INFO = "".concat(SETTINGS, "/company-legal-info");
export var SETTINGS_COMPANY_STATUS_TIMERS = "".concat(SETTINGS, "/company-status-timers");
export var SETTINGS_TARIFF_PLANS = "".concat(SETTINGS, "/tariff-plans");
export var SETTINGS_ORDER = "".concat(SETTINGS, "/order-settings");
export var COMPANY = "/company";
export var SIGN_IN = "/sign-in";
export var SIGN_IN_RESPONSE = "".concat(SIGN_IN, "/response");
export var SIGN_UP = "/sign-up";
export var SIGN_UP_VERIFY = "".concat(SIGN_UP, "/verify");
export var SIGN_UP_RESPONSE = "/sign-up/response";
export var SIGN_UP_INVITE_RESPONSE = "/sign-up/invite-response";
export var INVITE = "/invite/:id";
export var NOT_FOUND = "*";
export var APP_MENU = "".concat(APP, "/menu");
export var MENU_PAGE = "".concat(APP_MENU, "/:menu_id");
export var APP_AREAS = "".concat(APP, "/areas");
export var APP_AREA_PAGE = "".concat(APP, "/areas/:area_id");
export var MENU_SHORT_INTRO_PAGE = "".concat(APP_MENU, "/:menu_id/short-intro");
export var MENU_CATEGORY_PAGE = "".concat(APP_MENU, "/:menu_id/c/:category_id");
export var MENU_CATEGORY_PAGE_ADD = "".concat(APP_MENU, "/:menu_id/c/:category_id/add");
export var APP_COMPANY = "".concat(APP, "/company");
export var APP_COMPANY_ACCESS_QR = "".concat(APP_COMPANY, "/access-qr");
export var APP_COMPANY_COMMON_INFO = "".concat(APP_COMPANY, "/common-info");
export var APP_MENU_OPTION_ROUTE = "".concat(MENU_PAGE, "/c/:category_id/options/:option_id");
export var APP_ORDER_MONITOR = "".concat(APP, "/order-monitor");
export var APP_ORDERS = "".concat(APP, "/orders");
export var APP_OPERATION_MONITOR = "".concat(APP, "/operation-monitor");
export var APP_OPERATION_MONITOR_DETAILS = "".concat(APP, "/operation-monitor/:id");
export var APP_BILLING_PAYMENT_METHODS = "".concat(APP, "/billing/payment-methods");
export var APP_STATIONS = "".concat(APP, "/stations");
export var APP_DEVIES = "".concat(APP, "/devices");
export function buildRoute(route, params) {
    var res = route;
    //console.log("hasLanguageInPath", route, document.location.pathname, hasLanguageInPath(document.location.pathname))
    // if(hasLanguageInPath(document.location.pathname)){        
    //     res = `/${getLanguageFromPath(document.location.pathname)}${route}`
    // }
    _.each(params, function (val, name) {
        var regex = new RegExp(":".concat(name), "i");
        res = res.replace(regex, val);
    });
    return res;
}
