var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import styled from "styled-components";
import { ModalForm } from "../common/ModalForm";
import { DishFormInputs, DishFormAllergens, DishFormLabels, DishFormProperties, DishFormOptions, DishTags } from "../dish/DishForm";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FormCheckboxInput from "../common/form/FormCheckboxInput";
import { FormProvider } from "react-hook-form";
var TranslateContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border-top:#ccc solid 1px;\n    margin: 0.5rem 0;\n"], ["\n    border-top:#ccc solid 1px;\n    margin: 0.5rem 0;\n"])));
export var MenuItemEdit = React.memo(function (props) {
    var onSubmit = props.onSubmit, onCancel = props.onCancel, dish = props.dish, category = props.category;
    var _a = useParams(), menu_id = _a.menu_id, category_id = _a.category_id;
    var t = useTranslation().t;
    var title = dish == null ? t("category.add.item.text") : t("category.edit.item.text", {
        name: dish.name
    });
    return React.createElement(ModalForm, { title: title, onSubmit: onSubmit, onClose: onCancel, formProps: {
            mode: "onChange",
            defaultValues: dish ? dish : {}
        } }, function (formReturn) {
        var control = formReturn.control;
        return React.createElement(FormProvider, __assign({}, formReturn),
            React.createElement(DishFormInputs, { control: control }),
            React.createElement(DishFormProperties, { control: control }),
            React.createElement(DishFormLabels, { control: control }),
            React.createElement(DishFormAllergens, { control: control }),
            React.createElement(DishFormOptions, { control: control, menuId: menu_id, categoryId: category_id }),
            React.createElement(DishTags, { category: category, control: control }),
            dish && React.createElement(TranslateContainer, null,
                React.createElement(FormCheckboxInput, { label: t("translate.label"), control: control, name: "translate" })));
    });
});
var templateObject_1;
