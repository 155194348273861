import * as React from "react";
import { DayTimeItem } from "../menu/MenuForm";
import { VerticalContainer } from "../common/VerticalContainer";
import { SettingsPageContainer, SettingsPageContent, SettingsPageHeader, SettingsPageTitle } from "../settings";
import { useTranslation } from "react-i18next";
import { userStore } from "../../store/user.store";
import { CompanyPermissions } from "../../common/user/Permission";
var WorkingHours = React.memo(function (props) {
    var t = useTranslation().t;
    var control = props.control;
    var hasPermission = userStore(function (state) { return state.hasPermission; });
    var disabled = !hasPermission(CompanyPermissions.COMPANY_EDIT);
    return React.createElement(SettingsPageContainer, null,
        React.createElement(SettingsPageHeader, null,
            React.createElement(SettingsPageTitle, null, t("company.sidebar.working.hours"))),
        React.createElement(SettingsPageContent, null,
            React.createElement(VerticalContainer, null,
                React.createElement(DayTimeItem, { name: "workingHours", day: 0, control: control, disabled: disabled }),
                React.createElement(DayTimeItem, { name: "workingHours", day: 1, control: control, disabled: disabled }),
                React.createElement(DayTimeItem, { name: "workingHours", day: 2, control: control, disabled: disabled }),
                React.createElement(DayTimeItem, { name: "workingHours", day: 3, control: control, disabled: disabled }),
                React.createElement(DayTimeItem, { name: "workingHours", day: 4, control: control, disabled: disabled }),
                React.createElement(DayTimeItem, { name: "workingHours", day: 5, control: control, disabled: disabled }),
                React.createElement(DayTimeItem, { name: "workingHours", day: 6, control: control, disabled: disabled }))));
});
export default WorkingHours;
