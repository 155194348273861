import * as React from "react";
import { CDropdown } from "@coreui/react";
export default React.memo(function (props) {
    var _a = React.useState(false), visible = _a[0], setVisible = _a[1];
    var toggleVisible = function () {
        setVisible(!visible);
    };
    var toggle = React.cloneElement(props.toggle, {
        onClick: toggleVisible
    });
    ;
    return React.createElement(React.Fragment, null,
        React.createElement(CDropdown, { alignment: "start", placement: "auto", visible: visible, onHide: function () {
                if (visible) {
                    toggleVisible();
                }
            } },
            toggle,
            props.children));
});
