var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { buildRoute, MENU_SHORT_INTRO_PAGE } from "../../constats/Routes";
import Categories from "./Categories";
var logger = require("js-logger").get("MenuPage");
var CategoriesContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["       \n    margin-top: 10px;    \n    display: flex\n"], ["       \n    margin-top: 10px;    \n    display: flex\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    padding:0 1rem;\n"], ["\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    padding:0 1rem;\n"])));
export default React.memo(function (props) {
    var params = useParams();
    var navigate = useNavigate();
    var location = useLocation();
    var goToIntroPage = function () {
        navigate(buildRoute(MENU_SHORT_INTRO_PAGE, {
            menu_id: params.menu_id
        }));
    };
    var t = useTranslation().t;
    var category_id = params.category_id;
    React.useEffect(function () {
        logger.info('CategoryId', params);
    }, [params]);
    return React.createElement(Container, null,
        React.createElement(Scrollbars, null,
            React.createElement(Categories, { menu_id: params.menu_id })));
});
var templateObject_1, templateObject_2;
