var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
var logger = require("js-logger").get("AppPage");
import Scrollbars from "react-custom-scrollbars-2";
import { Box } from "./common/FlexItem";
export var AppPageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: #fbfbfb;\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n"], ["\n    background-color: #fbfbfb;\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n"])));
export var AppPage = React.memo(function (props) {
    var children = props.children;
    return React.createElement(AppPageContainer, null, children);
});
export var AppPageTopImage = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: relative;\n    height: 66rem;\n    background: url(", ") no-repeat center center;\n    background-size: cover;\n    flex: none;\n"], ["\n    position: relative;\n    height: 66rem;\n    background: url(", ") no-repeat center center;\n    background-size: cover;\n    flex: none;\n"])), function (props) { return props.image; });
export var AppPageContent = React.memo(function (props) {
    var children = props.children, onScroll = props.onScroll;
    return React.createElement(Scrollbars, { onScroll: onScroll }, children);
});
var Container = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["    \n    padding: 0.7rem 0.7rem 0 0.7rem;\n    transition: margin 0.5s ease;\n    width: 100%;    \n"], ["    \n    padding: 0.7rem 0.7rem 0 0.7rem;\n    transition: margin 0.5s ease;\n    width: 100%;    \n"])));
var Content = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    border-bottom: #e3e2e2 solid 1px;\n    padding-bottom: 1rem;\n"], ["\n    border-bottom: #e3e2e2 solid 1px;\n    padding-bottom: 1rem;\n"])));
export var AppPageTopButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 2.5rem;\n    height: 2.5rem;\n    background: #fff;\n    background-size: 100%;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    box-shadow: 5px 10px 20px rgba(211, 209, 216, 0.3);\n    border-radius: 0.5rem;\n"], ["\n    width: 2.5rem;\n    height: 2.5rem;\n    background: #fff;\n    background-size: 100%;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    box-shadow: 5px 10px 20px rgba(211, 209, 216, 0.3);\n    border-radius: 0.5rem;\n"])));
export var AppPageTopLayout = React.memo(function (props) {
    var left = props.left, center = props.center, right = props.right, _a = props.scrollTop, scrollTop = _a === void 0 ? 0 : _a;
    // const springStyle = useSpring({
    //     from:{
    //         y: "0rem"
    //     },
    //     to: {
    //         y: "-17rem"
    //     }
    // })
    var transformY = scrollTop <= 17 ? "-".concat(Number(scrollTop).toFixed(2), "rem") : "-17rem";
    //logger.info("ScrollTop", scrollTop, `${transformY} 0 0 0`);
    return React.createElement(Container, { flex: "none" },
        React.createElement(Content, { flex: 1 },
            React.createElement(Box, { flex: "none" }, left),
            React.createElement(Box, { flex: 1 }, center),
            React.createElement(Box, { flex: "none" }, right)));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
