import { userStore } from "../store/user.store";
import { USER_ENDPOINT, INVITE_ENDPOINT } from "../constats/ApiEndpoints";
var logger = require("js-logger").get("user.request");
export function meRequest(token) {
    //const token = userStore.getState().token;
    return {
        url: "".concat(AUTH_BASE_URL, "/").concat(USER_ENDPOINT, "/me?token=").concat(token),
    };
}
export function userCompaniesRequest() {
    var token = userStore.getState().token;
    //logger.info("STATE", userStore.getState());
    var params = {};
    if (DEBUG_COMPANY) {
        params["company"] = DEBUG_COMPANY;
    }
    return {
        url: "".concat(API_BASE_URL, "/").concat(USER_ENDPOINT, "/company?token=").concat(token),
        params: params
    };
}
export function inviateUserRequest(email, role_id) {
    var token = userStore.getState().token;
    var companyId = userStore.getState().company.id;
    return {
        url: "".concat(API_BASE_URL, "/").concat(USER_ENDPOINT, "/invite?token=").concat(token),
        method: "POST",
        data: {
            company_id: companyId,
            email: email,
            role_id: role_id
        }
    };
}
export function updateUserRequest(userId, data) {
    var token = userStore.getState().token;
    var companyId = userStore.getState().company.id;
    return {
        url: "".concat(API_BASE_URL, "/").concat(USER_ENDPOINT, "/").concat(userId, "?token=").concat(token),
        method: "PUT",
        data: Object.assign({}, data, {
            company_id: companyId
        })
    };
}
export function deleteUserRequest(userId) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(USER_ENDPOINT, "/").concat(userId, "?token=").concat(token),
        method: "DELETE"
    };
}
export function blockUserRequest(userId) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(USER_ENDPOINT, "/").concat(userId, "/block?token=").concat(token),
        method: "PUT"
    };
}
export function unblockUserRequest(userId) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(USER_ENDPOINT, "/").concat(userId, "/unblock?token=").concat(token),
        method: "PUT"
    };
}
export function deleteUserInviteRequest(inviteId) {
    var token = userStore.getState().token;
    var companyId = userStore.getState().company.id;
    return {
        url: "".concat(API_BASE_URL, "/").concat(USER_ENDPOINT, "/invite/").concat(inviteId, "?token=").concat(token),
        method: "DELETE"
    };
}
export function canSendVerificationRequest() {
    var token = userStore.getState().token;
    return {
        url: "".concat(AUTH_BASE_URL, "/").concat(USER_ENDPOINT, "/can-verify?token=").concat(token),
    };
}
export function createVerificationRequest() {
    var token = userStore.getState().token;
    return {
        url: "".concat(AUTH_BASE_URL, "/").concat(USER_ENDPOINT, "/verify?token=").concat(token),
    };
}
export function verifyRequest(code) {
    var token = userStore.getState().token;
    return {
        url: "".concat(AUTH_BASE_URL, "/").concat(USER_ENDPOINT, "/verify?token=").concat(token),
        method: "post",
        data: {
            code: code
        }
    };
}
export function getInviteInfo(id) {
    return {
        url: "".concat(AUTH_BASE_URL, "/").concat(INVITE_ENDPOINT, "/").concat(id),
        method: "get"
    };
}
