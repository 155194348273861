var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import "./color.picker.css";
import { VerticalContainer } from "../VerticalContainer";
import { CFormInput } from "@coreui/react";
import { HexColorPicker } from "react-colorful";
import styled from "styled-components";
import { HoriontalContainer } from "../HorizontalContainer";
import Label from "../Label";
var logger = require("js-logger").get("ColorPicker");
var InputContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 5px 0;\n"], ["\n    margin: 5px 0;\n"])));
export var ColorPicker = React.memo(function (props) {
    var _a = React.useState(props.color ? props.color : "#dedede"), color = _a[0], setColor = _a[1];
    var onChange = function (newColor) {
        setColor(newColor);
        if (props.onChange) {
            props.onChange(newColor);
        }
    };
    var inputOnChange = function (e) {
        setColor(e.target.value);
    };
    React.useEffect(function () {
        setColor(props.color);
    }, [props.color]);
    return React.createElement(VerticalContainer, { className: "do-color-picker" },
        React.createElement(InputContainer, null,
            React.createElement(Label, null, "Click on pallet to select a color o enter a specific number"),
            React.createElement(HoriontalContainer, null,
                React.createElement(CFormInput, { value: color, onChange: inputOnChange }))),
        React.createElement(HexColorPicker, { color: color, onChange: onChange }));
});
var templateObject_1;
