import * as React from "react";
import { CFormSwitch } from "@coreui/react";
import { useController } from "react-hook-form";
import { randomString } from "../../../utils/StringUtils";
var FormSwitchInput = function (props) {
    var control = props.control, name = props.name, defaultValue = props.defaultValue, label = props.label, rules = props.rules, disabled = props.disabled, className = props.className;
    var _a = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, _c = _b.value, value = _c === void 0 ? false : _c, ref = _b.ref, fieldState = _a.fieldState;
    var onInputChange = function (e) {
        if (props.onChange) {
            props.onChange(e);
        }
        onChange(e);
    };
    var inputId = props.id ? props.id : randomString(6);
    return React.createElement(CFormSwitch, { id: inputId, onChange: onInputChange, onBlur: onBlur, 
        //value={value}
        ref: ref, label: label, disabled: disabled, checked: !!value, invalid: fieldState.invalid, className: className });
};
export default React.memo(FormSwitchInput);
