import * as React from "react";
import { HttpRequest } from "../../service/network.service";
import { checkCompanyRequest } from "../../request/local.request";
import FlexItem from "./FlexItem";
import { CAlert } from "@coreui/react";
import LoadingBox from "./LoadingBox";
import { useTranslation } from "react-i18next";
import { userStore } from "../../store/user.store";
var logger = require("js-logger").get("CheckCompany");
function CheckCompany(props) {
    var _a = React.useState(true), loading = _a[0], setLoading = _a[1];
    var _b = React.useState(null), error = _b[0], setError = _b[1];
    var children = props.children, exclude = props.exclude;
    var setLanguage = userStore(function (state) { return state.setLanguage; });
    var currentLanguage = userStore(function (state) { return state.language; });
    var t = useTranslation().t;
    var isInExclude = function () {
        if (!exclude || !exclude.length) {
            return false;
        }
        var excludeURL = exclude.find(function (url) { return document.location.href.indexOf(url) !== -1; });
        return excludeURL ? true : false;
    };
    var checkCompany = function () {
        logger.info("Exclute", exclude, document.location.href);
        if (isInExclude()) {
            setError(null);
            setLoading(false);
            return;
        }
        HttpRequest(checkCompanyRequest())
            .promise
            .then(function (res) {
            var _a = res.data, language = _a.language, languages = _a.languages, currency = _a.currency;
            logger.info("Check company data", res);
            if (languages.indexOf(currentLanguage) == -1) {
                setLanguage(language);
            }
        })
            .catch(function (err) {
            var _a;
            setError((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data);
        })
            .finally(function () {
            setLoading(false);
        });
    };
    React.useEffect(checkCompany, []);
    if (loading) {
        return React.createElement(LoadingBox, null);
    }
    if (error) {
        return React.createElement(FlexItem, { align: "center", justify: "center", flex: 1 },
            React.createElement(CAlert, { color: "danger" },
                t("error.company.notfounderror.message"),
                React.createElement("a", { href: "https://registration.ordish.app/admin/sign-up" }, "https://registration.ordish.app/admin/sign-up")));
    }
    return React.createElement(React.Fragment, null, children);
}
export default CheckCompany;
