import { ME_ENDPOINT } from "../constats/ApiEndpoints";
import { userStore } from "../store/user.store";
export function updateMyPhoneRequest(phone) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(ME_ENDPOINT, "/phone?token=").concat(token),
        method: "PUT",
        data: {
            phone: phone
        }
    };
}
