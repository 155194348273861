import * as React from "react";
import FormTextInput from "../common/form/FormTextInput";
import { FormHorizontalContainer, FormContainerColumn, ColumnTitle, SettingsFormPageLayout } from "./index";
import { userStore } from "../../store/user.store";
import { useTranslation } from "react-i18next";
export var Settings = React.memo(function (props) {
    var user = userStore(function (state) { return state.user; });
    var saveValues = function (values) {
    };
    var t = useTranslation().t;
    return React.createElement(SettingsFormPageLayout, { onSubmit: saveValues, title: "Settings", formConfig: {
            mode: 'onChange'
        } }, function (useFormReturn) { return React.createElement(FormHorizontalContainer, null,
        React.createElement(FormContainerColumn, null,
            React.createElement(ColumnTitle, null, t("settings.user.profile.title")),
            React.createElement(FormTextInput, { label: t("name.text"), name: "name", disabled: user.provider !== "local", defaultValue: "".concat(user.firstName), control: useFormReturn.control }),
            React.createElement(FormTextInput, { label: t("email.text"), name: "email", disabled: user.provider !== "local", defaultValue: "".concat(user.username), control: useFormReturn.control }),
            React.createElement(FormTextInput, { label: t("phone.text"), name: "phone", disabled: user.provider !== "local", control: useFormReturn.control })),
        React.createElement(FormContainerColumn, null,
            React.createElement(ColumnTitle, null, t("settings.security.title")),
            React.createElement(FormTextInput, { label: t("settings.current.password.label"), name: "cpassword", disabled: user.provider !== "local", control: useFormReturn.control }),
            React.createElement(FormTextInput, { label: t("settings.new.password.label"), name: "npassword", disabled: user.provider !== "local", control: useFormReturn.control }),
            React.createElement(FormTextInput, { label: t("settings.confirm.password.label"), name: "cnpassword", disabled: user.provider !== "local", control: useFormReturn.control }))); });
});
