var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { CDropdown, CDropdownDivider, CDropdownItem, CDropdownItemPlain, CDropdownMenu, CDropdownToggle, CFormCheck } from "@coreui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import filterSvg from "../../assets/svg/filter.svg";
var logger = require("js-logger").get("Filters");
var Icon = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 2em;\n    height: 2rem;\n    background: url(\"", "\") no-repeat;\n    background-size: 100%;\n"], ["\n    width: 2em;\n    height: 2rem;\n    background: url(\"", "\") no-repeat;\n    background-size: 100%;\n"])), filterSvg);
export var Filters = React.memo(function (props) {
    var statusFilters = props.statusFilters, typeFilters = props.typeFilters, stationFilters = props.stationFilters, statusFiltersUpdate = props.statusFiltersUpdate, typeFiltersUpdate = props.typeFiltersUpdate, stationFilterUpdate = props.stationFilterUpdate, stations = props.stations;
    logger.info("stations", stations, "stationFilters", stationFilters);
    var t = useTranslation().t;
    var updateTypeFiltersBuilder = function (type) { return function (e) {
        var _a;
        var value = e.target.checked;
        var update = Object.assign({}, typeFilters, (_a = {},
            _a[type] = value,
            _a));
        typeFiltersUpdate(update);
    }; };
    var updateStateFiltersBuilder = function (state) { return function (e) {
        var _a;
        var value = e.target.checked;
        var update = Object.assign({}, statusFilters, (_a = {},
            _a[state] = value,
            _a));
        statusFiltersUpdate(update);
    }; };
    var updateStationFiltersBuilder = function (id) { return function (e) {
        var _a;
        var value = e.target.checked;
        var update = Object.assign({}, stationFilters, (_a = {},
            _a[id] = value,
            _a));
        stationFilterUpdate(update);
    }; };
    return React.createElement(CDropdown, { autoClose: "outside" },
        React.createElement(CDropdownToggle, { color: "link", caret: false, style: {
                padding: 0,
            } },
            React.createElement(Icon, null)),
        React.createElement(CDropdownMenu, null,
            React.createElement(CDropdownItemPlain, null, t("station.text")),
            stations.map(function (station) {
                logger.info("stationFilters", stationFilters, "station", station, stationFilters[station.id]);
                return React.createElement(CDropdownItem, { key: station.id },
                    React.createElement(CFormCheck, { label: station.name, checked: stationFilters[station.id], onChange: updateStationFiltersBuilder(station.id) }));
            }),
            React.createElement(CDropdownItemPlain, null, t("order.status.label")),
            React.createElement(CDropdownItem, null,
                React.createElement(CFormCheck, { label: t("order.state.new.text"), checked: statusFilters.new == true, onChange: updateStateFiltersBuilder("new") })),
            React.createElement(CDropdownItem, null,
                React.createElement(CFormCheck, { label: t("order.state.in-progress.text"), checked: statusFilters["in-progress"] == true, onChange: updateStateFiltersBuilder("in-progress") })),
            React.createElement(CDropdownItem, null,
                React.createElement(CFormCheck, { label: t("order.state.ready.text"), checked: statusFilters.ready == true, onChange: updateStateFiltersBuilder("ready") })),
            React.createElement(CDropdownItem, null,
                React.createElement(CFormCheck, { label: t("order.state.completed.text"), checked: statusFilters.completed == true, onChange: updateStateFiltersBuilder("completed") })),
            React.createElement(CDropdownItem, null,
                React.createElement(CFormCheck, { label: t("order.state.canceled.text"), checked: statusFilters.canceled == true, onChange: updateStateFiltersBuilder("canceled") })),
            React.createElement(CDropdownDivider, null),
            React.createElement(CDropdownItemPlain, null, t("order.type.label")),
            React.createElement(CDropdownItem, null,
                React.createElement(CFormCheck, { label: t("order.type.inside.text"), checked: typeFilters.inside == true, onChange: updateTypeFiltersBuilder("inside") })),
            React.createElement(CDropdownItem, null,
                React.createElement(CFormCheck, { label: t("order.type.outside.text"), checked: typeFilters.outside == true, onChange: updateTypeFiltersBuilder("outside") })),
            React.createElement(CDropdownItem, null,
                React.createElement(CFormCheck, { label: t("order.type.delivery.text"), checked: typeFilters.delivery == true, onChange: updateTypeFiltersBuilder("delivery") }))));
});
var templateObject_1;
