var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import Scrollbars from "react-custom-scrollbars-2";
import CategoryItems from "./CategoryItems";
import { Options } from "./options/Options";
import { BackButton } from "../common/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { MENU_PAGE, buildRoute } from "../../constats/Routes";
import { getMenuCategoryRequest } from "../../request/menu.request";
import { useHttpRequesPrepareHandler } from "../../service/network.service";
import LoadingBox from "../common/LoadingBox";
import { userStore } from "../../store/user.store";
import { Box } from "../common/FlexItem";
var logger = require("js-logger").get("CategoryPage");
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 0 1rem;\n    flex:1;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n"], ["\n    padding: 0 1rem;\n    flex:1;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n"])));
var BackButtonContainer = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-top: 1rem;\n"], ["\n    margin-top: 1rem;\n"])));
export var CategoryPage = React.memo(function (props) {
    var navigate = useNavigate();
    var params = useParams();
    var companyId = userStore(function (state) { return state.company.id; });
    var language = userStore(function (state) { return state.language; });
    var menu_id = params.menu_id, category_id = params.category_id;
    var categoryRequestState = useHttpRequesPrepareHandler();
    var loadCategoryData = function () {
        categoryRequestState.run(getMenuCategoryRequest(companyId, menu_id, category_id));
    };
    React.useEffect(function () {
        loadCategoryData();
    }, []);
    React.useEffect(function () {
        loadCategoryData();
    }, [language]);
    if (categoryRequestState.inProgress || !categoryRequestState.executed) {
        return React.createElement(LoadingBox, null);
    }
    return React.createElement(Container, null,
        React.createElement(Box, { flex: "none" },
            React.createElement(BackButtonContainer, { align: "center", gap: "1rem" },
                React.createElement(BackButton, { onClick: function () {
                        navigate(buildRoute(MENU_PAGE, {
                            menu_id: menu_id
                        }));
                    } }),
                categoryRequestState.result.name)),
        React.createElement(Box, { flex: 1 },
            React.createElement(Scrollbars, null,
                React.createElement(CategoryItems, null),
                React.createElement(Options, null))));
});
var templateObject_1, templateObject_2;
