import * as React from "react";
import { CFormSelect, CFormLabel } from "@coreui/react";
import { useController } from "react-hook-form";
import { randomString } from "../../../utils/StringUtils";
var FormSelectInput = function (props) {
    var label = props.label, name = props.name, control = props.control, defaultValue = props.defaultValue, rules = props.rules, disabled = props.disabled, options = props.options, placeholder = props.placeholder, style = props.style;
    var inputId = props.id ? props.id : randomString(6);
    if (placeholder && !options.find(function (option) { return option.value == null; })) {
        options.unshift({
            label: placeholder,
            value: null
        });
    }
    var _a = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, _c = _b.value, value = _c === void 0 ? null : _c, ref = _b.ref, fieldState = _a.fieldState;
    return React.createElement(React.Fragment, null,
        label && React.createElement(CFormLabel, { htmlFor: inputId }, label),
        React.createElement(CFormSelect, { onChange: onChange, onBlur: onBlur, value: value, disabled: disabled, id: inputId, ref: ref, options: options, invalid: fieldState.invalid, 
            //valid={fieldState.isDirty && !fieldState.invalid}
            style: style }));
};
export default React.memo(FormSelectInput);
