import Logger from "js-logger";
Logger.useDefaults({
    defaultLevel: Logger.INFO
});
import { initi18n } from "./locale/i18next";
initi18n(document.location.pathname.indexOf("sign-up") == -1);
import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PaymentMethods } from "./billing/method";
import { AreaEditor } from "./component/area";
import { AreaPage } from "./component/area/AreaPage";
import CheckCompany from "./component/common/CheckCompany";
import CompanyPage, { CompanyPageDesing, CompanyPageDevices, CompanyPageInfo, CompanyPageMode, CompanyPageOperations, CompanyPagePayments, CompanyPageProcessors, CompanyPageStations, CompanyPageUsers } from "./component/company";
import { CategoryPage } from "./component/menu/CategoryPage";
import Editor from "./component/menu/MenuEditor";
import MenuPage from "./component/menu/MenuPage";
import NotificationContainer from "./component/notification/NotificationContainer";
import AppScreen from "./component/screen/AppScreen";
import CompanyScreen from "./component/screen/CompanyScreen";
import InvalidPathScreen from "./component/screen/InvalidPathScreen";
import InviteScreen from "./component/screen/InviteScreen";
import RootScreen from "./component/screen/RootScreen";
import SignInResponseScreen from "./component/screen/SignInResponseScreen";
import SignInScreen from "./component/screen/SignInScreen";
import SignUpResponseScreen from "./component/screen/SignUpResponseScreen";
import SignUpScreen from "./component/screen/SignUpScreen";
import VerifyCodeScreen from "./component/screen/VerifyCodeScreen";
import { SettingsPage } from "./component/settings";
import { CompanyLegalInfo } from "./component/settings/CompanyLegalInfo";
import { Settings } from "./component/settings/Setting";
import { TariffPlans } from "./component/settings/TariffPlans";
import { APP, COMPANY, INVITE, NOT_FOUND, ROOT, SIGN_IN, SIGN_IN_RESPONSE, SIGN_UP, SIGN_UP_RESPONSE, SIGN_UP_VERIFY, SIGN_UP_INVITE_RESPONSE } from "./constats/Routes";
import { DetailsContainer, OperationMonitorPage } from "./operation-monitor";
import { OrderMonitorPage } from "./order-monitor/OrderMonitorPage";
import { OrderPage } from "./orders";
import { NFCTest } from "./test/nfc.test";
import { StatusTimers } from "./component/settings/status.timers";
import SignUpInviteResponseScreen from "./component/screen/SignUpInviteResponseScreen";
import { TipsPage } from "./component/tips";
import { OrderSettingsPage } from "./component/order.settings";
export function App(props) {
    var exclude = EXCLUDE_CHECK ? EXCLUDE_CHECK.split(",") : undefined;
    return React.createElement(CheckCompany, { exclude: exclude },
        React.createElement(BrowserRouter, { basename: BASENAME },
            React.createElement(Routes, null,
                React.createElement(Route, { path: ROOT, element: React.createElement(RootScreen, null) }),
                React.createElement(Route, { path: APP, element: React.createElement(AppScreen, null) },
                    React.createElement(Route, { path: "", element: React.createElement(Editor, null) }),
                    React.createElement(Route, { path: "menu", element: React.createElement(Editor, null) },
                        React.createElement(Route, { path: ":menu_id" },
                            React.createElement(Route, { index: true, element: React.createElement(MenuPage, null) }),
                            React.createElement(Route, { path: "c/:category_id", element: React.createElement(CategoryPage, null) }))),
                    React.createElement(Route, { path: "settings", element: React.createElement(SettingsPage, null) },
                        React.createElement(Route, { path: "", element: React.createElement(Settings, null) }),
                        React.createElement(Route, { path: "company-legal-info", element: React.createElement(CompanyLegalInfo, null) }),
                        React.createElement(Route, { path: "tariff-plans", element: React.createElement(TariffPlans, null) }),
                        React.createElement(Route, { path: "company-status-timers", element: React.createElement(StatusTimers, null) })),
                    React.createElement(Route, { path: "company", element: React.createElement(CompanyPage, null) },
                        React.createElement(Route, { index: true, element: React.createElement(CompanyPageInfo, null) }),
                        React.createElement(Route, { path: "mode", element: React.createElement(CompanyPageMode, null) }),
                        React.createElement(Route, { path: "design", element: React.createElement(CompanyPageDesing, null) }),
                        React.createElement(Route, { path: "payments", element: React.createElement(CompanyPagePayments, null) }),
                        React.createElement(Route, { path: "processors", element: React.createElement(CompanyPageProcessors, null) }),
                        React.createElement(Route, { path: "operations", element: React.createElement(CompanyPageOperations, null) }),
                        React.createElement(Route, { path: "users", element: React.createElement(CompanyPageUsers, null) }),
                        React.createElement(Route, { path: "stations", element: React.createElement(CompanyPageStations, null) }),
                        React.createElement(Route, { path: "devices", element: React.createElement(CompanyPageDevices, null) }),
                        React.createElement(Route, { path: "tips", element: React.createElement(TipsPage, null) }),
                        React.createElement(Route, { path: "order-settings", element: React.createElement(OrderSettingsPage, null) })),
                    React.createElement(Route, { path: "areas", element: React.createElement(AreaEditor, null) },
                        React.createElement(Route, { path: ":area_id", element: React.createElement(AreaPage, null) })),
                    React.createElement(Route, { path: "order-monitor", element: React.createElement(OrderMonitorPage, null) }),
                    React.createElement(Route, { path: "orders", element: React.createElement(OrderPage, null) }),
                    React.createElement(Route, { path: "operation-monitor", element: React.createElement(OperationMonitorPage, null) },
                        React.createElement(Route, { path: ":id", element: React.createElement(DetailsContainer, null) })),
                    React.createElement(Route, { path: "billing" },
                        React.createElement(Route, { path: "payment-methods", element: React.createElement(PaymentMethods, null) }))),
                React.createElement(Route, { path: SIGN_IN, element: React.createElement(SignInScreen, null) }),
                React.createElement(Route, { path: SIGN_IN_RESPONSE, element: React.createElement(SignInResponseScreen, null) }),
                React.createElement(Route, { path: SIGN_UP, element: React.createElement(SignUpScreen, null) }),
                React.createElement(Route, { path: SIGN_UP_RESPONSE, element: React.createElement(SignUpResponseScreen, null) }),
                React.createElement(Route, { path: SIGN_UP_INVITE_RESPONSE, element: React.createElement(SignUpInviteResponseScreen, null) }),
                React.createElement(Route, { path: COMPANY, element: React.createElement(CompanyScreen, null) }),
                React.createElement(Route, { path: SIGN_UP_VERIFY, element: React.createElement(VerifyCodeScreen, null) }),
                React.createElement(Route, { path: INVITE, element: React.createElement(InviteScreen, null) }),
                React.createElement(Route, { path: NOT_FOUND, element: React.createElement(InvalidPathScreen, null) }),
                React.createElement(Route, { path: "nfc-test", element: React.createElement(NFCTest, null) }))),
        React.createElement(NotificationContainer, null));
}
