var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { cilXCircle } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CForm } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { QRCode } from "react-qrcode-logo";
import styled from "styled-components";
import { Touchable } from "../../common/Touchable";
import { MembersPermissions } from "../../common/user/Permission";
import { getCompanyMemebers } from "../../request/company.request";
import { blockUserRequest, deleteUserInviteRequest, deleteUserRequest, inviateUserRequest, unblockUserRequest, updateUserRequest } from "../../request/user.request";
import { HttpApiRequest, useHttpRequesPrepareHandler } from "../../service/network.service";
import { notificationStore } from "../../store/notifications.store";
import { userStore } from "../../store/user.store";
import { copyToClipBoard } from "../../utils/BrowserUtils";
import { Box } from "../common/FlexItem";
import FormSelectInput from "../common/form/FormSelectInput";
import FormSwitchInput from "../common/form/FormSwitchInput";
import FormTextInput from "../common/form/FormTextInput";
import { HoriontalContainer } from "../common/HorizontalContainer";
import LoadingBox from "../common/LoadingBox";
import { Modal, ModalBody } from "../common/Modal";
import { SettingsPageButtons, SettingsPageContainer, SettingsPageContent, SettingsPageHeader, SettingsPageTitle } from "../settings";
import { UserDeleteConfirm } from "./user/UserDeleteConfirm";
var logger = require("js-logger").get("Users");
var UserContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex: 45%;\n    padding: 10px;\n    border: #dedede solid 1px;\n    border-radius: 10px;\n\n"], ["\n    flex: 45%;\n    padding: 10px;\n    border: #dedede solid 1px;\n    border-radius: 10px;\n\n"])));
var UsersContainer = styled(HoriontalContainer)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["    \n    flex-wrap: wrap;\n    gap: 20px;    \n    margin-top: 10px;\n    margin-bottom: 10px;\n"], ["    \n    flex-wrap: wrap;\n    gap: 20px;    \n    margin-top: 10px;\n    margin-bottom: 10px;\n"])));
var UsersRow = styled(HoriontalContainer)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    gap: 20px;\n    margin: 10px 0;\n"], ["\n    gap: 20px;\n    margin: 10px 0;\n"])));
var UserFormSection = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-top: 10px;\n"], ["\n    margin-top: 10px;\n"])));
var UserTopBarContainer = styled(HoriontalContainer)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n"], ["\n"])));
var UserTopBarTitle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-weight: bold;\n    font-size: 14px;\n"], ["\n    font-weight: bold;\n    font-size: 14px;\n"])));
var UserTopBarTitleActions = styled(HoriontalContainer)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    flex: 1;\n    justify-content: flex-start;\n    align-items: center;\n    gap: 5px;\n    margin-left: 0.5rem;\n"], ["\n    flex: 1;\n    justify-content: flex-start;\n    align-items: center;\n    gap: 5px;\n    margin-left: 0.5rem;\n"])));
var CompanyUser = React.memo(function (props) {
    var member = props.member, onSubmit = props.onSubmit, onDelete = props.onDelete, blockUser = props.blockUser, unblockUser = props.unblockUser;
    var _a = useForm({
        mode: "onChange",
        defaultValues: member
    }), control = _a.control, handleSubmit = _a.handleSubmit, formState = _a.formState, reset = _a.reset;
    var t = useTranslation().t;
    var company = userStore(function (state) { return state.company; });
    var hasPermission = userStore(function (state) { return state.hasPermission; });
    var roles = company.roles.map(function (role) { return ({
        value: role.id,
        label: role.name
    }); });
    logger.info("ForState", formState.isDirty, formState.isValid, formState.errors);
    var onCancel = function () {
        reset(member);
    };
    var disabled = !hasPermission(MembersPermissions.MEMBERS_EDIT);
    return React.createElement(UserContainer, null,
        React.createElement(UserTopBarContainer, null,
            React.createElement(UserTopBarTitle, null, member.email),
            React.createElement(UserTopBarTitleActions, null,
                React.createElement(UserTelegramSubscribe, { telegram: member.telegram }),
                !disabled && member.user_id && React.createElement(FormSwitchInput, { control: control, name: "active", disabled: disabled, onChange: function (e) {
                        //logger.info("XXX", e.target.checked);
                        e.target.checked ? unblockUser() : blockUser();
                    } })),
            hasPermission(MembersPermissions.MEMBERS_DELETE) && onDelete && React.createElement(Touchable, { onTap: onDelete },
                React.createElement(CIcon, { icon: cilXCircle, size: "xl" }))),
        React.createElement(CForm, { onSubmit: handleSubmit(onSubmit) },
            React.createElement(FormSelectInput, { label: t("role.text"), control: control, name: "role_id", options: roles, disabled: disabled }),
            React.createElement(FormTextInput, { label: t("phone.text"), control: control, name: "phone", readOnly: true, disabled: true }),
            React.createElement(UserFormSection, null,
                React.createElement(FormSwitchInput, { label: t("users.send.order.emails.text"), control: control, name: "settings.send_order_email", disabled: disabled }),
                React.createElement(FormSwitchInput, { label: t("users.send.new.reservation.mails.text"), control: control, name: "settings.send_new_reservation_mails", disabled: disabled }),
                React.createElement(FormSwitchInput, { label: t("users.send.reservation.status.mails.text"), control: control, name: "settings.send_reservation_status_email", disabled: disabled })),
            React.createElement(Box, { justify: "flex-end", gap: "5px", margin: "10px 0 0 0" },
                React.createElement(CButton, { disabled: !formState.isDirty, color: "secondary", onClick: onCancel }, t("cancel.text")),
                React.createElement(CButton, { type: "submit", disabled: !formState.isDirty || !formState.isValid }, t("save.text")))));
});
var AddUserForm = React.memo(function (props) {
    var onSubmit = props.onSubmit, onCancel = props.onCancel;
    var company = userStore(function (state) { return state.company; });
    var t = useTranslation().t;
    var _a = useForm({
        mode: "onChange"
    }), handleSubmit = _a.handleSubmit, control = _a.control, formState = _a.formState;
    var roles = company.roles.map(function (role) { return ({
        value: role.id,
        label: role.name
    }); });
    roles.unshift({
        value: null,
        label: t("user.invite.role.select")
    });
    return React.createElement(Modal, { visible: true },
        React.createElement(ModalBody, null,
            React.createElement(CForm, { onSubmit: handleSubmit(onSubmit) },
                React.createElement(FormTextInput, { control: control, name: "email", label: t("email.text"), rules: {
                        required: true,
                        pattern: /\S+@\S+\.\S+/
                    } }),
                React.createElement(FormSelectInput, { label: t("role.text"), control: control, name: "role_id", rules: {
                        required: true,
                        validate: function (val) {
                            //console.log("Validate", val );
                            return val != null && val != t("user.invite.role.select");
                        }
                    }, options: roles }),
                React.createElement(Box, { justify: "flex-end", gap: "5px", margin: "10px 0 0 0" },
                    React.createElement(CButton, { color: "secondary", onClick: onCancel }, t("cancel.text")),
                    React.createElement(CButton, { type: "submit", disabled: !formState.isDirty || !formState.isValid }, t("save.text"))))));
});
export var CompanyUsers = React.memo(function (props) {
    var _a = React.useState(false), showInviteUser = _a[0], setShowInviteUser = _a[1];
    var _b = React.useState(null), deleteMemeber = _b[0], setDeleteMember = _b[1];
    var hasPermission = userStore(function (state) { return state.hasPermission; });
    var t = useTranslation().t;
    var companyMembersState = useHttpRequesPrepareHandler();
    //const addCompanyMemberStatus = useHttpRequesPreparetHandler();
    var refreshData = function () {
        companyMembersState.run(getCompanyMemebers());
    };
    var closeDeleteMemeber = function () {
        setDeleteMember(null);
    };
    var openConfirmDelete = function (member) {
        setDeleteMember(member);
    };
    React.useEffect(function () {
        refreshData();
    }, []);
    var inviteUser = function (values) {
        HttpApiRequest(inviateUserRequest(values.email, values.role_id))
            .promise
            .then(function () {
            notificationStore.getState().addNotification({
                message: t("invite.user.success.message", {
                    email: values.email
                }),
                type: "info"
            });
        })
            .catch(function (err) {
            logger.error(err);
        })
            .finally(function () {
            refreshData();
            setShowInviteUser(false);
        });
    };
    var updateUser = function (user) { return function (values) {
        logger.info("updateUser", values);
        HttpApiRequest(updateUserRequest(user.user_id, values))
            .promise
            .catch(function (err) {
            logger.error(err);
        })
            .finally(function () {
            refreshData();
            setShowInviteUser(false);
        });
    }; };
    var deleteUser = function (item) {
        HttpApiRequest(item.user_id ? deleteUserRequest(item.user_id) : deleteUserInviteRequest(item.invite_id))
            .promise
            .catch(function (err) {
            logger.error(err);
        })
            .finally(function () {
            setDeleteMember(null);
            refreshData();
        });
    };
    var blockUser = function (item) {
        HttpApiRequest(blockUserRequest(item.user_id))
            .promise
            .catch(function (err) {
            logger.error(err);
        })
            .finally(function () {
            setDeleteMember(null);
            refreshData();
        });
    };
    var unblockUser = function (item) {
        HttpApiRequest(unblockUserRequest(item.user_id))
            .promise
            .catch(function (err) {
            logger.error(err);
        })
            .finally(function () {
            setDeleteMember(null);
            refreshData();
        });
    };
    if (companyMembersState.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    var members = companyMembersState.result ? companyMembersState.result : [];
    return React.createElement(SettingsPageContainer, null,
        React.createElement(SettingsPageHeader, null,
            React.createElement(SettingsPageTitle, null, t("users.text")),
            React.createElement(SettingsPageButtons, null, hasPermission(MembersPermissions.MEMBERS_INVITE) && React.createElement(CButton, { onClick: function () {
                    setShowInviteUser(true);
                } }, t("company.user.add.button")))),
        React.createElement(SettingsPageContent, null,
            React.createElement(UsersContainer, null, members.map(function (member, index) {
                return React.createElement(CompanyUser, { onDelete: function () {
                        openConfirmDelete(member);
                    }, blockUser: function () {
                        blockUser(member);
                    }, unblockUser: function () {
                        unblockUser(member);
                    }, onSubmit: updateUser(member), member: member, key: member.email });
            })),
            showInviteUser && React.createElement(AddUserForm, { onCancel: function () {
                    setShowInviteUser(false);
                }, onSubmit: inviteUser }),
            deleteMemeber && React.createElement(UserDeleteConfirm, { onClose: closeDeleteMemeber, onConfirm: deleteUser, member: deleteMemeber })));
});
var LinkContainer = styled(Box)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    margin-top: 1rem;\n"], ["\n    margin-top: 1rem;\n"])));
var UserTelegramSubscribe = React.memo(function (props) {
    var telegram = props.telegram;
    var _a = React.useState(false), showQrModal = _a[0], setShowQrModal = _a[1];
    var _b = React.useState(false), copied = _b[0], setCopied = _b[1];
    var link = "https://telegram.me/Ordish_bot?start=".concat(telegram);
    var toggleQRModal = function () {
        setShowQrModal(!showQrModal);
    };
    var handleClick = function (e) {
        e.preventDefault();
        toggleQRModal();
    };
    var copyLink = function () {
        copyToClipBoard(link);
        setTimeout(function () {
            setCopied(true);
        }, 100);
    };
    return React.createElement(React.Fragment, null,
        telegram && React.createElement("a", { href: link, onClick: handleClick },
            React.createElement(FontAwesomeIcon, { icon: faTelegram, size: "xl" })),
        showQrModal && React.createElement(Modal, { visible: true, onClose: toggleQRModal },
            React.createElement(ModalBody, null,
                React.createElement(Box, { flexDirection: "column", align: "center" },
                    React.createElement(QRCode, { id: "qr", value: link }),
                    React.createElement(LinkContainer, { gap: "5px" },
                        React.createElement(CButton, { color: "secondary", onClick: toggleQRModal }, "Close"),
                        React.createElement(CButton, { onClick: copyLink }, copied ? "Copied" : "Copy Link"))))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
