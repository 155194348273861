var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { Box } from "./FlexItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
//import { CButton } from "@coreui/react";
import { faTrash, faPen, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../common/Button";
var logger = require("js-logger").get("ListItem");
import { isFunction } from "underscore";
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin:0.4rem 0;\n"], ["\n    margin:0.4rem 0;\n"])));
var Content = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background-color: ", ";\n    padding: 1rem;\n    min-height: 2.5rem;\n    border-radius: 0.5rem;\n"], ["\n    background-color: ", ";\n    padding: 1rem;\n    min-height: 2.5rem;\n    border-radius: 0.5rem;\n"])), function (props) { return props.active ? "#feede9" : "#f5f5f6"; });
var ContentPlace = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var Actions = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var DragHandle = styled(Box)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n"], ["\n"])));
export var ListItem = React.memo(function (props) {
    var children = props.children, sortable = props.sortable, onDelete = props.onDelete, onEdit = props.onEdit, actions = props.actions, item = props.item, active = props.active;
    return React.createElement(ListItemBase, { active: active, sortable: sortable, item: item, actions: React.createElement(React.Fragment, null,
            onEdit && React.createElement(EditButton, { onClick: function () {
                    onEdit(item);
                } }),
            onDelete && React.createElement(DeleteButton, { onClick: function () {
                    onDelete(item);
                } }),
            actions) }, children);
    // <Container flex={1} gap="0.5rem">
    //     {sortable && <DragHandle className="drag-handle" align="center" flex="none">
    //         <FontAwesomeIcon icon={faGripVertical} size="lg" color="#e5e5ea" />
    //     </DragHandle>}
    //     <Content>
    //         <ContentPlace align="center" flex={1}>
    //             {children}
    //         </ContentPlace>
    //         <Actions flex="none" gap="0.3rem">
    //             {onEdit && <Button onClick={() => {
    //                 onEdit(item)
    //             }} className="btn-primary">
    //                 <FontAwesomeIcon icon={faPen} />
    //             </Button>}
    //             {onDelete && <Button onClick={() => {
    //                 onDelete(item)
    //             }} className="btn-primary">
    //                 <FontAwesomeIcon icon={faTrash} />
    //             </Button>}
    //             {actions}
    //         </Actions>
    //     </Content>
    // </Container>
});
export var ListItemBase = React.memo(function (props) {
    var children = props.children, sortable = props.sortable, actions = props.actions, item = props.item, active = props.active;
    logger.info("[List Item Base]", item, active);
    return React.createElement(Container, { flex: 1, gap: "0.5rem" },
        sortable && React.createElement(DragHandle, { className: "drag-handle", align: "center", flex: "none" },
            React.createElement(FontAwesomeIcon, { icon: faGripVertical, size: "lg", color: "#e5e5ea" })),
        React.createElement(Content, { active: active },
            React.createElement(ContentPlace, { align: "center", flex: 1 }, children),
            React.createElement(ListItemActions, { item: item },
                isFunction(actions) && actions(item),
                actions)));
});
export var EditButton = function (props) {
    var onClick = props.onClick, item = props.item;
    return React.createElement(Button, { onClick: function () {
            logger.info("[EditButton]", "onClick", item);
            onClick(item);
        }, className: "btn-success" },
        React.createElement(FontAwesomeIcon, { icon: faPen }));
    // return <div onClick={() => {
    //     onClick(item);
    // }}>
    //     edit
    // </div>
};
export var DeleteButton = React.memo(function (props) {
    var onClick = props.onClick, item = props.item;
    return React.createElement(Button, { onClick: function () {
            onClick(item);
        }, className: "btn-danger" },
        React.createElement(FontAwesomeIcon, { icon: faTrash }));
});
export var QrButton = React.memo(function (props) {
    var onClick = props.onClick, item = props.item;
    return React.createElement(Button, { onClick: function () {
            onClick(item);
        }, className: "btn-info" },
        React.createElement(FontAwesomeIcon, { icon: faQrcode }));
});
export var ListItemActions = React.memo(function (props) {
    var children = props.children, item = props.item;
    return React.createElement(Actions, { flex: "none", gap: "0.3rem" }, children);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
