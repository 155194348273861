var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { CFormInput, CFormLabel } from "@coreui/react";
import { COMPANY_ENDPOINT } from "../../constats/ApiEndpoints";
import { userStore } from "../../store/user.store";
import { useHttpRequestHandler, useHttpRequesPrepareHandler } from "../../service/network.service";
import { getCompanyMediaRequest, getUploadCompanyMediaRequest } from "../../request/company.request";
import LoadingBox from "../common/LoadingBox";
import { SettingsPageContainer, SettingsPageContent, SettingsPageHeader, SettingsPageTitle } from "../settings";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
var logger = require("js-logger").get("Media");
var MediaContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 10px 0;\n"], ["\n    padding: 10px 0;\n"])));
var MediaUploadContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    // flex: 1;\n    display: flex;\n    height: 100px; \n    align-items: center;\n    justify-content: center\n\n"], ["\n    // flex: 1;\n    display: flex;\n    height: 100px; \n    align-items: center;\n    justify-content: center\n\n"])));
var MediaImage = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100%;\n    height: 100px;\n    background: url(", ") no-repeat center center;\n    background-size: contain\n"], ["\n    width: 100%;\n    height: 100px;\n    background: url(", ") no-repeat center center;\n    background-size: contain\n"])), function (props) { return props.src; });
var getMediaURL = function (item) {
    if (item.mediaURL) {
        return item.mediaURL;
    }
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/media/").concat(item.type, "?token=").concat(token);
};
var MediaItem = React.memo(function (props) {
    var item = props.item, type = props.type, uploadItem = props.uploadItem, disabled = props.disabled;
    return React.createElement(MediaContainer, null,
        item && React.createElement(MediaImage, { src: getMediaURL(item) }),
        React.createElement(MediaUploadContainer, null,
            React.createElement("div", null,
                React.createElement(CFormLabel, null,
                    "Upload ",
                    type),
                React.createElement(CFormInput, { disabled: disabled, type: "file", onChange: function (file) {
                        uploadItem(type, file.target.files[0]);
                    } }))));
});
var CompanyMedia = React.memo(function (props) {
    var t = useTranslation().t;
    var getCompanyMediaState = useHttpRequestHandler(getCompanyMediaRequest());
    var uploadCompanyMediaState = useHttpRequesPrepareHandler();
    var uploadItem = function (type, file) {
        logger.info("Upload", type, file);
        uploadCompanyMediaState.run(getUploadCompanyMediaRequest(type, file))
            .then(getCompanyMediaState.run);
    };
    if (getCompanyMediaState.inProgress || uploadCompanyMediaState.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    var items = getCompanyMediaState.result ? getCompanyMediaState.result : [];
    return React.createElement(SettingsPageContainer, null,
        React.createElement(SettingsPageHeader, null,
            React.createElement(SettingsPageTitle, null, t("company.sidebar.images"))),
        React.createElement(SettingsPageContent, null,
            React.createElement(MediaContainer, null,
                React.createElement(MediaItem, { uploadItem: uploadItem, type: "logo", item: items.find(function (item) { return item.type == "logo"; }) }),
                React.createElement(MediaItem, { uploadItem: uploadItem, type: "cover", item: items.find(function (item) { return item.type == "cover"; }) }))));
});
export default CompanyMedia;
var templateObject_1, templateObject_2, templateObject_3;
