var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { CFormInput, CFormLabel, CFormFeedback, CTooltip, CInputGroup, CInputGroupText } from "@coreui/react";
import { useController } from "react-hook-form";
import { randomString } from "../../../utils/StringUtils";
var logger = require("js-logger").get("FormTextInput");
import { useTranslation } from "react-i18next";
import { userStore } from "../../../store/user.store";
import styled from "styled-components";
var currencyItems = require("../../../../assets/json/currencies.json");
import { langDirection } from "../../../locale/i18next";
var GroupText = styled(CInputGroupText)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: #fff;\n    // border-color: #fff;\n"], ["\n    background: #fff;\n    // border-color: #fff;\n"])));
var LeftInput = styled(CFormInput)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border-right: 0;\n    \n"], ["\n    border-right: 0;\n    \n"])));
var RightInput = styled(CFormInput)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border-left: 0;\n"], ["\n    border-left: 0;\n"])));
var Group = styled(CInputGroup)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: ", "\n"], ["\n    display: flex;\n    flex-direction: ", "\n"])), function (props) { return props.direction == "rtl" ? "row-reverse" : "row"; });
export var CurrencySymbolPlacements = {
    AED: '# !',
    AFN: '#!',
    ALL: '# !',
    AMD: '#!',
    ANG: '!#',
    AOA: '!#',
    ARS: '!#',
    AUD: '!#',
    AWG: '!#',
    AZN: '!#',
    BAM: '!#',
    BBD: '!#',
    BDT: '!#',
    BGN: '!#',
    BHD: '!#',
    BIF: '!#',
    BMD: '!#',
    BND: '!#',
    BOB: '!#',
    BRL: '!#',
    BSD: '!#',
    BTC: '!#',
    BTN: '!#',
    BWP: '!#',
    BYR: '!#',
    BYN: '!#',
    BZD: '!#',
    CAD: '!#',
    CDF: '!#',
    CHF: '! #',
    CLP: '!#',
    CNY: '!#',
    COP: '!#',
    CRC: '!#',
    CUC: '!#',
    CUP: '!#',
    CVE: '!#',
    CZK: '# !',
    DJF: '!#',
    DKK: '# !',
    DOP: '!#',
    DZD: '!#',
    EEK: '!#',
    EGP: '!#',
    ERN: '!#',
    ETB: '!#',
    ETH: '!#',
    EUR: '!#',
    FJD: '!#',
    FKP: '!#',
    GBP: '!#',
    GEL: '!#',
    GGP: '!#',
    GHC: '!#',
    GHS: '!#',
    GIP: '!#',
    GMD: '!#',
    GNF: '!#',
    GTQ: '!#',
    GYD: '!#',
    HKD: '!#',
    HNL: '!#',
    HRK: '!#',
    HTG: '!#',
    HUF: '!#',
    IDR: '!#',
    ILS: '!#',
    IMP: '!#',
    INR: '!#',
    IQD: '!#',
    IRR: '!#',
    ISK: '# !',
    JEP: '!#',
    JMD: '!#',
    JOD: '!#',
    JPY: '!#',
    KES: '!#',
    KGS: '!#',
    KHR: '!#',
    KMF: '!#',
    KPW: '!#',
    KRW: '!#',
    KWD: '! #',
    KYD: '!#',
    KZT: '!#',
    LAK: '!#',
    LBP: '!#',
    LKR: '!#',
    LRD: '!#',
    LSL: '!#',
    LTC: '!#',
    LTL: '!#',
    LVL: '!#',
    LYD: '!#',
    MAD: '!#',
    MDL: '!#',
    MGA: '!#',
    MKD: '!#',
    MMK: '!#',
    MNT: '!#',
    MOP: '!#',
    MRO: '!#',
    MRU: '!#',
    MUR: '!#',
    MVR: '!#',
    MWK: '!#',
    MXN: '!#',
    MYR: '!#',
    MZN: '!#',
    NAD: '!#',
    NGN: '!#',
    NIO: '!#',
    NOK: '# !',
    NPR: '!#',
    NZD: '!#',
    OMR: '!#',
    PAB: '!#',
    PEN: '!#',
    PGK: '!#',
    PHP: '!#',
    PKR: '!#',
    PLN: '!#',
    PYG: '!#',
    QAR: '!#',
    RMB: '!#',
    RON: '!#',
    RSD: '!#',
    RUB: '# !',
    RWF: '!#',
    SAR: '!#',
    SBD: '!#',
    SCR: '!#',
    SDG: '!#',
    SEK: '# !',
    SGD: '!#',
    SHP: '!#',
    SLL: '!#',
    SOS: '!#',
    SRD: '!#',
    SSP: '!#',
    STD: '!#',
    STN: '!#',
    SVC: '!#',
    SYP: '!#',
    SZL: '!#',
    THB: '!#',
    TJS: '!#',
    TMT: '!#',
    TND: '!#',
    TOP: '!#',
    TRL: '!#',
    TRY: '# !',
    TTD: '!#',
    TVD: '!#',
    TWD: '! #',
    TZS: '!#',
    UAH: '!#',
    UGX: '!#',
    USD: '!#',
    UYU: '!#',
    UZS: '!#',
    VEF: '!#',
    VND: '!#',
    VUV: '!#',
    WST: '!#',
    XAF: '!#',
    XBT: '!#',
    XCD: '!#',
    XOF: '!#',
    XPF: '!#',
    YER: '!#',
    ZAR: '!#',
    ZMK: '!#',
    ZMW: '!#',
    ZWD: '!#',
    ZWL: '!#'
};
var FormCurrencyInput = function (props) {
    var control = props.control, name = props.name, defaultValue = props.defaultValue, label = props.label, type = props.type, rules = props.rules, disabled = props.disabled, style = props.style, inputRef = props.inputRef, onFocus = props.onFocus, onBlur = props.onBlur, info = props.info, size = props.size, placeholder = props.placeholder, className = props.className;
    var language = userStore(function (state) { return state.language; });
    var _a = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _b = _a.field, onChange = _b.onChange, _c = _b.value, value = _c === void 0 ? defaultValue ? defaultValue : "" : _c, fieldState = _a.fieldState;
    var t = useTranslation().t;
    var inputId = props.id ? props.id : randomString(6);
    var company = userStore(function (state) { return state.company; });
    //logger.info("Errors", fieldState.error);
    var currencyCode = company.currency ? company.currency : "USD";
    var currencyPlacementPattern = CurrencySymbolPlacements[currencyCode];
    var getPlacement = function (pattern) {
        var placement = null;
        switch (pattern) {
            case "!#":
                placement = "left";
                break;
            case "! #":
                placement = "left";
                break;
            case "#!":
                placement = "right";
                break;
            case "# !":
                placement = "right";
                break;
        }
        return placement;
    };
    var currencyPlacement = currencyPlacementPattern ? getPlacement(currencyPlacementPattern) : "left";
    var currency = currencyItems.find(function (item) { return item.code == currencyCode; });
    var regExpStr = "^[0-9]\\d*(((,\\d{3}){1})?(\\.\\d{0,".concat(currency.decimalDigits, "})?)$");
    //logger.info("regExpStr", regExpStr);
    var currencyRegExp = currency.decimalDigits > 0 ? new RegExp(regExpStr) : /^\d+$/;
    var handleInputChange = function (e) {
        var val = e.target.value;
        if (!val || currencyRegExp.test(val)) {
            onChange(e);
        }
    };
    var Input = React.createElement(Group, { size: size, direction: langDirection[language] },
        currencyPlacement == "left" && React.createElement(React.Fragment, null,
            React.createElement(GroupText, null, currency.symbolNative),
            React.createElement(RightInput, { onChange: handleInputChange, 
                // onBlur={onBlur}
                value: value, ref: inputRef, id: inputId, type: type, disabled: disabled, invalid: fieldState.invalid, 
                //valid={fieldState.isDirty && !fieldState.invalid}
                style: style, onBlur: onBlur, onFocus: onFocus, size: size, placeholder: placeholder, className: className })),
        currencyPlacement == "right" && React.createElement(React.Fragment, null,
            React.createElement(LeftInput, { onChange: handleInputChange, 
                // onBlur={onBlur}
                value: value, ref: inputRef, id: inputId, type: type, disabled: disabled, invalid: fieldState.invalid, 
                //valid={fieldState.isDirty && !fieldState.invalid}
                style: style, onBlur: onBlur, onFocus: onFocus, size: size, placeholder: placeholder, className: className }),
            React.createElement(GroupText, null, currency.symbolNative)));
    return React.createElement(React.Fragment, null,
        label && React.createElement(CFormLabel, { htmlFor: inputId }, label),
        info && React.createElement(CTooltip, { content: info }, Input),
        !info && Input,
        fieldState.invalid && React.createElement(CFormFeedback, { invalid: true },
            fieldState.error.message && fieldState.error.message,
            !fieldState.error.message && t("form.error.".concat(fieldState.error.type.toLowerCase(), ".message"))));
};
export default FormCurrencyInput;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
