var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import * as _ from "underscore";
import styled from "styled-components";
export var Box = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: ", ";\n    flex: ", ";\n    gap: ", ";\n    justify-content: ", ";\n    align-items: ", ";\n    margin: ", ";\n    wrap: ", ";\n    flex-direction: ", ";\n"], ["\n    display: ", ";\n    flex: ", ";\n    gap: ", ";\n    justify-content: ", ";\n    align-items: ", ";\n    margin: ", ";\n    wrap: ", ";\n    flex-direction: ", ";\n"])), function (props) { return props.display ? props.display : "flex"; }, function (props) { return props.flex ? props.flex : "inherit"; }, function (props) { return props.gap !== undefined ? props.gap : 0; }, function (props) { return props.justify !== undefined ? props.justify : "inherit"; }, function (props) { return props.align !== undefined ? props.align : "inherit"; }, function (props) { return props.margin ? props.margin : "none"; }, function (props) { return props.wrap ? props.wrap : "inherit"; }, function (props) { return props.flexDirection ? props.flexDirection : "row"; });
var getFlexStyle = function (props) {
    var style = {
        display: "flex"
    };
    if (props.flex) {
        style.flex = props.flex;
    }
    if (props.justify) {
        style.justifyContent = props.justify;
    }
    if (props.align) {
        style.alignItems = props.align;
    }
    if (props.flexDirection) {
        style.flexDirection = props.flexDirection;
    }
    if (props.gap) {
        style.gap = (typeof props.gap) == "string" ? props.gap : "".concat(props.gap, "px");
    }
    if (props.pad) {
        style.padding = (typeof props.pad) == "string" ? props.pad : "".concat(props.pad, "px");
    }
    if (props.style) {
        style = _.extend(style, props.style);
    }
    return style;
};
function FlexItem(props) {
    var children = props.children;
    var style = getFlexStyle(props);
    return React.createElement("div", { style: style }, children);
}
export default React.memo(FlexItem);
var templateObject_1;
