var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { CButton, CForm, CFormCheck } from "@coreui/react";
import { useHttpRequestHandler } from "../../service/network.service";
import { getMenusWithCategories } from "../../request/area.request";
import { useForm } from "react-hook-form";
import FormTextInput from "../common/form/FormTextInput";
import { useTranslation } from "react-i18next";
import FormCheckboxInput from "../common/form/FormCheckboxInput";
import styled from "styled-components";
var logger = require("js-logger").get("AreaForm");
import { FormRow } from "../../common/FormRow";
var Root = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 0;\n    margin: 0;\n    & ul li {\n        margin-left: 0.75rem;\n    }    \n"], ["\n    padding: 0;\n    margin: 0;\n    & ul li {\n        margin-left: 0.75rem;\n    }    \n"])));
var Leaf = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding:0;\n    margin: 0;\n"], ["\n    padding:0;\n    margin: 0;\n"])));
var BottomContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    padding: 10px 0 0 0;\n    & .form-check{\n        margin: 0.5rem 0;\n    }\n"], ["\n    display: flex;\n    padding: 10px 0 0 0;\n    & .form-check{\n        margin: 0.5rem 0;\n    }\n"])));
var BottomItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    flex: 1\n"], ["\n    flex: 1\n"])));
var ButtonsContainer = styled(BottomContainer)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    justify-content: flex-end;\n    gap: 10px;\n"], ["\n    justify-content: flex-end;\n    gap: 10px;\n"])));
var ErrorMessage = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    color: red;\n    font-size: 12px;\n"], ["\n    color: red;\n    font-size: 12px;\n"])));
export var AreaForm = React.memo(function (props) {
    var defaultValues = props.defaultValues, onCancel = props.onCancel, onSubmit = props.onSubmit, edit = props.edit;
    var t = useTranslation().t;
    var menuState = useHttpRequestHandler(getMenusWithCategories());
    var _a = useForm({
        mode: "onChange",
        defaultValues: defaultValues
    }), control = _a.control, handleSubmit = _a.handleSubmit, watch = _a.watch, getValues = _a.getValues, setValue = _a.setValue, formState = _a.formState;
    var cashEnabled = watch("paymentMethods.cash");
    var posEnabled = watch("paymentMethods.pos");
    var categories = watch("categories") || {};
    var menus = menuState.result ? menuState.result : [];
    var checkedCategories = Object.keys(categories).filter(function (key) { return categories[key]; });
    //logger.info("categories", categories);
    var checkedMenu = {};
    var toggleMenuChecked = function (menuId) { return function (e) {
        var menu = menus.find(function (item) { return item.id == menuId; });
        if (menu) {
            var target_1 = e.target;
            menu.categories.forEach(function (category) {
                setValue("categories.".concat(category.id), target_1.checked);
            });
        }
    }; };
    menus.forEach(function (menu) {
        checkedMenu[menu.id] = true;
        menu.categories.forEach(function (category) {
            if (!categories[category.id]) {
                checkedMenu[menu.id] = false;
            }
        });
    });
    logger.info("isDirty: ".concat(formState.isDirty, ", isValid: ").concat(formState.isValid));
    return React.createElement(CForm, { onSubmit: handleSubmit(onSubmit) },
        React.createElement(FormRow, null,
            React.createElement(FormTextInput, { control: control, name: "name", label: t("name.text"), rules: {
                    required: true,
                    minLength: 3
                } })),
        React.createElement(BottomContainer, null,
            React.createElement(BottomItem, null,
                React.createElement(FormCheckboxInput, { control: control, name: "paymentMethods.cash", label: t("payment.cash"), rules: {
                        validate: function (value) {
                            if (!value && !posEnabled) {
                                return t("form.area.payment.method.error");
                            }
                            return undefined;
                        }
                    } }),
                React.createElement(FormCheckboxInput, { control: control, name: "paymentMethods.pos", label: t("payment.pos"), rules: {
                        validate: function (value) {
                            if (!value && !cashEnabled) {
                                return t("form.area.payment.method.error");
                            }
                            return undefined;
                        }
                    } }),
                (formState.isDirty && (!posEnabled && !cashEnabled)) && React.createElement(ErrorMessage, null, t("form.area.payment.method.error"))),
            React.createElement(BottomItem, null,
                menus.map(function (menuItem, idx) {
                    return React.createElement(Root, { key: menuItem.id },
                        React.createElement(Leaf, null,
                            React.createElement(CFormCheck, { label: menuItem.name, checked: checkedMenu[menuItem.id], onChange: toggleMenuChecked(menuItem.id) })),
                        React.createElement(Root, null, menuItem.categories.map(function (item) { return React.createElement(Leaf, null,
                            React.createElement(FormCheckboxInput, { control: control, name: "categories.".concat(item.id), label: item.name })); })));
                }),
                (formState.isDirty && !Object.keys(checkedCategories).length) && React.createElement(ErrorMessage, null, t("form.area.category.error")))),
        !edit && React.createElement(React.Fragment, null,
            React.createElement(FormRow, null,
                React.createElement(FormTextInput, { control: control, name: "data.places_count", label: "Places count", rules: {
                        required: true,
                        pattern: /\d+/,
                        min: 0
                    } })),
            React.createElement(FormRow, null,
                React.createElement(FormTextInput, { control: control, name: "data.start_from", label: "Start From", rules: {
                        required: true,
                        pattern: /\d+/,
                        min: 0
                    } }))),
        React.createElement(ButtonsContainer, null,
            React.createElement(CButton, { color: "secondary", onClick: onCancel }, t("cancel.text")),
            React.createElement(CButton, { color: "success", type: "submit", disabled: !formState.isDirty || !formState.isValid }, t("save.text"))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
