var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
//const FacebookIconSvg = require("../../assets/svg/facebook.svg");
//const GoogleIconSvg = require("../../assets/svg/google.svg");
import FacebookIconSvg from "../../../assets/svg/facebook.svg";
import GoogleIconSvg from "../../../assets/svg/google.svg";
var Container = styled.div.attrs(function (props) { return props; })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n    align-items: center;    \n    // height 30px;\n    margin: 4px 0;\n    border:#ccc solid 1px;\n    border-radius: 4px;\n    padding: 2px 5px;    \n    cursor: pointer;\n    opacity: ", "\n"], ["\n    display: flex;\n    flex-direction: row;\n    align-items: center;    \n    // height 30px;\n    margin: 4px 0;\n    border:#ccc solid 1px;\n    border-radius: 4px;\n    padding: 2px 5px;    \n    cursor: pointer;\n    opacity: ", "\n"])), function (props) { return props.disabled ? "0.5" : "1"; });
var Icon = styled.div.attrs(function (props) { return props; })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 35px;\n    height: 35px;\n    background: url(", ") no-repeat\n"], ["\n    width: 35px;\n    height: 35px;\n    background: url(", ") no-repeat\n"])), function (props) { return props.icon == "facebook" ? FacebookIconSvg : GoogleIconSvg; });
var Text = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: 12px;\n    font-weight: bold;\n    margin-left: 5px;\n"], ["\n    font-size: 12px;\n    font-weight: bold;\n    margin-left: 5px;\n"])));
var SocialButton = React.memo(function (props) {
    var onClick = props.disabled ? undefined : props.onClick;
    var className = props.className;
    return React.createElement(Container, { disabled: props.disabled, onClick: onClick, className: className },
        React.createElement(Icon, { icon: props.icon }),
        React.createElement(Text, { className: "noselect" }, props.text));
});
export default SocialButton;
var templateObject_1, templateObject_2, templateObject_3;
