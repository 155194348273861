var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { OrderTable } from "./OrderTable";
import { Box } from "../component/common/FlexItem";
import styled from "styled-components";
import { PaymentStatus } from "../common/type";
import { Modal, ModalBody, ModalHeader, ModalHeaderTitle } from "../component/common/Modal";
import { formatCurrency } from "../utils/CurrencyUtils";
import Scrollbars from "react-custom-scrollbars-2";
import { Button } from "../common/Button";
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 100%;\n    position: relative;\n    padding: 0 1rem;\n"], ["\n    height: 100%;\n    position: relative;\n    padding: 0 1rem;\n"])));
var Row = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    display: inline-flex;\n    padding: 1rem 0;\n    border-bottom:", ";    \n"], ["\n    display: flex;\n    display: inline-flex;\n    padding: 1rem 0;\n    border-bottom:", ";    \n"])), function (props) { return props.noborder ? "none" : "#ccc solid 1px"; });
var TotalRow = styled(Row)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border-bottom: 0;\n    border-top: #ccc solid 1px;\n"], ["\n    border-bottom: 0;\n    border-top: #ccc solid 1px;\n"])));
var ChildRow = styled(Row)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: 0.5rem 0;\n    margin-left: 0.5rem;\n"], ["\n    padding: 0.5rem 0;\n    margin-left: 0.5rem;\n"])));
var RowLabel = styled(Box)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    flex: 1\n"], ["\n    flex: 1\n"])));
var RowValue = styled(Box)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-weight: bold;\n"], ["\n    font-weight: bold;\n"])));
var Section = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin-top: 1.5rem;\n"], ["\n    margin-top: 1.5rem;\n"])));
var SectionTitle = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    font-size: 0.8rem;\n    margin-bottom: 0.3rem;\n"], ["\n    font-size: 0.8rem;\n    margin-bottom: 0.3rem;\n"])));
// const OrderInfoContainer = styled.
var Buttons = styled(Box)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    margin: 1rem 0;\n    justify-content: center\n"], ["\n    margin: 1rem 0;\n    justify-content: center\n"])));
var OrderItem = React.memo(function (props) {
    var item = props.item, currency = props.currency;
    return React.createElement(React.Fragment, null,
        React.createElement(Row, { key: item.id, noborder: true },
            React.createElement(RowLabel, null, item.name),
            React.createElement(RowValue, { flex: "none" }, formatCurrency(item.price, currency))),
        item.options.map(function (option) { return React.createElement(ChildRow, { key: option.id, noborder: true },
            React.createElement(RowLabel, null,
                option.optionName,
                ": ",
                option.name),
            React.createElement(RowValue, { flex: "none" }, formatCurrency(option.price, currency))); }));
});
var OrderInfo = React.memo(function (props) {
    var order = props.order;
    var info = order.info;
    return React.createElement(Box, { flexDirection: "column" },
        React.createElement(Section, null,
            React.createElement(SectionTitle, null, "Delivery Info"),
            React.createElement(Box, { flexDirection: "column" },
                React.createElement(Row, null,
                    React.createElement(RowLabel, null, "Place"),
                    React.createElement(RowValue, null, info.place)),
                info.type == "Inside" && React.createElement(React.Fragment, null, info.comment && React.createElement(Row, null,
                    React.createElement(RowLabel, null, "Comment"),
                    React.createElement(RowValue, null, info.comment))),
                info.type == "Take away" && React.createElement(React.Fragment, null,
                    info.from && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "From"),
                        React.createElement(RowValue, null, info.from)),
                    info.when && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "When"),
                        React.createElement(RowValue, null, info.when)),
                    info.comment && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "Comment"),
                        React.createElement(RowValue, null, info.comment))),
                info.type == "Delivery" && React.createElement(React.Fragment, null,
                    info.from && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "From"),
                        React.createElement(RowValue, null, info.from)),
                    info.when && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "When"),
                        React.createElement(RowValue, null, info.when)),
                    info.address && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "Address"),
                        React.createElement(RowValue, null, info.address)),
                    info.recipient && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "Recipient"),
                        React.createElement(RowValue, null, info.recipient)),
                    info.comment && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "Comment"),
                        React.createElement(RowValue, null, info.comment))))),
        (info.name || info.phone) && React.createElement(Section, null,
            React.createElement(SectionTitle, null, "Client Info"),
            React.createElement(Box, { flexDirection: "column" },
                info.name && React.createElement(Row, null,
                    React.createElement(RowLabel, null, "Name"),
                    React.createElement(RowValue, null, info.name)),
                info.phone && React.createElement(Row, null,
                    React.createElement(RowLabel, null, "Phone"),
                    React.createElement(RowValue, null, info.phone)))),
        (!order.payment || order.payment.status !== PaymentStatus.COMPLETED) && React.createElement(Buttons, null,
            React.createElement(Button, { color: "primary" }, "Add Payment")));
});
var getOrderTotal = function (items) {
    var prices = [];
    items.forEach(function (item) {
        prices.push(item.price);
        item.options.forEach(function (opt) {
            prices.push(opt.price);
        });
    });
    return prices.reduce(function (a, b) { return a + b; }, 0);
};
export var OrderPage = React.memo(function (props) {
    var _a = React.useState(null), selected = _a[0], setSelected = _a[1];
    var _b = React.useState([]), checked = _b[0], setChecked = _b[1];
    return React.createElement(Container, { flex: 1 },
        React.createElement(OrderTable, { selected: selected, setSelected: setSelected, checked: checked, setChecked: setChecked }),
        selected && React.createElement(Modal, { visible: true },
            React.createElement(ModalHeader, { onClose: function () {
                    setSelected(null);
                } },
                React.createElement(ModalHeaderTitle, null, "Order #".concat(selected.name))),
            React.createElement(ModalBody, null,
                React.createElement(Scrollbars, null,
                    React.createElement(Box, { flexDirection: "column" },
                        React.createElement(Row, null,
                            React.createElement(RowLabel, null, "Type"),
                            React.createElement(RowValue, { flex: "none" }, selected.info.type)),
                        React.createElement(Section, null,
                            React.createElement(SectionTitle, null, "Goods"),
                            React.createElement(Box, { flexDirection: "column" },
                                selected.items.map(function (item) { return React.createElement(OrderItem, { key: item.id, item: item, currency: selected.currency }); }),
                                React.createElement(TotalRow, null,
                                    React.createElement(RowLabel, null, "Total"),
                                    React.createElement(RowValue, null, formatCurrency(getOrderTotal(selected.items), selected.currency))))),
                        React.createElement(OrderInfo, { order: selected }))))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
