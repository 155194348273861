var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Modal } from "../component/common/Modal";
import { BoardingButton } from "./button";
import styled from "styled-components";
//import { BoardingScreen } from "./screen";
import { Screen1 } from "./screen1";
import { Screen2 } from "./screen2";
import { Screen3 } from "./screen3";
import { Screen4 } from "./screen4";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import { ButtonsContent, ButtonLeft, ButtonRight, Bullets, Bullet } from "./screen";
import { useTranslation } from "react-i18next";
var logger = require("js-logger").get("Boarding");
var Content = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    overflow: hidden;\n"], ["\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    overflow: hidden;\n"])));
var SwiperContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    flex: 0.7;\n    width: 100%;\n    overflow: hidden;\n    position: relative;\n"], ["\n    flex: 0.7;\n    width: 100%;\n    overflow: hidden;\n    position: relative;\n"])));
export var PaginationContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 0.1;\n    margin-top: 2rem;\n"], ["\n    flex: 0.1;\n    margin-top: 2rem;\n"])));
export var Buttons = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    flex: 0.2;\n    display: flex;\n    width: 100%;\n    align-items: center;\n"], ["\n    flex: 0.2;\n    display: flex;\n    width: 100%;\n    align-items: center;\n"])));
var swiper = null;
export var Boarding = React.memo(function (props) {
    var onComplete = props.onComplete;
    var _a = React.useState(0), currentSlide = _a[0], setCurrentSlide = _a[1];
    var _b = React.useState(0), width = _b[0], setWidth = _b[1];
    var swiperContainerRef = React.useRef(null);
    var t = useTranslation().t;
    var nextSlide = function () {
        swiper.slideNext();
        logger.info("Swiper", swiper);
    };
    var skipSlides = function () {
        swiper.slideTo(3);
    };
    React.useEffect(function () {
        setWidth(swiperContainerRef.current.offsetWidth);
    }, []);
    return React.createElement(Modal, { fullscreen: true, visible: true, noBorderRadius: true },
        React.createElement(Content, null,
            React.createElement(SwiperContainer, { ref: swiperContainerRef }, width > 0 && React.createElement(Swiper, { onSwiper: function (instance) {
                    swiper = instance;
                }, slidesPerView: 1, style: {
                    height: "100%",
                    width: "".concat(width, "px")
                }, onSlideChange: function (swiper) {
                    setCurrentSlide(swiper.activeIndex);
                } },
                React.createElement(SwiperSlide, null,
                    React.createElement(Screen1, null)),
                React.createElement(SwiperSlide, null,
                    React.createElement(Screen2, null)),
                React.createElement(SwiperSlide, null,
                    React.createElement(Screen3, null)),
                React.createElement(SwiperSlide, null,
                    React.createElement(Screen4, null)))),
            React.createElement(PaginationContainer, null,
                React.createElement(Bullets, null,
                    React.createElement(Bullet, { active: currentSlide === 0 }),
                    React.createElement(Bullet, { active: currentSlide === 1 }),
                    React.createElement(Bullet, { active: currentSlide === 2 }),
                    React.createElement(Bullet, { active: currentSlide === 3 }))),
            React.createElement(Buttons, null,
                currentSlide < 3 && React.createElement(ButtonsContent, null,
                    React.createElement(ButtonLeft, null,
                        React.createElement(BoardingButton, { variant: "outline", onClick: skipSlides }, t("boarding.button.skip.text"))),
                    React.createElement(ButtonRight, null,
                        React.createElement(BoardingButton, { onClick: nextSlide }, t("boarding.button.next.text")))),
                currentSlide == 3 && React.createElement(ButtonsContent, null,
                    React.createElement(ButtonLeft, null),
                    React.createElement(ButtonRight, null,
                        React.createElement(BoardingButton, { onClick: onComplete }, t("boarding.button.complete.text")))))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
