var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { CFormInput, CFormLabel } from "@coreui/react";
import * as React from "react";
import styled from "styled-components";
var logger = require("js-logger").get("MobileFormTextInput");
//import composeRefs from '@seznam/compose-react-refs'
var OpenInputContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: fixed;\n    top: 0;\n    left: 0;\n    flex: 1;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;    \n    background-color: #FBFBFB;\n    z-index: 104;\n"], ["\n    position: fixed;\n    top: 0;\n    left: 0;\n    flex: 1;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;    \n    background-color: #FBFBFB;\n    z-index: 104;\n"])));
export var MobileFormTextInput = React.memo(function (props) {
    var onChange = props.onChange, value = props.value, disabled = props.disabled, inputRef = props.inputRef, placeholder = props.placeholder, invalid = props.invalid, style = props.style, id = props.id, type = props.type, size = props.size, label = props.label, disableHandleFocus = props.disableHandleFocus, readOnly = props.readOnly;
    var _a = React.useState(false), isFocused = _a[0], setIsFocused = _a[1];
    var textareAreaRef = React.useRef(null);
    var classNames = [];
    if (isFocused) {
        classNames.push("form-control-focus");
    }
    if (props.className) {
        classNames.push(props.className);
    }
    var className = classNames.join(" ");
    var handleFocus = function (e) {
        if (disableHandleFocus) {
            return;
        }
        setIsFocused(true);
        if (props.onFocus) {
            props.onFocus(e);
        }
    };
    var handleBlur = function (e) {
        setIsFocused(false);
        if (props.onBlur) {
            props.onBlur(e);
        }
        if (textareAreaRef.current) {
            var input = textareAreaRef.current;
            input.blur();
        }
        else {
            logger.info("Can not find textarea input");
        }
    };
    var handleChange = function (e) {
        logger.info("ON CHANGE", e.target.value);
        onChange(e);
    };
    React.useLayoutEffect(function () {
        if (isFocused) {
            //document.addEventListener("scroll", resizeHandler);
            setTimeout(function () {
                var input = textareAreaRef.current;
                if (input && input.value) {
                    var end = input.value.length;
                    input.setSelectionRange(end, end);
                    //logger.info("Ref", input);
                    input.focus();
                }
            }, 0);
        }
        else {
            //document.removeEventListener("resize", resizeHandler);
        }
    }, [isFocused]);
    return React.createElement(React.Fragment, null,
        label && React.createElement(CFormLabel, { htmlFor: id }, label),
        React.createElement(CFormInput, { onChange: handleChange, 
            // onBlur={onBlur}
            value: value, ref: inputRef, id: id, type: type, disabled: disabled, invalid: invalid, 
            //valid={fieldState.isDirty && !fieldState.invalid}
            style: style, onFocus: handleFocus, onBlur: handleBlur, size: size, className: className, placeholder: placeholder, readOnly: readOnly }));
});
var templateObject_1;
