import * as React from "react";
import { BoardingScreen } from "./screen";
import icon from "../../assets/boarding/4.svg";
import { DefaultText } from "./screen";
import { useTranslation } from "react-i18next";
import { BoardingButton } from "./button";
import { ButtonsContent, ButtonLeft, ButtonRight } from "./screen";
export var Screen4 = React.memo(function (props) {
    var t = useTranslation().t;
    return React.createElement(BoardingScreen, { icon: icon, text: React.createElement(DefaultText, null, t("boarding.screen4.message.text")), buttons: React.createElement(ButtonsContent, null,
            React.createElement(ButtonLeft, null),
            React.createElement(ButtonRight, null,
                React.createElement(BoardingButton, null, t("boarding.button.complete.text")))) });
});
