import { ORDER_ENDPOINT } from "../constats/ApiEndpoints";
import { userStore } from "../store/user.store";
export function getOrdersRequest(afterCursor, beforeCursor) {
    var token = userStore.getState().token;
    var companyId = userStore.getState().company.id;
    var params = {
        token: token
    };
    if (afterCursor) {
        params.afterCursor = afterCursor;
    }
    if (beforeCursor) {
        params.beforeCursor = beforeCursor;
    }
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(ORDER_ENDPOINT),
        params: params
    };
}
