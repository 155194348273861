var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Box } from "../component/common/FlexItem";
import styled from "styled-components";
import insideSvg from "../../assets/svg/dinner_dining.svg";
import deliverSvg from "../../assets/svg/electric_moped.svg";
import takeoutSvg from "../../assets/svg/takeout_dining.svg";
import { useTranslation } from "react-i18next";
import { Touchable } from "../common/Touchable";
import { useNavigate } from "react-router-dom";
import { APP_OPERATION_MONITOR_DETAILS, buildRoute } from "../constats/Routes";
// import ExpirationTimer from "../common/ExpirationTimer";
import { OrderTimer } from "./timer";
import { orderName } from "./utils";
var Container = styled(Touchable)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    min-height: 4rem;\n    border-bottom:#dadada solid 1px;\n    display: flex;\n"], ["\n    min-height: 4rem;\n    border-bottom:#dadada solid 1px;\n    display: flex;\n"])));
var Icon = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 3.5rem;\n    background:", ";\n    justify-content: center;\n    align-items: center;\n"], ["\n    width: 3.5rem;\n    background:", ";\n    justify-content: center;\n    align-items: center;\n"])), function (props) { return props.type == "delivery" ? "#3e9bb8" : props.type == "outside" ? "#d61075" : "#2ebb8e"; });
var Content = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding:0 1rem;\n    align-items: center;\n"], ["\n    padding:0 1rem;\n    align-items: center;\n"])));
var Type = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    color: #0a0a0a;\n    font-weight: bold;\n    \n"], ["\n    color: #0a0a0a;\n    font-weight: bold;\n    \n"])));
var Name = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 0.8rem;\n    color: #797979;\n    font-weight: bold;\n"], ["\n    font-size: 0.8rem;\n    color: #797979;\n    font-weight: bold;\n"])));
var SvgIcon = styled(Box)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    width: 50%;\n    height: 50%;\n    background: url(\"", "\") no-repeat center center;  \n    background-size: contain;  \n"], ["\n    width: 50%;\n    height: 50%;\n    background: url(\"", "\") no-repeat center center;  \n    background-size: contain;  \n"])), function (props) { return props.icon; });
export var Item = React.memo(function (props) {
    var item = props.item, onClick = props.onClick;
    if (item.order) {
        return React.createElement(RenderOM, { item: item, onClick: onClick });
    }
    return React.createElement(RenderOrder, { order: item, onClick: onClick });
});
export var RenderOM = React.memo(function (props) {
    var item = props.item, onClick = props.onClick;
    var order = item.order;
    var type = order.info.type.toLowerCase();
    var t = useTranslation().t;
    var navigate = useNavigate();
    var goToDetails = function (id) { return function () {
        if (onClick) {
            onClick(item);
            return;
        }
        navigate(buildRoute(APP_OPERATION_MONITOR_DETAILS, { id: id }));
    }; };
    return React.createElement(Container, { onTap: goToDetails(order.id) },
        React.createElement(Icon, { flex: "none", type: type },
            React.createElement(SvgIcon, { icon: type == "inside" ? insideSvg : type == "outside" ? takeoutSvg : deliverSvg })),
        React.createElement(Content, { flex: 1 },
            React.createElement(Box, { flex: 1, flexDirection: "column", align: "flex-start", justify: "center" },
                React.createElement(Type, null,
                    item.items[0].station_name,
                    type == "inside" && ", ".concat(t("opm.table.text", {
                        place: order.info.place
                    })),
                    ", ",
                    item.items.map(function (itm) { return itm.name; }).join(", ")),
                React.createElement(Name, null,
                    "#",
                    orderName(order.name))),
            React.createElement(Box, { flex: "none" },
                React.createElement(OrderTimer, { order: order }))));
});
export var RenderOrder = React.memo(function (props) {
    var order = props.order, onClick = props.onClick;
    var status = order.status;
    var type = order.info.type.toLowerCase();
    var t = useTranslation().t;
    var navigate = useNavigate();
    var goToDetails = function (id) { return function () {
        if (onClick) {
            onClick(order);
            return;
        }
        navigate(buildRoute(APP_OPERATION_MONITOR_DETAILS, { id: id }));
    }; };
    return React.createElement(Container, { onTap: goToDetails(order.id) },
        React.createElement(Icon, { flex: "none", type: type },
            React.createElement(SvgIcon, { icon: type == "inside" ? insideSvg : type == "outside" ? takeoutSvg : deliverSvg })),
        React.createElement(Content, { flex: 1 },
            React.createElement(Box, { flex: 1, flexDirection: "column", align: "flex-start", justify: "center" },
                React.createElement(Type, null,
                    t("order.type.".concat(type.toLowerCase(), ".text")),
                    type == "inside" && ", ".concat(order.info.place)),
                React.createElement(Name, null,
                    "#",
                    order.name)),
            React.createElement(Box, { flex: "none" },
                React.createElement(OrderTimer, { order: order }))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
