var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { Touchable } from "../common/Touchable";
var Container = styled(Touchable)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: #e8edf0;\n    width: 5rem;\n    height: 5rem;\n    padding: 3rem;\n    border: ", " solid 1px;\n    border-radius: 1rem;            \n    display: flex;\n    position: relative;\n"], ["\n    background-color: #e8edf0;\n    width: 5rem;\n    height: 5rem;\n    padding: 3rem;\n    border: ", " solid 1px;\n    border-radius: 1rem;            \n    display: flex;\n    position: relative;\n"])), function (props) { return props.active ? "#52d5fb" : "#f7f8fc"; });
export var QRCodeEl = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100%;\n    height: 100%;        \n    position: absolute;\n    top:0;\n    left:0;\n    z-index: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n"], ["\n    width: 100%;\n    height: 100%;        \n    position: absolute;\n    top:0;\n    left:0;\n    z-index: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n"])));
var QrImgContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 60%;\n    height: 60%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n"], ["\n    width: 60%;\n    height: 60%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n"])));
var QrImg = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    height: 100%;\n"], ["\n    height: 100%;\n"])));
export var QRCode = React.memo(function (props) {
    var url = props.url;
    // style={{            
    //     background: `url(${url}) no-repeat center center`,
    //     backgroundSize: "60%"
    // }}
    return React.createElement(QRCodeEl, null,
        React.createElement(QrImgContainer, null,
            React.createElement(QrImg, { src: url })));
});
export var QRCodeSvg = React.memo(function (props) {
    var svg = props.svg;
    // style={{            
    //     background: `url(${url}) no-repeat center center`,
    //     backgroundSize: "60%"
    // }}
    return React.createElement(QRCodeEl, null,
        React.createElement(QrImgContainer, { dangerouslySetInnerHTML: { __html: svg } }));
});
var QRCodeModal = styled(QRCode)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    position: static;\n"], ["\n    position: static;\n"])));
export var SvgVariant = React.memo(function (props) {
    var qrURL = props.qrURL;
    return React.createElement(React.Fragment, null,
        React.createElement(Container, { flex: "none", onTap: props.onClick },
            React.createElement(QRCode, { url: qrURL })));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
