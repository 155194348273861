import { CFormInput, CFormLabel, CTooltip } from "@coreui/react";
import * as React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { randomString } from "../../../utils/StringUtils";
var logger = require("js-logger").get("FormTextInput");
import { FormFeedback } from "../FormFeedback";
var FormPhoneInput = function (props) {
    var control = props.control, name = props.name, defaultValue = props.defaultValue, label = props.label, rules = props.rules, disabled = props.disabled, style = props.style, info = props.info, onFocus = props.onFocus, onBlur = props.onBlur, readOnly = props.readOnly;
    var t = useTranslation().t;
    var _a = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _b = _a.field, onChange = _b.onChange, _c = _b.value, value = _c === void 0 ? defaultValue ? defaultValue : "" : _c, fieldState = _a.fieldState;
    var inputId = props.id ? props.id : randomString(6);
    return React.createElement(React.Fragment, null,
        label && React.createElement(CFormLabel, { htmlFor: inputId }, label),
        info && React.createElement(CTooltip, { content: info },
            React.createElement(PhoneInput, { inputComponent: CFormInput, value: value, onChange: onChange, disabled: disabled, invalid: fieldState.invalid, style: style, onBlur: onBlur, onFocus: onFocus, readOnly: readOnly })),
        !info && React.createElement(PhoneInput, { inputComponent: CFormInput, value: value, onChange: onChange, disabled: disabled, invalid: fieldState.invalid, style: style, onBlur: onBlur, onFocus: onFocus, readOnly: readOnly }),
        fieldState.invalid && React.createElement(FormFeedback, { invalid: true },
            fieldState.error.message && fieldState.error.message,
            !fieldState.error.message && t("form.error.".concat(fieldState.error.type.toLowerCase(), ".message"))));
};
export default FormPhoneInput;
