import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { AreaQRZip } from "./AreaQRZip";
import { Button } from "../../common/Button";
export var AreaQrDownload = React.memo((function (props) {
    var area = props.area, places = props.places;
    var _a = React.useState(false), showDialog = _a[0], setShowDialog = _a[1];
    if (!places) {
        return null;
    }
    return React.createElement(Button, { color: "link", onClick: function () { return setShowDialog(true); } },
        React.createElement(FontAwesomeIcon, { icon: faDownload }),
        showDialog && React.createElement(AreaQRZip, { onClose: function () { return setShowDialog(false); }, area: area, place: places[0] }));
}));
