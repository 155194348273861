import * as React from "react";
import { CFormLabel, CFormFeedback, CTooltip } from "@coreui/react";
import { useController } from "react-hook-form";
import { randomString } from "../../../utils/StringUtils";
var logger = require("js-logger").get("FormColorInput");
import { useTranslation } from "react-i18next";
import { ColorPicker } from "../ColorPicker/ColorPicker";
var FormColorInput = function (props) {
    var control = props.control, defaultValue = props.defaultValue, name = props.name, label = props.label, type = props.type, rules = props.rules, disabled = props.disabled, style = props.style, inputRef = props.inputRef, onFocus = props.onFocus, onBlur = props.onBlur, info = props.info;
    var _a = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _b = _a.field, onChange = _b.onChange, value = _b.value, fieldState = _a.fieldState;
    var t = useTranslation().t;
    var inputId = props.id ? props.id : randomString(6);
    logger.info("Render", defaultValue, value, name);
    return React.createElement(React.Fragment, null,
        label && React.createElement(CFormLabel, { htmlFor: inputId }, label),
        info && React.createElement(CTooltip, { content: info },
            React.createElement(ColorPicker, { onChange: onChange, color: value })),
        !info && React.createElement(ColorPicker, { onChange: onChange, color: value }),
        fieldState.invalid && React.createElement(CFormFeedback, { invalid: true },
            fieldState.error.message && fieldState.error.message,
            !fieldState.error.message && t("form.error.".concat(fieldState.error.type.toLowerCase(), ".message"))));
};
export default FormColorInput;
