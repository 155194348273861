var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormCheck, CFormInput } from "@coreui/react";
import { Box } from "./FlexItem";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
var logger = require("js-logger").get("Multiselect");
import Scrollbars from "react-custom-scrollbars-2";
var Toggle = styled(CDropdownToggle)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    // padding: 0 5px;\n    // border-radius: 5px !important;\n"], ["\n    // padding: 0 5px;\n    // border-radius: 5px !important;\n"])));
export var Multiselect = React.memo(function (props) {
    var options = props.options, toggle = props.toggle, text = props.text, onChange = props.onChange, value = props.value, okButton = props.okButton, search = props.search;
    var _a = React.useState(value), localValue = _a[0], setLocalValue = _a[1];
    var _b = React.useState(""), searchStr = _b[0], setSearchStr = _b[1];
    var updateSelected = function (optionValue) { return function (e) {
        var target = e.target;
        //logger.info("Target", target.checked);
        var update = localValue.splice(0);
        if (target.checked) {
            update.push(optionValue);
        }
        else {
            update = update.filter(function (item) { return item !== optionValue; });
        }
        setLocalValue(update);
    }; };
    React.useEffect(function () {
        logger.info("Changes", localValue);
        if (!okButton) {
            onChange(localValue);
        }
    }, [localValue]);
    React.useEffect(function () {
        setLocalValue(value);
    }, [props.value]);
    var t = useTranslation().t;
    var renderOption = options;
    if (searchStr) {
        var pattern_1 = new RegExp(searchStr, "i");
        renderOption = options.filter(function (option) { return pattern_1.test(option.label); });
    }
    return React.createElement(CDropdown, { variant: "dropdown", autoClose: "outside", placement: "auto" },
        toggle && React.createElement(Toggle, { caret: false }, toggle),
        text && React.createElement(CDropdownToggle, null, text),
        React.createElement(CDropdownMenu, null,
            React.createElement(Box, { style: {
                    height: "20rem"
                } },
                React.createElement(Scrollbars, null,
                    search && React.createElement(CDropdownItem, null,
                        React.createElement(CFormInput, { placeholder: t("search.text"), size: "sm", value: searchStr, onChange: function (e) { return setSearchStr(e.target.value); } })),
                    renderOption.map(function (option) { return React.createElement(CDropdownItem, { key: option.value },
                        React.createElement(CFormCheck, { id: "checkbox_".concat(option.value), label: option.label, checked: localValue.find(function (item) { return item == option.value; }) !== undefined, onChange: updateSelected(option.value), disabled: option.disabled })); }),
                    okButton && React.createElement(CDropdownItem, null,
                        React.createElement(Box, { justify: "center" },
                            React.createElement(CButton, { size: "sm", onClick: function () {
                                    onChange(localValue);
                                } }, t("save.text"))))))));
});
var templateObject_1;
