var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box } from "../component/common/FlexItem";
import { DeleteButton, EditButton, ListItemBase, QrButton } from "../component/common/ListItem";
import LoadingBox from "../component/common/LoadingBox";
import { Modal, ModalBody, ModalHeader, ModalHeaderTitle } from "../component/common/Modal";
import { Section } from "../component/common/Section";
import { createDeviceRequest, deleteDeviceRequest, getDeviceQrRequest, getDevicesRequest, updateDeviceRequest } from "../request/device.request";
import { HttpApiRequest, useHttpRequestHandler } from "../service/network.service";
import { userStore } from "../store/user.store";
import { DeviceForm } from "./device.form";
var logger = require("js-logger").get("Devices");
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex: 1;        \n    height: 100%;\n    padding: 0 1rem;\n"], ["\n    flex: 1;        \n    height: 100%;\n    padding: 0 1rem;\n"])));
var Buttons = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    gap: 0.5rem;\n"], ["\n    display: flex;\n    gap: 0.5rem;\n"])));
var QRContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 50%;\n    height: 50%;\n\n    svg {\n        width: 100%;\n        height: 100%;\n    }\n"], ["\n    width: 50%;\n    height: 50%;\n\n    svg {\n        width: 100%;\n        height: 100%;\n    }\n"])));
var QrDetailsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: absolute;    \n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;    \n    background-color: #fff;\n    display: flex;\n    flex-direction: column;\n"], ["\n    position: absolute;    \n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;    \n    background-color: #fff;\n    display: flex;\n    flex-direction: column;\n"])));
var QrDetailsContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    flex:1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n"], ["\n    flex:1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n"])));
var QRCloseIconPosition = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    position: absolute;\n    right: 0.5rem;\n    top: 0.5rem\n"], ["\n    position: absolute;\n    right: 0.5rem;\n    top: 0.5rem\n"])));
export var Devices = React.memo(function (props) {
    var t = useTranslation().t;
    var company = userStore(function (state) { return state.company; });
    // const [devices, setDevices] = React.useState([]);
    var _a = React.useState(undefined), editDevice = _a[0], setEditDevice = _a[1];
    var _b = React.useState(undefined), qr = _b[0], setQr = _b[1];
    var devicesState = useHttpRequestHandler(getDevicesRequest());
    var devices = (devicesState.result && devicesState.result) || [];
    var createDevice = function (data) {
        HttpApiRequest(createDeviceRequest(data))
            .promise
            .then(function () {
            devicesState.run();
        })
            .catch(function (err) {
            logger.error(err);
        });
    };
    var updateDevice = function (id, data) {
        HttpApiRequest(updateDeviceRequest(id, data))
            .promise
            .then(function () {
            devicesState.run();
        })
            .catch(function (err) {
            logger.error(err);
        });
    };
    var deleteDevice = function (id) {
        HttpApiRequest(deleteDeviceRequest(id))
            .promise
            .then(function () {
            devicesState.run();
        })
            .catch(function (err) {
            logger.error(err);
        });
    };
    var getQr = function (id) {
        HttpApiRequest(getDeviceQrRequest(id))
            .promise
            .then(function (res) {
            setQr(res.data);
        })
            .catch(function (err) {
            logger.error(err);
        });
    };
    if (devicesState.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    return React.createElement(Container, null,
        React.createElement(Section, { title: t("devices.page.title"), onAdd: function () {
                setEditDevice(null);
            } }, devices.map(function (device) { return React.createElement(ListItemBase, { item: device, actions: React.createElement(Buttons, null,
                React.createElement(EditButton, { item: device, onClick: function (device) {
                        setEditDevice(device);
                    } }),
                React.createElement(QrButton, { item: device, onClick: function () {
                        getQr(device.id);
                    } }),
                React.createElement(DeleteButton, { item: device, onClick: function (device) {
                        if (confirm("Are you sure ?")) {
                            deleteDevice(device.id);
                        }
                    } })), key: device.id }, device.username); })),
        editDevice !== undefined && React.createElement(Modal, { visible: true },
            React.createElement(ModalHeader, null,
                React.createElement(ModalHeaderTitle, null, "Add Device")),
            React.createElement(ModalBody, null,
                React.createElement(DeviceForm, { device: editDevice, onCancel: function () {
                        setEditDevice(undefined);
                    }, roles: company.roles || [], onSave: function (data) {
                        if (editDevice) {
                            updateDevice(editDevice.id, data);
                        }
                        else {
                            createDevice(data);
                        }
                        setEditDevice(undefined);
                    } }))),
        qr && React.createElement(Modal, { visible: true },
            React.createElement(ModalHeader, { onClose: function () {
                    setQr(undefined);
                } }),
            React.createElement(ModalBody, null,
                React.createElement(Box, { justify: "center" },
                    React.createElement(QRContainer, { dangerouslySetInnerHTML: { __html: qr } })))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
