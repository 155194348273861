import * as React from "react";
import { CDropdown, CDropdownToggle, CDropdownItem, CDropdownMenu, CModal, CModalBody } from "@coreui/react";
import { userStore } from "../store/user.store";
import FlexItem from "./common/FlexItem";
import { useHttpRequestHandler, useHttpRequesPrepareHandler, HttpApiRequest } from "../service/network.service";
import { getLangugesRequest } from "../request/settings.request";
import LoadingBox from "./common/LoadingBox";
import { configureLanguageToCompany } from "../request/company.request";
import { userCompaniesRequest } from "../request/user.request";
var languages = require("../../assets/json/languages.json");
import { Multiselect } from "./common/MultiSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
var logger = require("js-logger").get("Language");
import { setUILanguage } from "../locale/i18next";
import { TranslatePermissions } from "../common/user/Permission";
var AvailableLanguages = React.memo(function (props) {
    var onConfigure = props.onConfigure;
    var availableLanguagesState = useHttpRequestHandler(getLangugesRequest());
    var addLanguageState = useHttpRequesPrepareHandler();
    var company = userStore.getState().company;
    var language = userStore(function (state) { return state.language; });
    var canTranslate = userStore(function (state) { return state.hasPermission(TranslatePermissions.TRANSLATE); });
    var addLanguages = availableLanguagesState.result ? availableLanguagesState.result : [];
    //const renderLanguages = addLanguages.filter(lang => !company.languages.includes(lang));
    var addLanguage = function (config) {
        addLanguageState.run(configureLanguageToCompany(config))
            .then(function () {
            onConfigure();
        });
    };
    if (addLanguageState.inProgress) {
        return React.createElement(CModal, { visible: true },
            React.createElement(CModalBody, null,
                React.createElement(LoadingBox, null)));
    }
    return React.createElement(Multiselect, { search: canTranslate, okButton: true, toggle: React.createElement(FontAwesomeIcon, { icon: faGlobe }), options: addLanguages.map(function (code) {
            return {
                value: code,
                label: languages.find(function (l) { return l["code"] == code; }).name,
                disabled: language === code || !canTranslate
            };
        }), value: company.languages.slice(0), onChange: function (val) {
            //logger.info("On Change 1", val);
            //logger.info("On Change 2", company.languages);
            //logger.info("Changes", );
            var update = {};
            val.forEach(function (lang) { return update[lang] = true; });
            //const disabled = val.length > company.languages.length ? _.difference(val, company.languages) : _.difference(company.languages, val);
            company.languages.forEach(function (lang) {
                if (val.indexOf(lang) == -1) {
                    update[lang] = false;
                }
            });
            addLanguage(update);
        } });
    // return <CDropdown>
    //     <CDropdownToggle caret={false} variant="outline" size="sm">+</CDropdownToggle>
    //     <CDropdownMenu>
    //         {renderLanguages.map(code => {
    //             const lang: any = languages.find((l: any) => l["code"] == code);
    //             return <CDropdownItem key={code} onClick={() => {
    //                 addLanguage(code);
    //             }}>{lang ? lang["name"] : code.toUpperCase()}</CDropdownItem>
    //         })}
    //     </CDropdownMenu>
    // </CDropdown>
});
var getShortLanguage = function (language) {
    // const res = [];
    // for(let i = 0; i < 3; i++){
    //     if(language[i]){
    //         res.push(language[i]);
    //     }
    // }
    return language && language.length ? language.substring(0, 3) : language;
};
var Language = React.memo(function (props) {
    var uStore = userStore();
    var createSetLanguage = function (language) { return function () {
        uStore.setLanguage(language);
        setUILanguage(language);
    }; };
    var company = userStore.getState().company;
    //const updateCompaniesState = useHttpRequesPreparetHandler();
    var currentLang = languages.find(function (l) { return l["code"] == uStore.language; });
    return React.createElement(FlexItem, { gap: 2 },
        React.createElement(CDropdown, { variant: "btn-group" },
            React.createElement(CDropdownMenu, null, company.languages.sort().map(function (code) {
                var lang = languages.find(function (l) { return l["code"] == code; });
                return React.createElement(CDropdownItem, { onClick: createSetLanguage(code), key: code }, lang ? lang["name"] : code.toUpperCase());
            })),
            React.createElement(CDropdownToggle, { color: "link", caret: false }, getShortLanguage(currentLang ? currentLang["name"] : uStore.language))),
        React.createElement(AvailableLanguages, { onConfigure: function () {
                HttpApiRequest(userCompaniesRequest())
                    .promise
                    .then(function (response) {
                    uStore.setCompany(response.data);
                });
            } }));
});
export default Language;
