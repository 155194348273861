var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faArrowRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as dayjs from "dayjs";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Board from "../../lib/react-trello";
import { Button } from "../common/Button";
import { Box } from "../component/common/FlexItem";
import { userStore } from "../store/user.store";
import { OrderCard } from "./OrderCard";
import { OrderTimer } from "../operation-monitor/timer";
var logger = require("js-logger").get("OrderMonitor");
var RevertContainer = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 5px;\n"], ["\n  margin-top: 5px;\n"])));
var OrderRevert = React.memo(function (props) {
    var revertTo = props.revertTo, order = props.order, changeOrderStatus = props.changeOrderStatus;
    var t = useTranslation().t;
    var companyId = userStore(function (state) { return state.company.id; });
    var updateStatus = function (status) { return function () {
        // jsonRpc.request("changeCompanyOrderStatus", {
        //   companyId,
        //   status: status,
        //   orderId: order.id
        // })
        changeOrderStatus(order, revertTo);
    }; };
    return React.createElement(RevertContainer, { justify: "flex-end", flex: 1 },
        React.createElement(Button, { size: "sm", color: "info", variant: "ghost", onClick: updateStatus(revertTo) },
            React.createElement(FontAwesomeIcon, { size: "sm", icon: faArrowRotateLeft })));
});
var OrderTitleCotainer = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 12px;\n  \n"], ["\n  font-size: 12px;\n  \n"])));
var OrderTitleDate = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #8e8b8b;\n  font-weight: normal;\n  margin-right: 5px;\n"], ["\n  color: #8e8b8b;\n  font-weight: normal;\n  margin-right: 5px;\n"])));
var getFormattedOrderDate = function (orderDateTime) {
    var todayDate = new Date();
    var orderDate = new Date();
    orderDate.setTime(orderDateTime);
    var formattedToday = dayjs(todayDate).format("YYYY-MM-DD");
    var formattedOrder = dayjs(orderDate).format("YYYY-MM-DD");
    if (formattedToday == formattedOrder) {
        return dayjs(orderDate).format("HH:mm");
    }
    return dayjs(orderDate).format("DD MMM");
};
var OrderTitle = React.memo(function (props) {
    var order = props.order, changeOrderStatus = props.changeOrderStatus;
    var t = useTranslation().t;
    var updateStatus = function (status) { return function () {
        changeOrderStatus(order, status);
        // jsonRpc.request("changeCompanyOrderStatus", {
        //   companyId,
        //   status: status,
        //   orderId: order.id
        // })
    }; };
    //const todayDate = new Date();
    //const orderDate = new Date();
    //orderDate.setTime(order.created);
    //dayjs(d)
    //logger.info(`today: ${todayDate.getDate()}, orderDate: ${orderDate.getDate()}`)
    return React.createElement(OrderTitleCotainer, { align: "center" },
        order.status != "new" && React.createElement(OrderRevert, { order: order, revertTo: getRevertTo(order.status), changeOrderStatus: changeOrderStatus }),
        order.name,
        React.createElement(OrderTitleDate, null,
            "\u00A0|\u00A0",
            getFormattedOrderDate(order.created)),
        React.createElement(Box, { flex: 1, justify: "flex-end" },
            order.status == "new" && React.createElement(Button, { onClick: updateStatus("in-progress"), size: "sm", color: "info", variant: "outline" }, t("order.button.accept.text")),
            order.status == "in-progress" && React.createElement(Button, { onClick: updateStatus("ready"), size: "sm", color: "info", variant: "outline" }, t("order.button.in-progress.text")),
            order.status == "ready" && React.createElement(Button, { size: "sm", onClick: updateStatus("completed"), color: "info", variant: "outline" }, t("order.button.ready.text"))));
});
var OrderItemsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: #8e8b8b;\n  font-weight: normal;\n"], ["\n  color: #8e8b8b;\n  font-weight: normal;\n"])));
var OrderItemContainer = styled(Box)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: #000\n"], ["\n  color: #000\n"])));
var OrderItemOptionsContainer = styled(Box)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-left: 15px;\n"], ["\n  margin-left: 15px;\n"])));
var OrderContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  // position: relative;\n"], ["\n  // position: relative;\n"])));
var OrderTimerContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 0;\n  right: 0;\n"], ["\n  position: absolute;\n  bottom: 0;\n  right: 0;\n"])));
var OrderItem = React.memo(function (props) {
    var item = props.item;
    return React.createElement(React.Fragment, null,
        React.createElement(OrderItemContainer, null,
            React.createElement(Box, { flex: 1 },
                item.count,
                " X ",
                item.name),
            React.createElement(Box, null, item.price * item.count)),
        item.options && React.createElement(OrderItemOptionsContainer, { flexDirection: "column" }, item.options.map(function (opt) { return React.createElement(Box, { flex: 1 },
            React.createElement(Box, { flex: 1 },
                opt.count,
                " X ",
                opt.name),
            React.createElement(Box, { flex: "none" }, opt.price * opt.count)); })));
});
var OrderItemsTitle = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-bottom: 5px;\n"], ["\n  margin-bottom: 5px;\n"])));
export var OrderItems = React.memo(function (props) {
    var t = useTranslation().t;
    var order = props.order, changeOrderStatus = props.changeOrderStatus;
    return React.createElement(OrderItemsContainer, null,
        order.items.map(function (item) { return React.createElement(OrderItem, { item: item }); }),
        React.createElement(OrderTimerContainer, null,
            React.createElement(OrderTimer, { order: order, width: "2.9rem", height: "2.9rem" })));
});
var OrderInfoContainer = styled(Box)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-top: 5px;\n  color: #000\n"], ["\n  margin-top: 5px;\n  color: #000\n"])));
var OrderInfoRow = styled(Box)(templateObject_11 || (templateObject_11 = __makeTemplateObject([""], [""])));
var OrderInfoRowLabel = styled(Box)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-style: italic\n"], ["\n  font-style: italic\n"
    //const OrderInfoRowContainer = styled(Box)
])));
//const OrderInfoRowContainer = styled(Box)
export var OrderInfo = React.memo(function (props) {
    var order = props.order;
    var info = order.info;
    var t = useTranslation().t;
    if (!info) {
        return null;
    }
    return React.createElement(OrderInfoContainer, { flexDirection: "column" },
        React.createElement(OrderInfoRow, null,
            React.createElement(OrderInfoRowLabel, null,
                t("order.type.label"),
                ": "),
            React.createElement("div", null, info.type)),
        React.createElement(OrderInfoRow, null,
            React.createElement(OrderInfoRowLabel, null,
                t("order.table.label"),
                ": "),
            React.createElement("div", null, info.place)),
        info.comment && React.createElement(OrderInfoRow, null,
            React.createElement(OrderInfoRowLabel, null,
                t("order.comment.label"),
                ": "),
            React.createElement("div", null, info.comment)),
        info.when && React.createElement(OrderInfoRow, null,
            React.createElement(OrderInfoRowLabel, null,
                t("order.when.label"),
                ": "),
            React.createElement("div", null, info.when)),
        info.address && React.createElement(OrderInfoRow, null,
            React.createElement(OrderInfoRowLabel, null,
                t("order.address.label"),
                ": "),
            React.createElement("div", null, info.address)),
        info.from && React.createElement(OrderInfoRow, null,
            React.createElement(OrderInfoRowLabel, null,
                t("order.from.label"),
                ": "),
            React.createElement("div", null, info.from)),
        info.name && React.createElement(OrderInfoRow, null,
            React.createElement(OrderInfoRowLabel, null,
                t("order.name.label"),
                ": "),
            React.createElement("div", null, info.name)),
        info.recipient && React.createElement(OrderInfoRow, null,
            React.createElement(OrderInfoRowLabel, null,
                t("order.recipient.label"),
                ": "),
            React.createElement("div", null, info.recipient)));
});
var LaneTitle = (function (props) {
    var t = useTranslation().t;
    return React.createElement(React.Fragment, null, t("orders.".concat(props.status, ".title")));
});
var getRevertTo = function (status) {
    switch (status) {
        case "in-progress":
            return "new";
        case "ready":
            return "in-progress";
        case "completed":
            return "ready";
        default:
            return status;
    }
};
var buildLandeObj = function (orders, status, changeOrderStatus) {
    return {
        id: status,
        orders: orders,
        title: React.createElement(LaneTitle, { status: status }),
        cards: createCards(orders, status, changeOrderStatus)
    };
};
var createCards = function (orders, status, changeOrderStatus) {
    return orders.filter(function (order) { return order.status == status; }).map(function (order) { return ({
        id: order.id,
        title: React.createElement(OrderTitle, { order: order, changeOrderStatus: changeOrderStatus }),
        description: React.createElement(React.Fragment, null,
            React.createElement(OrderItems, { order: order, changeOrderStatus: changeOrderStatus }),
            React.createElement(OrderInfo, { order: order })),
        metadata: order
    }); });
};
export var OrderMonitor = React.memo(function (props) {
    var boardEventBus = React.useRef(null);
    var orders = props.orders, changeOrderStatus = props.changeOrderStatus;
    var _a = React.useState(buildLandeObj(orders, "new", changeOrderStatus)), newOrders = _a[0], setNewOrders = _a[1];
    var _b = React.useState(buildLandeObj(orders, "in-progress", changeOrderStatus)), inProgressOrders = _b[0], setInProgressOrders = _b[1];
    var _c = React.useState(buildLandeObj(orders, "ready", changeOrderStatus)), readyOrders = _c[0], setReadyOrders = _c[1];
    var _d = React.useState(buildLandeObj(orders, "completed", changeOrderStatus)), completedOrders = _d[0], setCompletedOrders = _d[1];
    var setBoardEventBus = function (eb) {
        //b
        logger.info("Set Board Event Bus", eb);
        boardEventBus.current = eb;
    };
    var checkUpdateOrdersLane = function (lane, orders, updateCallback) {
        if (JSON.stringify(lane.orders) !== JSON.stringify(orders)) {
            updateCallback(orders);
        }
    };
    React.useEffect(function () {
        //logger.info("Update", orders);
        checkUpdateOrdersLane(newOrders, orders.filter(function (order) { return order.status == "new"; }), function (orders) {
            logger.info("Set New Orders", orders, boardEventBus.current);
            //newOrders.cards = createCards(orders, "new", changeOrderStatus);
            //setNewOrders(newOrders);
            boardEventBus.current.publish({ type: "UPDATE_CARDS", laneId: "new", cards: createCards(orders, "new", changeOrderStatus) });
        });
        checkUpdateOrdersLane(inProgressOrders, orders.filter(function (order) { return order.status == "in-progress"; }), function (orders) {
            inProgressOrders.cards = createCards(orders, "in-progress", changeOrderStatus);
            setInProgressOrders(inProgressOrders);
            boardEventBus.current.publish({ type: "UPDATE_CARDS", laneId: "in-progress", cards: createCards(orders, "in-progress", changeOrderStatus) });
        });
        checkUpdateOrdersLane(readyOrders, orders.filter(function (order) { return order.status == "ready"; }), function (orders) {
            readyOrders.cards = createCards(orders, "ready", changeOrderStatus);
            setReadyOrders(readyOrders);
        });
        checkUpdateOrdersLane(completedOrders, orders.filter(function (order) { return order.status == "completed"; }), function (orders) {
            completedOrders.cards = createCards(orders, "completed", changeOrderStatus);
            setCompletedOrders(completedOrders);
        });
    }, [orders]);
    return React.createElement(Board, { style: {
            backgroundColor: "#fbfbfb"
        }, data: {
            lanes: [
                newOrders,
                inProgressOrders,
                readyOrders,
                completedOrders
            ]
        }, laneDraggable: false, cardDraggable: false, hideCardDeleteIcon: true, components: {
            Card: OrderCard
        }, eventBusHandle: setBoardEventBus });
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
