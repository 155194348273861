var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { CButton } from "@coreui/react";
import * as React from "react";
import styled from "styled-components";
import CIcon from "@coreui/icons-react";
import { cilX } from "@coreui/icons";
import { Box } from "./FlexItem";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    // border:#b1b7c1 solid 1px;\n    // border-radius: 0.25rem;\n    display:flex;\n    align-items: center;\n    // padding: 2px 0;\n    flex-wrap: wrap;\n"], ["\n    // border:#b1b7c1 solid 1px;\n    // border-radius: 0.25rem;\n    display:flex;\n    align-items: center;\n    // padding: 2px 0;\n    flex-wrap: wrap;\n"])));
var TagsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    \n"], ["\n    \n"])));
var Input = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border:0;\n    outline: 0;\n    min-width: 10px;\n    flex: 1;\n    background-color: #FBFBFB\n"], ["\n    border:0;\n    outline: 0;\n    min-width: 10px;\n    flex: 1;\n    background-color: #FBFBFB\n"])));
export var TagInput = React.memo(function (props) {
    var _a = props.value, value = _a === void 0 ? [] : _a, onAdd = props.onAdd, onRemove = props.onRemove, labelKey = props.labelKey;
    var inputRef = React.useRef();
    var _b = React.useState(""), tag = _b[0], setTag = _b[1];
    var handleKeyDown = function (e) {
        if (e.key === "Enter" && tag) {
            e.preventDefault();
            e.stopPropagation();
            onAdd(tag, value.length);
            setTag("");
        }
        else if (e.key == "Backspace" && value.length && !tag) {
            onRemove(value[value.length - 1], value.length - 1);
        }
    };
    var handleChange = function (e) {
        setTag(e.target.value);
    };
    var handleRemove = function (tag, index) { return function () {
        onRemove(tag, index);
        inputRef.current.focus();
    }; };
    if (!value) {
        value = [];
    }
    return React.createElement(Container, { className: "form-control" },
        value.map(function (val, index) { return React.createElement(CButton, { key: labelKey ? val[labelKey] : val, color: "info", shape: "rounded-pill" },
            React.createElement(Box, { gap: "5px", align: "center" },
                labelKey ? val[labelKey] : val,
                React.createElement(CIcon, { icon: cilX, size: "sm", onClick: handleRemove(val, index) }))); }),
        React.createElement(Input, { ref: inputRef, onKeyDown: handleKeyDown, onChange: handleChange, value: tag }));
});
var templateObject_1, templateObject_2, templateObject_3;
