import { MENU_ENDPOINT } from "../constats/ApiEndpoints";
import { userStore } from "../store/user.store";
export function getMenuRequest(companyId) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "?token=").concat(token),
    };
}
export function updateMenuRequest(companyId, menuId, menu) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "?token=").concat(token),
        method: "PUT",
        data: menu
    };
}
export function createMenuRequest(companyId, menu) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "?token=").concat(token),
        method: "POST",
        data: menu
    };
}
export function deleteMenuRequest(companyId, menuId) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "?token=").concat(token),
        method: "DELETE"
    };
}
export function getMenuCategoriesRequest(companyId, menuId) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category?token=").concat(token),
        method: "GET"
    };
}
export function getMenuCategoryRequest(companyId, menuId, categoryId) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category/").concat(categoryId, "?token=").concat(token),
        method: "GET"
    };
}
export function createMenuCategoriesRequst(companyId, menuId, data) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category?token=").concat(token),
        method: "POST",
        data: data
    };
}
export function updateMenuCategoriesRequst(companyId, menuId, categoryId, data) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category/").concat(categoryId, "?token=").concat(token),
        method: "PUT",
        data: data
    };
}
export function sortMenuCategoriesRequest(companyId, menuId, data) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category/sort?token=").concat(token),
        method: "POST",
        data: data
    };
}
export function enableCategoryRequest(companyId, menuId, categoryId) {
    var token = userStore.getState().token;
    var params = {
        token: token
    };
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category/").concat(categoryId, "/enable"),
        method: "POST",
        params: params
    };
}
export function disableCategoryRequest(companyId, menuId, categoryId) {
    var token = userStore.getState().token;
    var params = {
        token: token
    };
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category/").concat(categoryId, "/disable"),
        method: "POST",
        params: params
    };
}
export function deleteMenuCategoriesRequst(companyId, menuId, categoryId) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category/").concat(categoryId, "?token=").concat(token),
        method: "DELETE"
    };
}
export function getMenuCategoryItemsRequest(companyId, menuId, categoryId) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category/").concat(categoryId, "/items?token=").concat(token),
        method: "GET"
    };
}
export function deleteMenuCategoryItemRequest(companyId, menuId, categoryId, itemId) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category/").concat(categoryId, "/items/").concat(itemId, "?token=").concat(token),
        method: "DELETE"
    };
}
export function createMenuCategoryItemRequest(companyId, menuId, categoryId, dish_id, options, tags) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category/").concat(categoryId, "/items?token=").concat(token),
        method: "POST",
        data: {
            dish_id: dish_id,
            options: options,
            tags: tags
        }
    };
}
export function updateMenuCategoryItemRequest(companyId, menuId, categoryId, id, options, tags) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category/").concat(categoryId, "/items/").concat(id, "?token=").concat(token),
        method: "PUT",
        data: {
            options: options,
            tags: tags
        }
    };
}
export function sortMenuCategoryItemsRequest(companyId, menuId, categoryId, data) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category/").concat(categoryId, "/items/sort?token=").concat(token),
        method: "POST",
        data: data
    };
}
export function enableMenuCategoryItemRequest(companyId, menuId, categoryId, id) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category/").concat(categoryId, "/items/").concat(id, "/enable"),
        method: "POST",
        params: {
            token: token
        }
    };
}
export function disableMenuCategoryItemRequest(companyId, menuId, categoryId, id) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/category/").concat(categoryId, "/items/").concat(id, "/disable"),
        method: "POST",
        params: {
            token: token
        }
    };
}
export function getMenuIntroRequest(companyId, menuId) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/short-intro-message"),
        method: "GET",
        params: {
            token: token
        }
    };
}
export function saveMenuIntroRequest(companyId, menuId, message) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/short-intro-message"),
        method: "POST",
        params: {
            token: token
        },
        data: {
            message: message
        }
    };
}
