var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { Box } from "./FlexItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Touchable } from "../../common/Touchable";
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    \n"], ["\n    \n"])));
var TitleLayout = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    color: #858992;\n    font-size: 1rem;\n    text-transform: uppercase;\n    margin: 1.3rem 0.3rem 0.4rem 0.3rem;\n    align-items: center;\n"], ["\n    display: flex;\n    color: #858992;\n    font-size: 1rem;\n    text-transform: uppercase;\n    margin: 1.3rem 0.3rem 0.4rem 0.3rem;\n    align-items: center;\n"])));
var TitleLayoutRight = styled(TitleLayout)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 1;\n    justify-content: flex-end;\n    align-items: center\n"], ["\n    flex: 1;\n    justify-content: flex-end;\n    align-items: center\n"])));
export var Section = React.memo(function (props) {
    var title = props.title, onAdd = props.onAdd, children = props.children, actions = props.actions;
    return React.createElement(Container, { flexDirection: "column" },
        React.createElement(TitleLayout, null,
            title,
            onAdd && React.createElement(TitleLayoutRight, null,
                React.createElement(Touchable, { onTap: onAdd },
                    React.createElement(FontAwesomeIcon, { icon: faPlus, size: "lg" }))),
            actions && React.createElement(TitleLayoutRight, null, actions !== undefined && actions)),
        children);
});
var templateObject_1, templateObject_2, templateObject_3;
