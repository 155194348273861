var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { QrReader } from "react-qr-reader";
import { Box } from "../component/common/FlexItem";
var logger = require("js-logger").get("QRScanner");
import { Button } from "./Button";
var RotateButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 3rem;\n    height: 3rem;\n    border-radius: 2rem;\n"], ["\n    width: 3rem;\n    height: 3rem;\n    border-radius: 2rem;\n"])));
var Container = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: relative;\n"], ["\n    position: relative;\n"])));
var RotateButtonPosition = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: absolute;\n    bottom: 1rem;\n    right: 1rem;\n    width: 3rem;\n    height: 3rem;\n    border-radius: 2rem;\n    background-color:#fff;\n"], ["\n    position: absolute;\n    bottom: 1rem;\n    right: 1rem;\n    width: 3rem;\n    height: 3rem;\n    border-radius: 2rem;\n    background-color:#fff;\n"])));
var Content = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 22rem;\n    height: 22rem;\n    position: relative;\n"], ["\n    width: 22rem;\n    height: 22rem;\n    position: relative;\n"])));
var Overlay = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 22rem;\n    height: 22rem;\n    border: #dedede dashed 1rem;\n    // position: relative;\n    overflow: hidden;    \n    padding: 1rem;\n"], ["\n    width: 22rem;\n    height: 22rem;\n    border: #dedede dashed 1rem;\n    // position: relative;\n    overflow: hidden;    \n    padding: 1rem;\n"])));
export var QRScannerComponent = React.memo(function (props) {
    var onScanSuccess = props.onScanSuccess, onError = props.onError;
    var _a = React.useState("environment"), facingMode = _a[0], setFacingMode = _a[1];
    // const toggleFacingMode = React.useCallback(() => {
    //     setFacingMode(facingMode === "user" ? "environment" : "user");
    // }, [facingMode])
    return React.createElement(Container, { flex: 1, justify: "center" },
        React.createElement(Content, null,
            React.createElement(Overlay, null,
                React.createElement(QrReader, { constraints: { facingMode: facingMode, aspectRatio: 1 }, onResult: function (result, error) {
                        if (error && onError) {
                            onError(error.message);
                            return;
                        }
                        if (result) {
                            onScanSuccess(result.getText());
                        }
                    }, videoStyle: { width: '100%' } }))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
