var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { CButton, CListGroup, CNav, CNavItem, CNavLink } from "@coreui/react";
import * as React from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import croissantImage from "../../../assets/company/croissant2.png";
import { Categories } from "../../preview/Categories";
import { Details, PreviewTopImage } from "../../preview/Details";
import { Options } from "../../preview/Options";
import { OrderButton } from "../../preview/OrderButton";
import { Box } from "../common/FlexItem";
import FormColorInput from "../common/form/FormColorInput";
import FormColorsRadio from "../common/form/FormColorsRadio";
import FormSelectInput from "../common/form/FormSelectInput";
import { HoriontalContainer } from "../common/HorizontalContainer";
import Label from "../common/Label";
import { VerticalContainer } from "../common/VerticalContainer";
import { FormContainerColumn, SettingsPageContainer, SettingsPageContent, SettingsPageHeader, SettingsPageTitle } from "../settings";
import { userStore } from "../../store/user.store";
import { loadGoogleFont } from "../../utils/HtmlUtils";
import { CompanyPermissions } from "../../common/user/Permission";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 10px 0;\n    display: flex;\n    flex:1\n"], ["\n    margin: 10px 0;\n    display: flex;\n    flex:1\n"])));
var Colors = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 10px 0;\n    display: flex;\n    flex: 1;\n    gap: 3px;\n"], ["\n    margin: 10px 0;\n    display: flex;\n    flex: 1;\n    gap: 3px;\n"])));
export var RecommendedColor = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 0.15;\n    height: 40px;\n    border: ", " solid 2px;\n    background-color: ", ";\n    border-radius: 4px;\n"], ["\n    flex: 0.15;\n    height: 40px;\n    border: ", " solid 2px;\n    background-color: ", ";\n    border-radius: 4px;\n"])), function (props) { return props.active ? "#4a4b45" : props.color; }, function (props) { return props.color; });
var PreviewTabsContainer = styled(HoriontalContainer)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin: 5px 0;\n    gap: 5px;\n"], ["\n    margin: 5px 0;\n    gap: 5px;\n"])));
var PreviewContainer = styled(VerticalContainer)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin: 5px;\n    padding: 10px;\n    border: #dedede solid 1px;\n    border-radius: 5px;\n    background-color: ", ";  \n    font-family: '", "', sans-serif !important;  \n    --bg-color: ", ";    \n    --btn-primary-color: ", ";\n    --btn-font-color: ", ";\n    --font-family: ", "; \n    & >* {\n        font-family: \"", "\", sans-serif !important;  \n    }\n\n"], ["\n    margin: 5px;\n    padding: 10px;\n    border: #dedede solid 1px;\n    border-radius: 5px;\n    background-color: ", ";  \n    font-family: '", "', sans-serif !important;  \n    --bg-color: ", ";    \n    --btn-primary-color: ", ";\n    --btn-font-color: ", ";\n    --font-family: ", "; \n    & >* {\n        font-family: \"", "\", sans-serif !important;  \n    }\n\n"])), function (props) { return props.bgColor ? props.bgColor : "#fbfbfb"; }, function (props) { return props.fontFamily; }, function (props) { return props.bgColor ? props.bgColor : "#fbfbfb"; }, function (props) { return props.primaryColor ? props.primaryColor : "#FE724C"; }, function (props) { return props.fontColor ? props.fontColor : "#ffffff"; }, function (props) { return props.fontFamily ? props.fontFamily : 'Rubik'; }, function (props) { return props.fontFamily; });
var PreviewTitle = styled.h6(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin: 10px 0 0 0;\n"], ["\n    margin: 10px 0 0 0;\n"])));
var PreviewDescription = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    font-size: 13px;\n    color: #454545;\n    maring: 0;\n"], ["\n    font-size: 13px;\n    color: #454545;\n    maring: 0;\n"])));
var Properties = styled(CListGroup)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    margin: 10px 0 0 0;\n"], ["\n    margin: 10px 0 0 0;\n"])));
var PreviewTimeContainer = styled(HoriontalContainer)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    margin: 0 0;\n    align-items: center\n"], ["\n    margin: 0 0;\n    align-items: center\n"])));
var PreviewTime = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    font-size: 11px;\n    margin-left: 5px;\n"], ["\n    font-size: 11px;\n    margin-left: 5px;\n"])));
var PreviewTabs = React.memo(function (props) {
    return React.createElement(PreviewTabsContainer, null,
        React.createElement(CButton, { variant: "outline", size: "sm" }, "Pizza"),
        React.createElement(CButton, { variant: "outline", size: "sm" }, "Salads"),
        React.createElement(CButton, { variant: "outline", size: "sm" }, "Soups"));
});
var Preview = React.memo(function (props) {
    var control = props.control;
    var colors = useWatch({
        control: control,
        name: "colors"
    });
    if (!colors) {
        colors = {};
    }
    var t = useTranslation().t;
    var fontFamily = useWatch({
        control: control,
        name: "font"
    });
    React.useEffect(function () {
        loadGoogleFont(fontFamily);
    }, [fontFamily]);
    return React.createElement(PreviewContainer, { fontFamily: fontFamily ? fontFamily : "Rubik", bgColor: colors.backgroundColor, primaryColor: colors.color, fontColor: colors.fontColor },
        React.createElement(Label, null, t("preview.text")),
        React.createElement(Categories, { active: "cake", categories: [
                {
                    id: "bread",
                    name: t("preview.category.1.name"),
                    active: true,
                    description: "",
                    sort: 0,
                    tags: ["Tag 1", "Tag 2"],
                    imageURL: "https://static.ordish.app/theme/1/categories/coffee.png"
                },
                {
                    id: "cake",
                    name: t("preview.category.2.name"),
                    active: true,
                    description: "",
                    sort: 0,
                    tags: ["Tag 1", "Tag 2"],
                    imageURL: "https://static.ordish.app/theme/1/categories/cake.png"
                },
                {
                    id: "caviar",
                    name: t("preview.category.3.name"),
                    active: true,
                    description: "",
                    sort: 0,
                    tags: ["Tag 1", "Tag 2"],
                    imageURL: "https://static.ordish.app/theme/1/categories/egg_sandwich.png"
                }
            ] }),
        React.createElement(PreviewTopImage, { image: croissantImage }),
        React.createElement(Details, { name: t("preview.dish.name"), details: "", price: 2, currency: "USD", categoryImage: "https://static.ordish.app/theme/1/categories/merry_pie.png" }),
        React.createElement(Box, { justify: "flex-end" },
            React.createElement(OrderButton, { color: colors.color, count: 1, onDecrement: function () { }, onIncrement: function () { } })),
        React.createElement(Options, { options: [
                {
                    id: "butter",
                    config: {},
                    items: [
                        {
                            id: "sans",
                            default: true,
                            enabled: true,
                            info: {
                                name: t("preview.option.1.item.1.name"),
                                description: ""
                            },
                            posId: '123',
                            price: 0,
                            sort: 0
                        },
                        {
                            id: "rb",
                            default: false,
                            enabled: true,
                            info: {
                                name: t("preview.option.1.item.2.name"),
                                description: ""
                            },
                            posId: '124',
                            price: 0.50,
                            sort: 1
                        },
                        {
                            id: "vb",
                            default: false,
                            enabled: true,
                            info: {
                                name: t("preview.option.1.item.3.name"),
                                description: ""
                            },
                            posId: '125',
                            price: 0.50,
                            sort: 1
                        }
                    ],
                    name: t("preview.option.1.name"),
                    sort: 0,
                    type: "single"
                },
                {
                    id: "jam",
                    config: {},
                    items: [
                        {
                            id: "sans",
                            default: true,
                            enabled: true,
                            info: {
                                name: t("preview.option.2.item.1.name"),
                                description: ""
                            },
                            posId: '123',
                            price: 10,
                            sort: 0
                        },
                        {
                            id: "sjam",
                            default: false,
                            enabled: true,
                            info: {
                                name: t("preview.option.2.item.2.name"),
                                description: ""
                            },
                            posId: '124',
                            price: 0.50,
                            sort: 1
                        },
                        {
                            id: "honey",
                            default: false,
                            enabled: true,
                            info: {
                                name: t("preview.option.2.item.3.name"),
                                description: ""
                            },
                            posId: '125',
                            price: 0.50,
                            sort: 1
                        }
                    ],
                    name: t("preview.option.2.name"),
                    sort: 0,
                    type: "multiple"
                }
            ], selected: [], onSelect: function () {
            } }));
});
var StyleColors = React.memo(function (props) {
    var control = props.control, colors = props.colors;
    var _a = React.useState("color"), active = _a[0], setActive = _a[1];
    var t = useTranslation().t;
    var updateActive = function (color) { return function (e) {
        if (e) {
            e.preventDefault();
        }
        setActive(color);
    }; };
    var hasPermission = userStore(function (state) { return state.hasPermission; });
    var disabled = !hasPermission(CompanyPermissions.COMPANY_EDIT);
    return React.createElement(React.Fragment, null,
        React.createElement(Colors, null,
            active == "color" && React.createElement(FormColorsRadio, { control: control, colors: ["#59b8e0", "#55aa68", "#f2a541", "#c42e74", "#925bd2", "#b4cc50"], name: "colors.color", disabled: disabled }),
            active == "backgroundColor" && React.createElement(FormColorsRadio, { control: control, colors: ["#59b8e0", "#55aa68", "#f2a541", "#c42e74", "#925bd2", "#fbfbfb"], name: "colors.backgroundColor", disabled: disabled }),
            active == "fontColor" && React.createElement(FormColorsRadio, { control: control, colors: ["#59b8e0", "#55aa68", "#f2a541", "#c42e74", "#925bd2", "#b4cc50"], name: "colors.fontColor", disabled: disabled })),
        React.createElement(CNav, { variant: "tabs" },
            React.createElement(CNavItem, null,
                React.createElement(CNavLink, { href: "#", active: active == "color", onClick: updateActive("color") }, t("style.custom.color.text"))),
            React.createElement(CNavItem, null,
                React.createElement(CNavLink, { href: "#", active: active == "backgroundColor", onClick: updateActive("backgroundColor") }, t("style.custom.background.text"))),
            React.createElement(CNavItem, null,
                React.createElement(CNavLink, { href: "#", active: active == "fontColor", onClick: updateActive("fontColor") }, t("style.custom.font.color.text")))),
        active == "color" && React.createElement(FormColorInput, { control: control, name: "colors.color", disabled: disabled }),
        active == "backgroundColor" && React.createElement(FormColorInput, { control: control, name: "colors.backgroundColor", disabled: disabled }),
        active == "fontColor" && React.createElement(FormColorInput, { control: control, name: "colors.fontColor", disabled: disabled }));
});
var Styles = React.memo(function (props) {
    var control = props.control;
    var t = useTranslation().t;
    var hasPermission = userStore(function (state) { return state.hasPermission; });
    var disabled = !hasPermission(CompanyPermissions.COMPANY_EDIT);
    return React.createElement(SettingsPageContainer, null,
        React.createElement(SettingsPageHeader, null,
            React.createElement(SettingsPageTitle, null, t("company.sidebar.styles"))),
        React.createElement(SettingsPageContent, null,
            React.createElement(Container, null,
                React.createElement(FormContainerColumn, null,
                    React.createElement(FormSelectInput, { label: "Font", control: control, name: "font", disabled: disabled, options: [
                            {
                                label: "Default",
                                value: "Rubik"
                            },
                            {
                                label: "Roboto",
                                value: "Roboto"
                            },
                            {
                                label: "Open Sans",
                                value: "Open Sans"
                            },
                            {
                                label: "Lato",
                                value: "Lato"
                            },
                            {
                                label: "Montserrat",
                                value: "Montserrat"
                            },
                            {
                                label: "Oswald",
                                value: "Oswald"
                            },
                            {
                                label: "Source Sans Pro",
                                value: "Source Sans Pro"
                            },
                            {
                                label: "Slabo 27px",
                                value: "Slabo 27px"
                            },
                            {
                                label: "Raleway",
                                value: "Raleway"
                            },
                            {
                                label: "PT Sans",
                                value: "PT Sans"
                            },
                            {
                                label: "Merriweather",
                                value: "Merriweather"
                            }
                        ] }),
                    React.createElement(StyleColors, { control: control }))),
            React.createElement(Preview, { control: control })));
});
export default Styles;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
