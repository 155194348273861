var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import styled from "styled-components";
var getButtonBkgColor = function (variant) {
    switch (variant) {
        case "default":
            return "#FE724C";
        case "outline":
            return "#fff";
        default:
            return "#FE724C";
    }
};
var getButtonTextColor = function (variant) {
    switch (variant) {
        case "default":
            return "#fff";
        case "outline":
            return "#FE724C";
        default:
            return "#fff";
    }
};
var ButtonContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: inline-block;\n    border-radius: 0.5rem;\n    background-color: ", ";\n    border: #FE724C solid 0.15rem;\n    color: ", ";\n    font-size: 1rem;\n    font-weight: 400;\n    padding: 0.25rem 1rem;\n    text-align: center;\n    opacity: ", "\n"], ["\n    display: inline-block;\n    border-radius: 0.5rem;\n    background-color: ", ";\n    border: #FE724C solid 0.15rem;\n    color: ", ";\n    font-size: 1rem;\n    font-weight: 400;\n    padding: 0.25rem 1rem;\n    text-align: center;\n    opacity: ", "\n"])), function (props) { return getButtonBkgColor(props.variant); }, function (props) { return getButtonTextColor(props.variant); }, function (props) { return props.disabled ? 0.5 : 1; });
export var BoardingButton = React.memo(function (props) {
    var children = props.children, onClick = props.onClick, rest = __rest(props, ["children", "onClick"]);
    var handleClick = function (e) {
        if (props.disabled) {
            return;
        }
        onClick(e);
    };
    return React.createElement(ButtonContainer, __assign({}, rest, { onClick: handleClick }), children);
});
var templateObject_1;
