var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { SettingsPageContainer, SettingsPageHeader, SettingsPageTitle, SettingsPageContent, FormContainerColumn, FormHorizontalContainer, FormVerticalContainer } from "../settings";
import FormTextInput from "../common/form/FormTextInput";
import FormTextAreaInput from "../common/form/FormTextAreaInput";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
var languages = require("../../../assets/json/languages.json");
var currencies = require("../../../assets/json/currencies.json");
import FormSelectInput from "../common/form/FormSelectInput";
import { useHttpRequestHandler } from "../../service/network.service";
import { getLangugesRequest } from "../../request/settings.request";
import { CurrencySymbolPlacements } from "../common/form/FormCurrencyInput";
import { userStore } from "../../store/user.store";
import { CompanyPermissions } from "../../common/user/Permission";
var currency = require("currency.js");
var logger = require("js-logger").get("CommonInfo");
//logger.info("currency", currency)
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 10px 0\n"], ["\n    padding: 10px 0\n"])));
var CommonInfo = React.memo(function (props) {
    var control = props.control;
    var t = useTranslation().t;
    var hasPermission = userStore(function (state) { return state.hasPermission; });
    var disalbed = !hasPermission(CompanyPermissions.COMPANY_EDIT);
    var availableLanguages = useHttpRequestHandler(getLangugesRequest(), []);
    return React.createElement(Container, null,
        React.createElement(SettingsPageContainer, null,
            React.createElement(SettingsPageHeader, null,
                React.createElement(SettingsPageTitle, null, t("company.common.info.title"))),
            React.createElement(SettingsPageContent, null,
                React.createElement(FormVerticalContainer, null,
                    React.createElement(FormHorizontalContainer, null,
                        React.createElement(FormContainerColumn, null,
                            React.createElement(FormTextInput, { label: t("company.common.info.place.text"), name: "name", control: control, disabled: disalbed }))),
                    React.createElement(FormHorizontalContainer, null,
                        React.createElement(FormContainerColumn, { flex: 0.5 },
                            React.createElement(FormTextInput, { label: t("company.common.info.vars.text"), name: "vars", control: control, disabled: disalbed })),
                        React.createElement(FormContainerColumn, { flex: 0.5 },
                            React.createElement(FormTextInput, { label: t("company.common.info.type.text"), name: "type", control: control, disabled: disalbed }))),
                    React.createElement(FormHorizontalContainer, null,
                        React.createElement(FormTextAreaInput, { label: t("company.common.info.short.text"), name: "shortInfo", control: control, disabled: disalbed })),
                    React.createElement(FormHorizontalContainer, null,
                        React.createElement(FormContainerColumn, null,
                            React.createElement(FormTextInput, { label: t("company.common.info.url.text"), name: "url", control: control, disabled: disalbed })),
                        React.createElement(FormContainerColumn, null,
                            React.createElement(FormTextInput, { label: t("company.common.info.email.text"), name: "email", control: control, disabled: disalbed }))),
                    React.createElement(FormHorizontalContainer, null,
                        React.createElement(FormContainerColumn, null,
                            React.createElement(FormTextInput, { label: t("company.common.info.phone.text"), name: "phone", control: control, disabled: disalbed }))),
                    React.createElement(FormHorizontalContainer, null,
                        React.createElement(FormContainerColumn, null,
                            React.createElement(FormTextInput, { label: t("company.common.info.wifissid.text"), name: "wifiSSID", control: control, disabled: disalbed })),
                        React.createElement(FormContainerColumn, null,
                            React.createElement(FormTextInput, { label: t("company.common.info.wifipass.text"), name: "wifiPass", control: control, disabled: disalbed })),
                        React.createElement(FormContainerColumn, null,
                            React.createElement(FormSelectInput, { label: t("company.common.info.wifienc.text"), name: "wifiEnc", control: control, disabled: disalbed, options: [
                                    { label: "WPA", value: "WAP" },
                                    { label: "WEP", value: "WEP" },
                                    { label: "None", value: "None" },
                                ], defaultValue: "None" }))),
                    React.createElement(FormHorizontalContainer, null,
                        React.createElement(FormContainerColumn, null,
                            React.createElement(FormSelectInput, { label: t("company.common.info.language.text"), name: "mainLanguage", control: control, disabled: disalbed, 
                                /////----//////
                                options: availableLanguages.result.map(function (code) {
                                    var lang = languages.find(function (item) { return item.code == code; });
                                    return {
                                        value: lang.code,
                                        label: lang.name
                                    };
                                }) })),
                        React.createElement(FormContainerColumn, null,
                            React.createElement(FormSelectInput, { label: t("company.common.info.currency.text"), name: "mainCurrency", control: control, disabled: disalbed, options: currencies.map(function (currencyObj) {
                                    logger.info(currencyObj.name, currencyObj.decimalDigits);
                                    return {
                                        value: currencyObj.code,
                                        label: "".concat(currencyObj.name, " ").concat(currency(2.45).format({
                                            pattern: CurrencySymbolPlacements[currencyObj.code],
                                            precision: currencyObj.decimalDigits,
                                            symbol: currencyObj.symbolNative
                                        }))
                                    };
                                }) }))),
                    React.createElement(FormHorizontalContainer, null,
                        React.createElement(FormTextAreaInput, { label: t("company.common.info.customtype.text"), name: "customTypeName", control: control, disabled: disalbed }))))));
});
export default CommonInfo;
var templateObject_1;
