var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Box } from "../component/common/FlexItem";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex-direction: column;\n    flex: 1;\n    height: 100%;\n"], ["\n    flex-direction: column;\n    flex: 1;\n    height: 100%;\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    flex: 0.1;\n"], ["\n    flex: 0.1;\n"])));
var ImageContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["    \n    flex: 0.7;\n    background: url(", ") no-repeat center center;\n    background-size: contain;\n"], ["    \n    flex: 0.7;\n    background: url(", ") no-repeat center center;\n    background-size: contain;\n"])), function (props) { return props.icon; });
var TextContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    flex: 0.2;\n    padding-top: 1rem;\n"], ["\n    flex: 0.2;\n    padding-top: 1rem;\n"])));
export var DefaultText = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 1.1rem;\n    line-height: 1.5rem;\n    font-weight: 400;\n    color: #000;\n    text-align: center;\n"], ["\n    font-size: 1.1rem;\n    line-height: 1.5rem;\n    font-weight: 400;\n    color: #000;\n    text-align: center;\n"])));
var ButtonsContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    flex: 0.25;\n    padding:0 1rem;\n    display: flex;\n    flex-direction: column;    \n    justify-content: center;\n"], ["\n    flex: 0.25;\n    padding:0 1rem;\n    display: flex;\n    flex-direction: column;    \n    justify-content: center;\n"])));
export var ButtonsContent = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    width: 100%;\n    \n"], ["\n    display: flex;\n    width: 100%;\n    \n"])));
export var ButtonLeft = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    display: flex;\n    justify-content: flex-start;\n"], ["\n    display: flex;\n    justify-content: flex-start;\n"])));
export var ButtonRight = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    display: flex;\n    flex: 1;\n    justify-content: flex-end;\n"], ["\n    display: flex;\n    flex: 1;\n    justify-content: flex-end;\n"])));
export var Bullets = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    display: flex;\n    margin:1rem 0;\n    gap: 0.5rem;\n    justify-content: center;\n\n"], ["\n    display: flex;\n    margin:1rem 0;\n    gap: 0.5rem;\n    justify-content: center;\n\n"])));
export var Bullet = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    display: inline-box;\n    width: 1rem;\n    height: 1rem;\n    background-color: #FE724C;\n    opacity: ", ";\n    border-radius: 0.5rem;\n\n"], ["\n    display: inline-box;\n    width: 1rem;\n    height: 1rem;\n    background-color: #FE724C;\n    opacity: ", ";\n    border-radius: 0.5rem;\n\n"])), function (props) { return props.active ? 1 : 0.2; });
export var BoardingScreen = React.memo(function (props) {
    var icon = props.icon, text = props.text;
    var t = useTranslation().t;
    return React.createElement(Container, null,
        React.createElement(Header, null),
        React.createElement(ImageContainer, { icon: icon }),
        React.createElement(TextContainer, null, text));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
