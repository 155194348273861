var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import { CountdownView } from "./countdown.view";
import { ExpiredView } from "./expired.view";
var logger = require("js-logger").get("Countdown");
var isExpired = function (expiration) {
    return Date.now() > expiration;
};
//const get
function formatTime(time) {
    var minutes = Math.floor(time / 60);
    var seconds = Math.floor(time % 60);
    if (seconds < 10) {
        seconds = "0".concat(seconds);
    }
    if (minutes < 10) {
        minutes = "0".concat(minutes);
    }
    return "".concat(minutes, ":").concat(seconds);
}
var Countdown = /** @class */ (function (_super) {
    __extends(Countdown, _super);
    function Countdown(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            timerSeconds: 0,
            timeLeft: 0,
            timePassed: 0
        };
        _this.timerId = null;
        var _a = _this.props, expiration = _a.expiration, timerSeconds = _a.timerSeconds, timePassed = _a.timePassed;
        if (!isExpired(expiration)) {
            _this.state.timerSeconds = timerSeconds;
            _this.state.timePassed = timePassed;
            _this.state.timeLeft = timerSeconds - timePassed;
        }
        else {
            _this.state.timePassed = (Date.now() - expiration) / 1000;
        }
        return _this;
    }
    Countdown.prototype.componentDidMount = function () {
        this.start();
    };
    Countdown.prototype.start = function () {
        var _this = this;
        this.timerId = setInterval(function () {
            var _a = _this.state, timeLeft = _a.timeLeft, timePassed = _a.timePassed;
            var updateTimeLeft = timeLeft > 0 ? timeLeft - 1 : timeLeft;
            var updateTimePassed = timePassed + 1;
            _this.setState({
                timeLeft: updateTimeLeft,
                timePassed: updateTimePassed
            });
        }, 1000);
    };
    Countdown.prototype.componentWillUnmount = function () {
        clearInterval(this.timerId);
    };
    Countdown.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if ((prevState.timeLeft != this.state.timeLeft) && this.state.timeLeft == 0) {
            var _a = this.props, onComplete = _a.onComplete, continueOnExpire = _a.continueOnExpire;
            if (onComplete) {
                onComplete();
            }
            if (continueOnExpire) {
                this.setState({ timePassed: 0 });
            }
        }
        if (prevProps.timerSeconds !== this.props.timerSeconds) {
            this.setState({
                timerSeconds: this.props.timerSeconds,
                timeLeft: this.props.timerSeconds - this.props.timePassed
            });
        }
        if (prevProps.timePassed !== this.props.timePassed) {
            this.setState({
                timePassed: this.props.timePassed,
                timeLeft: this.props.timerSeconds - this.props.timePassed
            });
        }
    };
    Countdown.prototype.render = function () {
        var expiration = this.props.expiration;
        var _a = this.state, timeLeft = _a.timeLeft, timePassed = _a.timePassed, timerSeconds = _a.timerSeconds;
        var timeValue = isExpired(expiration) ? timePassed : timeLeft;
        var formattedTime = this.props.formatTime ? this.props.formatTime(timeValue, isExpired(expiration)) : formatTime(timeValue);
        return isExpired(expiration) ? React.createElement(ExpiredView, { timePassed: formattedTime }) : React.createElement(CountdownView, { time: formattedTime, timeLeft: timeLeft, timePassed: timePassed, timeLimit: timerSeconds });
    };
    return Countdown;
}(React.PureComponent));
export { Countdown };
// export const Countdown = React.memo((props: CountdownProps) => {
//     const {
//         expiration,
//         onComplete,
//         continueOnExpire
//     } = props;
//     const [timerSeconds] = React.useState((expiration - Date.now()) / 1000);
//     const [timeLeft, setTimeLeft] = React.useState(timerSeconds);
//     const [timePassed, setTimePassed] = React.useState(isExpired(expiration) ? (Date.now() - expiration) / 1000 : 0);
//     const [completed, setCompleted] = React.useState(false);
//     // React.useEffect(() => {
//     //     //setCircleDasharray(timeLeft);
//     //     //if()        
//     //     setTimeout(() => {            
//     //         if (timeLeft > 0) {
//     //             setTimeLeft(timeLeft - 1);
//     //         }
//     //     }, 1000);
//     //     logger.info("Got it" );
//     // }, [timeLeft]);
//     // React.useEffect(() => {
//     //     setTimePassed(timePassed + 1);
//     // }, [timeLeft])
//     React.useEffect(() => {
//         const intervalId = setInterval(() => {
//             if (timeLeft > 0) {
//                 setTimeLeft(timeLeft - 1);
//             }
//             if (timeLeft == 0 && continueOnExpire) {
//                 setTimePassed(0);
//             }
//         }, 1000)
//         return () => {
//             clearInterval(intervalId);
//         }
//     }, [timeLeft]);
//     React.useEffect(() => {
//         setTimePassed(timePassed + 1);
//     }, [timePassed])
//     const timeValue = isExpired(expiration) ? timePassed : timeLeft;
//     const formattedTime = props.formatTime ? props.formatTime(timeValue, isExpired(expiration)) : formatTime(timeValue);
//     return isExpired(expiration) ? <ExpiredView timePassed={formattedTime} /> : <CountdownView time={formattedTime} timeLeft={timeLeft} timePassed={timePassed} timeLimit={timerSeconds} />
// })
