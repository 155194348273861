var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import FormRadioGroupInput from "../component/common/form/FormRadioGroupInput";
import FormTextAreaInput from "../component/common/form/FormTextAreaInput";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Button } from "../common/Button";
import { Box } from "../component/common/FlexItem";
import { useTranslation } from "react-i18next";
import { HttpApiRequest } from "../service/network.service";
import { getNotConfigurablePaymentMethods } from "../request/payment.request";
import LoadingBox from "../component/common/LoadingBox";
var logger = require("js-logger").get("AddPaymentForm");
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex-direction: column;\n    align-items: center;\n"], ["\n    flex-direction: column;\n    align-items: center;\n"])));
var Buttons = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-top: 1rem;\n    justify-content: center;\n    gap: 0.5rem\n"], ["\n    margin-top: 1rem;\n    justify-content: center;\n    gap: 0.5rem\n"])));
export var AddPaymentForm = React.memo(function (props) {
    var onClose = props.onClose, onSave = props.onSave;
    var t = useTranslation().t;
    var _a = React.useState([]), methods = _a[0], setMethods = _a[1];
    var _b = React.useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useForm(), control = _c.control, reset = _c.reset, formState = _c.formState, handleSubmit = _c.handleSubmit;
    React.useEffect(function () {
        setLoading(true);
        HttpApiRequest(getNotConfigurablePaymentMethods())
            .promise
            .then(function (res) {
            logger.info("Payment methods", res.data);
            setMethods(res.data);
            reset({
                method_id: res.data[0].id
            });
        })
            .catch(function (err) {
            logger.error(err);
        })
            .finally(function () {
            setLoading(false);
        });
    }, []);
    if (loading) {
        return React.createElement(LoadingBox, null);
    }
    return React.createElement(Container, null,
        React.createElement("form", { onSubmit: handleSubmit(onSave) },
            React.createElement(Box, { flexDirection: "column" },
                React.createElement(Box, { flex: "none" },
                    React.createElement(FormRadioGroupInput, { name: "method_id", control: control, items: methods.map(function (method) { return ({
                            label: method.name,
                            value: method.id
                        }); }), rules: { required: true } })),
                React.createElement(FormTextAreaInput, { label: t("order.payment.comment.label"), control: control, name: "comment" })),
            React.createElement(Buttons, null,
                React.createElement(Button, { onClick: onClose }, t("close.text")),
                React.createElement(Button, { color: "primary", type: "submit", disabled: !formState.isValid }, t("save.text")))));
});
var templateObject_1, templateObject_2;
