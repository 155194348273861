import * as React from "react";
import { useController } from "react-hook-form";
import { CFormFeedback } from "@coreui/react";
var logger = require("js-logger").get("FormGoogleAddressInput");
import GoogleAddressInput from "../GoogleAddressInput";
import { useTranslation } from "react-i18next";
var FormGoogleAddressInput = function (props) {
    var _a, _b;
    var control = props.control, name = props.name, defaultValue = props.defaultValue, label = props.label, rules = props.rules, disabled = props.disabled, inputRef = props.inputRef, apiKey = props.apiKey, placeholder = props.placeholder, onFocus = props.onFocus, info = props.info;
    var t = useTranslation().t;
    var _c = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _d = _c.field, onChange = _d.onChange, onBlur = _d.onBlur, _e = _d.value, value = _e === void 0 ? defaultValue ? defaultValue : "" : _e, fieldState = _c.fieldState, formState = _c.formState;
    return React.createElement(React.Fragment, null,
        React.createElement(GoogleAddressInput, { label: label, defaultValue: defaultValue, placeholder: placeholder, disabled: disabled, inputRef: inputRef, apiKey: apiKey, onChange: onChange, onBlur: onBlur, onFocus: onFocus, info: info }),
        fieldState.isDirty && !value && React.createElement(CFormFeedback, { invalid: true, style: { display: "block" } },
            ((_a = fieldState === null || fieldState === void 0 ? void 0 : fieldState.error) === null || _a === void 0 ? void 0 : _a.message) && fieldState.error.message,
            !((_b = fieldState === null || fieldState === void 0 ? void 0 : fieldState.error) === null || _b === void 0 ? void 0 : _b.message) && t("form.error.address.message")));
};
export default FormGoogleAddressInput;
