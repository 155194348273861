import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import * as _ from "underscore";
var logger = require("js-logger").get("i18next");
//const logger = require("js-logger").get("1i18next");
import { userStore } from "../store/user.store";
import * as en from "../../locales/i18n/en.json";
import * as de from "../../locales/i18n/de.json";
import * as es from "../../locales/i18n/es.json";
import * as ru from "../../locales/i18n/ru.json";
import * as iw from "../../locales/i18n/iw.json";
import * as ar from "../../locales/i18n/ar.json";
import * as cs from "../../locales/i18n/cs.json";
import * as fr from "../../locales/i18n/fr.json";
import * as ko from "../../locales/i18n/ko.json";
import * as pl from "../../locales/i18n/pl.json";
//console.log("EN", en)
var cleanEmptyProprties = function (data) {
    var obj = {};
    Object.keys(data).forEach(function (key) {
        if (data[key]) {
            obj[key] = data[key];
        }
    });
    return obj;
};
export var langDirection = {
    iw: "rtl",
    he: "rtl",
    ar: "rtl",
    fa: "rtl"
};
export var resources = {
    en: { common: en },
    de: { common: _.extend({}, en, cleanEmptyProprties(de)) },
    es: { common: _.extend({}, en, cleanEmptyProprties(es)) },
    ru: { common: _.extend({}, en, cleanEmptyProprties(ru)) },
    iw: { common: _.extend({}, en, cleanEmptyProprties(iw)) },
    he: { common: _.extend({}, en, cleanEmptyProprties(iw)) },
    ar: { common: _.extend({}, en, cleanEmptyProprties(ar)) },
    cs: { common: _.extend({}, en, cleanEmptyProprties(cs)) },
    fr: { common: _.extend({}, en, cleanEmptyProprties(fr)) },
    ko: { common: _.extend({}, en, cleanEmptyProprties(ko)) },
    pl: { common: _.extend({}, en, cleanEmptyProprties(pl)) },
};
//const languageFromPath = getLanguageFromPath(document.location.pathname);
var navigatorObj = navigator;
var lll = navigatorObj.language || navigatorObj.userLanguage;
logger.info("Find Language", lll, document.location.search);
export var setUILanguage = function (language) {
    document.getElementById("html").setAttribute("lang", language);
    i18n.changeLanguage(language);
    document.body.removeAttribute("direction");
    document.body.removeAttribute("style");
    if (langDirection[language]) {
        document.body.setAttribute("direction", "rtl");
        document.body.setAttribute("style", "direction: ".concat(langDirection[language]));
    }
};
export var initi18n = function (useStorage) {
    if (useStorage === void 0) { useStorage = false; }
    i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
        resources: resources,
        //lng: useStorage ? userStore.getState().language : navigatorObj.language,
        fallbackLng: "en",
        debug: process.env.NODE_ENV !== 'production',
        keySeparator: "__",
        ns: ['common'],
        defaultNS: "common",
        detection: {
            lookupQuerystring: "hl"
        },
        react: {
            // wait: false,
            bindI18n: 'languageChanged loaded',
            transSupportBasicHtmlNodes: true
            // bindStore: 'added removed',
            // nsMode: 'default'
        },
    })
        .then(function () {
        logger.info("i18nLanguage", i18n.language, "Storage Languge", userStore.getState().language, "useStorage", useStorage);
        if (useStorage && (i18n.language !== userStore.getState().language)) {
            setUILanguage(userStore.getState().language);
        }
        else {
            setUILanguage(i18n.language);
        }
    });
};
export default i18n;
