export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["CASH"] = "cash";
    PaymentMethod["POS"] = "pos";
})(PaymentMethod || (PaymentMethod = {}));
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["IN_PROGRESS"] = "in-progress";
    PaymentStatus["FAILED"] = "failed";
    PaymentStatus["REQUIRES_CAPTURE"] = "requires_capture";
    PaymentStatus["CANCELED"] = "canceled";
    PaymentStatus["COMPLETED"] = "completed";
    PaymentStatus["ACTION"] = "action";
    PaymentStatus["REFUNDED"] = "refunded";
})(PaymentStatus || (PaymentStatus = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["NEW"] = "new";
    OrderStatus["IN_PROGRESS"] = "in-progress";
    OrderStatus["READY"] = "ready";
    OrderStatus["COMPLETED"] = "completed";
})(OrderStatus || (OrderStatus = {}));
