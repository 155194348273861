import { Languages, defaultLanguage } from "../constats/Language";
export function getLanguage() {
    var language = navigator.language;
    if (!language) {
        var navigatorRecord = navigator;
        language = navigatorRecord["userLanguage"];
    }
    if (!language) {
        language = defaultLanguage;
    }
    language = language.split("-")[0];
    if (!Languages.includes(language)) {
        language = defaultLanguage;
    }
    return language;
}
export var copyToClipBoard = function (text) {
    var input = document.createElement("input");
    //input.style.position = "absolute",
    //input.style.position = "absolute",
    //input.style.visibility = "hidden"
    document.body.appendChild(input);
    input.value = text;
    //Select the content in the input element
    input.select();
    input.setSelectionRange(0, 99999);
    //copy the text inside the input element to clipboard
    document.execCommand('copy');
    input.remove();
};
