var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CForm, CModal, CModalBody } from "@coreui/react";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { createCompanyAreaPlaceRequest, deleteCompanyAreaPlaceRequest, getCompanyAreaPlacesRequest, getCompanyAreaRequest } from "../../request/area.request";
import { getCompanyAreaQRSettingsRequest } from "../../request/company.request";
import { useHttpRequesPrepareHandler, useHttpRequestHandler } from "../../service/network.service";
import { userStore } from "../../store/user.store";
import FormTextInput from "../common/form/FormTextInput";
import LoadingBox from "../common/LoadingBox";
import { AreaQRCode } from "./AreaQRCodeV1";
var logger = require("js-logger").get("AddPlaceForm");
//import { QRCode } from "../common/QRCodeV1";
import { faLink, faPlus, faUnlink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Scrollbars from "react-custom-scrollbars-2";
import { Button } from "../../common/Button";
import { APP_AREAS, buildRoute } from "../../constats/Routes";
import { addCompanyAreaPlaceAliasId, removeCompanyAreaPlaceAliasId } from "../../request/area.request";
import { HttpApiRequest } from "../../service/network.service";
import { Box } from "../common/FlexItem";
import { ListItem } from "../common/ListItem";
import { Modal, ModalBody, ModalHeader, ModalHeaderTitle } from "../common/Modal";
import { AreaQrDownload } from "./AreaQrDownload";
import { LinkPlace } from "./link.place";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["    \n    padding: 10px 10px 0 10px;    \n    flex: 1;\n    display: flex;\n    flex-direction: column; \n"], ["    \n    padding: 10px 10px 0 10px;    \n    flex: 1;\n    display: flex;\n    flex-direction: column; \n"])));
var PlaceContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 5px 10px;\n    border:#dedede solid 1px;\n    margin: 3px 0;\n    display: flex;\n"], ["\n    padding: 5px 10px;\n    border:#dedede solid 1px;\n    margin: 3px 0;\n    display: flex;\n"])));
var PlaceContainerActions = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    flex: 1;\n    justify-content: flex-end;\n    gap: 5px;\n    align-items: center\n"], ["\n    display: flex;\n    flex: 1;\n    justify-content: flex-end;\n    gap: 5px;\n    align-items: center\n"])));
var AreaBar = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    padding: 5px 0;\n"], ["\n    display: flex;\n    padding: 5px 0;\n"])));
var AreaBarActions = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    flex: 1;\n    display: flex;\n    justify-content: flex-end;\n    gap: 10px;\n"], ["\n    flex: 1;\n    display: flex;\n    justify-content: flex-end;\n    gap: 10px;\n"])));
var Places = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var PlacesBar = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    width: 100%;\n    display: flex;\n    align-items: center;\n    padding: 5px 0;\n"], ["\n    width: 100%;\n    display: flex;\n    align-items: center;\n    padding: 5px 0;\n"])));
var PlacesBarRight = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    flex: 1;\n    display: flex;\n    justify-content: flex-end;\n    gap: 5px;\n"], ["\n    flex: 1;\n    display: flex;\n    justify-content: flex-end;\n    gap: 5px;\n"])));
var PlacePosIDText = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    display: flex;\n    gap: 5px;\n    font-size: 0.75rem;\n    color: #858992;\n"], ["\n    display: flex;\n    gap: 5px;\n    font-size: 0.75rem;\n    color: #858992;\n"])));
var Place = React.memo(function (props) {
    var area = props.area, place = props.place, onDelete = props.onDelete, qrConfig = props.qrConfig, refresh = props.refresh;
    var t = useTranslation().t;
    var _a = React.useState(false), showLink = _a[0], setShowLink = _a[1];
    //const params = useParams();
    //const area_id: string = params.area_id as string;
    //const companyId = userStore(state => state.company.id);
    //const token = userStore(state => state.token);
    //const company = userStore(state => state.company);  
    var addAliasId = function (id) {
        logger.info("Save", id);
        HttpApiRequest(addCompanyAreaPlaceAliasId(area.id, place.id, id))
            .promise
            .then(function () {
            setShowLink(false);
            refresh();
        })
            .catch(function (err) {
            logger.error(err);
        });
    };
    var removeAlias = function () {
        if (confirm(t("place.link.confim.remove.text"))) {
            HttpApiRequest(removeCompanyAreaPlaceAliasId(area.id, place.id))
                .promise
                .then(function () {
                refresh();
            })
                .catch(function (err) {
                logger.error(err);
            });
        }
    };
    return React.createElement(ListItem
    //sortable
    //        active
    , { 
        //sortable
        //        active
        actions: React.createElement(PlaceContainerActions, null,
            React.createElement(Button, { onClick: place.aliasId ? removeAlias : function () {
                    setShowLink(true);
                } },
                React.createElement(FontAwesomeIcon, { icon: place.aliasId ? faUnlink : faLink, size: "1x" })),
            React.createElement(CButton, { style: {
                    "backgroundColor": "#5AA9E1",
                    "border-color": "#5AA9E1"
                } },
                React.createElement(AreaQRCode, { place: place, area: area, iconSize: "sm" })),
            React.createElement(CButton, { color: "danger" },
                React.createElement(CIcon, { icon: cilTrash, size: "sm", onClick: onDelete }))) },
        React.createElement(Box, { flexDirection: "column", align: "flex-start" },
            place.name,
            React.createElement(PlacePosIDText, null,
                React.createElement("div", null,
                    t("posId.text"),
                    ":"),
                React.createElement("div", null, place.data.posId))),
        showLink && React.createElement(Modal, { visible: true, noBorderRadius: true, fullscreen: true },
            React.createElement(ModalHeader, { onClose: function () {
                    setShowLink(false);
                } },
                React.createElement(ModalHeaderTitle, null, t("place.link.id.title"))),
            React.createElement(ModalBody, null,
                React.createElement(LinkPlace, { area_id: area.id, place_id: place.id, onCancel: function () { return setShowLink(false); }, onSave: addAliasId }))));
});
var FormButtons = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    margin-top: 10px;\n    display: flex;\n    gap: 5px;\n    justify-content: flex-end;\n"], ["\n    margin-top: 10px;\n    display: flex;\n    gap: 5px;\n    justify-content: flex-end;\n"])));
export var AddPlaceForm = React.memo(function (props) {
    var onSubmit = props.onSubmit, onCancel = props.onCancel;
    var _a = useForm({
        mode: "onChange"
    }), control = _a.control, handleSubmit = _a.handleSubmit, formState = _a.formState;
    var t = useTranslation().t;
    return React.createElement(CForm, { onSubmit: handleSubmit(onSubmit) },
        React.createElement(FormTextInput, { control: control, label: t("name.text"), name: "name", rules: {
                required: true,
                minLength: 3
            } }),
        React.createElement(FormTextInput, { control: control, label: t("posId.text"), name: "data.posId", rules: {
                required: true,
                minLength: 3
            } }),
        React.createElement(FormButtons, null,
            React.createElement(CButton, { color: "secondary", onClick: onCancel }, t("cancel.text")),
            React.createElement(CButton, { color: "primary", type: "submit", disabled: !formState.isDirty || !formState.isValid }, t("save.text"))));
});
var PlacesTitle = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    color: #858992;\n    font-size: 1rem;\n    font-weight: 400\n"], ["\n    color: #858992;\n    font-size: 1rem;\n    font-weight: 400\n"])));
export var AreaPage = React.memo(function (props) {
    var params = useParams();
    var area_id = params.area_id;
    var placesState = useHttpRequestHandler(getCompanyAreaPlacesRequest(area_id));
    var areaState = useHttpRequestHandler(getCompanyAreaRequest(area_id));
    var settingsState = useHttpRequestHandler(getCompanyAreaQRSettingsRequest());
    var createPlaceState = useHttpRequesPrepareHandler();
    var deletePlaceState = useHttpRequesPrepareHandler();
    var _a = React.useState(false), showForm = _a[0], setShowForm = _a[1];
    var t = useTranslation().t;
    var companyId = userStore(function (state) { return state.company.id; });
    var token = userStore(function (state) { return state.token; });
    var hasPermission = userStore(function (state) { return state.hasPermission; });
    var navigate = useNavigate();
    var addPlace = function () {
        setShowForm(true);
    };
    var closeForm = function () {
        setShowForm(false);
    };
    var savePlace = function (values) {
        logger.info(values);
        createPlaceState.run(createCompanyAreaPlaceRequest(area_id, values))
            .then(function () {
            placesState.run();
        })
            .finally(function () {
            closeForm();
        });
    };
    var deletePlace = function (id) { return function () {
        if (confirm(t("delete.confirm.text"))) {
            deletePlaceState.run(deleteCompanyAreaPlaceRequest(area_id, id))
                .finally(function () {
                placesState.run();
            });
        }
    }; };
    React.useEffect(function () {
        placesState.run();
        areaState.run();
    }, [area_id]);
    var area = areaState.result ? areaState.result : null;
    var places = placesState.result ? placesState.result : [];
    var loading = placesState.inProgress || areaState.inProgress || settingsState.inProgress;
    logger.info("Qr Settings", settingsState.result);
    return React.createElement(Container, null,
        loading && React.createElement(LoadingBox, null),
        !loading && React.createElement(Modal, { visible: true },
            React.createElement(ModalHeader, { onClose: function () {
                    navigate(buildRoute(APP_AREAS, {}));
                } },
                React.createElement(ModalHeaderTitle, null, area && area.name)),
            React.createElement(ModalBody, null,
                React.createElement(Scrollbars, null,
                    React.createElement(Places, null,
                        React.createElement(PlacesBar, null,
                            React.createElement(PlacesTitle, null, t("places.text")),
                            React.createElement(PlacesBarRight, null,
                                React.createElement(AreaQrDownload, { area: area, places: places }),
                                React.createElement(CButton, { color: "link", onClick: addPlace },
                                    React.createElement(FontAwesomeIcon, { icon: faPlus })))),
                        places.map(function (place) { return React.createElement(Place, { refresh: function () {
                                placesState.run();
                            }, area: area, key: place.id, place: place, onDelete: deletePlace(place.id), qrConfig: {
                                frColor: settingsState.result.frColor ? settingsState.result.frColor : "#000000",
                                qrColor: settingsState.result.frColor ? settingsState.result.qrColor : "#000000",
                                text: t("area.qr.text", {
                                    table: place.name
                                }),
                                tpl: settingsState.result.tpl ? settingsState.result.tpl : "tpl2"
                            } }); }))))),
        showForm && React.createElement(CModal, { visible: true, onClose: closeForm },
            React.createElement(CModalBody, null,
                React.createElement(AddPlaceForm, { onSubmit: savePlace, onCancel: closeForm }))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
