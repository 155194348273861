var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { Box } from "../common/FlexItem";
import Scrollbars from "react-custom-scrollbars-2";
import { Sortable } from "../common/Sortable";
import { Section } from "../common/Section";
import { useTranslation } from "react-i18next";
import { DeleteButton, ListItemBase } from "../common/ListItem";
var logger = require('js-logger').get("Kitchens");
import { EditButton } from "../common/ListItem";
import { CFormSwitch } from "@coreui/react";
import { HttpApiRequest, useHttpRequesPrepareHandler } from "../../service/network.service";
import { getStationsRequest, createStationRequest, updateStationRequest, deleteStationRequest } from "../../request/station.request";
import LoadingBox from "../common/LoadingBox";
import { Modal, ModalBody, ModalHeader, ModalHeaderTitle } from "../common/Modal";
import { StationForm } from "./StationForm";
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 1rem;\n    flex: 1;\n    height: 100%;\n    // width: 100%;\n"], ["\n    padding: 1rem;\n    flex: 1;\n    height: 100%;\n    // width: 100%;\n"])));
export var Stations = React.memo(function (props) {
    var t = useTranslation().t;
    var _a = React.useState(undefined), editItem = _a[0], setEditItem = _a[1];
    var stationsState = useHttpRequesPrepareHandler();
    var loadStations = React.useCallback(function () {
        stationsState.run(getStationsRequest());
    }, []);
    var closeStationForm = React.useCallback(function () {
        setEditItem(undefined);
    }, []);
    var openStationForm = React.useCallback(function (editItem) {
        setEditItem(editItem);
    }, []);
    var saveStation = React.useCallback(function (values) {
        HttpApiRequest(editItem == null ? createStationRequest(values.name) : updateStationRequest(editItem.id, values.name))
            .promise
            .then(function () {
            closeStationForm();
            loadStations();
        })
            .catch(function (err) {
            logger.error(err);
        });
        setEditItem(null);
    }, [editItem]);
    React.useEffect(function () {
        loadStations();
    }, []);
    var deleteStation = React.useCallback(function (item) {
        HttpApiRequest(deleteStationRequest(item.id))
            .promise
            .then(loadStations)
            .catch(function (err) {
            logger.error(err);
        });
    }, []);
    function handleDragEnd(items) {
    }
    var stations = stationsState.result || [];
    return React.createElement(Container, null,
        stationsState.inProgress && React.createElement(LoadingBox, null),
        !stationsState.inProgress &&
            React.createElement(Scrollbars, null,
                React.createElement(Section, { title: t("kitchen.section.title"), onAdd: function () { openStationForm(null); } },
                    React.createElement(Sortable, { items: stations, handle: ".drag-handle", sortKey: "sort", renderItem: function (item) {
                            return React.createElement(ListItemBase, { key: item.id, item: item, sortable: true, active: true, actions: function (item) {
                                    logger.info("Render Actions.....", item);
                                    return React.createElement(React.Fragment, null,
                                        React.createElement(EditButton, { item: item, onClick: function (item) {
                                                openStationForm(item);
                                            } }),
                                        React.createElement(DeleteButton, { item: item, onClick: function (item) {
                                                if (confirm("Are you sure ?")) {
                                                    deleteStation(item);
                                                }
                                            } }));
                                } },
                                React.createElement(Box, { aling: "center", gap: "0.5rem" },
                                    React.createElement(CFormSwitch, { checked: item.active, onChange: function (e) {
                                            //toggleActive(item as AreaType)
                                        } }),
                                    React.createElement("div", null, item.name)));
                        }, onSort: handleDragEnd }))),
        editItem !== undefined && React.createElement(Modal, { visible: true },
            React.createElement(ModalHeader, null,
                React.createElement(ModalHeaderTitle, null, editItem == null ? t("station.add.item.text") : t("station.edit.item.text", {
                    name: editItem.name
                }))),
            React.createElement(ModalBody, null,
                React.createElement(StationForm, { onSubmit: saveStation, onCancel: function () { return setEditItem(undefined); }, station: editItem ? editItem : undefined }))));
});
var templateObject_1;
