var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { userStore } from "../../store/user.store";
import { APP } from "../../constats/Routes";
import { Navigate } from "react-router-dom";
var logger = require("js-logger").get("Verified");
import { wrapSignedIn } from "./SignedIn";
var NotVerified = React.memo(function (props) {
    var userState = userStore();
    logger.info("Verified", userState.verified);
    if (userState.verified) {
        return React.createElement(Navigate, { to: APP });
    }
    return React.createElement(React.Fragment, null, props.children);
});
var SignInWrapped = wrapSignedIn(NotVerified);
export default wrapSignedIn(NotVerified);
export function wrapNotVerified(WrappedComponent) {
    return function (props) { return React.createElement(SignInWrapped, null,
        React.createElement(WrappedComponent, __assign({}, props))); };
}
