import * as _ from "underscore";
export function fromJsonToForm(data, formData, parentKey) {
    if (formData === void 0) { formData = null; }
    if (parentKey === void 0) { parentKey = null; }
    if (_.isObject(data)) {
        Object.keys(data).forEach(function (key) {
            if (_.isArray(data[key])) {
                (data[key].forEach(function (val, index) {
                    fromJsonToForm(val, formData, "".concat(key, "[]"));
                }));
            }
            else if (_.isObject(data[key])) {
                fromJsonToForm(data[key], formData, key);
            }
            else {
                var formKey = parentKey ? "".concat(parentKey, ".").concat(key) : key;
                formData.append(formKey, data[key]);
            }
        });
    }
    else if (_.isArray(data)) {
        data.forEach(function (val, index) {
            var key = parentKey ? "".concat(parentKey, "[]") : "[]";
            fromJsonToForm(val, formData, key);
        });
    }
    else {
        formData.append(parentKey, data);
    }
}
export function convertModelToFormData(model, form, namespace) {
    if (form === void 0) { form = null; }
    if (namespace === void 0) { namespace = ''; }
    var formData = form || new FormData();
    var formKey;
    var _loop_1 = function (propertyName) {
        if (!model.hasOwnProperty(propertyName) || !model[propertyName])
            return "continue";
        var formKey_1 = namespace ? "".concat(namespace, "[").concat(propertyName, "]") : propertyName;
        if (model[propertyName] instanceof Date)
            formData.append(formKey_1, model[propertyName].toISOString());
        else if (model[propertyName] instanceof Array) {
            model[propertyName].forEach(function (element, index) {
                var tempFormKey = "".concat(formKey_1, "[").concat(index, "]");
                convertModelToFormData(element, formData, tempFormKey);
            });
        }
        else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
            convertModelToFormData(model[propertyName], formData, formKey_1);
        else {
            var prop = model[propertyName];
            if (prop instanceof File) {
                formData.append(formKey_1, model[propertyName], prop.name);
            }
            else {
                formData.append(formKey_1, model[propertyName].toString());
            }
        }
    };
    for (var propertyName in model) {
        _loop_1(propertyName);
    }
    return formData;
}
