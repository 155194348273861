var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CFormLabel } from "@coreui/react";
import { PhoneNumberUtil } from "google-libphonenumber";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Localize from "../../common/Localize";
import { canUseCompanyNameRequest, canUseDomainRequest } from "../../request/local.request";
import { HttpRequest } from "../../service/network.service";
import { getHostWithProtocol } from "../../utils/URLUtils";
import FormGoogleAddressInput from "../common/form/FormGoogleAddressInput";
import FormGroupTextInput from "../common/form/FormGroupTextInput";
import FormPhoneInput from "../common/form/FormPhoneInput";
import FormTextInput from "../common/form/FormTextInput";
import { wrapNotSignedIn } from "../common/NotSignedIn";
import SocialButton from "../common/SocialButton";
var phoneCodes = require("../../../assets/json/country.code.json");
var phoneUtil = PhoneNumberUtil.getInstance();
import { Boarding, PaginationContainer } from "../../boarding";
import FormRadioGroupInput from "../common/form/FormRadioGroupInput";
import { CompanyMode } from "../../constants/Mode";
import { SwiperSlide, Swiper } from "swiper/react";
import 'swiper/css/bundle';
import { Bullets, Bullet, } from "../../boarding/screen";
import { Buttons } from "../../boarding";
import { ButtonLeft, ButtonRight, ButtonsContent, } from "../../boarding/screen";
import { BoardingButton } from "../../boarding/button";
import { isEmpty } from "underscore";
var domainBlackList = ["registration", "admin", "www", "ked", "welcome", "hello", "delete", "erase", "my", "iam", "caffe", "food", "meal", "good", "bad"];
var logger = require("js-logger").get("SignUpScreen");
var VSpacer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    height: 7px\n"], ["\n    width: 100%;\n    height: 7px\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    width: 100%;    \n"], ["\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    width: 100%;    \n"])));
var TextContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    justify-content: center;\n    padding: 1rem 0;\n"], ["\n    display: flex;\n    justify-content: center;\n    padding: 1rem 0;\n"])));
var Text = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 60%;\n    text-align: center;\n    line-height: 22px;\n"], ["\n    width: 60%;\n    text-align: center;\n    line-height: 22px;\n"])));
var SwiperContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    flex: 1;\n"], ["\n    flex: 1;\n"])));
var SocialButtons = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["    \n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n    justify-content: center;\n    align-items: center;\n    flex: 1;\n    height: 100%;\n"], ["    \n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n    justify-content: center;\n    align-items: center;\n    flex: 1;\n    height: 100%;\n"])));
var SignUpButton = styled(SocialButton)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    min-width: 15rem;\n"], ["\n    min-width: 15rem;\n"])));
var SlideContent = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    // flex: 1;\n    // display: flex;\n    padding: 0 1rem;\n    margin-top: 2rem;\n"], ["\n    // flex: 1;\n    // display: flex;\n    padding: 0 1rem;\n    margin-top: 2rem;\n"])));
var ModeTitle = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    font-size: 1rem;\n    font-weight: bold;\n    text-align: center;\n    padding-bottom: 1rem;\n"], ["\n    font-size: 1rem;\n    font-weight: bold;\n    text-align: center;\n    padding-bottom: 1rem;\n"])));
var BottomButtons = styled(Buttons)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    padding: 0 1rem;\n"], ["\n    padding: 0 1rem;\n"])));
var swiper = null;
var SignUpScreen = React.memo(function () {
    var _a = React.useState(false), boardingShow = _a[0], setBoardingShow = _a[1];
    var _b = React.useState(0), currentSlide = _b[0], setCurrentSlide = _b[1];
    var _c = useForm({
        mode: "onChange",
        defaultValues: {
            mode: 0
        }
    }), formState = _c.formState, setValue = _c.setValue, getValues = _c.getValues, watch = _c.watch, control = _c.control, handleSubmit = _c.handleSubmit;
    var company = watch("company");
    var address = watch("address");
    var domain = watch("domain");
    var phone = watch("phone");
    var mode = watch("mode");
    var canGoNext = React.useCallback(function () {
        switch (currentSlide) {
            case 0:
                return company && company.length > 2;
            case 1:
                return (address === null || address === void 0 ? void 0 : address.address) && (address === null || address === void 0 ? void 0 : address.address.length);
            case 2:
                return domain && domain.length >= 5 && (!formState.errors.domain || isEmpty(formState.errors.domain));
            case 3:
                logger.info("phone", phone, formState.errors.phone);
                return phone && !formState.errors.phone;
            case 4:
                return mode != undefined;
        }
    }, [company, currentSlide, address, phone, domain, mode]);
    //logger.info("Phone", phone, "canGoNext", currentSlide, canGoNext());
    var _d = React.useState(""), addresSearch = _d[0], setAddresSearch = _d[1];
    var t = useTranslation().t;
    var googleLogin = function () {
        var baseURL = "".concat(getHostWithProtocol()).concat(BASENAME == "/" ? "" : BASENAME);
        //logger.info("baseURL", baseURL);
        var backURL = "".concat(baseURL, "/sign-up/response");
        var _a = getValues(["company", "domain", "phone", "address", "mode"]), company = _a[0], domain = _a[1], phone = _a[2], address = _a[3], mode = _a[4];
        logger.info("phone", phone);
        ;
        var url = "".concat(AUTH_BASE_URL, "/google/oauth/sign-up?phone=").concat(encodeURIComponent("".concat(phone)), "&company=").concat(encodeURIComponent(company), "&domain=").concat(encodeURIComponent(domain.toLowerCase()), "&backURL=").concat(encodeURIComponent(backURL), "&address=").concat(encodeURIComponent(JSON.stringify(address)), "&mode=").concat(mode);
        //logger.info("location", url);
        document.location.href = url;
    };
    var facebookLogin = function () {
        var baseURL = "".concat(getHostWithProtocol()).concat(BASENAME == "/" ? "" : BASENAME);
        //logger.info("baseURL", baseURL);
        var backURL = "".concat(baseURL, "/sign-up/response");
        var _a = getValues(["company", "domain", "phone", "address", "mode"]), company = _a[0], domain = _a[1], phone = _a[2], address = _a[3], mode = _a[4];
        var url = "".concat(AUTH_BASE_URL, "/facebook/oauth/sign-up?phone=").concat(encodeURIComponent("".concat(phone)), "&company=").concat(encodeURIComponent(company), "&domain=").concat(encodeURIComponent(domain.toLowerCase()), "&backURL=").concat(encodeURIComponent(backURL), "&address=").concat(encodeURIComponent(JSON.stringify(address)), "&mode=").concat(mode);
        //logger.info("phone", phone);
        document.location.href = url;
    };
    var checkDomain = function (domain) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, HttpRequest(canUseDomainRequest(domain)).promise];
                case 1:
                    _a.sent();
                    return [2 /*return*/, undefined];
                case 2:
                    e_1 = _a.sent();
                    return [2 /*return*/, t("form.error.invalid.domain.message")];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var checkCompanyName = function (company) { return __awaiter(void 0, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, HttpRequest(canUseCompanyNameRequest(company)).promise];
                case 1:
                    _a.sent();
                    return [2 /*return*/, undefined];
                case 2:
                    e_2 = _a.sent();
                    return [2 /*return*/, t("form.error.invalid.company.message")];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var createDomainFromCompany = function (company) {
        setValue("domain", company.trim().replace(/ /g, "-").toLowerCase());
    };
    var nextSlide = function () {
        swiper.slideNext();
    };
    var prevSlipe = function () {
        swiper.slidePrev();
    };
    var onComplete = function () {
    };
    return React.createElement(Container, null,
        !boardingShow && React.createElement(Boarding, { onComplete: function () {
                setBoardingShow(true);
            } }),
        React.createElement(TextContainer, null,
            React.createElement(Text, null,
                React.createElement(Localize, { langKey: "sign.up.welcome.text" }))),
        React.createElement(SwiperContainer, null,
            React.createElement(Swiper, { onSwiper: function (instance) {
                    swiper = instance;
                }, onSlideChange: function (swiper) {
                    setCurrentSlide(swiper.activeIndex);
                }, slidesPerView: 1, style: {
                    width: "100%",
                    height: "100%"
                } },
                React.createElement(SwiperSlide, null,
                    React.createElement(SlideContent, null,
                        React.createElement(FormTextInput, { disableHandleFocus: true, control: control, name: "company", label: t("sign.up.company.label"), 
                            //disabled={signUpState.inProgress}
                            rules: {
                                required: true,
                                minLength: 2,
                                // validate: async (value: string) => {
                                //     return await checkCompanyName(value);
                                // }
                            }, onBlur: function () {
                                var _a = getValues(["company", "address", "domain"]), company = _a[0], address = _a[1], domain = _a[2];
                                if (!address && company) {
                                    setAddresSearch(company);
                                }
                                if (!domain && company) {
                                    createDomainFromCompany(company);
                                }
                            }, info: t("sign.up.company.description") }))),
                React.createElement(SwiperSlide, null,
                    React.createElement(SlideContent, null,
                        React.createElement(FormGoogleAddressInput, { control: control, placeholder: t("sign.up.address.label"), name: "address", apiKey: GOOGLE_MAPS_API_KEY, label: t("sign.up.address.label"), info: t("sign.up.address.description"), defaultValue: addresSearch, rules: {
                                required: true,
                                minLength: 10,
                            } }))),
                React.createElement(SwiperSlide, null,
                    React.createElement(SlideContent, null,
                        React.createElement(CFormLabel, null, t("sign.up.domain.label")),
                        React.createElement(FormGroupTextInput, { disableHandleFocus: true, control: control, name: "domain", 
                            // label="Domain"
                            //disabled={signUpState.inProgress}
                            rules: {
                                required: true,
                                minLength: 5,
                                // maxLength: 15,
                                validate: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                logger.info("VALIDATE...");
                                                if (domainBlackList.includes(value)) {
                                                    return [2 /*return*/, t("form.error.invalid.domain.message")];
                                                }
                                                if (!/^[a-zA-Z0-9\-]{5,63}$/.test(value)) {
                                                    return [2 /*return*/, t("form.error.invalid.domain.characters.message")];
                                                }
                                                return [4 /*yield*/, checkDomain(value)];
                                            case 1: return [2 /*return*/, _a.sent()];
                                        }
                                    });
                                }); }
                            }, info: t("sign.up.domain.description"), group: "ordish.app" }))),
                React.createElement(SwiperSlide, null,
                    React.createElement(SlideContent, null,
                        React.createElement(FormPhoneInput, { label: t("phone.text"), name: "phone", control: control, rules: {
                                required: true,
                                minLength: 4,
                                validate: function (phone) {
                                    try {
                                        var p = phoneUtil.parse(phone);
                                        if (!phoneUtil.isValidNumber(p)) {
                                            return t("error.invalid.phone.number");
                                        }
                                        return undefined;
                                    }
                                    catch (err) {
                                        return t("error.invalid.phone.number");
                                    }
                                }
                            }, info: t("sign.up.phone.description") }))),
                React.createElement(SwiperSlide, null,
                    React.createElement(SlideContent, null,
                        React.createElement(ModeTitle, null, t("sign.up.mode.title")),
                        React.createElement(FormRadioGroupInput, { control: control, name: "mode", items: [
                                {
                                    label: t("company.mode.only.menu.text"),
                                    value: CompanyMode.MODE_MENU
                                },
                                {
                                    label: t("company.mode.no.payments.text"),
                                    value: CompanyMode.MODE_ORDER
                                },
                                {
                                    label: t("company.mode.offline.payments.text"),
                                    value: CompanyMode.MODE_OFFLINE_PAYMENT
                                },
                                {
                                    label: t("company.mode.separate.payments.text"),
                                    value: CompanyMode.MODE_SEPARATE_PAYMENT
                                },
                                {
                                    label: t("company.mode.all.payments.text"),
                                    value: CompanyMode.MODE_ALL
                                }
                            ] }))),
                React.createElement(SwiperSlide, null,
                    React.createElement(SocialButtons, null,
                        React.createElement(SignUpButton, { disabled: !formState.isDirty || !formState.isValid, icon: "google", onClick: googleLogin, text: t("sign.up.google.text") }),
                        React.createElement(SignUpButton, { disabled: !formState.isDirty || !formState.isValid, icon: "facebook", onClick: facebookLogin, text: t("sign.up.facebook.text") }))))),
        React.createElement(PaginationContainer, null,
            React.createElement(Bullets, null,
                React.createElement(Bullet, { active: currentSlide === 0 }),
                React.createElement(Bullet, { active: currentSlide === 1 }),
                React.createElement(Bullet, { active: currentSlide === 2 }),
                React.createElement(Bullet, { active: currentSlide === 3 }),
                React.createElement(Bullet, { active: currentSlide === 4 }),
                React.createElement(Bullet, { active: currentSlide === 5 }))),
        React.createElement(BottomButtons, null,
            React.createElement(ButtonsContent, null,
                React.createElement(ButtonLeft, null, currentSlide > 0 && React.createElement(BoardingButton, { variant: "outline", onClick: prevSlipe }, t("sign.up.button.prev.text"))),
                React.createElement(ButtonRight, null, currentSlide < 5 && React.createElement(BoardingButton, { onClick: nextSlide, disabled: !canGoNext() }, t("boarding.button.next.text"))))));
});
export default wrapNotSignedIn(SignUpScreen);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
