var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CompanyMode } from "../../constants/Mode";
import styled from "styled-components";
import FormRadioGroupInput from "../common/form/FormRadioGroupInput";
import { Button } from "../../common/Button";
import { HttpApiRequest, useHttpRequestHandler } from "../../service/network.service";
import { getCompanyModelRequest, updateCompanyModeRequest } from "../../request/company.request";
import LoadingBox from "../common/LoadingBox";
var logger = require("js-logger").get("Mode");
var Title = styled.h3(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-size: 1.5rem;\n    text-align: center;\n    margin: 0 0 0.5rem 0;\n"], ["\n    font-size: 1.5rem;\n    text-align: center;\n    margin: 0 0 0.5rem 0;\n"])));
var Description = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 0.5rem 0;\n    font-size: 0.9rem;\n    padding: 0 1rem;\n    color: #605c5c;\n\n\n"], ["\n    margin: 0.5rem 0;\n    font-size: 0.9rem;\n    padding: 0 1rem;\n    color: #605c5c;\n\n\n"])));
var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: 0 0.5rem;\n"], ["\n    padding: 0 0.5rem;\n"])));
var Buttons = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-top: 0.5rem;\n    display: flex;\n    justify-content: center;   \n"], ["\n    margin-top: 0.5rem;\n    display: flex;\n    justify-content: center;   \n"])));
export var CompanyModePage = React.memo(function (props) {
    var _a = useForm(), control = _a.control, formState = _a.formState, reset = _a.reset, handleSubmit = _a.handleSubmit;
    var t = useTranslation().t;
    var modeStatus = useHttpRequestHandler(getCompanyModelRequest());
    React.useEffect(function () {
        if (modeStatus.result) {
            reset({ mode: modeStatus.result });
        }
    }, [modeStatus.result]);
    var updateCompanyMode = function (values) {
        HttpApiRequest(updateCompanyModeRequest(parseInt(values.mode)))
            .promise
            .then(function () {
            modeStatus.run();
        })
            .catch(function (err) {
            logger.error(err);
        });
    };
    if (modeStatus.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    return React.createElement(Container, null,
        React.createElement(Title, null, t("company.mode.page.title")),
        React.createElement(Description, null, t("company.mode.page.description")),
        React.createElement("form", { onSubmit: handleSubmit(updateCompanyMode) },
            React.createElement(FormRadioGroupInput, { control: control, name: "mode", items: [
                    {
                        label: t("company.mode.only.menu.text"),
                        value: CompanyMode.MODE_MENU
                    },
                    {
                        label: t("company.mode.no.payments.text"),
                        value: CompanyMode.MODE_ORDER
                    },
                    {
                        label: t("company.mode.offline.payments.text"),
                        value: CompanyMode.MODE_OFFLINE_PAYMENT
                    },
                    {
                        label: t("company.mode.separate.payments.text"),
                        value: CompanyMode.MODE_SEPARATE_PAYMENT
                    },
                    {
                        label: t("company.mode.all.payments.text"),
                        value: CompanyMode.MODE_ALL
                    }
                ] }),
            React.createElement(Buttons, null,
                React.createElement(Button, { type: "submit", color: "primary", disabled: !formState.isDirty }, t("save.text")))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
