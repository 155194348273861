import * as React from "react";
import * as ReactDOM from "react-dom";
var modalRoot = document.getElementById('modal');
export var Portal = function (props) {
    var el = React.useRef(document.createElement('div'));
    React.useEffect(function () {
        modalRoot.appendChild(el.current);
        return function () {
            modalRoot.removeChild(el.current);
        };
    }, []);
    return ReactDOM.createPortal(props.children, el.current);
};
