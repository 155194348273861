var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { useRef, useState } from "react";
import styled from "styled-components";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n"], ["\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n"])));
var CodeLabel = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-bottom: 16px;\n"], ["\n    margin-bottom: 16px;\n"])));
var CodeInputs = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    justify-content: start;\n    align-items: center;\n"], ["\n    display: flex;\n    justify-content: start;\n    align-items: center;\n"])));
var CodeInput = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    border: none;\n    background-image: none;\n    background-color: transparent;\n    -webkit-box-shadow: none;\n    -moz-box-shadow: none;\n    box-shadow: none;\n    text-align: center;\n    height: 60px;\n    width: 40px;\n    border-radius: 10px;\n    margin: 0 4px;\n    padding: 0;\n    border: 2px solid #4f5b66;\n    font-size: 38px;\n    &:focus{\n        outline: none;\n    }\n    &input:nth-child(3n) {\n        margin-right: 24px;\n      }\n"], ["\n    border: none;\n    background-image: none;\n    background-color: transparent;\n    -webkit-box-shadow: none;\n    -moz-box-shadow: none;\n    box-shadow: none;\n    text-align: center;\n    height: 60px;\n    width: 40px;\n    border-radius: 10px;\n    margin: 0 4px;\n    padding: 0;\n    border: 2px solid #4f5b66;\n    font-size: 38px;\n    &:focus{\n        outline: none;\n    }\n    &input:nth-child(3n) {\n        margin-right: 24px;\n      }\n"])));
var InputCode = function (props) {
    var length = props.length, label = props.label, loading = props.loading, onComplete = props.onComplete;
    var _a = useState(__spreadArray([], Array(length), true).map(function () { return ""; })), code = _a[0], setCode = _a[1];
    var inputs = useRef([]);
    // Typescript
    // useRef<(HTMLInputElement | null)[]>([])
    var processInput = function (e, slot) {
        var num = e.target.value;
        if (/[^0-9]/.test(num))
            return;
        var newCode = __spreadArray([], code, true);
        newCode[slot] = num;
        setCode(newCode);
        if (slot !== length - 1) {
            inputs.current[slot + 1].focus();
        }
        if (newCode.every(function (num) { return num !== ""; })) {
            onComplete(newCode.join(""));
        }
    };
    var onKeyUp = function (e, slot) {
        if (e.keyCode === 8 && !code[slot] && slot !== 0) {
            var newCode = __spreadArray([], code, true);
            newCode[slot - 1] = "";
            setCode(newCode);
            inputs.current[slot - 1].focus();
        }
    };
    return (React.createElement(Container, null,
        React.createElement(CodeLabel, null, label),
        React.createElement(CodeInputs, null, code.map(function (num, idx) {
            return (React.createElement(CodeInput, { key: idx, 
                // type="text"
                inputMode: "numeric", pattern: "[0-9]*", maxLength: 1, value: num, autoFocus: !code[0].length && idx === 0, readOnly: loading, onChange: function (e) { return processInput(e, idx); }, onKeyUp: function (e) { return onKeyUp(e, idx); }, ref: function (ref) { return inputs.current.push(ref); } }));
        }))));
};
export default InputCode;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
