import create from "zustand";
export var notificationStore = create(function (set) { return ({
    notifications: [],
    addNotification: function (notification) {
        set(function (state) {
            var prev = state.notifications;
            var update = prev.slice(0);
            update.push(notification);
            return {
                notifications: update
            };
        });
    },
    removeNotification: function (id) {
        set(function (state) {
            var update = state.notifications.filter(function (notifiction) { return notifiction.id !== id; });
            return {
                notifications: update
            };
        });
    }
}); });
