var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { userStore } from "../../store/user.store";
import { useTranslation } from "react-i18next";
import { useHttpRequesPrepareHandler, useHttpRequestHandler } from "../../service/network.service";
import { getCompanyMediaRequest, updateCompanyQRSettingsRequest, getCompanyQRSettingsRequest } from "../../request/company.request";
import { useForm } from "react-hook-form";
import LoadingBox from "./LoadingBox";
import { CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from "@coreui/react";
import styled from "styled-components";
import { QRCode } from "react-qrcode-logo";
import { COMPANY_ENDPOINT } from "../../constats/ApiEndpoints";
import FormTextInput from "./form/FormTextInput";
import FormCheckboxInput from "./form/FormCheckboxInput";
var QRModalContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n"])));
var QRModalInputs = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100%;\n    display: flex;\n    gap: 5px;\n    & > div {\n        flex: 1\n    }\n"], ["\n    width: 100%;\n    display: flex;\n    gap: 5px;\n    & > div {\n        flex: 1\n    }\n"])));
var QRModalCheckbox = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100%;\n    display: flex;    \n    justify-content: flex-start;\n    margin: 5px 0 0 0;\n"], ["\n    width: 100%;\n    display: flex;    \n    justify-content: flex-start;\n    margin: 5px 0 0 0;\n"])));
export var QRCodeModal = React.memo(function (props) {
    // const [bgColor, setBgColor] = React.useState("#ffffff");
    // const [fgColor, setFgColor] = React.useState("#000000");
    var companyId = userStore.getState().company.id;
    //const company = userStore.getState().companies.find(company => company.id == companyId);
    var token = userStore.getState().token;
    var t = useTranslation().t;
    var getCompanyMediaState = useHttpRequestHandler(getCompanyMediaRequest());
    var getCompanyQRSettingsState = useHttpRequestHandler(getCompanyQRSettingsRequest());
    var saveCompanyQRSettingsState = useHttpRequesPrepareHandler();
    //const defaultValues = getCompanySettingsState.executed ? (getCompanySettingsState.result as Record<string, any>).qr : {};
    var _a = useForm({
        mode: "onChange",
        //defaultValues
    }), control = _a.control, watch = _a.watch, reset = _a.reset, getValues = _a.getValues;
    var bgColor = watch("bgColor");
    var fgColor = watch("fgColor");
    var addLogo = watch("addLogo");
    var download = function () {
        var canvas = document.querySelector('canvas#qr-code');
        var link = document.createElement('a');
        link.download = 'qr-code.png';
        link.href = canvas.toDataURL();
        link.click();
    };
    var media = getCompanyMediaState.result ? getCompanyMediaState.result : [];
    var logo = media.length > 0 ? media.find(function (item) { return item.type == "logo"; }) : null;
    var link = props.link, onClose = props.onClose;
    React.useEffect(function () {
        if (getCompanyQRSettingsState.result) {
            //console.log("XXX", (getCompanySettingsState.result as Record<string, any>).qr);
            reset(getCompanyQRSettingsState.result);
        }
    }, [getCompanyQRSettingsState.result]);
    React.useEffect(function () {
        return function () {
            saveCompanyQRSettingsState.run(updateCompanyQRSettingsRequest(getValues()));
        };
    }, []);
    if (getCompanyMediaState.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    return React.createElement(CModal, { visible: true, onClose: onClose },
        React.createElement(CModalHeader, null,
            React.createElement(CModalTitle, null, t("qr.code.text"))),
        React.createElement(CModalBody, null,
            getCompanyQRSettingsState.inProgress && React.createElement(LoadingBox, null),
            !getCompanyQRSettingsState.inProgress && React.createElement(QRModalContent, null,
                logo && addLogo && React.createElement(QRCode, { id: "qr-code", value: link, bgColor: bgColor, fgColor: fgColor, logoImage: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/media/logo?token=").concat(token), ecLevel: "H", removeQrCodeBehindLogo: true, enableCORS: true }),
                !(logo && addLogo) && React.createElement(QRCode, { id: "qr-code", value: link, bgColor: bgColor, fgColor: fgColor }),
                React.createElement(QRModalInputs, null,
                    React.createElement("div", null,
                        React.createElement(FormTextInput, { name: "bgColor", label: "Bg color", control: control })),
                    React.createElement("div", null,
                        React.createElement(FormTextInput, { name: "fgColor", label: "Bg color", control: control }))),
                logo && React.createElement(QRModalCheckbox, null,
                    React.createElement(FormCheckboxInput, { name: "addLogo", label: t("company.settings.qr.addLogo.label"), control: control })))),
        React.createElement(CModalFooter, null,
            React.createElement(CButton, { color: "primary", onClick: download }, "Download"),
            React.createElement(CButton, { color: "secondary", onClick: onClose }, "Close")));
});
var templateObject_1, templateObject_2, templateObject_3;
