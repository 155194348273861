import * as React from "react";
import CIcon from "@coreui/icons-react";
import { cilQrCode } from "@coreui/icons";
import { AreaQRCodeModal } from "./AreaQRCodeModalV1";
export var AreaQRCode = React.memo(function (props) {
    var iconSize = props.iconSize, place = props.place, area = props.area;
    var _a = React.useState(false), showQR = _a[0], setShowQR = _a[1];
    var onClose = function () {
        setShowQR(false);
    };
    var openQRCode = function () {
        setShowQR(true);
    };
    return React.createElement(React.Fragment, null,
        React.createElement(CIcon, { size: iconSize ? iconSize : "xl", icon: cilQrCode, onClick: openQRCode }),
        showQR && React.createElement(AreaQRCodeModal, { area: area, place: place, onClose: onClose }));
});
