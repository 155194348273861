var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Box } from "../common/FlexItem";
import EditorMenu from "./EditorMenu";
import Menus from "./Menus";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    height: 100%;\n"], ["\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    height: 100%;\n"])));
var Content = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    height: 100%;\n"], ["\n    height: 100%;\n"])));
var Editor = function () {
    return React.createElement(Container, null,
        React.createElement(EditorMenu, null),
        React.createElement(Box, { flex: "none" },
            React.createElement(Menus, null)),
        React.createElement(Content, { flex: 1 },
            React.createElement(Outlet, null)));
};
export default React.memo(Editor);
var templateObject_1, templateObject_2;
