var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { userStore } from "../../store/user.store";
import { APP } from "../../constats/Routes";
import { Navigate } from "react-router-dom";
import { HttpApiRequest } from "../../service/network.service";
import { meRequest } from "../../request/user.request";
var logger = require("js-logger").get("NotSignedIn");
import LoadingBox from "./LoadingBox";
var NotSignedIn = React.memo(function (props) {
    var _a = React.useState(false), checked = _a[0], setChecked = _a[1];
    var _b = React.useState(false), loggedIn = _b[0], setLoggedIn = _b[1];
    var userState = userStore();
    logger.info("State", userState);
    var getUserInfo = function () {
        //console.log("XXXXX Get user info...");
        if (!userState.token) {
            setLoggedIn(false);
            setChecked(true);
            return;
        }
        var token = userState.token;
        HttpApiRequest(meRequest(token))
            .promise
            .then(function (res) {
            setLoggedIn(true);
            setChecked(true);
        })
            .catch(function (err) {
            logger.error(err);
            setLoggedIn(false);
            setChecked(true);
        });
    };
    React.useEffect(getUserInfo, []);
    if (!checked) {
        return React.createElement(LoadingBox, null);
    }
    if (loggedIn) {
        return React.createElement(Navigate, { to: APP });
    }
    return React.createElement(React.Fragment, null, props.children);
});
export default NotSignedIn;
export function wrapNotSignedIn(WrappedComponent) {
    return function (props) { return React.createElement(NotSignedIn, null,
        React.createElement(WrappedComponent, __assign({}, props))); };
}
