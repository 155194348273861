var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { CAvatar, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from "@coreui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CompanyPermissions, MonitorPermissions, OrderPermissions } from "../common/user/Permission";
import styled from "styled-components";
import { MO_ADMIN_TOKEN } from "../constats/Cookies";
import { APP_AREAS, APP_COMPANY, APP_MENU, APP_OPERATION_MONITOR, APP_ORDERS, APP_ORDER_MONITOR, buildRoute, SETTINGS } from "../constats/Routes";
import cookiesService from "../service/cookies.service";
import { userStore } from "../store/user.store";
import { AppPageTopLayout } from "./AppPage";
import { Box } from "./common/FlexItem";
import { Drawer, DrawerButton } from "./drawer";
var logger = require("js-logger").get("TopBar");
var CompanyTitle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-size: 1.1rem;\n    color: #858992;\n    font-weight: 300\n"], ["\n    font-size: 1.1rem;\n    color: #858992;\n    font-weight: 300\n"])));
var CompanyLogo = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    height: 3rem\n"], ["\n    height: 3rem\n"])));
var MenuContainer = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100%;\n    margin-top: 7rem;\n    padding: 0 0.8rem;\n"], ["\n    width: 100%;\n    margin-top: 7rem;\n    padding: 0 0.8rem;\n"])));
var MenuItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 1rem;\n    padding: 2rem 0;\n    border-bottom: #e3e2e2 solid 1px;\n"], ["\n    font-size: 1rem;\n    padding: 2rem 0;\n    border-bottom: #e3e2e2 solid 1px;\n"])));
var DrawerAvatar = styled(CAvatar)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 7rem;\n    height: 7rem;\n"], ["\n    width: 7rem;\n    height: 7rem;\n"])));
var UserInfoContainer = styled(Box)(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var UserInfoName = styled.h4(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    font-size: 1rem;\n    font-weight: bold;    \n    margin-top: 0.4rem;\n"], ["\n    font-size: 1rem;\n    font-weight: bold;    \n    margin-top: 0.4rem;\n"])));
export var CompanyInfo = React.memo(function (props) {
    var uStore = userStore();
    var company = uStore.company;
    return React.createElement(Box, { flexDirection: "column", align: "center" },
        company.logo && React.createElement(CompanyLogo, { src: company.logo }),
        React.createElement(CompanyTitle, null, company.name));
});
var UserInfo = React.memo(function (props) {
    var uStore = userStore();
    var user = uStore.user;
    return React.createElement(UserInfoContainer, { align: "center", justify: "center", flexDirection: "column" },
        React.createElement(DrawerAvatar, { src: user.picture },
            user.firstName,
            user.lastName),
        React.createElement(UserInfoName, null,
            user.firstName,
            " ",
            user.lastName));
});
var DrawerMenu = React.memo(function (props) {
    var onClose = props.onClose;
    //const user = userStore(state => state.user);
    var hasPermission = userStore(function (state) { return state.hasPermission; });
    var navigate = useNavigate();
    var goTo = function (url) { return function () {
        onClose();
        navigate(url);
    }; };
    var t = useTranslation().t;
    return React.createElement(MenuContainer, { flexDirection: "column" },
        React.createElement(UserInfo, null),
        React.createElement(MenuItem, { onClick: goTo(buildRoute(APP_MENU, {})) }, t("menu.editor.text")),
        React.createElement(MenuItem, { onClick: goTo(buildRoute(APP_AREAS, {})) }, t("area.editor.text")),
        hasPermission(MonitorPermissions.MONITOR_VIEW) && React.createElement(MenuItem, { onClick: goTo(buildRoute(APP_ORDER_MONITOR, {})) }, t("menu.editor.order.monitor.text")),
        hasPermission(MonitorPermissions.MONITOR_VIEW) && React.createElement(MenuItem, { onClick: goTo(buildRoute(APP_OPERATION_MONITOR, {})) }, t("menu.editor.om.text")),
        hasPermission(OrderPermissions.ORDER_VIEW) && React.createElement(MenuItem, { onClick: goTo(buildRoute(APP_ORDERS, {})) }, t("menu.editor.orders.text")),
        hasPermission(CompanyPermissions.COMPANY_VIEW) && React.createElement(MenuItem, { onClick: goTo(buildRoute(APP_COMPANY, {})) }, t("drawer.menu.settings")));
});
//const DrawerMenuItem = React.memo((props) => {})
export var TopBar = React.memo(function (props) {
    var uStore = userStore();
    var navigate = useNavigate();
    var user = uStore.user;
    var _a = React.useState(false), drawerVisible = _a[0], setDrawerVisible = _a[1];
    var toggleDrawerVisible = function () {
        setDrawerVisible(!drawerVisible);
    };
    var goTo = function (route) { return function (e) {
        if (e) {
            e.preventDefault();
        }
        navigate(route);
    }; };
    var logout = function () {
        cookiesService.removeCookie(MO_ADMIN_TOKEN);
        uStore.clear();
    };
    return React.createElement(React.Fragment, null,
        React.createElement(AppPageTopLayout, { left: React.createElement(DrawerButton, { onClick: toggleDrawerVisible }), center: React.createElement(CompanyInfo, null), right: React.createElement(CDropdown, null,
                React.createElement(CDropdownToggle, { component: "div", caret: false, variant: "outline", color: "link", style: {
                        padding: 0,
                        borderRadius: 0
                    } },
                    React.createElement(CAvatar, { src: user.picture },
                        user.firstName,
                        user.lastName)),
                React.createElement(CDropdownMenu, null,
                    React.createElement(CDropdownItem, { onClick: goTo(SETTINGS) }, "Settings"),
                    React.createElement(CDropdownItem, { onClick: logout }, "Logout"))) }),
        React.createElement(Drawer, { visible: drawerVisible, onClose: function () {
                setDrawerVisible(false);
            } },
            React.createElement(DrawerMenu, { onClose: function () {
                    setDrawerVisible(false);
                } })));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
