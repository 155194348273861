import { WebSocketConnection } from "../service/websocket.service";
import { WebsocketJsonRpcService } from "../service/websocket.jsonrpc.service";
import { userStore } from "../store/user.store";
var jsonRPC = null;
var baseURL = API_BASE_URL.replace("https", "wss").replace("http", "ws");
export var useJsonRPC = function () {
    var token = userStore(function (state) { return state.token; });
    var query = [{
            name: "token",
            value: token
        }];
    if (DEBUG_COMPANY) {
        query.push({
            name: "company",
            value: DEBUG_COMPANY
        });
    }
    var queryString = query.map(function (val) { return "".concat(val.name, "=").concat(val.value); }).join("&");
    console.log("Query String", queryString);
    if (!jsonRPC) {
        jsonRPC = new WebsocketJsonRpcService(new WebSocketConnection("".concat(baseURL, "?").concat(queryString)));
    }
    jsonRPC.getWebSocketConnection().connect();
    return jsonRPC;
};
