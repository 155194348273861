var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { CButton, CForm, CNav, CNavItem, CNavLink } from "@coreui/react";
import * as React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Devices } from "../../devices";
import styled from "styled-components";
import { PaymentMethods } from "../../billing/method";
import { HasPemission } from "../../common/user/HasPermission";
import { CompanyPermissions } from "../../common/user/Permission";
import { APP_COMPANY } from "../../constats/Routes";
import { getCompanyInfoRequest, updateCompanyInfoRequest } from "../../request/company.request";
import { userCompaniesRequest } from "../../request/user.request";
import { HttpApiRequest, useHttpRequesPrepareHandler, useHttpRequestHandler } from "../../service/network.service";
import { userStore } from "../../store/user.store";
import LoadingBox from "../common/LoadingBox";
import { VerticalContainer } from "../common/VerticalContainer";
import { Stations } from "../station";
import AccessQR from "./AccessQR";
import CommonInfo from "./CommonInfo";
import CompanyMedia from "./Media";
import Styles from "./Styles";
import { CompanyUsers } from "./Users";
import WorkingHours from "./WorkingHours";
import { CompanyModePage } from "./Mode";
var logger = require("js-logger").get("CompanyPage");
var ContentLayout = styled(VerticalContainer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%\n"], ["\n    width: 100%\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    flex: 1;\n    display: flex;\n    width: 100%;\n    height: 100%;\n"], ["\n    flex: 1;\n    display: flex;\n    width: 100%;\n    height: 100%;\n"])));
var SideBarContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    height: 100%;\n    display: flex;\n"], ["\n    height: 100%;\n    display: flex;\n"])));
var ContentContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    flex: 1;        \n    max-height: \"100%\";\n    overflow: auto;\n  \n"], ["\n    flex: 1;        \n    max-height: \"100%\";\n    overflow: auto;\n  \n"])));
var Tabs = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: flex;\n    padding:0 1rem;\n    min-height: 5.5rem;\n    margin-top: 2rem;\n"], ["\n    display: flex;\n    padding:0 1rem;\n    min-height: 5.5rem;\n    margin-top: 2rem;\n"])));
var TabsContiner = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    display: flex;\n    width: 100%;\n    // height: 5rem;\n    overflow: auto;\n\n    & li {\n        flex-wrap: nowrap;\n    }\n"], ["\n    display: flex;\n    width: 100%;\n    // height: 5rem;\n    overflow: auto;\n\n    & li {\n        flex-wrap: nowrap;\n    }\n"])));
var SaveButtons = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    justify-content: center;\n    margin: 1rem 0;\n    gap: 0.5rem;\n"], ["\n    display: flex;\n    justify-content: center;\n    margin: 1rem 0;\n    gap: 0.5rem;\n"])));
var CompanyPage = React.memo(function (props) {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var location = useLocation();
    var uStore = userStore();
    var commonInfoRef = React.createRef();
    var workingHoursRef = React.createRef();
    var stylesRef = React.createRef();
    var usersRef = React.createRef();
    var formRef = React.createRef();
    var mediaRef = React.createRef();
    var accessQRRef = React.createRef();
    var companyInfoState = useHttpRequestHandler(getCompanyInfoRequest());
    var updateCompanyInfoState = useHttpRequesPrepareHandler();
    var navigateTabBuilder = function (route) { return function (e) {
        if (e) {
            e.preventDefault();
        }
        navigate(route);
    }; };
    var params = useParams();
    var page = location.pathname.replace(APP_COMPANY, "");
    if (page) {
        page = page.replace("/", "");
    }
    //logger.info("params", params, "location", location, "page", page);
    var _a = useForm({
        mode: "onChange",
        //defaultValues: companyInfoState.result ? companyInfoState.result as Record<string, any> : undefined,
        //shouldUnregister: true,
        defaultValues: companyInfoState.result
    }), control = _a.control, formState = _a.formState, handleSubmit = _a.handleSubmit, reset = _a.reset;
    React.useEffect(function () {
        var data = companyInfoState.result;
        if (!data) {
            data = {};
        }
        if (!data.colors) {
            data.colors = {
                color: "#FE724C",
                fontColor: "#ffffff"
            };
        }
        reset(companyInfoState.result);
    }, [companyInfoState.result]);
    var goTo = function (route) { return function (e) {
        if (e) {
            e.preventDefault();
        }
        navigate(route);
    }; };
    var createScrollTo = function (ref) { return function (e) {
        if (e) {
            e.preventDefault();
        }
        if (ref && ref.current) {
            ref.current.scrollIntoView({
                behavior: "smooth"
            });
        }
    }; };
    var saveCompany = function (values) {
        logger.info("Values", values);
        updateCompanyInfoState.run(updateCompanyInfoRequest(values))
            .then(function () {
            companyInfoState.run();
            HttpApiRequest(userCompaniesRequest())
                .promise
                .then(function (res) {
                uStore.setCompany(res.data);
            })
                .catch(function (err) {
                logger.error(err);
            });
        });
    };
    if (companyInfoState.inProgress || updateCompanyInfoState.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    logger.info("Company Info", companyInfoState.result);
    //logger.info("Values", getValues(["name","mainCurrenct"]))
    return React.createElement(HasPemission, { permission: CompanyPermissions.COMPANY_VIEW },
        React.createElement(Container, null,
            React.createElement(ContentLayout, null,
                React.createElement(Tabs, null,
                    React.createElement(Scrollbars, null,
                        React.createElement(CNav, { variant: "tabs" },
                            React.createElement(TabsContiner, null,
                                React.createElement(CNavItem, null,
                                    React.createElement(CNavLink, { active: !page, onClick: navigateTabBuilder("/app/company") }, t("page.company.info.title"))),
                                React.createElement(CNavItem, null,
                                    React.createElement(CNavLink, { active: page == "mode", onClick: navigateTabBuilder("/app/company/mode") }, t("page.company.mode.title"))),
                                React.createElement(CNavItem, null,
                                    React.createElement(CNavLink, { active: page == "design", onClick: navigateTabBuilder("/app/company/design") }, t("page.company.design.title"))),
                                React.createElement(CNavItem, null,
                                    React.createElement(CNavLink, { active: page == "payments", onClick: navigateTabBuilder("/app/company/payments") }, t("page.company.payments.title"))),
                                React.createElement(CNavItem, null,
                                    React.createElement(CNavLink, { active: page == "processors", onClick: navigateTabBuilder("/app/company/processors") }, t("page.company.processors.title"))),
                                React.createElement(CNavItem, null,
                                    React.createElement(CNavLink, { active: page == "operations", onClick: navigateTabBuilder("/app/company/operations") }, t("page.company.operations.title"))),
                                React.createElement(CNavItem, null,
                                    React.createElement(CNavLink, { active: page == "stations", onClick: navigateTabBuilder("/app/company/stations") }, t("page.company.stations.title"))),
                                React.createElement(CNavItem, null,
                                    React.createElement(CNavLink, { active: page == "devices", onClick: navigateTabBuilder("/app/company/devices") }, t("page.company.devices.title"))),
                                React.createElement(CNavItem, null,
                                    React.createElement(CNavLink, { active: page == "users", onClick: navigateTabBuilder("/app/company/users") }, t("page.company.users.title"))),
                                React.createElement(CNavItem, null,
                                    React.createElement(CNavLink, { active: page == "tips", onClick: navigateTabBuilder("/app/company/tips") }, t("page.company.tips.title"))),
                                React.createElement(CNavItem, null,
                                    React.createElement(CNavLink, { active: page == "order-settings", onClick: navigateTabBuilder("/app/company/order-settings") }, t("order.settings.page.title"))))))),
                React.createElement(ContentContainer, null,
                    React.createElement(Scrollbars, null,
                        React.createElement(Outlet, { context: {
                                commonInfoRef: commonInfoRef,
                                workingHoursRef: workingHoursRef,
                                stylesRef: stylesRef,
                                usersRef: usersRef,
                                formRef: formRef,
                                accessQRRef: accessQRRef,
                                mediaRef: mediaRef,
                                control: control,
                                formState: formState,
                                saveCompany: saveCompany,
                                companyInfoState: companyInfoState,
                                updateCompanyInfoState: updateCompanyInfoState,
                                handleSubmit: handleSubmit
                            } }))))));
});
export default CompanyPage;
export var CompanyPageInfo = React.memo(function (props) {
    var outletContext = useOutletContext();
    var _a = outletContext, handleSubmit = _a.handleSubmit, saveCompany = _a.saveCompany, formRef = _a.formRef, accessQRRef = _a.accessQRRef, commonInfoRef = _a.commonInfoRef, control = _a.control, formState = _a.formState;
    var t = useTranslation().t;
    return React.createElement(CForm, { id: "company-form", onSubmit: handleSubmit(saveCompany), ref: formRef },
        React.createElement("div", { ref: accessQRRef },
            React.createElement(AccessQR, null)),
        React.createElement("div", { ref: commonInfoRef },
            React.createElement(CommonInfo, { control: control })),
        React.createElement(SaveButtons, null,
            React.createElement(CButton, { color: "secondary" }, t("cancel.text")),
            React.createElement(CButton, { type: "submit", disabled: !formState.isDirty, form: "company-form" }, t("save.text"))));
});
export var CompanyPageOperations = React.memo(function (props) {
    var outletContext = useOutletContext();
    var _a = outletContext, handleSubmit = _a.handleSubmit, saveCompany = _a.saveCompany, formRef = _a.formRef, workingHoursRef = _a.workingHoursRef, control = _a.control, formState = _a.formState;
    var t = useTranslation().t;
    return React.createElement(CForm, { id: "company-form", onSubmit: handleSubmit(saveCompany), ref: formRef },
        React.createElement("div", { ref: workingHoursRef },
            React.createElement(WorkingHours, { control: control })),
        React.createElement(SaveButtons, null,
            React.createElement(CButton, { color: "secondary" }, t("cancel.text")),
            React.createElement(CButton, { type: "submit", disabled: !formState.isDirty, form: "company-form" }, t("save.text"))));
});
export var CompanyPageDesing = React.memo(function (props) {
    var outletContext = useOutletContext();
    var _a = outletContext, handleSubmit = _a.handleSubmit, saveCompany = _a.saveCompany, formRef = _a.formRef, mediaRef = _a.mediaRef, stylesRef = _a.stylesRef, control = _a.control, formState = _a.formState;
    var t = useTranslation().t;
    return React.createElement(CForm, { id: "company-form", onSubmit: handleSubmit(saveCompany), ref: formRef },
        React.createElement("div", { ref: mediaRef },
            React.createElement(CompanyMedia, null)),
        React.createElement("div", { ref: stylesRef },
            React.createElement(Styles, { control: control })),
        React.createElement(SaveButtons, null,
            React.createElement(CButton, { color: "secondary" }, t("cancel.text")),
            React.createElement(CButton, { type: "submit", disabled: !formState.isDirty, form: "company-form" }, t("save.text"))));
});
export var CompanyPageUsers = React.memo(function (props) {
    return React.createElement(CompanyUsers, null);
});
export var CompanyPageMode = React.memo(function (props) {
    return React.createElement(CompanyModePage, null);
});
export var CompanyPagePayments = React.memo(function (props) {
    return React.createElement("div", null, "Payments Page");
});
export var CompanyPageProcessors = React.memo(function (props) {
    return React.createElement(PaymentMethods, null);
});
export var CompanyPageStations = React.memo(function (props) {
    return React.createElement(Stations, null);
});
export var CompanyPageDevices = React.memo(function (props) {
    return React.createElement(Devices, null);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
