import { setCookie, getCookie, removeCookie } from "typescript-cookie";
var CookiesService = /** @class */ (function () {
    function CookiesService() {
    }
    CookiesService.prototype.setCookie = function (name, value) {
        setCookie(name, value, {
            path: "/",
            expires: 365
        });
    };
    CookiesService.prototype.getCookie = function (name) {
        return getCookie(name);
    };
    CookiesService.prototype.removeCookie = function (name) {
        return removeCookie(name);
    };
    return CookiesService;
}());
var cookiesService = new CookiesService();
export default cookiesService;
