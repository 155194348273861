var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { HoriontalContainer } from "../common/HorizontalContainer";
import { VerticalContainer } from "../common/VerticalContainer";
import { CButton, CBadge } from "@coreui/react";
var TariffPlansContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex: 1;\n    padding: 10px;\n"], ["\n    flex: 1;\n    padding: 10px;\n"])));
var CurrentTariffPlanContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    border: orange solid 1px;\n    border-radius: 4px;\n    flex: 1;\n    padding: 5px 10px;\n    margin: 5px 0;\n"], ["\n    display: flex;\n    border: orange solid 1px;\n    border-radius: 4px;\n    flex: 1;\n    padding: 5px 10px;\n    margin: 5px 0;\n"])));
var TariffPlanContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    border:#ccc solid 1px;\n    border-radius: 4px;\n    flex: 1;\n    padding: 5px 10px;\n    margin: 5px 0;\n"], ["\n    display: flex;\n    border:#ccc solid 1px;\n    border-radius: 4px;\n    flex: 1;\n    padding: 5px 10px;\n    margin: 5px 0;\n"])));
var TariffPlanName = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 16px;\n    text-transform: uppercase;\n    font-weight: bold\n"], ["\n    font-size: 16px;\n    text-transform: uppercase;\n    font-weight: bold\n"])));
var TariffPlanPrice = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 14px;\n    text-transform: uppercase;\n    font-weight: bold;\n    margin-right: 5px;\n"], ["\n    font-size: 14px;\n    text-transform: uppercase;\n    font-weight: bold;\n    margin-right: 5px;\n"])));
var SectionTitle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 11px;\n    text-transform: uppercase;\n    color: #ccc;\n    border-bottom: #ccc solid 1px;\n    margin: 5px 0;\n"], ["\n    font-size: 11px;\n    text-transform: uppercase;\n    color: #ccc;\n    border-bottom: #ccc solid 1px;\n    margin: 5px 0;\n"])));
export var TariffPlans = React.memo(function (props) {
    var tariffs = [
        {
            id: "1234",
            name: "Basic",
            price: 12.22
        },
        {
            id: "1235",
            name: "Standart",
            price: 20.22
        },
        {
            id: "1236",
            name: "Pro",
            price: 55.22
        }
    ];
    // const { tariffs } = props;
    return React.createElement(TariffPlansContainer, null,
        React.createElement(SectionTitle, null, "Current Plan"),
        React.createElement(CurrentTariffPlanContainer, null,
            React.createElement(HoriontalContainer, { style: {
                    alignItems: "center",
                    flex: 1
                } },
                React.createElement(VerticalContainer, null,
                    React.createElement(HoriontalContainer, null,
                        React.createElement(TariffPlanName, null, "Trial"),
                        "\u00A0 \u00A0",
                        React.createElement(CBadge, { color: "danger" }, "Expired at: 23/05/2022"))),
                React.createElement(HoriontalContainer, { style: { flex: 1, justifyContent: "flex-end", alignItems: "center" } },
                    React.createElement(TariffPlanPrice, null, "FREE")))),
        React.createElement(SectionTitle, null, "Other Plans"),
        tariffs.map(function (tariff) { return React.createElement(TariffPlan, { key: tariff.id, plan: tariff }); }));
});
var TariffPlan = React.memo(function (props) {
    var plan = props.plan;
    return React.createElement(TariffPlanContainer, null,
        React.createElement(HoriontalContainer, { style: {
                alignItems: "center",
                flex: 1
            } },
            React.createElement(VerticalContainer, null,
                React.createElement(TariffPlanName, null, plan.name)),
            React.createElement(HoriontalContainer, { style: { flex: 1, justifyContent: "flex-end", alignItems: "center" } },
                React.createElement(TariffPlanPrice, null,
                    "$",
                    plan.price,
                    "/month"),
                React.createElement(CButton, null, "Select"))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
