var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Box } from "../component/common/FlexItem";
import { SvgVariant, QRCode } from "./SvgVariant";
import styled from "styled-components";
import { userStore } from "../store/user.store";
import { CloseIcon } from "../common/CloseIcon";
import Scrollbars from "react-custom-scrollbars-2";
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex-direction: column;\n    height: 100%;\n"], ["\n    flex-direction: column;\n    height: 100%;\n"])));
var Variants = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;    \n    flex-wrap: wrap;    \n    flex-direction: column;    \n    padding: 1rem;\n    flex: 1;\n    position: relative;\n"], ["\n    display: flex;    \n    flex-wrap: wrap;    \n    flex-direction: column;    \n    padding: 1rem;\n    flex: 1;\n    position: relative;\n"])));
var Row = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: none;\n    justify-content: space-between;\n    margin-bottom: 1rem;\n"], ["\n    flex: none;\n    justify-content: space-between;\n    margin-bottom: 1rem;\n"])));
var Inputs = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    flex: none;\n    margin: 1rem 0 0 0;\n    padding: 0 1rem;\n"], ["\n    flex: none;\n    margin: 1rem 0 0 0;\n    padding: 0 1rem;\n"])));
var Column = styled(Box)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    flex: 1;\n    flex-direction: column;\n"], ["\n    flex: 1;\n    flex-direction: column;\n"])));
var QrDetailsContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    position: absolute;    \n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;    \n    background-color: #fff;\n    display: flex;\n    flex-direction: column;\n"], ["\n    position: absolute;    \n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;    \n    background-color: #fff;\n    display: flex;\n    flex-direction: column;\n"])));
var QrDetailsContent = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    flex:1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n"], ["\n    flex:1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n"])));
var QRCloseIconPosition = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    position: absolute;\n    right: 0.5rem;\n    top: 0.5rem\n"], ["\n    position: absolute;\n    right: 0.5rem;\n    top: 0.5rem\n"])));
export var areaQrUrlBuiler = function (params) {
    var frColor = params.frColor, qrColor = params.qrColor, token = params.token, text = params.text, tpl = params.tpl, companyId = params.companyId, areaId = params.areaId, placeId = params.placeId;
    var queryParams = { frColor: frColor, qrColor: qrColor, token: token, text: text, tpl: tpl };
    var queryParamsRec = queryParams;
    if (DEBUG_COMPANY) {
        queryParamsRec["company"] = DEBUG_COMPANY;
    }
    var queryString = Object.keys(queryParamsRec).map(function (k) { return "".concat(k, "=").concat(encodeURIComponent(queryParamsRec[k])); }).join("&");
    return "".concat(API_BASE_URL, "/").concat(companyId, "/qr/area/").concat(areaId, "/place/").concat(placeId, "?").concat(queryString);
};
export var areaQrDownloadUrlBuiler = function (params) {
    var frColor = params.frColor, qrColor = params.qrColor, token = params.token, text = params.text, tpl = params.tpl, companyId = params.companyId, areaId = params.areaId, placeId = params.placeId;
    var queryParams = { frColor: frColor, qrColor: qrColor, token: token, text: text, tpl: tpl };
    if (params.type) {
        queryParams["type"] = params.type;
    }
    if (DEBUG_COMPANY) {
        queryParams["company"] = DEBUG_COMPANY;
    }
    var queryString = Object.keys(queryParams).map(function (k) { return "".concat(k, "=").concat(encodeURIComponent(queryParams[k])); }).join("&");
    return "".concat(API_BASE_URL, "/").concat(companyId, "/qr/area/").concat(areaId, "/place/").concat(placeId, "/download?").concat(queryString);
};
export var areaZipDownloadUrlBuiler = function (params) {
    var frColor = params.frColor, qrColor = params.qrColor, token = params.token, text = params.text, tpl = params.tpl, companyId = params.companyId, areaId = params.areaId;
    var queryParams = { frColor: frColor, qrColor: qrColor, token: token, text: text, tpl: tpl };
    if (params.type) {
        queryParams["type"] = params.type;
    }
    var queryString = Object.keys(queryParams).map(function (k) { return "".concat(k, "=").concat(encodeURIComponent(queryParams[k])); }).join("&");
    return "".concat(API_BASE_URL, "/").concat(companyId, "/qr/area/").concat(areaId, "/download?").concat(queryString);
};
export var AreaQrSvg = React.memo(function (props) {
    var _a = React.useState(null), variant = _a[0], setVariant = _a[1];
    var frColor = props.frColor, qrColor = props.qrColor, text = props.text, area = props.area, place = props.place;
    var token = userStore(function (state) { return state.token; });
    var companyId = userStore(function (state) { return state.company.id; });
    var urlBuiler = function (tpl) {
        return areaQrUrlBuiler({
            tpl: tpl,
            placeId: place.id,
            areaId: area.id,
            frColor: frColor,
            qrColor: qrColor,
            text: text,
            token: token,
            companyId: companyId
        });
    };
    var setVariantBuilder = function (tpl) { return function () {
        setVariant(tpl);
        if (props.onVariant) {
            props.onVariant(tpl);
        }
    }; };
    return React.createElement(Container, null,
        React.createElement(Variants, null,
            React.createElement(Scrollbars, null,
                React.createElement(Row, null,
                    React.createElement(SvgVariant, { active: true, qrURL: urlBuiler("tpl2"), onClick: setVariantBuilder("tpl2") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl3"), onClick: setVariantBuilder("tpl3") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl4"), onClick: setVariantBuilder("tpl4") })),
                React.createElement(Row, null,
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl5"), onClick: setVariantBuilder("tpl5") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl6"), onClick: setVariantBuilder("tpl6") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl7"), onClick: setVariantBuilder("tpl7") })),
                React.createElement(Row, null,
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl8"), onClick: setVariantBuilder("tpl8") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl9"), onClick: setVariantBuilder("tpl9") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl10"), onClick: setVariantBuilder("tpl10") })),
                React.createElement(Row, null,
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl11"), onClick: setVariantBuilder("tpl11") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl12"), onClick: setVariantBuilder("tpl12") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl13"), onClick: setVariantBuilder("tpl13") })),
                React.createElement(Row, null,
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl14"), onClick: setVariantBuilder("tpl14") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl15"), onClick: setVariantBuilder("tpl15") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl16"), onClick: setVariantBuilder("tpl16") })),
                React.createElement(Row, null,
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl17"), onClick: setVariantBuilder("tpl17") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl18"), onClick: setVariantBuilder("tpl18") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl19"), onClick: setVariantBuilder("tpl19") })),
                React.createElement(Row, null,
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl20"), onClick: setVariantBuilder("tpl20") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl21"), onClick: setVariantBuilder("tpl21") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl22"), onClick: setVariantBuilder("tpl22") })),
                React.createElement(Row, null,
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl23"), onClick: setVariantBuilder("tpl23") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl24"), onClick: setVariantBuilder("tpl24") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl25"), onClick: setVariantBuilder("tpl25") })),
                React.createElement(Row, null,
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl26"), onClick: setVariantBuilder("tpl26") }),
                    React.createElement(SvgVariant, { qrURL: urlBuiler("tpl27"), onClick: setVariantBuilder("tpl27") }),
                    React.createElement("div", { style: { width: "6rem" } }))),
            variant && React.createElement(QrDetailsContainer, null,
                React.createElement(QrDetailsContent, null,
                    React.createElement(QRCode, { url: urlBuiler(variant) }),
                    React.createElement(QRCloseIconPosition, null,
                        React.createElement(CloseIcon, { onClick: function () {
                                console.log("ZoOxz");
                                setVariant(null);
                            } }))))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
