import * as React from "react";
import { userStore } from "../../store/user.store";
import { useNavigate } from "react-router-dom";
import { APP } from "../../constats/Routes";
export var HasPemission = React.memo(function (props) {
    var permission = props.permission, children = props.children;
    var hasPermission = userStore(function (state) { return state.hasPermission; });
    var hp = hasPermission(permission);
    var navigate = useNavigate();
    React.useEffect(function () {
        if (!hp) {
            navigate(APP);
        }
    }, [hp]);
    return React.createElement(React.Fragment, null, hp && children);
});
