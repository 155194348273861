import * as _ from "underscore";
export function getLanguageFromPath(path) {
    var parts = path.split("/");
    console.log("Language Parts", parts);
    if (parts.length < 2) {
        return null;
    }
    parts.shift();
    return ((parts[0] != "" && parts[0] !== "app") && parts[1] == "app") ? parts[0] : null;
}
export function hasLanguageInPath(path) {
    var parts = path.split("/");
    if (parts.length < 2) {
        return false;
    }
    parts.shift();
    console.log;
    return parts[0] !== "app" && parts[1] == "app";
}
export function getLocation(url) {
    var a = document.createElement('a');
    a.href = url;
    return a;
}
export function getURLParams() {
    var paramsString = document.location.search.replace("?", "");
    var paramsPairs = paramsString.split("&");
    var params = {};
    _.each(paramsPairs, function (pair) {
        var keyVal = pair.split("=");
        params[decodeURIComponent(keyVal[0])] = decodeURIComponent(keyVal[1]);
    });
    return params;
}
export function getHost() {
    var hostname = document.location.hostname;
    return hostname;
}
export function getSubdomain() {
    var host = getHost();
    var parts = host.split(".");
    return parts.length == 3 ? parts[0] : false;
}
export function getHostWithProtocol() {
    var _a = document.location, hostname = _a.hostname, protocol = _a.protocol, port = _a.port;
    return "".concat(protocol, "//").concat(hostname).concat(parseInt(port) == 80 ? "" : ":" + port);
}
