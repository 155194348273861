import { TIPS_ENDPOINT } from "../constats/ApiEndpoints";
import { userStore } from "../store/user.store";
export function getTipsRequest() {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(TIPS_ENDPOINT),
        method: "GET",
        params: {
            token: token
        }
    };
}
export function updateTipsRequest(request) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(TIPS_ENDPOINT),
        method: "POST",
        params: {
            token: token
        },
        data: request
    };
}
