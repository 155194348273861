import * as React from "react";
import { CFormCheck } from "@coreui/react";
import { useController } from "react-hook-form";
import { randomString } from "../../../utils/StringUtils";
var FormCheckboxInput = function (props) {
    var control = props.control, name = props.name, defaultValue = props.defaultValue, label = props.label, rules = props.rules, disabled = props.disabled, inline = props.inline, className = props.className;
    var _a = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, _c = _b.value, value = _c === void 0 ? false : _c, ref = _b.ref, fieldState = _a.fieldState;
    var onInputChange = function (e) {
        if (props.onChage) {
            props.onChage(e);
        }
        onChange(e);
    };
    var inputId = props.id ? props.id : randomString(6);
    return React.createElement(CFormCheck, { id: inputId, onChange: onInputChange, onBlur: onBlur, 
        //value={value}
        ref: ref, label: label, disabled: disabled, checked: !!value, inline: inline, invalid: fieldState.invalid, 
        //valid={fieldState.isDirty && !fieldState.invalid}
        className: className, style: {
            "flex-direction": "row-reverse"
        } });
};
export default React.memo(FormCheckboxInput);
