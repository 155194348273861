import axios from "axios";
import { userStore } from "./store/user.store";
var sendMessage = function (error) {
    //axios.post(`${API_BASE_HTTP_URL}/client-error/web`, error);
    //axios.post(`http://192.168.5.178:8090/client-error/web`, error);
    return axios.post("".concat(API_BASE_URL, "/error/client"), error);
};
var errors = {};
var addError = function (error) {
    var user = userStore.getState().user;
    if (!errors[error.id]) {
        errors[error.id] = {
            error: error,
            occurrences: 0
        };
    }
    var item = errors[error.id];
    item.occurrences++;
    if (Object.keys(errors).length == 1) {
        sendMessage({
            message: error.message,
            stack: error.stack,
            userId: (user && user.id) || null
        });
    }
};
if (ENV === "prod") {
    window.addEventListener("error", function (e) {
        var err = JSON.parse(JSON.stringify(e, ['message', 'filename', 'lineno', 'colno']));
        err.stack = e.error.stack;
        err.timestamp = Date.now();
        err.id = err.message + err.filename + err.lineno;
        addError(err);
    });
}
