var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { CButton, CModalBody } from "@coreui/react";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "../../common/Button";
import { getCompanyAreaQRSettingsRequest, getCompanyInfoRequest, updateCompanyAreaQRSettingsRequest } from "../../request/company.request";
import { HttpApiRequest, useHttpRequesPrepareHandler, useHttpRequestHandler } from "../../service/network.service";
import { userStore } from "../../store/user.store";
import { areaQrDownloadUrlBuiler, AreaQrSvg, areaQrUrlBuiler } from "../../svg/AreaQrSvg";
import { QRCode } from "../../svg/SvgVariant";
import { Box } from "../common/FlexItem";
import FormTextInput from "../common/form/FormTextInput";
import LoadingBox from "../common/LoadingBox";
import { Modal, ModalHeader, ModalHeaderTitle } from "../common/Modal";
var logger = require("js-logger").get("AreaQRCodeModal");
var QRModalContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    height: 100%;\n    position: relative;\n    flex-direction: column;\n    padding: 1rem;\n"], ["\n    display: flex;\n    height: 100%;\n    position: relative;\n    flex-direction: column;\n    padding: 1rem;\n"])));
var DownloadButtonsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    top:0;\n    left:0;\n    right:0;\n    bottom: 0;\n    background-color: #fff;    \n    display: flex;\n"], ["\n    position: absolute;\n    top:0;\n    left:0;\n    right:0;\n    bottom: 0;\n    background-color: #fff;    \n    display: flex;\n"])));
var DownloadButtonsContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 1;\n    padding: 1rem;\n    display: flex;\n    flex-direction: column;    \n"], ["\n    flex: 1;\n    padding: 1rem;\n    display: flex;\n    flex-direction: column;    \n"])));
var QRButtons = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding:0 0.5rem\n"], ["\n    padding:0 0.5rem\n"])));
var DownloadQrCodeContainer = styled(Box)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    position: relative;\n"], ["\n    position: relative;\n"])));
var QRModalBody = styled(CModalBody)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    padding: 0;\n"], ["\n    padding: 0;\n"])));
export var AreaQRCodeModal = React.memo(function (props) {
    var t = useTranslation().t;
    var onClose = props.onClose, area = props.area, place = props.place;
    var _a = React.useState(null), selected = _a[0], setSelected = _a[1];
    var _b = React.useState(null), showDownload = _b[0], setShowDownload = _b[1];
    var companyId = userStore(function (state) { return state.company.id; });
    var token = userStore(function (state) { return state.token; });
    var getCompanyAreaQRSettingsState = useHttpRequestHandler(getCompanyAreaQRSettingsRequest());
    var companyInfoState = useHttpRequestHandler(getCompanyInfoRequest());
    var saveCompanyQRSettingsState = useHttpRequesPrepareHandler();
    //const defaultValues = getCompanySettingsState.executed ? (getCompanySettingsState.result as Record<string, any>).qr : {};
    var _c = useForm({
        mode: "onChange",
        defaultValues: {
            frColor: "#000000",
            qrColor: "#000000",
            text: t("area.qr.text", {
                "table": "{{table}}"
            })
        }
    }), control = _c.control, watch = _c.watch, reset = _c.reset, getValues = _c.getValues;
    var frColor = watch("frColor");
    var qrColor = watch("qrColor");
    var text = watch("text");
    var download = function (type) {
        var _a = getValues(), frColor = _a.frColor, qrColor = _a.qrColor, text = _a.text;
        var link = document.createElement('a');
        link.download = 'qr-code.png';
        var params = {
            text: text,
            companyId: companyId,
            areaId: area.id,
            placeId: place.id,
            frColor: frColor,
            qrColor: qrColor,
            type: type,
            tpl: selected,
            token: token
        };
        if (DEBUG_COMPANY) {
            params = Object.assign({}, params, {
                company: DEBUG_COMPANY
            });
        }
        link.href = areaQrDownloadUrlBuiler(params);
        link.click();
    };
    React.useEffect(function () {
        Promise.all([
            HttpApiRequest(getCompanyAreaQRSettingsRequest()).promise,
            HttpApiRequest(getCompanyInfoRequest()).promise
        ]).then(function (res) {
            var _a;
            var qrSettings = res[0], info = res[1];
            var update = {};
            var companyInfo = info.data;
            if (qrSettings.data) {
                Object.assign(update, qrSettings.data);
            }
            if (companyInfo && !update.frColor) {
                update["frColor"] = ((_a = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.colors) === null || _a === void 0 ? void 0 : _a.color) || "#000000";
            }
            if (!update.qrColor) {
                update["qrColor"] = "#000000";
            }
            if (!update.text) {
                update.text = t("area.qr.text");
            }
            reset(update);
        }).catch(function (err) {
            logger.error(err);
        });
        return function () {
            saveCompanyQRSettingsState.run(updateCompanyAreaQRSettingsRequest(getValues()));
        };
    }, []);
    if (getCompanyAreaQRSettingsState.inProgress || companyInfoState.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    return React.createElement(Modal, { visible: true, fullscreen: true, noBorderRadius: true },
        React.createElement(ModalHeader, { onClose: onClose },
            React.createElement(ModalHeaderTitle, null, t("qr.code.text"))),
        React.createElement(QRModalBody, null,
            getCompanyAreaQRSettingsState.inProgress && React.createElement(LoadingBox, null),
            !getCompanyAreaQRSettingsState.inProgress && React.createElement(QRModalContent, null,
                React.createElement(Box, { flexDirection: "column", flex: 1 },
                    React.createElement(Box, { flex: "none", flexDirection: "column", gap: "0.5rem" },
                        React.createElement(Box, { gap: "1rem" },
                            React.createElement(Box, { flexDirection: "column", flex: 0.5 },
                                React.createElement(FormTextInput, { disableHandleFocus: true, control: control, name: "frColor", placeholder: "Frame Color", size: "sm", label: t("area.qr.frame.color.label") })),
                            React.createElement(Box, { flexDirection: "column", flex: 0.5 },
                                React.createElement(FormTextInput, { disableHandleFocus: true, control: control, name: "qrColor", placeholder: "Qr Color", size: "sm", label: t("area.qr.qr.color.label") }))),
                        React.createElement(Box, { flexDirection: "column" },
                            React.createElement(FormTextInput, { disableHandleFocus: true, control: control, name: "text", placeholder: "Text", size: "sm", label: t("area.qr.text.label") }))),
                    React.createElement(Box, { flex: 1 },
                        React.createElement(AreaQrSvg, { area: area, place: place, frColor: frColor, qrColor: qrColor, text: text, onVariant: function (tpl) { return setSelected(tpl); } }))),
                showDownload && React.createElement(DownloadButtonsContainer, null,
                    React.createElement(DownloadButtonsContent, null,
                        React.createElement(DownloadQrCodeContainer, { align: "center", justify: "center", flex: 1 },
                            React.createElement(QRCode, { url: areaQrUrlBuiler({
                                    companyId: companyId,
                                    placeId: place.id,
                                    areaId: area.id,
                                    token: token,
                                    frColor: frColor,
                                    qrColor: qrColor,
                                    text: text,
                                    tpl: selected
                                }) })),
                        React.createElement(Box, { flex: "none", flexDirection: "column" },
                            React.createElement(Box, { flex: 1, flexDirection: "column", justify: "flex-end", gap: "0.5rem" },
                                React.createElement(Button, { color: "primary", onClick: function () { return download("svg"); } }, "Download SVG"),
                                React.createElement(Button, { color: "primary", onClick: function () { return download("png"); } }, "Download PNG"),
                                React.createElement(Button, { color: "primary", onClick: function () { return download("jpg"); } }, "Download JPG"))))),
                React.createElement(QRButtons, { justify: "flex-end", gap: "0.5rem" },
                    React.createElement(CButton, { color: "primary", onClick: function () {
                            setShowDownload(true);
                        }, disabled: !selected }, t("download.text")),
                    React.createElement(CButton, { color: "secondary", onClick: onClose }, t("close.text"))))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
