var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { Box } from "../component/common/FlexItem";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate } from "react-router-dom";
import { Touchable } from "../common/Touchable";
import { useTranslation } from "react-i18next";
var CategoriesContainer = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 15px 0 0 0;\n    padding: 0 8px;\n"], ["\n    margin: 15px 0 0 0;\n    padding: 0 8px;\n"])));
var CategoryItemContainer = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 60px;\n    min-height: 75px;\n    background-color: ", ";\n    box-shadow: 5px 10px 20px rgba(211, 209, 216, 0.3); \n    border-radius: 10px;    \n    flex-wrap: wrap;\n    color: var(--btn-font-color)\n"], ["\n    width: 60px;\n    min-height: 75px;\n    background-color: ", ";\n    box-shadow: 5px 10px 20px rgba(211, 209, 216, 0.3); \n    border-radius: 10px;    \n    flex-wrap: wrap;\n    color: var(--btn-font-color)\n"])), function (props) { return props.active ? "var(--btn-primary-color)" : "#fff"; });
var CategoryItemLayout = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 1\n"], ["\n    flex: 1\n"])));
var CategoryItemIcon = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-top: 5px;\n    width: 30px;\n    height: 30px;\n    border-radius: 15px;\n    background: #fff url(", ") no-repeat center center;\n    background-size: 70%;\n        \n"], ["\n    margin-top: 5px;\n    width: 30px;\n    height: 30px;\n    border-radius: 15px;\n    background: #fff url(", ") no-repeat center center;\n    background-size: 70%;\n        \n"])), function (props) { return props.imageURL; });
var CategoryItemName = styled(Box)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 12px;\n    color: ", ";\n    margin: 5px 0 0 0;\n    padding-bottom: 10px;\n    text-align: center;\n    line-height: 14px;\n"], ["\n    font-size: 12px;\n    color: ", ";\n    margin: 5px 0 0 0;\n    padding-bottom: 10px;\n    text-align: center;\n    line-height: 14px;\n"])), function (props) { return props.active ? "#fff" : "#111719"; });
var ScrollContainer = styled(Box)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        \n"], ["\n        \n"])));
export var Categories = React.memo(function (props) {
    var t = useTranslation().t;
    var active = props.active, categories = props.categories;
    var favourites = [];
    var navigate = useNavigate();
    var goToCategory = function (id) { return function () {
    }; };
    return React.createElement(CategoriesContainer, { flex: "none" },
        React.createElement(Scrollbars, { style: {
                height: "80px"
            } },
            React.createElement(ScrollContainer, { gap: "5px" }, categories.map(function (category) { return React.createElement(CategoryItem, { onTap: goToCategory(category.id), key: category.id, icon: category.imageURL, name: category.name, active: active == category.id }); }))));
});
export var CategoryItem = React.memo(function (props) {
    var icon = props.icon, name = props.name, onTap = props.onTap, active = props.active;
    var nodeRef = React.useRef(null);
    return React.createElement(Touchable, { onTap: onTap },
        React.createElement(CategoryItemContainer, { justify: "center", flex: "none", active: active },
            React.createElement(CategoryItemLayout, { flexDirection: "column", align: "center" },
                React.createElement(CategoryItemIcon, { align: "center", flex: "none", imageURL: icon }),
                React.createElement(CategoryItemName, { align: "center", flex: "none", active: active, justify: "center", className: "prevent-select" }, name))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
