var JsonRPC = require("simple-jsonrpc-js");
var logger = require("js-logger").get("WsJsonRpc");
var handleRequest = function (handlers, method, args) {
    //console.log("HANDLE JSON RPC", method);
    if (handlers[method]) {
        handlers[method].forEach(function (handler) {
            try {
                handler.apply(handler, args);
            }
            catch (err) {
                logger.error(err);
            }
        });
    }
};
var WebsocketJsonRpcService = /** @class */ (function () {
    function WebsocketJsonRpcService(connection) {
        var _this = this;
        this.offHandlers = [];
        this.queue = [];
        this.handlers = {};
        this.getWebSocketConnection = function () {
            return _this.connection;
        };
        this.request = function (method, params) {
            console.log("REQUEST", method, _this.connection.isConnected());
            if (!_this.connection.isConnected()) {
                _this.connection.connect();
                return new Promise(function (res, rej) {
                    _this.queue.push(function () {
                        return _this.request(method, params)
                            .then(res)
                            .catch(rej);
                    });
                });
            }
            return new Promise(function (res, rej) {
                var resolved = false;
                _this.jsonRpc.call(method, params)
                    .then(res)
                    .catch(rej)
                    .finally(function () {
                    resolved = true;
                });
            });
        };
        this.notification = function (method, params) {
            if (!_this.connection.isConnected()) {
                _this.connection.connect();
                _this.queue.push(function () {
                    _this.notification(method, params);
                    return Promise.resolve();
                });
            }
            _this.jsonRpc.notification(method, params);
        };
        this.subscribe = function (method, params, handler) {
            var me = _this;
            if (!_this.handlers[method]) {
                _this.handlers[method] = [];
                //console.log("HANDLE JSON RPC METHOD", method);
                _this.jsonRpc.on(method, params, function () {
                    handleRequest(me.handlers, method, Array.prototype.slice.call(arguments));
                });
            }
            if (_this.handlers[method].indexOf(handler) === -1) {
                _this.handlers[method].push(handler);
            }
            return function () {
                //console.log("BEFORE REMOVE REQUEST", this.handlers[method])
                if (_this.handlers[method]) {
                    var idx = _this.handlers[method].indexOf(handler);
                    if (idx != -1) {
                        _this.handlers[method].splice(idx, 1);
                    }
                }
                //console.log("AFTER REMOVE REQUEST", this.handlers[method])
            };
        };
        this.subscribeOnce = function (method, params, handler) {
            logger.info(method);
            if (_this.handlers[method]) {
                logger.info("".concat(method, " - exists"));
                return;
            }
            return _this.subscribe(method, params, handler);
        };
        this.executeQueue = function () {
            if (_this.queue.length) {
                var item = _this.queue.shift();
                item()
                    .finally(function () {
                    _this.executeQueue();
                });
            }
            else {
                console.log("QUEUE IS EMPTY");
            }
        };
        this.queue = [];
        this.handlers = {};
        this.connection = connection;
        this.jsonRpc = new JsonRPC();
        this.jsonRpc.toStream = function (msg) {
            if (!_this.connection.isConnected()) {
                _this.queue.push(function () {
                    _this.connection.send(msg);
                    return Promise.resolve();
                });
                return;
            }
            logger.info("---->", msg);
            _this.connection.send(msg);
        };
        this.offHandlers.push(this.connection.on("message", function (event) {
            logger.info("<----", event.data);
            _this.jsonRpc.messageHandler(event.data);
        }));
        this.offHandlers.push(this.connection.on("open", function () {
            _this.executeQueue();
        }));
    }
    return WebsocketJsonRpcService;
}());
export { WebsocketJsonRpcService };
