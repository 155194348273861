var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { DishDetailsContainer, DishInfoContainer, DishInfoDetails, DishInfoName, DishInfoDescription, DishInfoPrice } from "./DishDetails";
import { getCurrencyFormat } from "../utils/CurrencyUtils";
import { PriceWithCurrency } from "./PriceWithCurrency";
var logger = require("js-logger").get("DishDetails");
export var DishInfoIcon = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    // width: 9rem;\n    // height: 9rem;\n    background: url(", ") no-repeat;\n    background-size: contain;\n    margin: 5px;\n"], ["\n    // width: 9rem;\n    // height: 9rem;\n    background: url(", ") no-repeat;\n    background-size: contain;\n    margin: 5px;\n"])), function (props) { return props.icon; });
var DishName = styled(DishInfoName)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: 18px;\n    line-height: 22px;\n"], ["\n    font-size: 18px;\n    line-height: 22px;\n"])));
var DishDesc = styled(DishInfoDescription)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: 16px;\n"], ["\n    font-size: 16px;\n"])));
var DishIcon = styled(DishInfoIcon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width:80px;\n    height: 80px;\n"], ["\n    width:80px;\n    height: 80px;\n"])));
var DishPrice = styled(DishInfoPrice)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 16px;\n"], ["\n    font-size: 16px;\n"])));
export var DishDetails = React.memo(function (props) {
    var categoryImage = props.categoryImage, name = props.name, details = props.details, price = props.price, currency = props.currency;
    return React.createElement(DishDetailsContainer, { flexDirection: "row", flex: "none" },
        React.createElement(DishInfoContainer, { flex: 1, gap: "1rem" },
            React.createElement(DishInfoIcon, { icon: categoryImage }),
            React.createElement(DishInfoDetails, { flexDirection: "column", justify: "center" },
                React.createElement(DishInfoName, { className: "name" }, name),
                React.createElement(DishInfoDescription, { className: "description" }, details))),
        React.createElement(DishInfoPrice, { flex: "none", justify: "flex-end", align: "center" },
            React.createElement(PriceWithCurrency, { value: price })));
});
export var Details = React.memo(function (props) {
    var categoryImage = props.categoryImage, name = props.name, details = props.details, price = props.price, currency = props.currency;
    var currencyFormat = getCurrencyFormat(price, currency);
    //logger.info("currencyFormat", currencyFormat);
    return React.createElement(DishDetailsContainer, { flexDirection: "row", flex: "none" },
        React.createElement(DishInfoContainer, { flex: 1 },
            React.createElement(DishIcon, { icon: categoryImage }),
            React.createElement(DishInfoDetails, { flexDirection: "column", justify: "center" },
                React.createElement(DishName, { className: "name" }, name),
                details && React.createElement(DishDesc, { className: "description" }, details))),
        React.createElement(DishPrice, { flex: "none", justify: "flex-end", align: "center" },
            React.createElement(PriceWithCurrency, { value: price })));
});
export var PreviewTopImage = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    position: relative;\n    width: 100%;\n    height: 200px;\n    background: url(\"", "\") no-repeat center center;\n    background-size: contain;\n    flex: none;\n"], ["\n    position: relative;\n    width: 100%;\n    height: 200px;\n    background: url(\"", "\") no-repeat center center;\n    background-size: contain;\n    flex: none;\n"])), function (props) { return props.image; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
