import { flatten, groupBy } from "underscore";
var logger = require("js-logger").get("OPUtils");
export function splitOrderByItem(order) {
    var items = order.items;
    return items.map(function (item) { return ({
        order: order,
        status: item.status,
        stations_id: item.station_id,
        items: [item]
    }); });
}
export function filterOrderByStatus(orders, status) {
    return orders.filter(function (order) { return order.status === status; });
}
export function splitOrdersByItem(orders, status) {
    logger.info("orders", orders);
    var containers = flatten(orders.map(splitOrderByItem));
    if (!status) {
        return containers;
    }
    return containers.filter(function (container) { return container.status === status; });
}
export function groupByStation(containers) {
    var groupped = groupBy(containers, function (container) { return "".concat(container.order.id, "_").concat(container.items[0].station_id); });
    return Object.keys(groupped).map(function (key) { return ({
        order: groupped[key][0].order,
        status: groupped[key][0].status,
        stations_id: groupped[key][0].stations_id,
        items: flatten(groupped[key].map(function (item) { return item.items; }))
    }); });
}
export function orderName(orderName) {
    if (!orderName) {
        return "";
    }
    var parts = orderName.split("-");
    if (parts.length < 2) {
        return orderName;
    }
    return parts[1];
}
