var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
// import Countdown, { CountdownRenderProps } from "react-countdown";
import styled from "styled-components";
import useSound from "use-sound";
import alertSound from "../../assets/sound/fail-notification.wav";
import { ordersStore } from "../store/orders.store";
// import { StatusTimer } from "../constats/StatusTimers";
import { number } from "prop-types";
import { Countdown } from "../component/common/countdown/countdown";
import { userStore } from "../store/user.store";
var logger = require("js-logger").get("OrderTimer");
var TimerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: inline-flex;\n    // background-color: ", ";    \n    padding: 0.5rem;\n    border-radius: 0.3rem;\n    margin-bottom: 0.5rem;\n    color: #fff;\n    font-family: monspace;\n    width: ", ";\n    height: ", ";\n    align-items: center;\n    \n"], ["\n    display: inline-flex;\n    // background-color: ", ";    \n    padding: 0.5rem;\n    border-radius: 0.3rem;\n    margin-bottom: 0.5rem;\n    color: #fff;\n    font-family: monspace;\n    width: ", ";\n    height: ", ";\n    align-items: center;\n    \n"])), function (props) { return props.completed ? "#f44c19" : "#57d397"; }, function (props) { return props.width ? props.width : "3.5rem"; }, function (props) { return props.height ? props.height : "3.5rem"; });
var getOrderTimerByStatus = function (order) {
    var items = order.items;
    var status = items[0].status;
    var prepareTime = items.filter(function (item) { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.properties) === null || _a === void 0 ? void 0 : _a.prepare_time) !== undefined; }).map(function (item) { return parseInt(item.properties.prepare_time); });
    if (prepareTime.length && items[0].status == "in_progress") {
        var maxPrepareTime = Math.max.apply(number, prepareTime);
        return maxPrepareTime * (60 * 1000);
    }
    var statusTimers = ordersStore.getState().timers || { new: 3, "in-progress": 3, ready: 3 };
    if (!statusTimers[status]) {
        return null;
    }
    return statusTimers[status] * (60 * 1000);
};
var getOrderChangeTime = function (order) {
    var items = order.items;
    var updated = items[0].updated;
    return updated;
};
var getOrderStatusExpiration = function (order) {
    var timerInterval = getOrderTimerByStatus(order);
    if (null == timerInterval) {
        return null;
    }
    var changeTime = getOrderChangeTime(order);
    return changeTime + timerInterval;
};
var getOrderChangeStatusPassedTime = function (order) {
    var timerInterval = getOrderTimerByStatus(order);
    if (null == timerInterval) {
        return null;
    }
    //const expiration = getOrderStatusExpiration(order);
    var changeTime = getOrderChangeTime(order);
    return Date.now() - changeTime;
};
var isMuted = function (soundKey) {
    var soundConfig = JSON.parse(localStorage.getItem(soundKey) || '{"mute": false}');
    return soundConfig.mute;
};
var formatDigits = function (d) { return d < 10 ? "0".concat(d) : d; };
function formatTime(time) {
    //logger.info("Format time", time);
    time = Math.abs(time);
    var minutes = Math.floor(time / 60);
    var seconds = Math.floor(time % 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    if (days > 0) {
        return "".concat(days, "d");
    }
    if (hours > 1) {
        return "".concat(formatDigits(hours), "h");
    }
    if (minutes >= 10) {
        return "".concat(formatDigits(minutes), "m");
    }
    return "".concat(formatDigits(minutes), ":").concat(formatDigits(seconds));
}
export var OrderTimer = React.memo(function (props) {
    var order = props.order, width = props.width, height = props.height;
    var now = Date.now();
    var expiration = getOrderStatusExpiration(order);
    var _a = React.useState(expiration < now), completed = _a[0], setCompleted = _a[1];
    var userId = userStore(function (state) { return state.user.id; });
    var soundKey = "".concat(userId, "_sound");
    var play = useSound(alertSound)[0];
    React.useEffect(function () {
        var now = Date.now();
        var expiration = getOrderStatusExpiration(order);
        if (expiration) {
            setCompleted(expiration < now);
        }
    }, [order]);
    if (!expiration) {
        return null;
    }
    var passed = getOrderChangeStatusPassedTime(order);
    //if(order.status === "new")
    logger.info(order.name, order.items, passed / (1000 * 60), getOrderTimerByStatus(order) / (1000 * 60));
    return React.createElement(TimerContainer, { completed: completed, width: width, height: height },
        React.createElement(Countdown, { expiration: expiration, continueOnExpire: true, formatTime: formatTime, timePassed: passed / 1000, timerSeconds: getOrderTimerByStatus(order) / 1000 }));
});
var templateObject_1;
