var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
var Row = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 1rem 0;\n"], ["\n    margin: 1rem 0;\n"])));
export var FormRow = React.memo(function (props) {
    return React.createElement(Row, null, props.children);
});
var templateObject_1;
