import * as EventEmitter from "eventemitter3";
var logger = require("js-logger").get("WebSocketConnection");
function randomIntFromInterval(min, max) {
    if (min === void 0) { min = 500; }
    if (max === void 0) { max = 4000; }
    return Math.floor(Math.random() * (max - min + 1) + min);
}
var WebSocketConnection = /** @class */ (function () {
    function WebSocketConnection(url) {
        this.url = url;
        this.connection = null;
        this.handlers = {};
        this.events = new EventEmitter();
        this.connectCount = 0;
    }
    WebSocketConnection.prototype.connect = function () {
        var _this = this;
        if (null == this.connection) {
            this.connectCount++;
            try {
                this.connection = new WebSocket(this.url);
                ["open", "message", "error", "close"].forEach(function (eventName) {
                    _this.connection.addEventListener(eventName, function (event) {
                        _this.events.emit(eventName, event);
                    });
                });
                this.connection.addEventListener("open", function (event) {
                    if (_this.connectCount > 1) {
                        _this.events.emit("reconnect", event);
                    }
                });
                this.connection.addEventListener("close", function (event) {
                    _this.connection = null;
                });
                this.connection.addEventListener("error", function (event) {
                    _this.connection = null;
                    setTimeout(function () {
                        _this.reconnect();
                    }, randomIntFromInterval());
                });
            }
            catch (err) {
                logger.erroro("Connection error", err);
                this.connection = null;
            }
        }
    };
    WebSocketConnection.prototype.updateURL = function (url) {
        this.url = url;
        this.reconnect();
    };
    WebSocketConnection.prototype.disconnect = function () {
        if (this.connection != null) {
            this.connection.close();
        }
    };
    WebSocketConnection.prototype.on = function (event, handler) {
        var _this = this;
        this.events.addListener(event, handler);
        return function () {
            _this.events.removeListener(event, handler);
        };
    };
    WebSocketConnection.prototype.send = function (message) {
        this.connection.send(message);
    };
    WebSocketConnection.prototype.isConnected = function () {
        return this.connection != null && this.connection.readyState == 1;
    };
    WebSocketConnection.prototype.isConnecting = function () {
        return this.connection != null && this.connection.readyState == 0;
    };
    WebSocketConnection.prototype.reconnect = function () {
        //logger.info("RECONNECT");        
        if (this.isConnected()) {
            this.disconnect();
        }
        this.connect();
    };
    return WebSocketConnection;
}());
export { WebSocketConnection };
