var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { cilPencil, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CFormSwitch, CModalTitle, CNavItem } from "@coreui/react";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AreaPermissions } from "../../common/user/Permission";
import { userStore } from "../../store/user.store";
import styled from "styled-components";
import { APP_AREAS, APP_AREA_PAGE, buildRoute } from "../../constats/Routes";
import { activateAreaRequest, createCompanyAreaRequest, deactivateAreaRequest, deleteCompanyAreaRequest, getCompanyAreasRequest, sortCompanyAreasRequest, updateCompanyAreaRequest } from "../../request/area.request";
import { useHttpRequesPrepareHandler, useHttpRequestHandler } from "../../service/network.service";
import { Box } from "../common/FlexItem";
import { EditButton, ListItemBase } from "../common/ListItem";
import { Modal, ModalBody, ModalHeader } from "../common/Modal";
import { Section } from "../common/Section";
import { Sortable } from "../common/Sortable";
import { AreaForm } from "./AreaForm";
import { AreaPage } from "./AreaPage";
var logger = require("js-logger").get("Area");
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["    \n    padding: 10px 10px 0 10px;    \n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n"], ["    \n    padding: 10px 10px 0 10px;    \n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n"])));
var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    flex: 1;\n    padding: 10px;\n"], ["\n    flex: 1;\n    padding: 10px;\n"])));
var ButtonWithNoPadding = styled(CButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: 0 2px;\n"], ["\n    padding: 0 2px;\n"])));
var SidebarItemLayout = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    flex: 1\n"], ["\n    display: flex;\n    flex: 1\n"])));
var SidebarItemLayoutLeft = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: flex;  \n    gap: 10px;\n    align-items: center;\n"], ["\n    display: flex;  \n    gap: 10px;\n    align-items: center;\n"])));
var SidebarItemLayoutRight = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    flex: 1;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    gap: 5px;\n    & .form-switch {\n        padding-bottom: 0;\n        min-height: 0;\n    }\n"], ["\n    flex: 1;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    gap: 5px;\n    & .form-switch {\n        padding-bottom: 0;\n        min-height: 0;\n    }\n"])));
var Item = React.memo(function (props) {
    var item = props.item, editArea = props.editArea, toggleActive = props.toggleActive, goToArea = props.goToArea, deleteArea = props.deleteArea;
    return React.createElement(CNavItem, { key: item.id, href: "#", onClick: function (e) { return e.preventDefault(); } },
        React.createElement(SidebarItemLayout, null,
            React.createElement(SidebarItemLayoutLeft, null,
                React.createElement("div", { className: "drag-handle" },
                    React.createElement(FontAwesomeIcon, { icon: faGripVertical, size: "1x" })),
                React.createElement("div", { onClick: goToArea(item.id) }, item.name)),
            React.createElement(SidebarItemLayoutRight, null,
                React.createElement(CIcon, { icon: cilPencil, onClick: function () {
                        editArea(item);
                    } }),
                React.createElement(CIcon, { icon: cilTrash, onClick: function () {
                        deleteArea(item.id);
                    } }),
                React.createElement(CFormSwitch, { checked: item.active, onChange: function (e) {
                        toggleActive(item);
                    } }))));
});
var editAreaBuilder = function (area) { return function (editArea) { return function () {
    logger.info("[editAreaBuilder]", area);
    editArea(area);
}; }; };
var AreaItems = React.memo(function (props) {
    var refreshAreas = props.refreshAreas, areas = props.areas, editArea = props.editArea;
    var deleteAreaState = useHttpRequesPrepareHandler();
    var toggleActiveAreaState = useHttpRequesPrepareHandler();
    var sortAreasState = useHttpRequesPrepareHandler();
    var t = useTranslation().t;
    var navigate = useNavigate();
    var hasPermission = userStore(function (state) { return state.hasPermission; });
    var goToArea = function (id) { return function (e) {
        logger.info("GO to", id);
        if (e) {
            e.preventDefault();
        }
        navigate(buildRoute(APP_AREA_PAGE, {
            area_id: id
        }));
    }; };
    var deleteArea = function (id) {
        if (confirm(t("delete.confirm.text"))) {
            deleteAreaState.run(deleteCompanyAreaRequest(id))
                .then(function () {
                //areaState.run();
                navigate(APP_AREAS);
            });
        }
    };
    var toggleActive = function (item) {
        var axioReqConfig = item.active ? deactivateAreaRequest(item.id) : activateAreaRequest(item.id);
        toggleActiveAreaState.run(axioReqConfig)
            .then(refreshAreas);
    };
    //const areas: Array<AreaType> = areaState.result ? (areaState.result as Array<AreaType>) : [];
    function handleDragEnd(items) {
        var sortData = {};
        items.forEach(function (area, idx) {
            sortData[area.id] = idx;
        });
        //logger.info(`DragEnd, active: ${getAreaIndex(areas, active.id)}, over: ${getAreaIndex(areas, over.id)}`);
        logger.info("SortData", sortData);
        sortAreasState.run(sortCompanyAreasRequest(sortData))
            .catch(function (err) {
            logger.error(err);
        });
    }
    // const handleEdit = React.useCallback((area: AreaType) => {
    //     logger.info("Edit Area", area, areas);
    //     editArea(area);
    // }, [])
    //const handleEdit = editAreaBuilder(editArea);
    return React.createElement(Container, null,
        React.createElement(Scrollbars, null,
            React.createElement(Section, { title: t("area.page.sidebar.title"), onAdd: hasPermission(AreaPermissions.AREA_ADD) ? function () {
                    editArea(null);
                } : undefined },
                React.createElement(Sortable, { items: areas, handle: ".drag-handle", sortKey: "sort", renderItem: function (item) {
                        return React.createElement(ListItemBase, { key: item.id, item: item, sortable: hasPermission(AreaPermissions.AREA_UPDATE), active: item.active, 
                            // onEdit={(area) => {
                            //     logger.info("Edit Area", area);
                            //     editArea(area as AreaType)
                            // }}
                            // onDelete={(area) => {
                            //     deleteArea(area.id)
                            // }}
                            actions: function (item) {
                                logger.info("Render Actions.....", item);
                                return React.createElement(React.Fragment, null, hasPermission(AreaPermissions.AREA_UPDATE) && React.createElement(EditButton, { item: item, onClick: function (item) {
                                        logger.info("Areas", areas);
                                        editArea(item);
                                    } }));
                            } },
                            React.createElement(Box, { aling: "center", gap: "0.5rem" },
                                React.createElement(CFormSwitch, { checked: item.active, onChange: function (e) {
                                        toggleActive(item);
                                    } }),
                                React.createElement("div", { onClick: goToArea(item.id) }, item.name)));
                    }, onSort: handleDragEnd }))));
});
export var AreaEditor = React.memo(function (props) {
    var t = useTranslation().t;
    var areaState = useHttpRequestHandler(getCompanyAreasRequest());
    var createAreaState = useHttpRequesPrepareHandler();
    var _a = React.useState([]), areas = _a[0], setAreas = _a[1];
    var _b = React.useState(undefined), editItem = _b[0], setEditItem = _b[1];
    var refreshAreas = React.useCallback(function () {
        areaState.run();
    }, []);
    var editArea = React.useCallback(function (area) {
        setEditItem(area);
    }, []);
    React.useEffect(function () {
        if (areaState.result) {
            setAreas(areaState.result.slice(0));
        }
    }, [areaState.result]);
    var createArea = function (values) {
        //logger.info("Values", values);
        //return;
        var requestConfig = editItem ? updateCompanyAreaRequest(editItem.id, values) : createCompanyAreaRequest(values);
        createAreaState.run(requestConfig)
            .then(function () {
            refreshAreas();
        })
            .finally(function () {
            editArea(undefined);
        });
    };
    return React.createElement(Container, null,
        React.createElement(AreaItems, { areas: areas, refreshAreas: refreshAreas, editArea: editArea }),
        React.createElement(Routes, null,
            React.createElement(Route, { path: ":area_id", element: React.createElement(AreaPage, null) })),
        editItem !== undefined && React.createElement(Modal, { visible: true },
            React.createElement(ModalHeader, { onClose: function () {
                    editArea(undefined);
                } },
                React.createElement(CModalTitle, null, t(editItem ? "edit.area.title" : "create.area.title"))),
            React.createElement(ModalBody, null,
                React.createElement(Scrollbars, null,
                    React.createElement(AreaForm, { onSubmit: createArea, onCancel: function () { return editArea(undefined); }, edit: editItem != null, defaultValues: editItem ? editItem : {
                            data: {
                                start_from: "".concat((areas.length + 1), "00")
                            },
                            paymentMethods: {
                                cash: true,
                                pos: true
                            }
                        } })))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
