var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { useController } from "react-hook-form";
var logger = require("js-logger").get("FormDropZone");
import { CButton, CImage, CListGroup, CListGroupItem, CModal, CModalBody } from "@coreui/react";
import { Box } from "../FlexItem";
import { useTranslation } from "react-i18next";
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { Button } from "../../../common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faImage } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalHeader, ModalHeaderTitle } from "../Modal";
import styled from "styled-components";
import { Touchable } from "../../../common/Touchable";
var ImagePreviewContainer = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 40px;\n    height: 40px;\n"], ["\n    width: 40px;\n    height: 40px;\n"])));
var ImagePreview = React.memo(function (props) {
    var image = props.image;
    var _a = React.useState(null), imageURL = _a[0], setImageURL = _a[1];
    var _b = React.useState(false), showImage = _b[0], setShowImage = _b[1];
    var t = useTranslation().t;
    React.useEffect(function () {
        if (typeof image === "string") {
            setImageURL(image);
        }
        else if (image instanceof File) {
            var reader = new FileReader();
            reader.onload = function (e) {
                imageURL(e.target.result);
            };
            reader.readAsDataURL(image);
        }
        else if (image.url !== undefined) {
            setImageURL(image.url);
        }
    }, []);
    return React.createElement(ImagePreviewContainer, null,
        imageURL && React.createElement(Touchable, { onTap: function () {
                setShowImage(true);
            } },
            React.createElement(CImage, { src: imageURL, width: 40 })),
        showImage && React.createElement(CModal, { visible: true },
            React.createElement(CModalBody, null,
                React.createElement(Box, { justify: "center" },
                    React.createElement(CImage, { src: imageURL, width: 200 })),
                React.createElement(Box, { justify: "center" },
                    React.createElement(CButton, { onClick: function () {
                            setShowImage(false);
                        } }, t("close.text"))))));
});
var getBytesFromBase64String = function (base64String) {
    return base64String.split(',')[1].length;
};
function formatBytes(bytes, decimals) {
    if (decimals === void 0) { decimals = 2; }
    if (!+bytes)
        return '0 Bytes';
    var k = 1024;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return "".concat(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), " ").concat(sizes[i]);
}
var ImageSize = React.memo(function (props) {
    var image = props.image;
    var size = typeof image === "string" ? getBytesFromBase64String(image) : image.size;
    return React.createElement(React.Fragment, null, formatBytes(parseInt("".concat(size))));
});
var ImageInfo = React.memo(function (props) {
    var file = props.file;
    var t = useTranslation().t;
    return React.createElement(Box, { align: "center" },
        React.createElement(Box, { flex: 1 },
            React.createElement(ImagePreview, { image: file })),
        React.createElement(ImageSize, { image: file }));
});
var ButtonsContainer = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 0.3rem 0;\n"], ["\n    margin: 0.3rem 0;\n"])));
export var FormDropZoneOrPhoto = React.memo(function (props) {
    var control = props.control, name = props.name, defaultValue = props.defaultValue;
    var _a = React.useState(false), showCamera = _a[0], setShowCamera = _a[1];
    var fileInputRef = React.useRef(null);
    var t = useTranslation().t;
    var toggleShowCamera = function () {
        setShowCamera(!showCamera);
    };
    var _b = useController({
        name: name,
        control: control,
        defaultValue: defaultValue
    }), _c = _b.field, onChange = _c.onChange, onBlur = _c.onBlur, _d = _c.value, value = _d === void 0 ? null : _d, ref = _c.ref, fieldState = _b.fieldState;
    var onDrop = React.useCallback(function (acceptedFiles) {
        // Do something with the files
        logger.info("Files", acceptedFiles);
        onChange(acceptedFiles[0]);
        //setAcceptedFiles(acceptedFiles);
    }, []);
    var _e = useDropzone({ onDrop: onDrop }), acceptedFiles = _e.acceptedFiles, getRootProps = _e.getRootProps, getInputProps = _e.getInputProps, isDragActive = _e.isDragActive;
    return React.createElement("section", { style: {
            width: "100%",
            marginTop: "5px"
        } },
        React.createElement(ButtonsContainer, { gap: "0.5rem" },
            React.createElement(Button, { color: "primary", onClick: toggleShowCamera },
                React.createElement(FontAwesomeIcon, { icon: faCamera })),
            React.createElement(Button, { type: "button", color: "primary", onClick: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    fileInputRef.current.click();
                } },
                React.createElement(FontAwesomeIcon, { icon: faImage }))),
        value && React.createElement(CListGroup, null,
            React.createElement(CListGroupItem, null,
                React.createElement(ImageInfo, { file: value }))),
        !value && React.createElement(CListGroup, null,
            React.createElement(CListGroupItem, null, t("dish.nophoto.text"))),
        React.createElement("div", __assign({}, getRootProps({ className: 'dropzone' }), { ref: fileInputRef }),
            React.createElement("input", __assign({}, getInputProps())),
            React.createElement("p", null, t("files.drag.and.drop.text"))),
        showCamera && React.createElement(Modal, { visible: true, fullscreen: true },
            React.createElement(ModalHeader, { onClose: toggleShowCamera },
                React.createElement(ModalHeaderTitle, null, "Make Photo")),
            React.createElement(Camera, { idealFacingMode: FACING_MODES.ENVIRONMENT, imageType: "png", onTakePhoto: function (dataURI) {
                    onChange(dataURI);
                    toggleShowCamera();
                } })));
});
var templateObject_1, templateObject_2;
