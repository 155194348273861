var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Box } from "../component/common/FlexItem";
//import { FlexBox } from "@app/common/component/Flex";
export var DishDetailsContainer = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-top: 2rem;\n    padding-bottom: 2rem;\n    background: var(--bg-color)\n\n"], ["\n    padding-top: 2rem;\n    padding-bottom: 2rem;\n    background: var(--bg-color)\n\n"])));
export var DishInfoContainer = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n"], ["\n"])));
export var DishInfoDetails = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 1;    \n"], ["\n    flex: 1;    \n"])));
export var DishInfoName = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 4.2rem;\n    color: #111719;\n    line-height: 5rem;\n"], ["\n    font-size: 4.2rem;\n    color: #111719;\n    line-height: 5rem;\n"])));
export var DishInfoDescription = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 3.8rem;\n    color: #858992;\n"], ["\n    font-size: 3.8rem;\n    color: #858992;\n"])));
export var DishInfoPrice = styled(Box)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 4.2rem;  \n    padding-right: 3rem;\n"], ["\n    font-size: 4.2rem;  \n    padding-right: 3rem;\n"])));
export var DishInfoCurency = styled(Box)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    color: var(--btn-primary-color)\n"], ["\n    color: var(--btn-primary-color)\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
