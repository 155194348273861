var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { getCurrencyFormat } from "../utils/CurrencyUtils";
import styled from "styled-components";
import { Box } from "../component/common/FlexItem";
import { userStore } from "../store/user.store";
var CurrencySymbol = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: var(--btn-primary-color)\n"], ["\n    color: var(--btn-primary-color)\n"])));
var Container = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n"], ["\n"])));
export var PriceWithCurrency = React.memo(function (props) {
    var value = props.value, _a = props.prefix, prefix = _a === void 0 ? "" : _a;
    var currency = userStore(function (state) { return state.company.currency; });
    var currencyFormat = getCurrencyFormat(value, currency);
    return React.createElement(Container, null,
        React.createElement("span", null, prefix),
        currencyFormat.placement == "left" && React.createElement(React.Fragment, null,
            React.createElement(CurrencySymbol, null, currencyFormat.currency),
            currencyFormat.value),
        currencyFormat.placement == "right" && React.createElement(React.Fragment, null,
            currencyFormat.value,
            React.createElement(CurrencySymbol, null, currencyFormat.currency)));
});
var templateObject_1, templateObject_2;
