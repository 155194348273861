import { COMPANY_ENDPOINT } from "../constats/ApiEndpoints";
import { userStore } from "../store/user.store";
export function getCompanyModelRequest() {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/mode"),
        method: "GET",
        params: {
            token: token
        }
    };
}
export function updateCompanyModeRequest(mode) {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/mode"),
        method: "PUT",
        data: { mode: mode },
        params: {
            token: token
        }
    };
}
export function getCompanyInfoRequest() {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/info"),
        method: "GET",
        params: {
            token: token
        }
    };
}
export function updateCompanyInfoRequest(data) {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/info"),
        method: "PUT",
        data: data,
        params: {
            token: token
        }
    };
}
export function getCompanyQRSettingsRequest() {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/settings/qr"),
        method: "GET",
        params: {
            token: token
        }
    };
}
export function updateCompanyQRSettingsRequest(data) {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/settings/qr"),
        method: "PUT",
        data: data,
        params: {
            token: token
        }
    };
}
export function getCompanyAreaQRSettingsRequest() {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/settings/areaqr"),
        method: "GET",
        params: {
            token: token
        }
    };
}
export function updateCompanyAreaQRSettingsRequest(data) {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/settings/areaqr"),
        method: "PUT",
        data: data,
        params: {
            token: token
        }
    };
}
export function getCompanyStatusTimersSettingsRequest() {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/settings/status-timers"),
        method: "GET",
        params: {
            token: token
        }
    };
}
export function updateCompanyStatusTimersSettingsRequest(data) {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/settings/status-timers"),
        method: "PUT",
        data: data,
        params: {
            token: token
        }
    };
}
export function getCompanyMemebers() {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/members"),
        params: {
            token: token
        }
    };
}
export function getCompanyMediaRequest() {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/media"),
        params: {
            token: token
        }
    };
}
export function getUploadCompanyMediaRequest(type, file) {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    var formData = new FormData();
    formData.append("media", file);
    formData.append("type", type);
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/media"),
        method: "POST",
        params: {
            token: token
        },
        data: formData
    };
}
export function configureLanguageToCompany(config) {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/configure-languages"),
        method: "POST",
        params: {
            token: token
        },
        data: config
    };
}
export function getCompanyOrderSettings() {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/order-settings"),
        method: "GET",
        params: {
            token: token
        },
    };
}
export function updateCompanyOrderSettings(settings) {
    var companyId = userStore.getState().company.id;
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(COMPANY_ENDPOINT, "/").concat(companyId, "/order-settings"),
        method: "POST",
        params: {
            token: token
        },
        data: settings
    };
}
