var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { CFormFeedback, CInputGroup, CInputGroupText, CTooltip } from "@coreui/react";
import * as React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { langDirection } from "../../../locale/i18next";
import { userStore } from "../../../store/user.store";
import styled from "styled-components";
import { randomString } from "../../../utils/StringUtils";
import { MobileFormTextInput } from "./MobileFormTextInput";
var logger = require("js-logger").get("FormTextInput");
var Group = styled(CInputGroup)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: ", "\n"], ["\n    display: flex;\n    flex-direction: ", "\n"])), function (props) { return props.direction == "rtl" ? "row-reverse" : "row"; });
var FormGroupTextInput = function (props) {
    var control = props.control, name = props.name, defaultValue = props.defaultValue, label = props.label, type = props.type, rules = props.rules, disabled = props.disabled, inputRef = props.inputRef, info = props.info, size = props.size, placeholder = props.placeholder, style = props.style, group = props.group, disableHandleFocus = props.disableHandleFocus;
    var language = userStore(function (state) { return state.language; });
    var _a = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _b = _a.field, onChange = _b.onChange, _c = _b.value, value = _c === void 0 ? defaultValue ? defaultValue : "" : _c, fieldState = _a.fieldState;
    var t = useTranslation().t;
    var inputId = props.id ? props.id : randomString(6);
    //logger.info("Errors", fieldState.error);
    return React.createElement(React.Fragment, null,
        React.createElement(Group, { direction: langDirection[language] },
            info && React.createElement(CTooltip, { content: info },
                React.createElement(MobileFormTextInput, { onChange: onChange, 
                    // onBlur={onBlur}
                    value: value, inputRef: inputRef, id: inputId, type: type, disabled: disabled, invalid: fieldState.invalid, 
                    //valid={fieldState.isDirty && !fieldState.invalid}
                    style: style, size: size, placeholder: placeholder, label: label, disableHandleFocus: disableHandleFocus })),
            !info && React.createElement(MobileFormTextInput, { onChange: onChange, 
                // onBlur={onBlur}
                value: value, inputRef: inputRef, id: inputId, type: type, disabled: disabled, invalid: fieldState.invalid, 
                //valid={fieldState.isDirty && !fieldState.invalid}
                style: style, size: size, label: label, placeholder: placeholder, disableHandleFocus: disableHandleFocus }),
            React.createElement(CInputGroupText, null, group)),
        fieldState.invalid && React.createElement(CFormFeedback, { invalid: true, style: {
                display: "block"
            } },
            fieldState.error.message && fieldState.error.message,
            !fieldState.error.message && t("form.error.".concat(fieldState.error.type.toLowerCase(), ".message"))));
};
export default FormGroupTextInput;
var templateObject_1;
