var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { userStore } from "../../store/user.store";
import { SIGN_IN } from "../../constats/Routes";
import { Navigate } from "react-router-dom";
import cookiesService from "../../service/cookies.service";
import { MO_ADMIN_TOKEN } from "../../constats/Cookies";
import { meRequest } from "../../request/user.request";
import { HttpApiRequest } from "../../service/network.service";
import { useNavigate } from "react-router-dom";
var logger = require("js-logger").get("SignedIn");
import LoadingBox from "./LoadingBox";
var SignedIn = React.memo(function (props) {
    var userState = userStore();
    var _a = React.useState(false), checked = _a[0], setChecked = _a[1];
    var navigate = useNavigate();
    logger.info("XXXXX", "Step 1");
    var getUserInfo = function (token) {
        //console.log("XXXXX Get user info...");
        HttpApiRequest(meRequest(token))
            .promise
            .then(function (res) {
            var _a = res.data, verified = _a.verified, rest = __rest(_a, ["verified"]);
            logger.info("XXXXX", verified);
            userState.signIn(token, rest, verified);
            setChecked(true);
        })
            .catch(function (err) {
            logger.error(err);
            setChecked(true);
        });
    };
    var checkIsLoggedIs = function () {
        var token = cookiesService.getCookie(MO_ADMIN_TOKEN);
        if (!token) {
            setChecked(true);
            return;
        }
        getUserInfo(token);
    };
    React.useEffect(function () {
        checkIsLoggedIs();
    }, []);
    // React.useEffect(() => {
    //     if(checked && !userState.signIn){
    //         navigate(SIGN_IN)
    //     }
    // }, [checked])
    if (!checked) {
        return React.createElement(LoadingBox, null);
    }
    if (!userState.signedIn) {
        return React.createElement(Navigate, { to: SIGN_IN });
    }
    return React.createElement(React.Fragment, null, props.children);
});
export default SignedIn;
export function wrapSignedIn(WrappedComponent) {
    return function (props) { return React.createElement(SignedIn, null,
        React.createElement(WrappedComponent, __assign({}, props))); };
}
