var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody, ModalHeaderTitle } from "../../../component/common/Modal";
import { Button } from "../../../common/Button";
import { isNFCSupported, NFCWrite } from "../../../utils/NFCUtils";
import { useTranslation } from "react-i18next";
import { Box } from "../../../component/common/FlexItem";
import * as nfcLotties from "../../../../assets/json/lottie-nfc.json";
import Lottie from "react-lottie";
var DescContainer = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 1rem 0;\n"], ["\n    margin: 1rem 0;\n"])));
var WorkingContainer = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n"], ["\n"])));
var WorkingText = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: relative;\n    left: -1rem;\n"], ["\n    position: relative;\n    left: -1rem;\n"])));
var StatusContainer = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-top: 2.5rem;\n    font-size: 1.5rem;\n    color: ", ";\n"], ["\n    margin-top: 2.5rem;\n    font-size: 1.5rem;\n    color: ", ";\n"])), function (props) { return props.success ? "green" : "red"; });
var ButtonsContainer = styled(Box)(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
export var NFCWriteButton = React.memo(function (props) {
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    var _b = React.useState(false), busy = _b[0], setBusy = _b[1];
    var _c = React.useState(null), error = _c[0], setError = _c[1];
    var _d = React.useState(null), success = _d[0], setSuccess = _d[1];
    var data = props.data, type = props.type;
    var t = useTranslation().t;
    var writeNFC = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setBusy(true);
                    setSuccess(null);
                    setError(null);
                    setShowModal(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, NFCWrite({
                            records: [{
                                    recordType: type,
                                    data: data
                                }]
                        })];
                case 2:
                    res = _a.sent();
                    // const res = await new Promise((res, rej) => {
                    //     setTimeout(() => {
                    //         res(true);
                    //     }, 2000)
                    // });
                    if (res === true) {
                        setSuccess(true);
                    }
                    else {
                        setSuccess(false);
                        setError(res);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    setSuccess(false);
                    setError(err_1);
                    return [3 /*break*/, 4];
                case 4:
                    setBusy(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return React.createElement(React.Fragment, null,
        isNFCSupported() && React.createElement(Button, { color: "primary", onClick: writeNFC, disabled: busy }, t("burn.nfc.text")),
        showModal && React.createElement(Modal, { visible: true },
            React.createElement(ModalHeader, { onClose: function () { return setShowModal(false); } },
                React.createElement(ModalHeaderTitle, null, t("nfc.writing.title"))),
            React.createElement(ModalBody, null,
                React.createElement(Box, { flexDirection: "column" },
                    React.createElement(Box, { flex: 1, flexDirection: "column" },
                        busy && React.createElement(WorkingContainer, { justify: "center", align: "center", flex: "none", flexDirection: "column" },
                            React.createElement(Box, null,
                                React.createElement(Lottie, { options: {
                                        loop: true,
                                        autoplay: true,
                                        animationData: nfcLotties
                                    }, width: "7rem", height: "7rem" }),
                                React.createElement(WorkingText, null, t("nfc.writing.text"))),
                            React.createElement(DescContainer, null, t("nfc.writing.description"))),
                        !busy && (success !== null) && React.createElement(StatusContainer, { success: success, justify: "center", align: "center", flex: "none" },
                            success && t("nfc.writing.success.text"),
                            !success && t("nfc.writing.error.text", {
                                error: error.message
                            }))),
                    React.createElement(ButtonsContainer, { flex: "none", justify: "center" },
                        React.createElement(Button, { onClick: function () { setShowModal(false); } }, t("cancel.text")))))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
