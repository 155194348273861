var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { CFormFeedback } from "@coreui/react";
import { useController } from "react-hook-form";
var logger = require("js-logger").get("FormTextInput");
import { useTranslation } from "react-i18next";
import styled from "styled-components";
export var Color = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex: 0.15;\n    height: 40px;\n    border: ", " solid 2px;\n    background-color: ", ";\n    border-radius: 4px;\n"], ["\n    flex: 0.15;\n    height: 40px;\n    border: ", " solid 2px;\n    background-color: ", ";\n    border-radius: 4px;\n"])), function (props) { return props.active ? "#4a4b45" : props.color; }, function (props) { return props.color; });
var FormColorsRadio = function (props) {
    var control = props.control, name = props.name, defaultValue = props.defaultValue, label = props.label, rules = props.rules, info = props.info, colors = props.colors;
    var _a = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _b = _a.field, onChange = _b.onChange, value = _b.value, fieldState = _a.fieldState;
    var t = useTranslation().t;
    return React.createElement(React.Fragment, null,
        colors.map(function (color) { return React.createElement(Color, { key: color, color: color, active: value == color, onClick: function (e) {
                onChange(color);
            } }); }),
        fieldState.invalid && React.createElement(CFormFeedback, { invalid: true },
            fieldState.error.message && fieldState.error.message,
            !fieldState.error.message && t("form.error.".concat(fieldState.error.type.toLowerCase(), ".message"))));
};
export default FormColorsRadio;
var templateObject_1;
