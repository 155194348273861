import { STATION_ENDPOINT } from "../constats/ApiEndpoints";
import { userStore } from "../store/user.store";
export function getStationsRequest() {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(STATION_ENDPOINT),
        method: "GET",
        params: {
            token: token
        }
    };
}
export function createStationRequest(name) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(STATION_ENDPOINT),
        method: "POST",
        params: {
            token: token
        },
        data: {
            name: name
        }
    };
}
export function updateStationRequest(id, name) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(STATION_ENDPOINT, "/").concat(id),
        method: "PUT",
        params: {
            token: token
        },
        data: {
            name: name
        }
    };
}
export function deleteStationRequest(id) {
    var token = userStore.getState().token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(STATION_ENDPOINT, "/").concat(id),
        method: "DELETE",
        params: {
            token: token
        }
    };
}
