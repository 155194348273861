var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import volumeOn from "../../assets/svg/volume-on.svg";
import volumeOff from "../../assets/svg/volume-off.svg";
import { Touchable } from "../common/Touchable";
var Icon = styled(Touchable)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 2em;\n    height: 2rem;\n    background: url(\"", "\") no-repeat;\n    background-size: 100%;\n"], ["\n    width: 2em;\n    height: 2rem;\n    background: url(\"", "\") no-repeat;\n    background-size: 100%;\n"])), function (props) { return props.mute ? volumeOff : volumeOn; });
export var Volume = React.memo(function (props) {
    // const userId = userStore(state => state.user.id);
    // const volumeKey = `${userId}_volume`;
    // const [volume, setVolume] = useLocalStorageItem(volumeKey, "on");
    // const toggleVolume = (volume: string) => {
    //     logger.info("Volume Toggle", volume)
    //     setVolume(volume === "on" ? "off" : "on");
    // }
    // logger.info("Volume Render", volume);
    var mute = props.mute, onToggle = props.onToggle;
    return React.createElement(Icon, { mute: mute, onTap: onToggle });
    //const [] = React.useState(true);
});
var templateObject_1;
