import * as React from "react";
import { Box } from "../../../component/common/FlexItem";
import { CFormInput } from "@coreui/react";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { useTranslation } from "react-i18next";
import { randomNumber } from "../../../utils/NumberUtils";
export var UserDeleteConfirm = React.memo(function (props) {
    var t = useTranslation().t;
    var onClose = props.onClose, member = props.member, onConfirm = props.onConfirm;
    var _a = React.useState(0), sum = _a[0], setSum = _a[1];
    var _b = React.useState([randomNumber(1, 10), randomNumber(1, 10)]), digits = _b[0], setDigits = _b[1];
    var _c = React.useState(true), disabled = _c[0], setDisabled = _c[1];
    React.useEffect(function () {
        setDisabled(!(sum === (digits[0] + digits[1])));
    }, [sum]);
    return React.createElement(ConfirmDialog, { title: t("user.delete.confirm.title"), onCancel: onClose, onConfirm: function () {
            console.log("On Confirm", member);
            onConfirm(member);
        }, disabled: disabled },
        React.createElement(Box, { align: "center", gap: "0.5rem" },
            React.createElement(Box, { flex: "none" }, t("user.delete.confirm.text", {
                n1: digits[0],
                n2: digits[1]
            })),
            React.createElement(Box, { flex: 1 },
                React.createElement(CFormInput, { type: "number", onChange: function (e) { return setSum(parseInt(e.target.value)); } }))));
});
