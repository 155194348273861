var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import styled from "styled-components";
import { Touchable } from "../common/Touchable";
import CheckBoxIconOn from "../../assets/icon/checkbox/checkbox-on.svg";
import CheckBoxIconOff from "../../assets/icon/checkbox/checkbox-off.svg";
var logger = require("js-logger").get("Checkbox");
var CheckBoxIcon = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 22px;\n    height: 22px;\n    background: url(\"", "\") no-repeat;\n    background-size: 100%;\n    & path {\n        fill: var(--btn-primary-color)\n    }\n"], ["\n    width: 22px;\n    height: 22px;\n    background: url(\"", "\") no-repeat;\n    background-size: 100%;\n    & path {\n        fill: var(--btn-primary-color)\n    }\n"
    // const CheckboxIconOff = styled(CheckboxIcon)`
    //     background: url(${offSvg}) no-repeat;
    // `;
    // const CheckboxIconOn = styled(CheckboxIcon)`
    //     background: url(${onSvg}) no-repeat;
    // `;
])), function (props) { return props.checked ? CheckBoxIconOn : CheckBoxIconOff; });
// const CheckboxIconOff = styled(CheckboxIcon)`
//     background: url(${offSvg}) no-repeat;
// `;
// const CheckboxIconOn = styled(CheckboxIcon)`
//     background: url(${onSvg}) no-repeat;
// `;
var CheckboxLabel = styled(Touchable)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: ", ";        \n    color: ", ";    \n    display: flex;\n    align-items: center;            \n"], ["\n    font-size: ", ";        \n    color: ", ";    \n    display: flex;\n    align-items: center;            \n"])), function (props) { return props.size == "default" ? "16px" : "14px"; }, function (props) { return props.size == "default" ? "#111719" : "#858992"; });
var Input = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 1px;\n    height: 1px;\n"], ["\n    width: 1px;\n    height: 1px;\n"])));
var LabelText = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-left: 1rem;    \n"], ["\n    margin-left: 1rem;    \n"])));
var Checkbox = /** @class */ (function (_super) {
    __extends(Checkbox, _super);
    function Checkbox(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            checked: false
        };
        _this.handleChange = function () {
            var onChange = _this.props.onChange;
            var checked = !_this.state.checked;
            // this.setState({
            //     checked
            // }, () => {
            //     if(onChange){
            //         onChange(checked);
            //     }
            // })
        };
        if (undefined !== _this.props.checked) {
            _this.state.checked = _this.props.checked;
        }
        return _this;
    }
    Checkbox.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (prevProps.checked !== this.props.checked) {
            this.setState({
                checked: this.props.checked
            });
        }
    };
    Checkbox.prototype.render = function () {
        var checked = this.state.checked;
        var label = this.props.label;
        return React.createElement(CheckboxLabel, { onTap: this.handleChange },
            React.createElement(CheckBoxIcon, { checked: checked }),
            React.createElement(Input, { type: "checkbox" }),
            React.createElement(LabelText, { checked: checked }, label));
    };
    return Checkbox;
}(React.PureComponent));
export { Checkbox };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
