var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useForm } from "react-hook-form";
import FormTextInput from "../component/common/form/FormTextInput";
import styled from "styled-components";
import { Box } from "../component/common/FlexItem";
import FormSelectInput from "../component/common/form/FormSelectInput";
import { Button } from "../common/Button";
import { useTranslation } from "react-i18next";
import { CForm } from "@coreui/react";
var logger = require("js-logger").get("device.form");
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex-direction: column;\n"], ["\n    flex-direction: column;\n"])));
var Buttons = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-top: 1rem;\n    display: flex;\n    gap: 1rem;\n    justify-content: center;\n\n"], ["\n    margin-top: 1rem;\n    display: flex;\n    gap: 1rem;\n    justify-content: center;\n\n"])));
export var DeviceForm = React.memo(function (props) {
    var roles = props.roles, onSave = props.onSave, onCancel = props.onCancel, device = props.device;
    var t = useTranslation().t;
    logger.info("Device", device);
    var _a = useForm({
        mode: "onChange",
        defaultValues: device
    }), control = _a.control, handleSubmit = _a.handleSubmit, formState = _a.formState;
    var options = roles.map(function (role) { return ({ label: role.name, value: role.id }); });
    // options.unshift({
    //     label: "Select Role",
    //     value: null
    // });
    return React.createElement(Container, null,
        React.createElement(CForm, { onSubmit: handleSubmit(onSave) },
            React.createElement(FormTextInput, { control: control, name: "username", label: t("device.form.name.label"), rules: {
                    minLength: 4,
                    required: true
                } }),
            React.createElement(FormSelectInput, { placeholder: "Select Role", label: t("device.form.role.label"), control: control, name: "role_id", options: options, rules: {
                    required: true,
                    validate: function (val) {
                        //console.log("Validate", val );
                        return val != null && val != "Select Role";
                    }
                } }),
            React.createElement(Buttons, null,
                React.createElement(Button, { onClick: onCancel }, t("cancel.text")),
                React.createElement(Button, { type: "submit", color: "primary", disabled: !formState.isDirty || !formState.isValid }, t("save.text")))));
});
var templateObject_1, templateObject_2;
