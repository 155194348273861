var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Box } from "../../../component/common/FlexItem";
import styled from "styled-components";
import { QRScannerComponent } from "../../../common/QrScanner";
import { Button } from "../../../common/Button";
import { useTranslation } from "react-i18next";
import { HttpApiRequest } from "../../../service/network.service";
import { canUseCompanyAreaPlaceAliasId } from "../../../request/area.request";
import LoadingBox from "../../../component/common/LoadingBox";
var logger = require("js-logger").get("LinkPlace");
import Scrollbars from "react-custom-scrollbars-2";
import { NFCWriteButton } from "./nfc.write";
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 100%;    \n    width: 100%;\n    flex-direction: column;\n    & section {\n        width: 100%;\n    }\n"], ["\n    height: 100%;    \n    width: 100%;\n    flex-direction: column;\n    & section {\n        width: 100%;\n    }\n"])));
var TextContainer = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 1rem 0; \n    justify-content: center;\n"], ["\n    margin: 1rem 0; \n    justify-content: center;\n"])));
var TextErrorContainer = styled(TextContainer)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    color: red\n"], ["\n    color: red\n"])));
var DescriptionContainer = styled(TextContainer)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding:0 1.7rem;\n    \n"], ["\n    padding:0 1.7rem;\n    \n"])));
var Buttons = styled(Box)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    justify-content: center;\n    gap: 0.3rem;\n"], ["\n    justify-content: center;\n    gap: 0.3rem;\n"])));
export var LinkPlace = React.memo(function (props) {
    var onCancel = props.onCancel, onSave = props.onSave, area_id = props.area_id, place_id = props.place_id;
    var _a = React.useState(""), id = _a[0], setId = _a[1];
    var _b = React.useState(false), busy = _b[0], setBusy = _b[1];
    var _c = React.useState(false), error = _c[0], setError = _c[1];
    var _d = React.useState(false), invalid = _d[0], setInvalid = _d[1];
    var t = useTranslation().t;
    var handleScan = function (data) {
        setInvalid(false);
        if (data.indexOf("g.ordish.app") === -1) {
            setInvalid(true);
            return;
        }
        setId(data.replace("https://", "").replace("http://", "").split(".")[0]);
    };
    React.useEffect(function () {
        if (id) {
            setInvalid(false);
            setError(null);
            setBusy(true);
            HttpApiRequest(canUseCompanyAreaPlaceAliasId(area_id, place_id, id))
                .promise
                .catch(function (err) {
                setError(true);
            })
                .finally(function () {
                setBusy(false);
            });
        }
    }, [id]);
    return React.createElement(Container, null,
        React.createElement(Scrollbars, null,
            React.createElement(QRScannerComponent, { onScanSuccess: handleScan }),
            React.createElement(DescriptionContainer, null, t("place.link.description")),
            busy && React.createElement(LoadingBox, null),
            !busy && React.createElement(React.Fragment, null,
                id && !error && React.createElement(TextContainer, { flex: "none" }, t("place.link.success.text", {
                    id: id
                })),
                id && error && React.createElement(TextErrorContainer, { flex: "none" }, t("place.link.error.text", {
                    id: id
                })),
                id && React.createElement(Buttons, { flex: "none" },
                    React.createElement(Button, { onClick: onCancel }, t("cancel.text")),
                    React.createElement(NFCWriteButton, { data: "https://".concat(id, ".g.ordish.app"), type: "url" }),
                    React.createElement(Button, { color: "primary", onClick: function () { return onSave(id); }, disabled: busy || error }, t("assign.text"))),
                invalid && React.createElement(TextErrorContainer, { flex: "none" }, t("place.link.invalid.text", {
                    id: id
                })))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
