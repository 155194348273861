import * as React from "react";
import { CToaster, CToast, CToastBody, CToastHeader } from "@coreui/react";
import { notificationStore } from "../../store/notifications.store";
import { randomString } from "../../utils/StringUtils";
var logger = require("js-logger").get("NotificationContainer");
export function createNotification(info) {
    return Object.assign({}, {
        id: randomString(10)
    }, info);
}
var createToast = function (props) {
    var notification = props.notification, onClose = props.onClose, onShow = props.onShow;
    return React.createElement(CToast, { visible: true, autohide: notification.autohide, color: notification.type },
        notification.title && React.createElement(CToastHeader, { closeButton: notification.closable }, notification.title),
        React.createElement(CToastBody, null, notification.message));
};
var NotificationContainer = React.memo(function (props) {
    var _a = React.useState(), notification = _a[0], setNotification = _a[1];
    var nStore = notificationStore();
    var checkToast = function () {
        logger.info("Check Toast");
        var notifications = nStore.notifications;
        if (notifications.length) {
            var notification_1 = notifications[0];
            nStore.removeNotification(notification_1.id);
            setNotification(createToast({
                notification: notification_1,
                onClose: function () {
                    logger.info("onClose", notification_1);
                    //checkToast()
                }
            }));
        }
    };
    //React.useEffect(checkToast, []);
    React.useEffect(checkToast, [nStore.notifications]);
    return React.createElement(React.Fragment, null,
        React.createElement(CToaster, { push: notification, placement: "top-end" }));
});
export default NotificationContainer;
