import { userStore } from "../store/user.store";
export function getCompanyAreasRequest() {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area"),
        params: {
            token: token
        }
    };
}
export function sortCompanyAreasRequest(data) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area/sort"),
        method: "POST",
        data: data,
        params: {
            token: token
        }
    };
}
export function deleteCompanyAreaRequest(id) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area/").concat(id),
        method: "DELETE",
        params: {
            token: token
        }
    };
}
export function getMenusWithCategories() {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area/menu-with-categories"),
        params: {
            token: token
        }
    };
}
export function createCompanyAreaRequest(data) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area"),
        method: 'POST',
        data: data,
        params: {
            token: token
        }
    };
}
export function updateCompanyAreaRequest(id, data) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area/").concat(id),
        method: 'PUT',
        data: data,
        params: {
            token: token
        }
    };
}
export function getCompanyAreaRequest(area_id) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area/").concat(area_id),
        method: 'GET',
        params: {
            token: token
        }
    };
}
export function activateAreaRequest(area_id) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area/").concat(area_id, "/activate"),
        method: 'PUT',
        params: {
            token: token
        }
    };
}
export function deactivateAreaRequest(area_id) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area/").concat(area_id, "/deactivate"),
        method: 'PUT',
        params: {
            token: token
        }
    };
}
export function getCompanyAreaPlacesRequest(area_id) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area/").concat(area_id, "/place"),
        method: 'GET',
        params: {
            token: token
        }
    };
}
export function createCompanyAreaPlaceRequest(area_id, data) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area/").concat(area_id, "/place"),
        method: 'POST',
        data: data,
        params: {
            token: token
        }
    };
}
export function deleteCompanyAreaPlaceRequest(area_id, id) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area/").concat(area_id, "/place/").concat(id),
        method: 'DELETE',
        params: {
            token: token
        }
    };
}
///
export function canUseCompanyAreaPlaceAliasId(area_id, id, aliasId) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area/").concat(area_id, "/place/").concat(id, "/alias/").concat(aliasId),
        method: 'GET',
        data: {
            aliasId: aliasId
        },
        params: {
            token: token
        }
    };
}
export function addCompanyAreaPlaceAliasId(area_id, id, aliasId) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area/").concat(area_id, "/place/").concat(id, "/alias"),
        method: 'POST',
        data: {
            aliasId: aliasId
        },
        params: {
            token: token
        }
    };
}
export function removeCompanyAreaPlaceAliasId(area_id, id) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/area/").concat(area_id, "/place/").concat(id, "/alias"),
        method: 'DELETE',
        params: {
            token: token
        }
    };
}
