var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import FlexItem from "../common/FlexItem";
import { CForm } from "@coreui/react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import FormTextInput from "../common/form/FormTextInput";
import SocialButton from "../common/SocialButton";
import { useTranslation } from "react-i18next";
import { getHostWithProtocol } from "../../utils/URLUtils";
import { useHttpRequestHandler } from "../../service/network.service";
import FormPhoneInput from "../common/form/FormPhoneInput";
import { useParams } from "react-router-dom";
import { getInviteInfo } from "../../request/user.request";
import LoadingBox from "../common/LoadingBox";
var logger = require("js-logger").get("InviteScreen");
var VSpacer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    height: 7px\n"], ["\n    width: 100%;\n    height: 7px\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    // flex: 1;\n    flex-direction: column;\n\n"], ["\n    display: flex;\n    // flex: 1;\n    flex-direction: column;\n\n"])));
var Buttons = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["    \n    margin-top: 10px;\n"], ["    \n    margin-top: 10px;\n"])));
var InviteScreen = React.memo(function () {
    var params = useParams();
    var invateStatus = useHttpRequestHandler(getInviteInfo(params.id));
    var _a = useForm({
        mode: "onChange",
    }), formState = _a.formState, setValue = _a.setValue, getValues = _a.getValues, watch = _a.watch, control = _a.control;
    var t = useTranslation().t;
    var googleLogin = function () {
        var baseURL = "".concat(getHostWithProtocol()).concat(BASENAME == "/" ? "" : BASENAME);
        //logger.info("baseURL", baseURL);
        var backURL = "".concat(baseURL, "/sign-up/invite-response");
        var phone = getValues(["phone"])[0];
        logger.info("phone", phone);
        ;
        var url = "".concat(AUTH_BASE_URL, "/google/oauth/invite/sign-up?phone=").concat(encodeURIComponent("".concat(phone)), "&backURL=").concat(encodeURIComponent(backURL), "&invite_id=").concat(params.id);
        //logger.info("location", url);
        document.location.href = url;
    };
    var facebookLogin = function () {
        var baseURL = "".concat(getHostWithProtocol()).concat(BASENAME == "/" ? "" : BASENAME);
        //logger.info("baseURL", baseURL);
        var backURL = "".concat(baseURL, "/sign-up/invite-response");
        var phone = getValues(["company", "domain", "phone", "phoneNumber"])[0];
        var url = "".concat(AUTH_BASE_URL, "/facebook/oauth/invite/sign-up?phone=").concat(encodeURIComponent("".concat(phone)), "&backURL=").concat(encodeURIComponent(backURL), "&invite_id=").concat(params.id);
        //logger.info("phone", phone);
        document.location.href = url;
    };
    if (invateStatus.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    var result = (invateStatus.result && invateStatus.result) || {};
    return React.createElement(FlexItem, { flex: 1, align: "center", justify: "center" },
        React.createElement(Container, null,
            React.createElement(CForm, null,
                React.createElement(FormTextInput, { control: control, name: "company", label: t("sign.up.company.label"), defaultValue: result.company, disabled: true }),
                React.createElement(VSpacer, null),
                React.createElement(FormPhoneInput, { label: "Phone", name: "phone", control: control, rules: {
                        required: true,
                        minLength: 4
                    }, info: t("sign.up.phone.description") }),
                React.createElement(Buttons, null,
                    React.createElement(SocialButton, { disabled: !formState.isDirty || !formState.isValid, icon: "google", onClick: googleLogin, text: t("sign.up.google.text") }),
                    React.createElement(SocialButton, { disabled: !formState.isDirty || !formState.isValid, icon: "facebook", onClick: facebookLogin, text: t("sign.up.facebook.text") })))));
});
export default InviteScreen;
var templateObject_1, templateObject_2, templateObject_3;
