var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import FormInput from "../../component/common/form/FormTextInput";
import FormSelectInput from "../../component/common/form/FormSelectInput";
import { useForm } from "react-hook-form";
import { Box } from "../../component/common/FlexItem";
import styled from "styled-components";
import { Button } from "../../common/Button";
import { Trans, useTranslation } from "react-i18next";
import FormCheckboxInput from "../../component/common/form/FormCheckboxInput";
import { sortBy } from "underscore";
var Form = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex: 1;\n    flex-direction: column;\n    justify-content: flex-end;\n"], ["\n    flex: 1;\n    flex-direction: column;\n    justify-content: flex-end;\n"])));
var Buttons = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-top: 1rem;\n"], ["\n    margin-top: 1rem;\n"])));
var Info = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin-top: 1rem;\n    flex-direction: column;\n    gap: 1rem\n"], ["\n    margin-top: 1rem;\n    flex-direction: column;\n    gap: 1rem\n"])));
export var PaymentMethodForm = React.memo(function (props) {
    var onSave = props.onSave, onCancel = props.onCancel, config = props.config, data = props.data;
    var t = useTranslation().t;
    var defaultValues = {};
    if (data) {
        defaultValues.field = {};
        data.fields.forEach(function (field) {
            defaultValues.field[field.field_id] = field.value;
        });
        defaultValues.enabled = data.enabled;
    }
    var _a = useForm({
        mode: "onChange",
        defaultValues: defaultValues
    }), control = _a.control, handleSubmit = _a.handleSubmit, formState = _a.formState;
    var isValid = formState.isValid;
    var isDirty = formState.isDirty;
    return React.createElement(Box, null,
        React.createElement(Form, { onSubmit: handleSubmit(onSave) },
            sortBy(config.fields, "sort").map(function (field) {
                switch (field.type) {
                    case "text":
                    case "string":
                        return React.createElement(FormInput, { key: field.id, label: t("payment.method.".concat(config.name, ".field.").concat(field.name)), control: control, name: "field.".concat(field.id) });
                    case "number":
                        return React.createElement(FormInput, { key: field.id, label: field.label, control: control, name: "field.".concat(field.id) });
                    case "select":
                        return React.createElement(FormSelectInput, { key: field.id, label: field.label, control: control, name: "field.".concat(field.id), options: field.options.map(function (itm) { return ({
                                value: itm.id,
                                label: itm.name,
                            }); }) });
                }
            }),
            data && React.createElement(FormCheckboxInput, { name: "enabled", control: control, label: t("enabled.text") }),
            React.createElement(Buttons, { gap: "0.5rem" },
                React.createElement(Button, { onClick: onCancel }, t("cancel.text")),
                React.createElement(Button, { color: "primary", disabled: !isDirty || !isValid, type: "submit" }, t("save.text"))),
            React.createElement(Info, null,
                React.createElement(React.Fragment, null,
                    React.createElement("a", { href: t("payment.method.".concat(config.name, ".link")) }, t("payment.method.".concat(config.name, ".link"))))),
            React.createElement(React.Fragment, null,
                React.createElement(Trans, { i18nKey: "payment.method.".concat(config.name, ".text") }))));
});
var templateObject_1, templateObject_2, templateObject_3;
