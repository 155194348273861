import * as React from "react";
import { Box } from "../common/FlexItem";
import Scrollbars from "react-custom-scrollbars-2";
import { SettingsFormPageLayout } from ".";
import { useTranslation } from "react-i18next";
import { FormVerticalContainer, FormHorizontalContainer } from ".";
import FormTextInput from "../common/form/FormTextInput";
import { HttpApiRequest } from "../../service/network.service";
import { getCompanyStatusTimersSettingsRequest, updateCompanyStatusTimersSettingsRequest } from "../../request/company.request";
import LoadingBox from "../common/LoadingBox";
var logger = require("js-logger").get("StatusTimers");
export var StatusTimers = React.memo(function (props) {
    var t = useTranslation().t;
    var _a = React.useState(null), statusTimers = _a[0], setStatusTimers = _a[1];
    var _b = React.useState(true), busy = _b[0], setBusy = _b[1];
    var loadData = function () {
        setBusy(true);
        HttpApiRequest(getCompanyStatusTimersSettingsRequest())
            .promise
            .then(function (res) {
            setStatusTimers(res.data);
        })
            .catch(function (err) {
            logger.error(err);
        })
            .finally(function () {
            setBusy(false);
        });
    };
    React.useEffect(loadData, []);
    var saveValues = function (values) {
        var data = {};
        Object.keys(values).forEach(function (key) {
            if (values[key]) {
                data[key] = parseInt(values[key]);
            }
        });
        HttpApiRequest(updateCompanyStatusTimersSettingsRequest(data))
            .promise
            .then(loadData)
            .catch(function (err) {
            logger.error(err);
        });
        //logger.info("Values", data);
    };
    if (busy) {
        return React.createElement(LoadingBox, null);
    }
    return React.createElement(Box, { flex: 1 },
        React.createElement(Scrollbars, { style: {
                height: "100%"
            } },
            React.createElement(SettingsFormPageLayout, { onSubmit: saveValues, title: t("company.settings.legal.info.text"), formConfig: {
                    mode: 'onChange',
                } }, function (useFormReturn) { return React.createElement(FormVerticalContainer, null,
                React.createElement(FormHorizontalContainer, null,
                    React.createElement(FormTextInput, { label: t("settings.status.timers.new.label"), name: "new", control: useFormReturn.control, defaultValue: "".concat((statusTimers === null || statusTimers === void 0 ? void 0 : statusTimers.new) || "") })),
                React.createElement(FormHorizontalContainer, null,
                    React.createElement(FormTextInput, { label: t("settings.status.timers.in_progress.label"), name: "in-progress", control: useFormReturn.control, defaultValue: "".concat(statusTimers["in-progress"] || "") })),
                React.createElement(FormHorizontalContainer, null,
                    React.createElement(FormTextInput, { label: t("settings.status.timers.ready.label"), name: "ready", control: useFormReturn.control, defaultValue: "".concat((statusTimers === null || statusTimers === void 0 ? void 0 : statusTimers.ready) || "") }))); })));
});
