import { MENU_ENDPOINT } from "../constats/ApiEndpoints";
import { userStore } from "../store/user.store";
export function getMenuOptionsRequest(menuId, categoryId) {
    var token = userStore.getState().token;
    var companyId = userStore.getState().company.id;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/c/").concat(categoryId, "/options?token=").concat(token),
    };
}
export function getMenuOptionRequest(menuId, categoryId, id) {
    var token = userStore.getState().token;
    var companyId = userStore.getState().company.id;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/c/").concat(categoryId, "/options/").concat(id, "?token=").concat(token),
    };
}
export function sortMenuOptionRequest(menuId, categoryId, data) {
    var token = userStore.getState().token;
    var companyId = userStore.getState().company.id;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/c/").concat(categoryId, "/options/sort?token=").concat(token),
        method: "POST",
        data: data
    };
}
export function createMenuOptionRequest(menuId, categoryId, data) {
    var token = userStore.getState().token;
    var companyId = userStore.getState().company.id;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/c/").concat(categoryId, "/options?token=").concat(token),
        method: "POST",
        data: data
    };
}
export function updateMenuOptionRequest(menuId, categoryId, id, data) {
    var token = userStore.getState().token;
    var companyId = userStore.getState().company.id;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/c/").concat(categoryId, "/options/").concat(id, "?token=").concat(token),
        method: "PUT",
        data: data
    };
}
export function deleteMenuOptionRequest(menuId, categoryId, id) {
    var token = userStore.getState().token;
    var companyId = userStore.getState().company.id;
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(MENU_ENDPOINT, "/").concat(menuId, "/c/").concat(categoryId, "/options/").concat(id, "?token=").concat(token),
        method: "DELETE"
    };
}
