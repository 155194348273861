import { LOCAL_ENDPOINT } from "../constats/ApiEndpoints";
export function canUseDomainRequest(domain) {
    return {
        url: "".concat(AUTH_BASE_URL, "/").concat(LOCAL_ENDPOINT, "/can-use-domain"),
        method: "GET",
        params: {
            domain: domain
        }
    };
}
export function canUseCompanyNameRequest(company) {
    return {
        url: "".concat(AUTH_BASE_URL, "/").concat(LOCAL_ENDPOINT, "/can-use-company"),
        method: "GET",
        params: {
            company: company
        }
    };
}
export function checkCompanyRequest() {
    var params = {};
    if (DEBUG_COMPANY) {
        params["company"] = DEBUG_COMPANY;
    }
    return {
        url: "".concat(AUTH_BASE_URL, "/").concat(LOCAL_ENDPOINT, "/check"),
        method: "GET",
        params: params
    };
}
