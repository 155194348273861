var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { Box } from "./FlexItem";
import { Portal } from "./Portal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong, faPlus, faRightLong } from "@fortawesome/free-solid-svg-icons";
// import { CButton } from "@coreui/react";
import { userStore } from "../../store/user.store";
import { langDirection } from "../../locale/i18next";
import { Button } from "../../common/Button";
var Overlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: #000;\n    opacity: 0.5;\n    z-index: 100;\n"], ["\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: #000;\n    opacity: 0.5;\n    z-index: 100;\n"])));
var Container = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;    \n    z-index: 101;\n"], ["\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;    \n    z-index: 101;\n"])));
var Content = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 1;\n    margin: ", ";\n    padding: 0 1rem;\n    background-color: #FBFBFB;\n    border-top-left-radius: ", ";\n    border-top-right-radius: ", ";\n"], ["\n    flex: 1;\n    margin: ", ";\n    padding: 0 1rem;\n    background-color: #FBFBFB;\n    border-top-left-radius: ", ";\n    border-top-right-radius: ", ";\n"])), function (props) { return props.fullscreen ? "0" : "9rem 0 0 0"; }, function (props) { return props.noBorderRadius ? "0" : "1.8rem"; }, function (props) { return props.noBorderRadius ? "0" : "1.8rem"; });
var Header = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: 1rem 0;\n    border-bottom: #e2e2e2 solid 1px;\n"], ["\n    padding: 1rem 0;\n    border-bottom: #e2e2e2 solid 1px;\n"])));
export var ModalHeaderTitle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 1.5rem;\n    color: #111719;\n    text-align: center\n"], ["\n    font-size: 1.5rem;\n    color: #111719;\n    text-align: center\n"])));
var HeaderSymbol = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 1.5rem;\n    color: #858992;\n    font-family: Arial;\n"], ["\n    font-size: 1.5rem;\n    color: #858992;\n    font-family: Arial;\n"])));
export var BackButton = React.memo(function (props) {
    var onClick = props.onClick;
    var language = userStore(function (state) { return state.language; });
    return React.createElement(Button, { color: "link", onClick: onClick },
        React.createElement(FontAwesomeIcon, { icon: langDirection[language] === "rtl" ? faRightLong : faLeftLong, size: "lg", color: "#858992" }));
});
export var ModalHeader = React.memo(function (props) {
    var children = props.children, onClose = props.onClose, onAdd = props.onAdd, rightButton = props.rightButton;
    return React.createElement(Header, { flex: "none" },
        onClose && React.createElement(Box, { flex: 0.2, align: "center" },
            React.createElement(BackButton, { onClick: onClose })),
        React.createElement(Box, { flex: 1, justify: "center" }, children),
        (onAdd || onClose || rightButton) && React.createElement(Box, { flex: 0.2, justify: "flex-end" },
            (onAdd && !rightButton) && React.createElement(Button, { color: "link", onClick: onAdd },
                React.createElement(FontAwesomeIcon, { icon: faPlus, size: "lg", color: "#858992" })),
            (!onAdd && rightButton) && rightButton));
});
var Body = styled(Box)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    padding: 1rem 0;\n"], ["\n    padding: 1rem 0;\n"])));
export var ModalBody = React.memo(function (props) {
    var children = props.children;
    return React.createElement(Body, null, children);
});
export var Modal = React.memo(function (props) {
    var onClose = props.onClose, visible = props.visible, hideOverlay = props.hideOverlay, noBorderRadius = props.noBorderRadius;
    if (!visible) {
        return null;
    }
    return React.createElement(Portal, null,
        !hideOverlay && React.createElement(Overlay, { onClick: onClose }),
        React.createElement(Container, null,
            React.createElement(Content, { flexDirection: "column", fullscreen: props.fullscreen, noBorderRadius: noBorderRadius }, props.children)));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
