import * as React from "react";
import { Box } from "./FlexItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
export var SortItemLayout = React.memo(function (props) {
    var children = props.children, _a = props.dragHandleClassName, dragHandleClassName = _a === void 0 ? "drag-handle" : _a;
    return React.createElement(Box, { align: "center", gap: "5px", flex: 1 },
        React.createElement(Box, { className: dragHandleClassName, flex: "none" },
            React.createElement(FontAwesomeIcon, { icon: faGripVertical, size: "1x" })),
        React.createElement(Box, null, children));
});
