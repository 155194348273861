var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Section } from "../common/Section";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Box } from "../common/FlexItem";
import Scrollbars from "react-custom-scrollbars-2";
import { HttpApiRequest, useHttpRequesPrepareHandler } from "../../service/network.service";
import { getTipsRequest, updateTipsRequest } from "../../request/tips.request";
import { CFormSwitch } from "@coreui/react";
import { Button } from "../../common/Button";
import LoadingBox from "../common/LoadingBox";
import { MobileFormTextInput } from "../common/form/MobileFormTextInput";
var logger = require("js-logger").get("tips");
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 1rem;\n    flex: 1;\n    flex-direction: column;\n    height: 100%;\n    // width: 100%;\n"], ["\n    padding: 1rem;\n    flex: 1;\n    flex-direction: column;\n    height: 100%;\n    // width: 100%;\n"])));
var ItemsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-top: 20px;\n"], ["\n    margin-top: 20px;\n"])));
var UserInputText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: 1rem;\n    width: 170px;\n"], ["\n    font-size: 1rem;\n    width: 170px;\n"])));
var TipFormItem = React.memo(function (props) {
    var t = useTranslation().t;
    var item = props.item, onChange = props.onChange, globalActive = props.globalActive;
    var userInput = item.userInput;
    return React.createElement(Box, { gap: "10px", align: "center", style: {
            margin: "5px 0"
        } },
        !userInput && React.createElement(MobileFormTextInput, { label: t("tips.percents.label"), value: item.percent, onChange: function (e) {
                var percent = parseInt(e.target.value);
                if (percent > 0) {
                    item.percent = percent;
                }
                onChange(item);
            }, style: {
                flex: 0,
                width: "100px"
            } }),
        userInput && React.createElement(UserInputText, null, "User Input"),
        React.createElement(CFormSwitch, { disabled: !globalActive, label: t("page.company.tips.enable.label"), checked: item.enabled, onChange: function (e) {
                item.enabled = !item.enabled;
                onChange(item);
            } }));
});
export var TipsPage = React.memo(function () {
    var t = useTranslation().t;
    var _a = React.useState([]), tips = _a[0], setTips = _a[1];
    var _b = React.useState(false), active = _b[0], setActive = _b[1];
    var _c = React.useState(false), dirty = _c[0], setDirty = _c[1];
    var tipsState = useHttpRequesPrepareHandler();
    var loadTips = React.useCallback(function () {
        tipsState.run(getTipsRequest());
    }, []);
    var updateTips = function () {
        HttpApiRequest(updateTipsRequest({
            active: active,
            tips: tips
        })).promise.then(loadTips)
            .catch(function (err) {
            logger.error(err);
        });
    };
    React.useEffect(function () {
        loadTips();
    }, []);
    React.useEffect(function () {
        var _a, _b;
        if (tipsState.executed && (!tipsState.result || !((_b = (_a = tipsState === null || tipsState === void 0 ? void 0 : tipsState.result) === null || _a === void 0 ? void 0 : _a.tips) === null || _b === void 0 ? void 0 : _b.length))) {
            setTips([
                {
                    percent: 10,
                    enabled: false,
                    userInput: false,
                },
                {
                    percent: 15,
                    enabled: false,
                    userInput: false,
                },
                {
                    percent: 20,
                    enabled: false,
                    userInput: false,
                },
                {
                    percent: 5,
                    enabled: false,
                    userInput: true,
                }
            ]);
        }
        else if (tipsState.executed) {
            setTips(tipsState.result.tips);
            setActive(tipsState.result.active);
        }
    }, [tipsState.executed]);
    var updateItem = function (idx, item) {
        //item.enabled = !item.enabled;
        var update = tips.slice(0);
        var clone = update[idx];
        clone.enabled = item.enabled;
        clone.percent = item.percent;
        setTips(update);
        setDirty(true);
    };
    var toggleActive = function () {
        setActive(!active);
        setDirty(true);
    };
    return React.createElement(Container, null,
        tipsState.inProgress && React.createElement(LoadingBox, null),
        React.createElement(Section, { title: t("tips.page.title") },
            React.createElement(Scrollbars, null,
                React.createElement(CFormSwitch, { label: t("page.company.tips.enable.label"), checked: active, onChange: function () {
                        toggleActive();
                    } }),
                React.createElement(ItemsContainer, null, tips.map(function (tip, idx) { return React.createElement(TipFormItem, { globalActive: active, key: idx, item: tip, onChange: function (update) {
                        updateItem(idx, update);
                    } }); })))),
        React.createElement(Button, { color: "primary", disabled: !dirty, onClick: updateTips }, t("save.text")));
});
var templateObject_1, templateObject_2, templateObject_3;
