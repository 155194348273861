export var MenuPermissions;
(function (MenuPermissions) {
    MenuPermissions["MENU_ADD"] = "menu_create";
    MenuPermissions["MENU_UPDATE"] = "menu_edit";
    MenuPermissions["MENU_DELETE"] = "menu_delete";
    MenuPermissions["MENU_EXPORT"] = "menu_export";
    MenuPermissions["MENU_IMPORT"] = "menu_import";
})(MenuPermissions || (MenuPermissions = {}));
export var CategoryPermissions;
(function (CategoryPermissions) {
    CategoryPermissions["CATEGORY_ADD"] = "category_create";
    CategoryPermissions["CATEGORY_UPDATE"] = "category_edit";
    CategoryPermissions["CATEGORY_DELETE"] = "category_delete";
})(CategoryPermissions || (CategoryPermissions = {}));
export var DishPermissions;
(function (DishPermissions) {
    DishPermissions["DISH_ADD"] = "dish_create";
    DishPermissions["DISH_UPDATE"] = "dish_edit";
    DishPermissions["DISH_DELETE"] = "dish_delete";
})(DishPermissions || (DishPermissions = {}));
export var OptionPermissions;
(function (OptionPermissions) {
    OptionPermissions["OPTION_ADD"] = "option_create";
    OptionPermissions["OPTION_UPDATE"] = "option_edit";
    OptionPermissions["OPTION_DELETE"] = "option_delete";
})(OptionPermissions || (OptionPermissions = {}));
export var TranslatePermissions;
(function (TranslatePermissions) {
    TranslatePermissions["TRANSLATE"] = "translate";
})(TranslatePermissions || (TranslatePermissions = {}));
export var MonitorPermissions;
(function (MonitorPermissions) {
    MonitorPermissions["MONITOR_VIEW"] = "monitor_view";
    MonitorPermissions["MONITOR_STATUS_CHANGE"] = "monitor_status_change";
})(MonitorPermissions || (MonitorPermissions = {}));
export var AreaPermissions;
(function (AreaPermissions) {
    AreaPermissions["AREA_ADD"] = "area_create";
    AreaPermissions["AREA_UPDATE"] = "area_edit";
})(AreaPermissions || (AreaPermissions = {}));
export var CompanyPermissions;
(function (CompanyPermissions) {
    CompanyPermissions["COMPANY_VIEW"] = "company_view";
    CompanyPermissions["COMPANY_EDIT"] = "company_edit";
})(CompanyPermissions || (CompanyPermissions = {}));
export var MembersPermissions;
(function (MembersPermissions) {
    MembersPermissions["MEMBERS_INVITE"] = "members_invite";
    MembersPermissions["MEMBERS_EDIT"] = "members_edit";
    MembersPermissions["MEMBERS_DELETE"] = "members_delete";
})(MembersPermissions || (MembersPermissions = {}));
export var OrderPermissions;
(function (OrderPermissions) {
    OrderPermissions["ORDER_VIEW"] = "order_view";
})(OrderPermissions || (OrderPermissions = {}));
export var SummaryReportPermissions;
(function (SummaryReportPermissions) {
    SummaryReportPermissions["SUMMARY_REPORT_VIEW"] = "summary_report_view";
})(SummaryReportPermissions || (SummaryReportPermissions = {}));
