var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CFormSwitch, CNavLink } from "@coreui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getStationsRequest } from "../../request/station.request";
import styled from "styled-components";
import { CategoryPermissions } from '../../common/user/Permission';
import { buildRoute, MENU_CATEGORY_PAGE } from "../../constats/Routes";
import { createMenuCategoriesRequst, deleteMenuCategoriesRequst, disableCategoryRequest, enableCategoryRequest, getMenuCategoriesRequest, sortMenuCategoriesRequest, updateMenuCategoriesRequst } from "../../request/menu.request";
import { HttpApiRequest, useHttpRequesPrepareHandler, useHttpRequestHandler } from "../../service/network.service";
import { categoriesStore } from "../../store/categories.store";
import { userStore } from "../../store/user.store";
import { Box } from "../common/FlexItem";
import FormCheckboxInput from '../common/form/FormCheckboxInput';
import FormInput from '../common/form/FormInput';
import FormTagInput from '../common/form/FormTagInput';
import FormTextInput from "../common/form/FormTextInput";
import { ListItem } from '../common/ListItem';
import { ModalForm } from '../common/ModalForm';
import { Section } from '../common/Section';
import { Select } from '../common/Select';
import { Sortable } from '../common/Sortable';
var logger = require("js-logger").get("Category");
var languages = require("../../../assets/json/languages.json");
import LoadingBox from "../common/LoadingBox";
import FormSelectInput from "../common/form/FormSelectInput";
var categoryImages = ["taco", "bagel", "baguette", "bao_bun", "biscuits", "bitten_sandwich",
    "bread_loaf", "bread_rolling_pin", "bread", "brezel", "burrito", "cake", "caviar", "chapati",
    "cheese", "chicken", "coffee_capsule", "coffee", "cola", "egg_sandwich", "eggs", "food_wine",
    "french_fries", "friedchicken", "gift", "greek_salad", "green_tea", "guacamole", "gyoza", "hamburger",
    "hot_dog", "icons8_porridge", "lasagna", "meat", "merry_pie", "milk_bottle", "noodles", "nut", "omlette",
    "paella", "pancake", "pasta", "popcorn", "pork", "potato_chips", "poultry", "quesadilla", "refreshments",
    "rice", "salad", "salami_pizza", "salmon-sushi", "sandwich", "sausage", "steak", "streetfood", "sushi",
    "take-away_food", "tapas", "toast", "udon", "wine"];
var ButtonsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 5px 0 0 0;\n    padding: 0 0 5rem 0;\n"], ["\n    margin: 5px 0 0 0;\n    padding: 0 0 5rem 0;\n"])));
var CategoriesTitleContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    color: #858992;\n    font-size: 1rem;\n    text-transform: uppercase;\n    margin: 1.3rem 0.3rem 0.4rem 0.3rem;\n    align-items: center;\n"], ["\n    display: flex;\n    color: #858992;\n    font-size: 1rem;\n    text-transform: uppercase;\n    margin: 1.3rem 0.3rem 0.4rem 0.3rem;\n    align-items: center;\n"])));
var CategoriesTitleRight = styled(CategoriesTitleContainer)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 1;\n    justify-content: flex-end;\n    align-items: center\n"], ["\n    flex: 1;\n    justify-content: flex-end;\n    align-items: center\n"])));
var CategoriesContainer = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n"], ["\n"])));
var TranslateContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["    \n    margin: 1rem 0 1rem 0;\n"], ["    \n    margin: 1rem 0 1rem 0;\n"])));
var AddCategory = React.memo(function (props) {
    var category = props.category;
    var stationsState = useHttpRequestHandler(getStationsRequest());
    var defaultValues = {};
    if (category) {
        defaultValues = {
            name: category.name,
            description: category.description,
            tags: category.tags,
            image: category.image,
            markers: category.markers
        };
    }
    var t = useTranslation().t;
    var company = userStore(function (state) { return state.company; });
    var uiLanguage = userStore(function (state) { return state.language; });
    var translateSources = company.languages.filter(function (l) { return l !== uiLanguage; }).map(function (code) {
        var lang = languages.find(function (item) { return item.code == code; });
        return {
            value: code,
            label: lang.name
        };
    });
    if (stationsState.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    var stations = stationsState.result ? stationsState.result : [];
    defaultValues.station_id = category ? category.station_id : stations[0] ? stations[0].id : null;
    return React.createElement(ModalForm, { onSubmit: props.onSubmit, onClose: props.onCancel, title: t(category ? "edit.category.text" : "add.category.text"), formProps: {
            mode: "onChange",
            defaultValues: defaultValues ? defaultValues : undefined
        } }, function (formReturn) {
        var control = formReturn.control, watch = formReturn.watch, trigger = formReturn.trigger, setValue = formReturn.setValue;
        var image = watch("image");
        return React.createElement(React.Fragment, null,
            React.createElement(FormTextInput, { name: "name", control: control, label: t("name.text"), rules: {
                    required: true,
                    minLength: 2
                } }),
            React.createElement(FormInput, { name: "image", control: control, label: t("image.text"), rules: {
                    required: true,
                    minLength: 2
                }, Component: Select, inputProps: {
                    search: true,
                    options: categoryImages.sort(),
                    toggle: React.createElement(Box, { gap: "5px", align: "center" }, image ? React.createElement(React.Fragment, null,
                        React.createElement("img", { src: "https://static.ordish.app/theme/1/categories/".concat(image, ".png"), width: "30" }),
                        t("category.".concat(image, ".label"))) : "Select Image"),
                    renderOption: function (option) {
                        return React.createElement(Box, { gap: "5px", align: "center" },
                            React.createElement("img", { src: "https://static.ordish.app/theme/1/categories/".concat(option, ".png"), width: "30" }),
                            t("category.".concat(option, ".label")));
                    }
                } }),
            React.createElement(FormSelectInput, { control: control, name: "station_id", label: t("station.text"), options: stations.map(function (station) { return ({
                    value: station.id,
                    label: station.name
                }); }) }),
            React.createElement(FormTagInput, { name: "tags", control: control, label: t("tags.text"), defaultValue: [], labelKey: "tag", createTag: function (tag, index) { return ({
                    tag: tag,
                    sort: index
                }); }, onChange: function () {
                    trigger("name");
                } }),
            React.createElement(TranslateContainer, null,
                React.createElement(FormCheckboxInput, { control: control, name: "markers.alcohol", label: t("alcohol.label") })),
            category !== null && React.createElement(TranslateContainer, null,
                React.createElement(FormCheckboxInput, { control: control, name: "translate", label: t("translate.label"), onChage: function (e) {
                        if (e.target.checked) {
                            //trigger("translateSource");
                            logger.info("TranslateSource", e.target.checked);
                            var fromLng = company.language != uiLanguage ? company.language : translateSources[0].value;
                            logger.info("TranslateSource", fromLng);
                            setValue("translateSource", fromLng, {
                                shouldDirty: true,
                                shouldTouch: true,
                                shouldValidate: true
                            });
                        }
                    } })));
    });
});
var Link = styled(CNavLink)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    color: #5669de;\n    text-decoration: underline;\n"], ["\n    color: #5669de;\n    text-decoration: underline;\n"])));
export default React.memo(function (props) {
    var _a = React.useState(undefined), editCategory = _a[0], setEditCategory = _a[1];
    var company = userStore(function (state) { return state.company; });
    var language = userStore(function (state) { return state.language; });
    var hasPermission = userStore(function (state) { return state.hasPermission; });
    var categoriesState = useHttpRequesPrepareHandler();
    var cStore = categoriesStore();
    var t = useTranslation().t;
    var navigate = useNavigate();
    var loadCategories = function () {
        categoriesState.run(getMenuCategoriesRequest(company.id, props.menu_id))
            .then(function (data) {
            var categories = data;
            cStore.setItems(data);
            // if (categories.length) {
            //     const category = categories[0];
            //     navigate(buildRoute(MENU_CATEGORY_PAGE, {
            //         menu_id: category.menu_id,
            //         category_id: category.id
            //     }));
            // }
        });
    };
    var saveCategory = function (data) {
        data.tags.map(function (tag, index) {
            tag.sort = index;
            return tag;
        });
        // logger.info("Category", data);
        // return;
        var requestConfig = editCategory ? updateMenuCategoriesRequst(company.id, props.menu_id, editCategory.id, data) : createMenuCategoriesRequst(company.id, props.menu_id, data);
        HttpApiRequest(requestConfig)
            .promise
            .then(loadCategories)
            .catch(function (err) {
            logger.err(err);
        })
            .finally(function () {
            setEditCategory(undefined);
        });
    };
    var deleteCategory = function (data) {
        if (confirm("Are you sure")) {
            HttpApiRequest(deleteMenuCategoriesRequst(company.id, props.menu_id, data.id))
                .promise
                .then(loadCategories)
                .catch(function (err) {
                logger.err(err);
            })
                .finally(function () {
                setEditCategory(undefined);
            });
        }
    };
    var sortCategories = function (data) {
        HttpApiRequest(sortMenuCategoriesRequest(company.id, props.menu_id, data))
            .promise
            .catch(function (err) {
            logger.err(err);
        });
    };
    var enableCategory = function (id) {
        return HttpApiRequest(enableCategoryRequest(company.id, props.menu_id, id))
            .promise
            .then(loadCategories)
            .catch(function (err) {
            logger.err(err);
        });
    };
    var disableCategory = function (id) {
        return HttpApiRequest(disableCategoryRequest(company.id, props.menu_id, id))
            .promise
            .then(loadCategories)
            .catch(function (err) {
            logger.err(err);
        });
    };
    var goToCategoryPage = function (category) {
        navigate(buildRoute(MENU_CATEGORY_PAGE, {
            menu_id: category.menu_id,
            category_id: category.id
        }));
        //console.log(props.category);
    };
    React.useEffect(function () {
        loadCategories();
    }, []);
    React.useEffect(function () {
        loadCategories();
    }, [props.menu_id, language]);
    var toggleCategoryState = function (category) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, category.active];
                case 1:
                    (_a.sent()) ? disableCategory(category.id) : enableCategory(category.id);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDragEnd = function (items) {
        logger.info("DragEnd", event);
        var sortData = {};
        items.forEach(function (area, idx) {
            sortData[area.id] = idx;
        });
        logger.info("SortData", sortData);
        sortCategories(sortData);
    };
    // if (!categoriesState.executed) {
    //     return <>Loading...</>
    // }
    var categories = categoriesState.result;
    return React.createElement(Section, { title: t("categories.text"), onAdd: hasPermission(CategoryPermissions.CATEGORY_ADD) ? function () {
            setEditCategory(null);
        } : undefined },
        React.createElement(Sortable, { items: categories, sortFn: function (items) {
                if (!items || !items.length) {
                    return [];
                }
                var sorted = items.sort(function (a, b) {
                    if (a.sort == b.sort) {
                        return b.created - a.created;
                    }
                    return a.sort - b.sort;
                });
                return sorted;
            }, handle: '.drag-handle', renderItem: function (item) {
                var category = item;
                return React.createElement(ListItem, { active: category.active, sortable: hasPermission(CategoryPermissions.CATEGORY_UPDATE), onEdit: hasPermission(CategoryPermissions.CATEGORY_UPDATE) ? function () {
                        setEditCategory(item);
                    } : undefined, onDelete: hasPermission(CategoryPermissions.CATEGORY_DELETE) ? function () {
                        deleteCategory(item);
                    } : undefined },
                    hasPermission(CategoryPermissions.CATEGORY_UPDATE) && React.createElement(CFormSwitch, { checked: category.active, onChange: function () {
                            toggleCategoryState(category);
                        } }),
                    React.createElement(Link, { onClick: function () {
                            goToCategoryPage(item);
                        } }, item.name));
            }, onSort: handleDragEnd }),
        editCategory !== undefined && React.createElement(AddCategory, { onSubmit: function (values) {
                saveCategory(values);
            }, onCancel: function () {
                //logger.info("On Canel");
                setEditCategory(undefined);
            }, category: editCategory }));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
