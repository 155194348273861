import { userStore } from "../store/user.store";
export function getCompanyPaymentMethods() {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/company-payment-method"),
        params: {
            token: token
        }
    };
}
export function getPaymentMethods() {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/payment-method"),
        params: {
            token: token
        }
    };
}
export function getNotConfigurablePaymentMethods() {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/payment-method/not-configurable"),
        params: {
            token: token
        }
    };
}
export function markAsPaidRequest(data) {
    var _a = userStore.getState(), company = _a.company, token = _a.token, user = _a.user;
    return {
        url: "".concat(API_BASE_URL, "/").concat(company.id, "/order/mark-as-paid"),
        method: "POST",
        data: {
            method_id: data.method_id,
            comment: data.comment,
            user_id: user.id,
            orders: data.orders
        },
        params: {
            token: token
        }
    };
}
export function createCompanyPayment(data) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/company-payment-method"),
        method: "POST",
        params: {
            token: token
        },
        data: data
    };
}
export function updateCompanyPayment(id, data) {
    var _a = userStore.getState(), company = _a.company, token = _a.token;
    return {
        url: "".concat(API_BASE_URL, "/company-payment-method/").concat(id),
        method: "PUT",
        params: {
            token: token
        },
        data: data
    };
}
