var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../common/Button";
import styled from "styled-components";
import { Box } from "../common/FlexItem";
import FormTextInput from "../common/form/FormTextInput";
import { useTranslation } from "react-i18next";
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var Buttons = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 2rem 0 0 0;\n"], ["\n    margin: 2rem 0 0 0;\n"])));
var Form = styled.form(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 1;\n"], ["\n    flex: 1;\n"])));
export var StationForm = React.memo(function (props) {
    var onSubmit = props.onSubmit, onCancel = props.onCancel, station = props.station;
    var defaultValues = station ? { name: station.name } : undefined;
    var _a = useForm({
        mode: "onChange",
        defaultValues: defaultValues
    }), control = _a.control, handleSubmit = _a.handleSubmit, formState = _a.formState;
    var t = useTranslation().t;
    return React.createElement(Container, null,
        React.createElement(Form, { onSubmit: handleSubmit(onSubmit) },
            React.createElement(FormTextInput, { label: t("name.text"), control: control, name: "name", rules: {
                    required: true,
                    min: 1
                } }),
            React.createElement(Buttons, { gap: "1rem", justify: "flex-end" },
                React.createElement(Button, { onClick: onCancel }, t("cancel.text")),
                React.createElement(Button, { color: "primary", type: "submit", disabled: !formState.isDirty || !formState.isValid }, t("save.text")))));
});
var templateObject_1, templateObject_2, templateObject_3;
