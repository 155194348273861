var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { SettingsPageContainer, SettingsPageContent, SettingsPageHeader, SettingsPageTitle } from "../settings";
import { useTranslation } from "react-i18next";
import { CInputGroup, CInputGroupText, CFormInput, CButton } from "@coreui/react";
import { userStore } from "../../store/user.store";
import styled from "styled-components";
import CIcon from "@coreui/icons-react";
import { cilQrCode } from "@coreui/icons";
import { QRCodeModal } from "../common/QRCodeModal";
var QRCodeContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 10px 0;\n"], ["\n    padding: 10px 0;\n"])));
var QRCodeForm = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 5px 0;\n"], ["\n    margin: 5px 0;\n"])));
var AccessQR = React.memo(function () {
    var t = useTranslation().t;
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    var closeModal = function () {
        setShowModal(false);
    };
    var openModal = function () {
        setShowModal(true);
    };
    var uState = userStore();
    var company = uState.company;
    return React.createElement(React.Fragment, null,
        React.createElement(SettingsPageContainer, null,
            React.createElement(SettingsPageHeader, null,
                React.createElement(SettingsPageTitle, null, t("company.sidebar.accessqr"))),
            React.createElement(SettingsPageContent, null,
                React.createElement(QRCodeContainer, null,
                    React.createElement(QRCodeForm, null,
                        React.createElement(CInputGroup, null,
                            React.createElement(CInputGroupText, null, t("company.sidebar.accessqr")),
                            React.createElement(CFormInput, { readOnly: true, value: "https://".concat(company.domain, ".ordish.app") }),
                            React.createElement(CButton, { type: "button", color: "primary", variant: "outline" }, t("access.qr.page.copy.button")))),
                    React.createElement(CButton, { variant: "outline", onClick: openModal },
                        React.createElement(CIcon, { icon: cilQrCode, size: "sm" }),
                        "\u00A0 \u00A0",
                        t("access.qr.page.download.button"))))),
        showModal && React.createElement(QRCodeModal, { onClose: closeModal, link: "https://".concat(company.domain, ".ordish.app") }));
});
export default AccessQR;
var templateObject_1, templateObject_2;
