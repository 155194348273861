import * as React from "react";
import { CFormLabel, CFormFeedback, CTooltip } from "@coreui/react";
import { useController } from "react-hook-form";
import { randomString } from "../../../utils/StringUtils";
var logger = require("js-logger").get("FormTagInput");
import { useTranslation } from "react-i18next";
import { TagInput } from "../TagInput";
var FormTagInput = function (props) {
    var control = props.control, name = props.name, defaultValue = props.defaultValue, label = props.label, rules = props.rules, info = props.info, createTag = props.createTag, labelKey = props.labelKey;
    var _a = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _b = _a.field, onChange = _b.onChange, _c = _b.value, value = _c === void 0 ? defaultValue ? defaultValue : "" : _c, onBlur = _b.onBlur, fieldState = _a.fieldState;
    logger.info("fieldState.isDirty", fieldState.isDirty, "fieldState.error", fieldState.error, "fieldState.isTouched", fieldState.isTouched);
    var t = useTranslation().t;
    var inputId = props.id ? props.id : randomString(6);
    //logger.info("Errors", fieldState.error);
    var hasTag = function (tag) {
        return value.find(function (item) {
            var itemTag = labelKey ? item[labelKey] : item;
            return itemTag == tag;
        }) !== undefined;
    };
    var onAdd = function (tag, index) {
        if (hasTag(tag)) {
            return;
        }
        var update = value.slice(0);
        update.push(createTag(tag, index));
        //logger.info("XXX1 ");
        onChange(update);
        if (props.onChange) {
            props.onChange(update);
        }
    };
    var onRemove = function (tag, index) {
        //const update = value.slice(0);
        value.splice(index, 1);
        //logger.info("XXX1 2");
        onChange(value);
        if (props.onChange) {
            props.onChange(value);
        }
    };
    return React.createElement(React.Fragment, null,
        label && React.createElement(CFormLabel, { htmlFor: inputId }, label),
        info && React.createElement(CTooltip, { content: info },
            React.createElement(TagInput, { onAdd: onAdd, onRemove: onRemove, value: value, labelKey: labelKey })),
        !info && React.createElement(TagInput, { onAdd: onAdd, onRemove: onRemove, value: value, labelKey: labelKey }),
        fieldState.invalid && React.createElement(CFormFeedback, { invalid: true },
            fieldState.error.message && fieldState.error.message,
            !fieldState.error.message && t("form.error.".concat(fieldState.error.type.toLowerCase(), ".message"))));
};
export default FormTagInput;
