import * as React from "react";
var logger = require("js-logger").get("LocalStorage");
export function useLocalStorageItem(key, defaultVal) {
    if (defaultVal === void 0) { defaultVal = null; }
    var val = localStorage.getItem(key);
    //logger.info("useLocalStorageItem", "key", key, "defaultVal", defaultVal, "val", val);
    var _a = React.useState(val ? val : defaultVal), value = _a[0], setValue = _a[1];
    React.useEffect(function () {
        logger.info("New Val", value);
        localStorage.setItem(key, value);
    }, [value]);
    return [value, setValue];
}
