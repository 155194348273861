var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { cilOptions, cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CDropdownDivider, CDropdownItem, CDropdownMenu, CDropdownToggle, 
// CModal,
// CModalBody,
// CModalHeader,
// CModalTitle,
CNav, CNavItem, CNavLink } from "@coreui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { buildRoute, MENU_PAGE } from "../../constats/Routes";
import { createMenuRequest, deleteMenuRequest, getMenuRequest, updateMenuRequest } from "../../request/menu.request";
import { HttpApiRequest, useHttpRequesPrepareHandler } from "../../service/network.service";
import { userStore } from "../../store/user.store";
import CustomDropDown from "../common/CustomDropDown";
import MenuForm from "./MenuForm";
import { useAppEventEmitter, AppEvents } from "../../event/AppEventEmitter";
import { MenuPermissions } from "../../common/user/Permission";
var logger = require("js-logger").get("Menus");
var MenusPage = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex: 1;\n"], ["\n    display: flex;\n    flex: 1;\n"])));
var Page = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    // width: 100%;    \n"], ["\n    // width: 100%;    \n"])));
var PageContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    // display: flex;\n    // flex: 1;\n    overflow: hidden;\n    position: relative;\n    height: 5rem;\n"], ["\n    // display: flex;\n    // flex: 1;\n    overflow: hidden;\n    position: relative;\n    height: 5rem;\n"])));
var MenuTabs = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: absolute;\n    width: 200rem;\n    display: flex;\n"], ["\n    position: absolute;\n    width: 200rem;\n    display: flex;\n"])));
var Container = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["    \n    padding: 10px 10px 0 10px;    \n    flex: 1;\n    display: flex;\n    flex-direction: column;\n"], ["    \n    padding: 10px 10px 0 10px;    \n    flex: 1;\n    display: flex;\n    flex-direction: column;\n"])));
var AddMenuIcon = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n"], ["\n    display: flex;\n    align-items: center;\n"])));
var Toggle = styled(CDropdownToggle)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    padding: 0;\n"], ["\n    padding: 0;\n"])));
var MenuItem = function (props) {
    var navigate = useNavigate();
    var params = useParams();
    var menu = props.menu;
    var hasPemission = userStore(function (state) { return state.hasPermission; });
    var canEdit = hasPemission(MenuPermissions.MENU_UPDATE);
    var canDelete = hasPemission(MenuPermissions.MENU_DELETE);
    return React.createElement(CNavItem, null,
        React.createElement(CNavLink, { active: params.menu_id == menu.id, href: buildRoute(MENU_PAGE, {
                menu_id: menu.id
            }), onClick: function (e) {
                e.preventDefault();
                if (e.target.pathname) {
                    navigate(e.target.pathname);
                }
            } },
            menu.name,
            (canEdit || canDelete) && React.createElement(CustomDropDown, { toggle: React.createElement(CDropdownToggle, { caret: false, color: "link", style: {
                        padding: 0
                    } },
                    React.createElement(Toggle, { color: "link", caret: false, style: {} },
                        React.createElement(CIcon, { icon: cilOptions }))) },
                React.createElement(CDropdownMenu, null,
                    canEdit && React.createElement(CDropdownItem, { onClick: function () {
                            props.onEdit(props.menu);
                        } }, "Edit"),
                    React.createElement(CDropdownDivider, null),
                    canDelete && React.createElement(CDropdownItem, { href: "#", onClick: function () {
                            props.onDelete(props.menu);
                        } }, "Delete")))));
};
var MenuItemContainer = function (props) {
    var menuStatus = useHttpRequesPrepareHandler();
    var company = userStore(function (state) { return state.company; });
    var language = userStore(function (state) { return state.language; });
    var _a = React.useState(undefined), edit = _a[0], setEdit = _a[1];
    var t = useTranslation().t;
    var navigate = useNavigate();
    var params = useParams();
    var hasPemission = userStore(function (state) { return state.hasPermission; });
    var loadMenu = function (first) {
        menuStatus.run(getMenuRequest(company.id))
            .then(function (data) {
            if (first && !params.menu_id) {
                var result_1 = data;
                if (result_1 && result_1[0]) {
                    navigate(buildRoute(MENU_PAGE, {
                        menu_id: result_1[0].id
                    }));
                }
                //logger.info("Params:", params);
            }
        });
    };
    var saveMenu = function (values) {
        logger.info("Values", values);
        logger.info("Menu", edit);
        if (edit) {
            return HttpApiRequest(updateMenuRequest(company.id, edit.id, values)).promise
                .then(function () {
                loadMenu(false);
            })
                .catch(function (err) {
                logger.error(err);
            })
                .finally(function () {
                setEdit(undefined);
            });
        }
        else {
            return HttpApiRequest(createMenuRequest(company.id, values)).promise
                .then(function () {
                loadMenu(false);
            })
                .catch(function (err) {
                logger.error(err);
            })
                .finally(function () {
                setEdit(undefined);
            });
        }
    };
    var deleteMenu = function (menu) {
        if (confirm("Are you sure ?")) {
            HttpApiRequest(deleteMenuRequest(company.id, menu.id))
                .promise
                .then(function () {
                loadMenu(false);
            })
                .catch(function (err) {
                logger.error(err);
            });
        }
    };
    React.useEffect(function () {
        loadMenu(true);
        var appEventEmitter = useAppEventEmitter();
        var refresh = function () {
            loadMenu(true);
        };
        appEventEmitter.addListener(AppEvents.REFRESH_MENUS, refresh);
        return function () {
            appEventEmitter.removeListener(AppEvents.REFRESH_MENUS, refresh);
        };
    }, []);
    React.useEffect(function () {
        loadMenu(true);
    }, [language]);
    if (!menuStatus.executed || menuStatus.inProgress) {
        return React.createElement(React.Fragment, null, "\"...Loading\"");
    }
    //logger.info("Edit", menuStatus.result);
    var result = menuStatus.result;
    return React.createElement(React.Fragment, null,
        React.createElement(Page, null,
            React.createElement(PageContent, null,
                React.createElement(CNav, { variant: "tabs" },
                    result && result.map(function (menu) { return React.createElement(MenuItem, { key: menu.id, menu: menu, onEdit: function (item) { return setEdit(item); }, onDelete: deleteMenu }); }),
                    hasPemission(MenuPermissions.MENU_ADD) && React.createElement(AddMenuIcon, null,
                        React.createElement(CIcon, { icon: cilPlus, size: "xl", onClick: function () {
                                setEdit(null);
                            } }))))),
        edit !== undefined && React.createElement(MenuForm, { menu: edit, onCancel: function () {
                setEdit(undefined);
            }, onSubmit: saveMenu }));
};
var Menus = function (props) {
    return React.createElement(MenusPage, null,
        React.createElement(Container, null,
            React.createElement(MenuItemContainer, null)));
};
export default React.memo(Menus);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
