export var CompanyMode;
(function (CompanyMode) {
    CompanyMode[CompanyMode["MODE_MENU"] = 0] = "MODE_MENU";
    CompanyMode[CompanyMode["MODE_ORDER"] = 1] = "MODE_ORDER";
    CompanyMode[CompanyMode["MODE_OFFLINE_PAYMENT"] = 2] = "MODE_OFFLINE_PAYMENT";
    CompanyMode[CompanyMode["MODE_SEPARATE_PAYMENT"] = 3] = "MODE_SEPARATE_PAYMENT";
    CompanyMode[CompanyMode["MODE_ALL"] = 4] = "MODE_ALL";
})(CompanyMode || (CompanyMode = {}));
// export const MODE_MENU = "menu";
// export const MODE_ORDER = "order"
// export const MODE_OFFLINE_PAYMENT = "offline_payment"
// export const MODE_SEPARATE_PAYMENT = "separate_payment"
// export const MODE_ALL = "all"
