var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import styled from "styled-components";
import RadioIconOn from "../../assets/icon/radio/radio-on.svg";
import RadioIconOff from "../../assets/icon/radio/radio-off.svg";
import { Box } from "../component/common/FlexItem";
import { Touchable } from "../common/Touchable";
var logger = require("js-logger").get("RadioInput");
var RadioLabel = styled(Touchable)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-size: ", ";        \n    color: ", ";\n    display: flex;    \n    flex: 1;    \n    align-items: center\n"], ["\n    font-size: ", ";        \n    color: ", ";\n    display: flex;    \n    flex: 1;    \n    align-items: center\n"])), function (props) { return props.size == "default" ? "16px" : "14px"; }, function (props) { return props.size == "default" ? "#111719" : "#858992"; });
var RadioLabelHorizontal = styled(RadioLabel)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border-right: #858992 solid 0.4rem;\n    padding-left: 12px;\n    &:last-child{\n        border-right: 0;        \n    }\n"], ["\n    border-right: #858992 solid 0.4rem;\n    padding-left: 12px;\n    &:last-child{\n        border-right: 0;        \n    }\n"])));
var RadioIcon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 22px;\n    height: 22px;\n    & path {\n        fill: var(--btn-primary-color)\n    }\n    background: url(\"", "\") no-repeat;\n    background-size: 100%;\n"], ["\n    width: 22px;\n    height: 22px;\n    & path {\n        fill: var(--btn-primary-color)\n    }\n    background: url(\"", "\") no-repeat;\n    background-size: 100%;\n"
    // const RadioIconOff = styled(RadioIcon)`
    //     background: url(${offSvg}) no-repeat;
    // `;
    // const RadioIconOn = styled(RadioIcon)`
    //     background: url(${onSvg}) no-repeat;
    // `;
])), function (props) { return props.checked ? RadioIconOn : RadioIconOff; });
// const RadioIconOff = styled(RadioIcon)`
//     background: url(${offSvg}) no-repeat;
// `;
// const RadioIconOn = styled(RadioIcon)`
//     background: url(${onSvg}) no-repeat;
// `;
var Input = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 1px;\n    height: 1px;\n"], ["\n    width: 1px;\n    height: 1px;\n"])));
var LabelText = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin-left: 1rem;    \n"], ["\n    margin-left: 1rem;    \n"])));
var RadioInput = /** @class */ (function (_super) {
    __extends(RadioInput, _super);
    function RadioInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function () {
            var _a = _this.props, onClick = _a.onClick, value = _a.value;
            onClick(value);
        };
        return _this;
    }
    RadioInput.prototype.render = function () {
        var _a = this.props, label = _a.label, _b = _a.checked, checked = _b === void 0 ? false : _b, value = _a.value, onClick = _a.onClick, layout = _a.layout, _c = _a.size, size = _c === void 0 ? "default" : _c, style = _a.style;
        var Label = layout == "horizontal" ? RadioLabelHorizontal : RadioLabel;
        return React.createElement(Label, { onTap: this.handleClick, size: size, style: style },
            React.createElement(RadioIcon, { checked: checked }),
            React.createElement(Input, { type: "radio" }),
            React.createElement(LabelText, { style: style, checked: checked }, label));
    };
    return RadioInput;
}(React.PureComponent));
export { RadioInput };
var RadioInputGroupContainer = styled(Box)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    flex-direction: ", "\n"], ["\n    flex-direction: ", "\n"])), function (props) { return props.layout == "horizontal" ? "row" : "column"; });
export var RadioInputGroup = React.memo(function (props) {
    var items = props.items, onChange = props.onChange, _a = props.layout, layout = _a === void 0 ? "horizontal" : _a, _b = props.size, size = _b === void 0 ? "default" : _b, className = props.className, radioStyle = props.radioStyle;
    logger.info("Size", size);
    var _c = React.useState(props.value), value = _c[0], setValue = _c[1];
    React.useEffect(function () {
        if (onChange) {
            onChange(value);
        }
    }, [value]);
    return React.createElement(RadioInputGroupContainer, { layout: layout, className: className }, items.map(function (item) { return React.createElement(RadioInput, { label: item.label, checked: item.value == value, value: item.value, onClick: function (value) {
            setValue(value);
        }, layout: layout, size: size, style: radioStyle }); }));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
