import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./App";
import "../css/normalize.css";
import "../css/app.css";
// import '@coreui/coreui/dist/css/coreui.min.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
import "../scss/ordish.scss";
import "./error.handler";
ReactDOM.render(React.createElement(App, null), document.getElementById("app"));
