import * as _ from "underscore";
export var USER_ENDPOINT = "user";
export var DEVICE_ENDPOINT = "device";
export var ME_ENDPOINT = "me";
export var AUTH_ENDPOINT = "auth";
export var MENU_ENDPOINT = "menu";
export var DISH_ENDPOINT = "dish";
export var AREA_ENDPOINT = "area";
export var LOCAL_ENDPOINT = "local";
export var COMPANY_ENDPOINT = "company";
export var INVITE_ENDPOINT = "invite";
export var SETTINGS_ENDPOINT = "settings";
export var ORDER_ENDPOINT = "order";
export var LOCAL_SIGN_UP_ENDPOINT = "".concat(LOCAL_ENDPOINT, "/sign-up");
export var STATION_ENDPOINT = "station";
export var TIPS_ENDPOINT = "tips";
export var AREA_QR_CODE_URL = "".concat(API_BASE_URL, "/:companyId/area/:areaId/qr?token=:token");
export var AREA_PLACE_QR_CODE_URL = "".concat(API_BASE_URL, "/:companyId/qr/area/:areaId/place/:placeId/download?token=:token&qrColor=:qrColor&frColor=:frColor&text=:text&tpl=:tpl");
export function buildURL(route, params) {
    var res = route;
    if (DEBUG_COMPANY) {
        params["company"] = DEBUG_COMPANY;
        if (res.indexOf("?") !== -1) {
            res = "".concat(route, "&company=:company");
        }
        else {
            res = "".concat(route, "?company=:company");
        }
    }
    //console.log("PARAMS", params, route);
    //console.log("hasLanguageInPath", route, document.location.pathname, hasLanguageInPath(document.location.pathname))
    // if(hasLanguageInPath(document.location.pathname)){        
    //     res = `/${getLanguageFromPath(document.location.pathname)}${route}`
    // }
    _.each(params, function (val, name) {
        var regex = new RegExp(":".concat(name), "i");
        res = res.replace(regex, val);
    });
    return res;
}
