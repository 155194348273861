var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { OrderMonitor } from ".";
import { Box } from "../component/common/FlexItem";
import Scrollbars from "react-custom-scrollbars-2";
import styled from "styled-components";
import { userStore } from "../store/user.store";
import { useJsonRPC } from "../hook/ws.jsonrpc.hook";
var logger = require("js-logger").get("OrderMonitorPage");
import { HasPemission } from "../common/user/HasPermission";
import { MonitorPermissions } from "../common/user/Permission";
import LoadingBox from "../component/common/LoadingBox";
import { Modal } from "../component/common/Modal";
//const baseURL = API_BASE_URL.replace("https", "wss").replace("http", "ws");
//const jsonRpc = new WebsocketJsonRpcService(new WebSocketConnection(baseURL))
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-top: 1rem;\n    height: 100%;\n"], ["\n    padding-top: 1rem;\n    height: 100%;\n"])));
export var OrderMonitorPage = React.memo(function (props) {
    var jsonRpc = useJsonRPC();
    var _a = React.useState([]), orders = _a[0], setOrders = _a[1];
    var _b = React.useState(false), loading = _b[0], setLoading = _b[1];
    var companyId = userStore(function (state) { return state.company.id; });
    var getCompanyOrders = function () {
        setLoading(true);
        jsonRpc.request("getCompanyOrders", { companyId: companyId })
            .then(function (response) {
            //logger.info(response)
            setOrders(response.orders);
        })
            .catch(function (err) {
            logger.error(err);
        })
            .finally(function () {
            setLoading(false);
        });
    };
    React.useEffect(function () {
        getCompanyOrders();
        return function () {
            jsonRpc.getWebSocketConnection().disconnect();
        };
    }, []);
    React.useEffect(function () {
        return jsonRpc.subscribe("order.update", "pass", function (orderUpdateData) {
            var companyId = orderUpdateData.companyId, orderId = orderUpdateData.orderId;
            // jsonRpc.request("getCompanyOrder", { companyId, orderId })
            //     .then((order: Record<string, any>) => {                    
            //         //let update = orders.find(ord => ord.id == order.id);
            //         //update = Object.assign({}, update, order);
            //         //logger.info("Update", update);
            //         //update.status = status;
            //         //setOrders(orders.slice(0));
            //     })
            //     .catch(err => {
            //         logger.error(err);
            //     })
        });
    }, [orders]);
    var updateOrderStatus = function (order, status) {
        jsonRpc.request("changeCompanyOrderStatus", {
            companyId: companyId,
            status: status,
            orderId: order.id
        }).then(function (order) {
            var exists = orders.find(function (ord) { return ord.id == order.id; });
            var idx = orders.indexOf(exists);
            var update = orders.slice(0);
            update[idx] = order;
            setOrders(update);
            //logger.info("Exists", order, exists, orders.indexOf(exists),);
        });
    };
    return React.createElement(HasPemission, { permission: MonitorPermissions.MONITOR_VIEW },
        React.createElement(Container, { flex: 1 },
            React.createElement(Scrollbars, { style: {
                    height: "100%",
                    width: "100%"
                } },
                React.createElement(OrderMonitor, { orders: orders, changeOrderStatus: function (order, status) {
                        // const update = orders.find(ord => ord.id == order.id);
                        // update.status = status;
                        // update.updated = Date.now();
                        // setOrders(orders.slice(0));
                        updateOrderStatus(order, status);
                        //console.log("Update", update, "Status", status);
                    } }))),
        React.createElement(Modal, { visible: loading, fullscreen: true },
            React.createElement(LoadingBox, null)));
});
var templateObject_1;
