var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { CFormLabel, CTooltip } from "@coreui/react";
import { useController } from "react-hook-form";
import { randomString } from "../../../utils/StringUtils";
var logger = require("js-logger").get("FormTextInput");
import { useTranslation } from "react-i18next";
import { FormFeedback } from "../FormFeedback";
var FormInput = function (props) {
    var control = props.control, name = props.name, defaultValue = props.defaultValue, label = props.label, type = props.type, rules = props.rules, disabled = props.disabled, style = props.style, inputRef = props.inputRef, onFocus = props.onFocus, onBlur = props.onBlur, info = props.info, size = props.size, placeholder = props.placeholder, Component = props.Component, inputProps = props.inputProps;
    var _a = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _b = _a.field, onChange = _b.onChange, _c = _b.value, value = _c === void 0 ? defaultValue ? defaultValue : "" : _c, fieldState = _a.fieldState;
    var t = useTranslation().t;
    var inputId = props.id ? props.id : randomString(6);
    //logger.info("Errors", fieldState.error);
    return React.createElement("div", { className: "form-item" },
        label && React.createElement(CFormLabel, { htmlFor: inputId }, label),
        info && React.createElement(CTooltip, { content: info },
            React.createElement(Component, __assign({ onChange: onChange, 
                // onBlur={onBlur}
                value: value, ref: inputRef, id: inputId, type: type, disabled: disabled, invalid: fieldState.invalid, 
                //valid={fieldState.isDirty && !fieldState.invalid}
                style: style, onBlur: onBlur, onFocus: onFocus, size: size, placeholder: placeholder }, inputProps))),
        !info && React.createElement(Component, __assign({ onChange: onChange, 
            // onBlur={onBlur}
            value: value, ref: inputRef, id: inputId, type: type, disabled: disabled, invalid: fieldState.invalid, 
            //valid={fieldState.isDirty && !fieldState.invalid}
            style: style, onBlur: onBlur, onFocus: onFocus, size: size, placeholder: placeholder }, inputProps)),
        fieldState.invalid && React.createElement(FormFeedback, { invalid: true },
            fieldState.error.message && fieldState.error.message,
            !fieldState.error.message && t("form.error.".concat(fieldState.error.type.toLowerCase(), ".message"))));
};
export default FormInput;
