var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { OptionPermissions } from "../../../common/user/Permission";
import styled from "styled-components";
import { AppEvents, useAppEventEmitter } from "../../../event/AppEventEmitter";
import { createMenuOptionRequest, deleteMenuOptionRequest, getMenuOptionsRequest, sortMenuOptionRequest, updateMenuOptionRequest } from "../../../request/options.request";
import { HttpApiRequest, useHttpRequestHandler } from "../../../service/network.service";
import { userStore } from "../../../store/user.store";
import { ListItem } from "../../common/ListItem";
import LoadingBox from "../../common/LoadingBox";
import { Section } from "../../common/Section";
import { Sortable } from "../../common/Sortable";
import { OptionPageForm } from "./OptionPage";
var logger = require("js-logger").get("OptoinsSidebar");
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding:0 5px 0 10px;\n"], ["\n    padding:0 5px 0 10px;\n"])));
export var Options = React.memo(function (props) {
    var params = useParams();
    var menu_id = params.menu_id, category_id = params.category_id;
    var t = useTranslation().t;
    var menuOptionsState = useHttpRequestHandler(getMenuOptionsRequest(menu_id, category_id));
    var language = userStore(function (state) { return state.language; });
    var hasPermission = userStore(function (state) { return state.hasPermission; });
    var _a = React.useState(undefined), editItem = _a[0], setEditItem = _a[1];
    var appEventEmitter = useAppEventEmitter();
    var refreshOptions = function () {
        menuOptionsState.run();
    };
    React.useEffect(function () {
        appEventEmitter.on(AppEvents.REFRESH_OPTIONS, refreshOptions);
        return function () {
            appEventEmitter.off(AppEvents.REFRESH_OPTIONS, refreshOptions);
        };
    }, []);
    React.useEffect(function () {
        refreshOptions();
    }, [menu_id, language]);
    var refreshItems = function () {
        setEditItem(undefined);
        menuOptionsState.run();
    };
    var saveItem = function (value) {
        HttpApiRequest(createMenuOptionRequest(menu_id, category_id, value))
            .promise
            .then(function (res) {
            // const id = res.data;
            // navigate(buildRoute(APP_MENU_OPTION_ROUTE, {
            //     menu_id,
            //     option_id: id
            // }));
            appEventEmitter.dispatch(AppEvents.REFRESH_OPTIONS, null);
        })
            .finally(refreshItems);
    };
    var updateItem = function (value) {
        var id = value.id, rest = __rest(value, ["id"]);
        logger.info("Update Option", rest, id);
        // return;        
        HttpApiRequest(updateMenuOptionRequest(menu_id, category_id, id, rest))
            .promise
            .then(function (res) {
            appEventEmitter.dispatch(AppEvents.REFRESH_OPTIONS, null);
        })
            .finally(refreshItems);
    };
    var onSort = function (items) {
        var update = {};
        items.forEach(function (item, index) {
            update[item.id] = index;
        });
        logger.info("Sorted", update, items);
        HttpApiRequest(sortMenuOptionRequest(menu_id, category_id, update))
            .promise
            .catch(function (err) {
            logger.error(err);
        });
    };
    var deleteItem = function (item) { return function () {
        if (confirm(t("delete.confirm.text"))) {
            HttpApiRequest(deleteMenuOptionRequest(menu_id, category_id, item.id))
                .promise
                .then(function () {
                menuOptionsState.run();
            });
        }
    }; };
    if (menuOptionsState.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    return React.createElement(Section, { title: t("options.title"), onAdd: hasPermission(OptionPermissions.OPTION_ADD) ? function () {
            setEditItem(null);
        } : undefined },
        React.createElement(Sortable, { items: menuOptionsState.result, sortFn: function (items) {
                if (!items || !items.length) {
                    return [];
                }
                var sorted = items.sort(function (a, b) {
                    if (a.sort == b.sort) {
                        return b.created - a.created;
                    }
                    return a.sort - b.sort;
                });
                return sorted;
            }, onSort: onSort, renderItem: function (item) { return React.createElement(ListItem, { active: true, sortable: hasPermission(OptionPermissions.OPTION_UPDATE), onDelete: hasPermission(OptionPermissions.OPTION_DELETE) ? deleteItem(item) : undefined, onEdit: hasPermission(OptionPermissions.OPTION_UPDATE) ? function () {
                    setEditItem(item);
                } : undefined }, item.name); } }),
        editItem !== undefined && React.createElement(OptionPageForm, { onCancel: function (e) {
                // e.preventDefault();
                // e.stopPropagation();
                setEditItem(undefined);
            }, onSubmit: editItem ? updateItem : saveItem, option: editItem }));
});
var templateObject_1;
