var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { CButton } from "@coreui/react";
import FlexItem from "../common/FlexItem";
import styled from "styled-components";
import Language from "../Language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faFileImport } from "@fortawesome/free-solid-svg-icons";
import { userStore } from "../../store/user.store";
import { useParams } from "react-router-dom";
import { HttpApiRequest } from "../../service/network.service";
var logger = require("js-logger").get("EditorMenu");
import { useAppEventEmitter, AppEvents } from "../../event/AppEventEmitter";
import { useTranslation } from "react-i18next";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 0 10px;\n    margin-top: 10px;\n    display: flex;\n    width: 100%;\n"], ["\n    padding: 0 10px;\n    margin-top: 10px;\n    display: flex;\n    width: 100%;\n"])));
var LinkButton = styled(CButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display:flex;\n    justify-content: center;\n    align-items: center\n"], ["\n    display:flex;\n    justify-content: center;\n    align-items: center\n"])));
var FileImportContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position:relative;\n"], ["\n    position:relative;\n"])));
var FileInput = styled.input.attrs(function (props) { return ({
    type: "file",
    accept: ".json, application/json",
    name: "json"
}); })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: absolute;\n    z-index: 0;\n    opacity: 0;\n    width: 100%;\n    height: 100%;\n"], ["\n    position: absolute;\n    z-index: 0;\n    opacity: 0;\n    width: 100%;\n    height: 100%;\n"])));
var FormIFrame = styled.iframe.attrs(function (props) { return ({
    id: "import-iframe"
}); })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    position: absolute;\n    z-index: 0;\n    opacity: 0;\n"], ["\n    position: absolute;\n    z-index: 0;\n    opacity: 0;\n"])));
var ImportButton = styled(LinkButton)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    position: relative;\n    z-index: 1;\n    pointer-events: none;\n    height: 100%;\n"], ["\n    position: relative;\n    z-index: 1;\n    pointer-events: none;\n    height: 100%;\n"])));
var EditorMenu = function () {
    var companyId = userStore(function (state) { return state.company.id; });
    var domain = userStore(function (state) { return state.company.domain; });
    var token = userStore(function (state) { return state.token; });
    var language = userStore(function (state) { return state.language; });
    var menu_id = useParams().menu_id;
    var fileInputRef = React.useRef(null);
    var t = useTranslation().t;
    var appEventEmitter = useAppEventEmitter();
    var importMenu = function (file) {
        // logger.info("import file", importFile);
        var form = new FormData();
        form.append("json", file, file.name);
        HttpApiRequest({
            url: "".concat(API_BASE_URL, "/import/").concat(companyId, "?token=").concat(token),
            method: "POST",
            data: form
        }).promise
            .then(function () {
            appEventEmitter.dispatch(AppEvents.REFRESH_MENUS, {});
        });
    };
    return React.createElement(Container, null,
        React.createElement(FlexItem, { style: {
                width: "100%"
            }, align: "center" },
            React.createElement(Language, null),
            React.createElement(FlexItem, { flex: 1, flexDirection: "row", style: {
                    marginLeft: "4px"
                } },
                React.createElement(CButton, { color: "link", href: "https://".concat(domain, ".ordish.app"), target: "_blank" }, t("view.as.client.text")),
                menu_id && React.createElement(FlexItem, { justify: "flex-end", flex: 1, gap: 2 },
                    React.createElement(LinkButton, { size: "sm", color: "link", href: "".concat(API_BASE_URL, "/export/").concat(companyId, "/m/").concat(menu_id, "?token=").concat(token, "&hl=").concat(language) },
                        React.createElement(FontAwesomeIcon, { icon: faFileExport, size: "lg" })),
                    React.createElement(FileImportContainer, null,
                        React.createElement(FileInput, { ref: fileInputRef, onChange: function (e) {
                                logger.info("Files", fileInputRef.current.files);
                                if (fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files.length) {
                                    importMenu(fileInputRef.current.files[0]);
                                }
                            } }),
                        React.createElement(ImportButton, { size: "sm", color: "link", href: "".concat(API_BASE_URL, "/export/").concat(companyId, "/m/").concat(menu_id, "?token=").concat(token, "&hl=").concat(language) },
                            React.createElement(FontAwesomeIcon, { icon: faFileImport, size: "lg" })))))));
};
export default React.memo(EditorMenu);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
