import * as React from "react";
import FormTextInput from "../common/form/FormTextInput";
import { FormHorizontalContainer, FormContainerColumn, FormVerticalContainer, SettingsFormPageLayout } from "./index";
import { getCompanyInfoRequest, updateCompanyInfoRequest } from "../../request/company.request";
import LoadingBox from "../common/LoadingBox";
import FormGoogleAddressInput from "../common/form/FormGoogleAddressInput";
import Scrollbars from "react-custom-scrollbars-2";
import { useHttpRequestHandler, useHttpRequesPrepareHandler } from "../../service/network.service";
import { useTranslation } from "react-i18next";
import { Box } from "../common/FlexItem";
import FormSelectInput from "../common/form/FormSelectInput";
var logger = require("js-logger").get("CompanyLegalInfo");
export var CompanyLegalInfo = React.memo(function (props) {
    var _a;
    var getCompanyInfoState = useHttpRequestHandler(getCompanyInfoRequest());
    var saveCompanyInfoState = useHttpRequesPrepareHandler();
    var t = useTranslation().t;
    var saveValues = function (values) {
        logger.info(values);
        var update = {};
        Object.keys(values).forEach(function (k) {
            if (values[k]) {
                update[k] = values[k];
            }
        });
        saveCompanyInfoState.run(updateCompanyInfoRequest(update))
            .then(function () {
            getCompanyInfoState.run();
        })
            .catch(function (err) {
            logger.error(err);
        });
    };
    if (getCompanyInfoState.inProgress || saveCompanyInfoState.inProgress) {
        return React.createElement(LoadingBox, null);
    }
    var companyInfo = getCompanyInfoState.result ? getCompanyInfoState.result.info : {};
    var timezones = (getCompanyInfoState === null || getCompanyInfoState === void 0 ? void 0 : getCompanyInfoState.result) ? (_a = getCompanyInfoState === null || getCompanyInfoState === void 0 ? void 0 : getCompanyInfoState.result) === null || _a === void 0 ? void 0 : _a.timezones : [];
    if (!timezones.includes(null)) {
        timezones.unshift(null);
    }
    logger.info("CompanyInfo", companyInfo);
    return React.createElement(Box, { flex: 1 },
        React.createElement(Scrollbars, { style: {
                height: "100%"
            } },
            React.createElement(SettingsFormPageLayout, { onSubmit: saveValues, title: t("company.settings.legal.info.text"), formConfig: {
                    mode: 'onChange',
                } }, function (useFormReturn) {
                var _a, _b;
                return React.createElement(FormVerticalContainer, null,
                    React.createElement(FormHorizontalContainer, null,
                        React.createElement(FormTextInput, { label: t("legal.name.text"), name: "legalName", control: useFormReturn.control, defaultValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.legalName })),
                    React.createElement(FormHorizontalContainer, null,
                        React.createElement(FormContainerColumn, null,
                            React.createElement(FormTextInput, { label: t("id.text"), name: "id", control: useFormReturn.control, defaultValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.id }),
                            React.createElement(FormTextInput, { label: t("represented_by.text"), name: "represented_by", control: useFormReturn.control, defaultValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.represented_by }),
                            React.createElement(FormTextInput, { label: t("country.text"), name: "country", control: useFormReturn.control, defaultValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.country }),
                            React.createElement(FormTextInput, { label: t("phone.text"), name: "phone", control: useFormReturn.control, defaultValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.phone }),
                            React.createElement(FormGoogleAddressInput, { label: t("actual.address.text"), name: "actualAddress", apiKey: GOOGLE_MAPS_API_KEY, control: useFormReturn.control, defaultValue: (_a = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.actualAddress) === null || _a === void 0 ? void 0 : _a.address_string }),
                            React.createElement(FormSelectInput, { options: timezones.map(function (tz) { return ({ label: tz ? tz : "Select Timezone", value: tz }); }), label: t("timezone.address.text"), name: "timeZone", control: useFormReturn.control, defaultValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.timeZone })),
                        React.createElement(FormContainerColumn, null,
                            React.createElement(FormTextInput, { label: t("vat.id.text"), name: "vatID", control: useFormReturn.control, defaultValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.vatID }),
                            React.createElement(FormTextInput, { label: t("post.code.text"), name: "post_code", control: useFormReturn.control, defaultValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.post_code }),
                            React.createElement(FormTextInput, { label: t("city.text"), name: "city", control: useFormReturn.control, defaultValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.city }),
                            React.createElement(FormTextInput, { label: t("email.text"), name: "email", control: useFormReturn.control, defaultValue: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.email }),
                            React.createElement(FormGoogleAddressInput, { label: t("legal.address.text"), name: "legalAddress", apiKey: GOOGLE_MAPS_API_KEY, control: useFormReturn.control, defaultValue: (_b = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.legalAddress) === null || _b === void 0 ? void 0 : _b.address_string }))));
            })));
});
