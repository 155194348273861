var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { CForm } from "@coreui/react";
import FormTextInput from "../common/form/FormTextInput";
import FormSelectInput from "../common/form/FormSelectInput";
import FormSwitchInput from "../common/form/FormSwitchInput";
import FormRadioGroupInput from "../common/form/FormRadioGroupInput";
import FormCheckboxInput from "../common/form/FormCheckboxInput";
import FlexItem from "../common/FlexItem";
import styled from "styled-components";
var logger = require("js-logger").get("MenuForm");
import { useTranslation } from "react-i18next";
import { ModalForm } from "../common/ModalForm";
var InputWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: #ced4da solid 1px;\n    border-radius: 0.25rem;\n    flex: 1;\n    height: 100%;\n    padding: 0.310rem 0.75rem;    \n"], ["\n    border: #ced4da solid 1px;\n    border-radius: 0.25rem;\n    flex: 1;\n    height: 100%;\n    padding: 0.310rem 0.75rem;    \n"])));
var DayWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 5px 0\n"], ["\n    margin: 5px 0\n"])));
var formatTime = function (time) {
    return time < 10 ? "0".concat(time) : "".concat(time);
};
var getTimeOptions = function () {
    var hour = 0;
    var options = [];
    while (hour < 24) {
        for (var minute = 0; minute < 60; minute += 15) {
            var time = "".concat(formatTime(hour), ":").concat(formatTime(minute));
            options.push({
                label: time,
                value: time
            });
            if (minute == 45) {
                hour++;
            }
        }
    }
    return options;
};
export var DayTimeItem = function (props) {
    var day = props.day, control = props.control, disabled = props.disabled;
    var t = useTranslation().t;
    var name = props.name ? props.name : "day";
    return React.createElement(DayWrapper, null,
        React.createElement(FlexItem, { flexDirection: "row", flex: 1, gap: 5, align: "center" },
            React.createElement(FlexItem, { flex: 0.7 },
                React.createElement(InputWrapper, null,
                    React.createElement(FormSwitchInput, { name: "".concat(name, ".").concat(day, ".enabled"), control: control, label: t("dayofweek.".concat(day + 1)), disabled: disabled }))),
            React.createElement(FlexItem, { flex: 1, gap: 4 },
                React.createElement(FormSelectInput, { name: "".concat(name, ".").concat(day, ".from"), control: control, defaultValue: "10:00", options: getTimeOptions(), disabled: disabled }),
                React.createElement(FormSelectInput, { name: "".concat(name, ".").concat(day, ".to"), control: control, defaultValue: "19:00", options: getTimeOptions(), disabled: disabled }))));
};
var getDefaultValues = function (menuItem) {
    if (!menuItem) {
        return undefined;
    }
    var active = menuItem.active, description = menuItem.description, name = menuItem.name, schedule = menuItem.schedule, type = menuItem.type, menu = menuItem.menu;
    return {
        active: active,
        description: description,
        name: name,
        schedule: schedule ? schedule : undefined,
        type: type,
        menu: menu
    };
};
var Form = styled(CForm)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100%;\n"], ["\n    width: 100%;\n"])));
var MenuForm = function (props) {
    var defaultValues = getDefaultValues(props.menu);
    var t = useTranslation().t;
    var onSubmit = props.onSubmit, onCancel = props.onCancel;
    // const { control, watch, handleSubmit, formState } = useForm({
    //     defaultValues: defaultValues ? defaultValues as Record<string, any> : undefined,
    //     mode: "onChange"
    // });
    // const onSubmit = handleSubmit(props.onSubmit);
    //logger.info("forState", formState, formState.isDirty, formState.isValid);
    return React.createElement(ModalForm, { title: t("menu.item.edit.title"), formProps: {
            defaultValues: defaultValues ? defaultValues : undefined,
            mode: "onChange"
        }, onSubmit: onSubmit, onClose: onCancel }, function (formReturn) {
        var control = formReturn.control, watch = formReturn.watch;
        var menuVal = watch("menu");
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: "mb-3" },
                React.createElement(FormTextInput, { label: "Name", name: "name", control: control, rules: {
                        required: true,
                        minLength: 3
                    } })),
            React.createElement("div", { className: "mb-3" },
                React.createElement(FormTextInput, { label: "Description", name: "description", control: control, rules: {
                        required: true,
                        minLength: 3
                    } })),
            React.createElement("div", { className: "mb-3" },
                React.createElement(FormSelectInput, { control: control, name: "type", label: "Type", rules: {
                        required: true
                    }, placeholder: "Select Type", options: [
                        {
                            label: "Simple PDF menu",
                            value: "simple_pdf_menu"
                        },
                        {
                            label: "Interactive menu",
                            value: "interactive_menu"
                        },
                        {
                            label: "Menu category",
                            value: "menu_category"
                        },
                        {
                            label: "Custom link",
                            value: "custom_link"
                        },
                        {
                            label: "Menu delivery",
                            value: "menu delivery"
                        },
                        {
                            label: "Menu takeaway",
                            value: "menu delivery"
                        }
                    ] })),
            React.createElement("div", { className: "mb-3" },
                React.createElement(FormSwitchInput, { name: "active", control: control, label: "Active", rules: {
                        required: true
                    } })),
            React.createElement("div", { className: "mb-3" },
                React.createElement(FormRadioGroupInput, { name: "menu", rules: {
                        required: true
                    }, control: control, inline: true, items: [
                        {
                            label: "Permanent menu",
                            value: "permanent"
                        },
                        {
                            label: "Time limited",
                            value: "time_limited"
                        }
                    ] })),
            menuVal == "time_limited" && React.createElement("div", { className: "mb-3" },
                React.createElement(DayTimeItem, { name: "schedule", day: 0, control: control }),
                React.createElement(DayTimeItem, { name: "schedule", day: 1, control: control }),
                React.createElement(DayTimeItem, { name: "schedule", day: 2, control: control }),
                React.createElement(DayTimeItem, { name: "schedule", day: 3, control: control }),
                React.createElement(DayTimeItem, { name: "schedule", day: 4, control: control }),
                React.createElement(DayTimeItem, { name: "schedule", day: 5, control: control }),
                React.createElement(DayTimeItem, { name: "schedule", day: 6, control: control })),
            props.menu && React.createElement(FlexItem, { justify: "flex-start", flex: 1 },
                React.createElement(FormCheckboxInput, { label: t("translate.label"), control: control, name: "translate" })));
    });
};
export default React.memo(MenuForm);
var templateObject_1, templateObject_2, templateObject_3;
