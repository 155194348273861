var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { AppPageTopButtonContainer } from "../AppPage";
import CloseIconSvg from "../../../assets/svg/close-icon.svg";
import { Box } from "../common/FlexItem";
import Scrollbars from "react-custom-scrollbars-2";
import { userStore } from "../../store/user.store";
import { langDirection } from "../../locale/i18next";
import { Touchable } from "../../common/Touchable";
var DrawerButtonLine = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 0.2rem;    \n    background-color: #111719;\n    margin-left: 25%;\n"], ["\n    height: 0.2rem;    \n    background-color: #111719;\n    margin-left: 25%;\n"])));
var DrawerButtonLine1 = styled(DrawerButtonLine)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 50%;    \n"], ["\n    width: 50%;    \n"])));
var DrawerButtonLine2 = styled(DrawerButtonLine)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 35%;    \n    margin-top: 0.3rem\n"], ["\n    width: 35%;    \n    margin-top: 0.3rem\n"])));
var DrawerOverlay = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 100;    \n    background: #000;\n    opacity: 0.4\n"], ["\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 100;    \n    background: #000;\n    opacity: 0.4\n"])));
var DrawerContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    position: fixed;\n    left: ", ";\n    right: ", ";\n    top: 0;\n    width: 60%;\n    height: 100%;\n    z-index: 101;\n    display: flex;    \n    background-color: #FBFBFB;\n"], ["\n    position: fixed;\n    left: ", ";\n    right: ", ";\n    top: 0;\n    width: 60%;\n    height: 100%;\n    z-index: 101;\n    display: flex;    \n    background-color: #FBFBFB;\n"])), function (props) { return props.langDir == "rtl" ? "auto" : 0; }, function (props) { return props.langDir == "rtl" ? 0 : "auto"; });
var DrawerContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    position: relative;\n    width: 100%;\n"], ["\n    position: relative;\n    width: 100%;\n"])));
var DrawerCloseButtonPosition = styled(Box)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    position: absolute;\n    top: 0.7rem;\n    left: 0.7rem;\n"], ["\n    position: absolute;\n    top: 0.7rem;\n    left: 0.7rem;\n"
    // const DrawerButtonContainer = styled(AppPageTopButtonContainer)`
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // `
])));
export var DrawerButton = React.memo(function (props) {
    var onClick = props.onClick;
    return React.createElement(AppPageTopButtonContainer, { onClick: onClick },
        React.createElement(DrawerButtonLine1, null),
        React.createElement(DrawerButtonLine2, null));
});
var CloseIcon = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    width: 40%;\n    height: 40%;\n    background: url(", ") no-repeat;\n"], ["\n    width: 40%;\n    height: 40%;\n    background: url(", ") no-repeat;\n"])), CloseIconSvg);
var CloseButton = styled(Touchable)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    position: relative;\n    z-index: 103;\n"], ["\n    position: relative;\n    z-index: 103;\n"])));
export var CloseDrawerButton = React.memo(function (props) {
    var onClick = props.onClick;
    return React.createElement(CloseButton, { onTap: onClick },
        React.createElement(AppPageTopButtonContainer, null,
            React.createElement(Box, { flex: 1, align: "center", justify: "center" },
                React.createElement(CloseIcon, null))));
});
export var Drawer = React.memo(function (props) {
    var _a = React.useState(props.visible !== undefined ? props.visible : false), visible = _a[0], setVisible = _a[1];
    var language = userStore(function (state) { return state.language; });
    var toggleVisible = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setVisible(!visible);
    };
    React.useEffect(function () {
        setVisible(props.visible);
    }, [props.visible]);
    React.useEffect(function () {
        if (!visible && props.onClose) {
            props.onClose();
        }
    }, [visible]);
    if (!visible) {
        return null;
    }
    return React.createElement(React.Fragment, null,
        React.createElement(DrawerOverlay, { onClick: toggleVisible }),
        React.createElement(DrawerContainer, { langDir: langDirection[language] },
            React.createElement(DrawerContent, null,
                React.createElement(DrawerCloseButtonPosition, null,
                    React.createElement(CloseDrawerButton, { onClick: toggleVisible })),
                React.createElement(Scrollbars, null, props.children))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
