var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { IconButton } from "./IconButton";
import { Box } from "../component/common/FlexItem";
var logger = require("js-logger").get("OrderButton");
import { hexToRgb } from "../utils/ColorUtils";
var boxShadowRgba = function (color) {
    var rgb = hexToRgb(color);
    if (!color) {
        return "";
    }
    return "rgba(".concat(rgb.r, ",").concat(rgb.g, ",").concat(rgb.b, ", 0.2)");
};
function shadeColor(color, percent) {
    if (!color) {
        return "";
    }
    var rgb = {
        r: parseInt(color.substring(1, 3), 16),
        g: parseInt(color.substring(3, 5), 16),
        b: parseInt(color.substring(5, 7), 16)
    };
    // var R = parseInt(color.substring(1,3),16);
    // var G = parseInt(color.substring(3,5),16);
    // var B = parseInt(color.substring(5,7),16);
    var R = parseInt((rgb.r * (100 + percent) / 100).toFixed(0));
    var G = parseInt((rgb.g * (100 + percent) / 100).toFixed(0));
    var B = parseInt((rgb.b * (100 + percent) / 100).toFixed(0));
    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;
    var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));
    return "#" + RR + GG + BB;
}
var Button = styled(IconButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", ";  \n    color: var(--btn-font-color);\n    box-shadow: none;\n"], ["\n    background-color: ", ";  \n    color: var(--btn-font-color);\n    box-shadow: none;\n"])), function (props) { return shadeColor(props.color, 10); });
var Number = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: 20px;\n    color: var(--btn-font-color);\n    padding: 0 10px;\n"], ["\n    font-size: 20px;\n    color: var(--btn-font-color);\n    padding: 0 10px;\n"])));
var OrderButtonContainer = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: inline-flex;\n    background-color: var(--btn-primary-color);\n    border-radius: 25px;\n    flex: none;\n    color: var(--btn-font-color);\n    box-shadow: 0px 8px 18px ", ";\n"], ["\n    display: inline-flex;\n    background-color: var(--btn-primary-color);\n    border-radius: 25px;\n    flex: none;\n    color: var(--btn-font-color);\n    box-shadow: 0px 8px 18px ", ";\n"])), function (props) { return boxShadowRgba(props.color); });
export var OrderButton = React.memo(function (props) {
    var onDecrement = props.onDecrement, onIncrement = props.onIncrement, _a = props.count, count = _a === void 0 ? 0 : _a, color = props.color;
    var _b = React.useState(count), itemsCount = _b[0], setItemsCount = _b[1];
    var increment = function () {
        if (onIncrement) {
            onIncrement(itemsCount + 1);
        }
        setItemsCount(itemsCount + 1);
    };
    var decrement = function () {
        if (onDecrement) {
            onDecrement(itemsCount - 1);
        }
        setItemsCount(itemsCount - 1);
    };
    React.useEffect(function () {
        if (props.count !== undefined) {
            setItemsCount(props.count);
        }
    }, [props.count]);
    return React.createElement(OrderButtonContainer, { color: color },
        React.createElement(Button, { color: color, type: "button", onClick: function () { } }, "+"),
        itemsCount > 0 && React.createElement(Number, { align: "center" }, itemsCount),
        itemsCount > 0 && React.createElement(Button, { color: color, type: "button", onClick: function () { } }, "-"));
});
var templateObject_1, templateObject_2, templateObject_3;
