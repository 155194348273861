var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton } from "@coreui/react";
import * as React from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ModalForm } from "../../../component/common/ModalForm";
import styled from "styled-components";
import * as _ from "underscore";
import { sortBy } from "underscore";
import { randomString } from "../../../utils/StringUtils";
import { Box } from "../../common/FlexItem";
import FormCheckboxInput from "../../common/form/FormCheckboxInput";
import FormCurrencyInput from "../../common/form/FormCurrencyInput";
import FormRadioGroupInput from "../../common/form/FormRadioGroupInput";
import FormSwitchInput from "../../common/form/FormSwitchInput";
import FormTextInput from "../../common/form/FormTextInput";
import { Sortable } from "../../common/Sortable";
import { SortItemLayout } from "../../common/SortItemLayout";
var logger = require("js-logger").get("OptionPage");
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 0 10px;\n    flex: 1\n"], ["\n    padding: 0 10px;\n    flex: 1\n"])));
var FormRow = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 10px 0;\n    padding-bottom: 5px;    \n"], ["\n    margin: 10px 0;\n    padding-bottom: 5px;    \n"])));
var FormItemRow = styled(FormRow)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border-bottom: none;\n"], ["\n    border-bottom: none;\n"])));
var ErrorMessage = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    color: red\n"], ["\n    color: red\n"
    // export type OptionEditPageProps = {
    //     option: MenuOption
    //     onSubmit: (values: Record<string, any>) => void
    // }
    // export const OptionEditPage = React.memo((props: OptionEditPageProps) => {
    //     const { option, onSubmit } = props;
    //     return <OptionPageForm
    //         option={option}
    //         onSubmit={onSubmit}
    //     />
    // })
    // export type OptionAddPageProps = {
    //     onSubmit: (values: Record<string, any>) => void
    // }
    // export const OptionAddPage = React.memo((props: OptionAddPageProps) => {
    //     return <OptionPageForm
    //         onSubmit={props.onSubmit}
    //     />
    // })
])));
export var OptionPageForm = React.memo(function (props) {
    var option = props.option, onSubmit = props.onSubmit, onCancel = props.onCancel;
    var defaultValues = {
        sort: 0,
        type: "single",
        config: {
            required: false,
            showToCourier: false,
            countable: false
        },
        posId: "",
        name: "",
        items: [
            {
                sort: 0,
                default: true,
                name: "",
                description: "",
                posId: "",
                price: 0,
                enabled: true
            },
            {
                sort: 1,
                default: false,
                name: "",
                description: "",
                posId: "",
                price: 0,
                enabled: true
            }
        ]
    };
    if (option) {
        var items = option.items, optionData = __rest(option, ["items"]);
        defaultValues = __assign({}, optionData);
        defaultValues.items = _.sortBy(items, "sort");
    }
    var t = useTranslation().t;
    var title = option ? t("option.edit.item.text", {
        name: option.name
    }) : t("option.add.item.text");
    return React.createElement(ModalForm, { title: title, formProps: {
            mode: "onChange",
            defaultValues: defaultValues
        }, onSubmit: onSubmit, onClose: onCancel }, function (formReturn) {
        var formState = formReturn.formState, control = formReturn.control, watch = formReturn.watch, setValue = formReturn.setValue, getValues = formReturn.getValues;
        var _a = useFieldArray({
            control: control,
            name: "items",
            keyName: "key"
        }), fields = _a.fields, append = _a.append, remove = _a.remove, move = _a.move;
        var type = watch("type");
        var renderItemFields = sortBy(fields.map(function (field) {
            var fo = field;
            if (!fo._id) {
                fo._id = randomString(10);
            }
            return fo;
        }), "sort");
        var reOrderItems = function (items) {
            var moveData = [];
            items.forEach(function (item, idx) {
                var field = fields.find(function (f) { return f._id == item._id; });
                var fi = fields.indexOf(field);
                //logger.info("Field", field, "index", fi);
                setValue("items.".concat(fi, ".sort"), idx, {
                    shouldDirty: true
                });
                moveData.push([fi, idx]);
            });
            moveData.forEach(function (data) {
                move(data[0], data[1]);
            });
        };
        var removeItem = function (index) { return function () {
            logger.info("Remove index 1", index);
            if (confirm(t("delete.confirm.text"))) {
                logger.info("Remove index 2", index);
                setTimeout(function () {
                    remove(index);
                    setValue("type", type, {
                        shouldDirty: true
                    });
                }, 0);
            }
        }; };
        var setItemAsDefault = function (index) { return function (e) {
            var target = e.target;
            if (target.checked) {
                fields.forEach(function (field, idx) {
                    if (idx !== index) {
                        setValue("items.".concat(idx, ".default"), false, {
                            shouldDirty: true,
                            shouldValidate: true
                        });
                    }
                });
            }
            else {
                setTimeout(function () {
                    fields.forEach(function (field, idx) {
                        if (idx == index) {
                            setValue("items.".concat(idx, ".default"), true);
                        }
                    });
                }, 0);
            }
        }; };
        return React.createElement(React.Fragment, null,
            React.createElement(FormRow, null,
                React.createElement(FormTextInput, { name: "name", control: control, label: t("name.text") })),
            React.createElement(FormRow, null,
                React.createElement(FormRadioGroupInput, { control: control, name: "type", items: [
                        { label: t("menu.option.single.choice"), value: "single" },
                        { label: t("menu.option.multiple.choice"), value: "multiple" }
                    ], rules: {
                        required: true
                    } })),
            type == "single" && React.createElement(FormRow, null,
                React.createElement(FormCheckboxInput, { control: control, name: "config.showToCouries", label: t("option.shwoToCourier.label") }),
                React.createElement(FormCheckboxInput, { control: control, name: "config.required", label: t("required.text") })),
            type == "multiple" && React.createElement(FormRow, null,
                React.createElement(FormCheckboxInput, { control: control, name: "config.showToCouries", label: t("option.shwoToCourier.label") }),
                React.createElement(FormCheckboxInput, { control: control, name: "config.countable", label: t("countable.text") }),
                React.createElement(FormTextInput, { control: control, name: "config.maxItems", label: t("maxItems.text") })),
            React.createElement(FormRow, null,
                React.createElement(FormTextInput, { control: control, name: "posId", label: t("posId.text"), defaultValue: "" })),
            React.createElement(FormRow, null,
                React.createElement(Box, { align: "center" },
                    React.createElement(Box, null, t("items.text")),
                    React.createElement(Box, { flex: 1, justify: "flex-end" },
                        React.createElement(CButton, { size: "sm", onClick: function () {
                                append({
                                    sort: fields.length,
                                    default: fields.length == 0 ? true : false,
                                    name: "",
                                    description: "",
                                    posId: "",
                                    price: 0,
                                    enabled: true
                                });
                            } }, t("menu.option.item.add"))))),
            React.createElement(Sortable, { onSort: reOrderItems, items: renderItemFields.map(function (field) {
                    var item = field;
                    logger.info("option item", item);
                    return {
                        id: item.id ? item.id : item.key,
                        _id: item._id,
                        name: item.name,
                        description: item.description,
                        posId: item.posId,
                        price: item.price,
                        default: item.default,
                        enabled: item.enabled
                    };
                }), renderItem: function (item, index) { return React.createElement(FormItemRow, { key: item.id },
                    React.createElement(SortItemLayout, null,
                        React.createElement(Box, { key: item.id, gap: "5px" },
                            React.createElement(Box, { flex: "none" },
                                React.createElement(FormSwitchInput, { control: control, name: "items.".concat(index, ".enabled") })),
                            React.createElement(Box, { flex: 0.4, display: "inline-block" },
                                React.createElement(FormTextInput, { control: control, name: "items.".concat(index, ".name"), placeholder: t("menu.option.name.placeholder"), size: "sm", rules: {
                                        required: true,
                                        minLength: 2
                                    } })),
                            React.createElement(Box, { flex: 0.4, display: "inline-block" },
                                React.createElement(FormTextInput, { control: control, name: "items.".concat(index, ".description"), placeholder: t("menu.option.desc.placeholder"), size: "sm" })),
                            React.createElement(Box, { flex: 0.3, display: "inline-block" },
                                React.createElement(FormTextInput, { control: control, name: "items.".concat(index, ".posId"), placeholder: t("menu.option.posId.placeholder"), size: "sm" })),
                            React.createElement(Box, { flex: 0.4, display: "inline-block" },
                                React.createElement(FormCurrencyInput, { control: control, name: "items.".concat(index, ".price"), placeholder: t("menu.option.price.placeholder"), size: "sm", rules: {
                                        required: true,
                                        minLength: 1
                                    } })),
                            type == "single" && React.createElement(Box, { flex: 0.3, display: "inline-block" },
                                React.createElement(FormCheckboxInput, { label: t("default.text"), control: control, name: "items.".concat(index, ".default"), onChage: setItemAsDefault(index) })),
                            React.createElement(CIcon, { icon: cilTrash, onClick: removeItem(index) })))); } }),
            formState.isDirty && fields.length < 2 && React.createElement(ErrorMessage, null, t("form.options.items.invalid.length")),
            option && React.createElement("div", null,
                React.createElement(FormCheckboxInput, { label: t("translate.label"), control: control, name: "translate" })));
    });
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
