var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { CFormCheck } from "@coreui/react";
import * as React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { isArray } from "underscore";
import useSound from "use-sound";
import notificationSound from "../../assets/sound/positive-notification.wav";
import { HasPemission } from "../common/user/HasPermission";
import { MonitorPermissions } from "../common/user/Permission";
import { Box } from "../component/common/FlexItem";
import LoadingBox from "../component/common/LoadingBox";
import { Modal, ModalBody, ModalHeader } from "../component/common/Modal";
import { APP_OPERATION_MONITOR } from "../constats/Routes";
import { useLocalStorageItem } from "../hook/local.storage.hook";
import { useJsonRPC } from "../hook/ws.jsonrpc.hook";
import { getStationsRequest } from "../request/station.request";
import { useHttpRequestHandler } from "../service/network.service";
import { ordersStore } from "../store/orders.store";
import { userStore } from "../store/user.store";
import { Details } from "./detail";
import { Filters } from "./filters";
import { Group } from "./group";
import { Volume } from "./volume";
var logger = require("js-logger").get("OperationMonitorPage");
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 100%;\n"], ["\n    height: 100%;\n"])));
var groupOrders = function (orders) {
    var data = {};
    orders.forEach(function (order) {
        if (!data[order.status]) {
            data[order.status] = [];
        }
        data[order.status].push(order);
    });
    return data;
};
var Toolbar = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    background: #fff;\n    padding: 0.3rem 0.5rem;\n"], ["\n    display: flex;\n    background: #fff;\n    padding: 0.3rem 0.5rem;\n"])));
export var GroupType;
(function (GroupType) {
    GroupType["BY_STATION"] = "by-station";
    GroupType["BY_ITEM"] = "by-item";
})(GroupType || (GroupType = {}));
export var OperationMonitorPage = React.memo(function () {
    var jsonRpc = useJsonRPC();
    var companyId = userStore(function (state) { return state.company.id; });
    var userId = userStore(function (state) { return state.user.id; });
    var orders = ordersStore(function (state) { return state.orders; });
    var setOrders = ordersStore(function (state) { return state.setOrders; });
    var setTimers = ordersStore(function (state) { return state.setTimers; });
    var soundKey = "".concat(userId, "_sound");
    var play = useSound(notificationSound)[0];
    var getCompanyOrders = function () {
        jsonRpc.request("getCompanyOrders", { companyId: companyId })
            .then(function (response) {
            //logger.info(response)
            setOrders(response.orders);
            setTimers(response.statusTimers);
        })
            .catch(function (err) {
            logger.error(err);
        });
    };
    React.useEffect(function () {
        getCompanyOrders();
        jsonRpc.subscribe("order.update", "pass", function (data) {
            var columns = data.columns;
            if (columns && columns.length && columns.includes("status")) {
                var soundConfig = JSON.parse(localStorage.getItem(soundKey) || '{"mute": false}');
                if (!soundConfig.mute) {
                    logger.info("Play ))))");
                    play();
                }
            }
            setTimeout(function () {
                getCompanyOrders();
            }, 0);
        });
        return function () {
            jsonRpc.getWebSocketConnection().disconnect();
        };
    }, []);
    return React.createElement(HasPemission, { permission: MonitorPermissions.MONITOR_VIEW },
        React.createElement(Container, { flex: 1 },
            React.createElement(Scrollbars, { style: {
                    height: "100%"
                } },
                React.createElement(OrderGroups, { orders: orders })),
            React.createElement(Outlet, null)));
});
var withStations = function (WrappedComponent) {
    return function (props) {
        var stationState = useHttpRequestHandler(getStationsRequest());
        if (!stationState.result) {
            return React.createElement(LoadingBox, null);
        }
        return React.createElement(WrappedComponent, __assign({}, props, { stations: stationState.result }));
    };
};
var filterOrderItemsByStation = function (orders, stationFilters) {
    var ordersToFilter = isArray(orders) ? orders : [orders];
    var filtered = ordersToFilter.map(function (order) {
        var items = order.items, rest = __rest(order, ["items"]);
        return Object.assign({}, rest, {
            items: items.filter(function (item) { return stationFilters[item.station_id]; })
        });
    });
    return filtered;
};
export var DetailsContainer = withStations(React.memo(function (props) {
    var stations = props.stations;
    var params = useParams();
    var navigate = useNavigate();
    var orders = ordersStore(function (state) { return state.orders; });
    var order = orders.find(function (order) { return order.id == params.id; });
    var goToOrders = function () {
        navigate(APP_OPERATION_MONITOR);
    };
    var userId = userStore(function (state) { return state.user.id; });
    var stationFiltersKey = "".concat(userId, "_oporder_station_filters");
    var filters = {};
    stations.forEach(function (station) {
        filters[station.id] = true;
    });
    var _a = useLocalStorageItem(stationFiltersKey, JSON.stringify(filters)), stationFiltersStr = _a[0], updateStationFilters = _a[1];
    var statusFilters = JSON.parse(stationFiltersStr);
    if (!order) {
        return null;
    }
    return React.createElement(Modal, { visible: true, fullscreen: true, noBorderRadius: true },
        React.createElement(ModalHeader, { onClose: goToOrders }, order.name),
        React.createElement(ModalBody, null,
            React.createElement(Details, { order: filterOrderItemsByStation([order], statusFilters)[0] })));
}));
var OrderGroups = withStations(React.memo(function (props) {
    var orders = props.orders, stations = props.stations;
    var t = useTranslation().t;
    //logger.info("Orders", orders);
    var orderStatuses = ["new", "in-progress", "ready", "completed", "canceled"];
    var userId = userStore(function (state) { return state.user.id; });
    var typeFiltersKey = "".concat(userId, "_oporder_type_filters");
    var statusFiltersKey = "".concat(userId, "_oporder_status_filters");
    var stationFiltersKey = "".concat(userId, "_oporder_station_filters");
    var groupByKey = "".concat(userId, "_op_groupby");
    var _a = useLocalStorageItem(groupByKey, GroupType.BY_STATION), groupBy = _a[0], setGroupBy = _a[1];
    var soundKey = "".concat(userId, "_sound");
    var filters = {};
    stations.forEach(function (station) {
        filters[station.id] = true;
    });
    var _b = useLocalStorageItem(typeFiltersKey, JSON.stringify({
        "inside": true,
        "outside": true,
        "delivery": true
    })), typeFiltersStr = _b[0], updateTypeFilters = _b[1];
    var _c = useLocalStorageItem(statusFiltersKey, JSON.stringify({
        "new": true,
        "in-progress": true,
        "ready": true,
        "completed": true,
        "canceled": true
    })), statusFiltersStr = _c[0], updateStatusFilters = _c[1];
    var _d = useLocalStorageItem(stationFiltersKey, JSON.stringify(filters)), stationFiltersStr = _d[0], updateStationFilters = _d[1];
    var typeFilters = JSON.parse(typeFiltersStr);
    var statusFilters = JSON.parse(statusFiltersStr);
    var stationFilters = JSON.parse(stationFiltersStr);
    var _e = useLocalStorageItem(soundKey, JSON.stringify({
        mute: false
    })), soundConfigStr = _e[0], setSoundConfig = _e[1];
    var soundConfig = JSON.parse(soundConfigStr);
    return React.createElement(React.Fragment, null,
        React.createElement(Toolbar, null,
            React.createElement(Box, { flex: 1, align: "center" },
                React.createElement(Filters, { typeFilters: typeFilters, statusFilters: statusFilters, stationFilters: stationFilters, statusFiltersUpdate: function (update) { return updateStatusFilters(JSON.stringify(update)); }, typeFiltersUpdate: function (update) { return updateTypeFilters(JSON.stringify(update)); }, stationFilterUpdate: function (update) { return updateStationFilters(JSON.stringify(update)); }, stations: stations }),
                React.createElement(CFormCheck, { id: "split-by-dish", label: t("opm.split.by.dish.text"), checked: groupBy == GroupType.BY_ITEM, onChange: function (e) {
                        setGroupBy(e.target.checked ? GroupType.BY_ITEM : GroupType.BY_STATION);
                    } })),
            React.createElement(Volume, { mute: soundConfig.mute, onToggle: function () {
                    setSoundConfig(JSON.stringify(Object.assign({}, soundConfig, {
                        "mute": !soundConfig.mute
                    })));
                } })),
        orderStatuses.filter(function (status) { return statusFilters[status]; }).map(function (status) {
            return React.createElement(Group, { key: status, status: status, title: t("order.state.".concat(status, ".text")), orders: orders, typeFilters: typeFilters, groupBy: groupBy, stationFilters: stationFilters });
        }));
}));
var templateObject_1, templateObject_2;
