import * as React from "react";
import LoadingBox from "../common/LoadingBox";
import { useHttpRequestHandler } from "../../service/network.service";
import { wrapVerified } from "../common/Verified";
import { userCompaniesRequest } from "../../request/user.request";
import { useNavigate } from "react-router-dom";
import { APP } from "../../constats/Routes";
import { userStore } from "../../store/user.store";
var logger = require("js-logger").get("CompanyScreen");
import { appStore } from "../../store/app.store";
var CompanyScreen = React.memo(function () {
    var companiesState = useHttpRequestHandler(userCompaniesRequest());
    var navigate = useNavigate();
    var uStore = userStore();
    var aStore = appStore();
    aStore.appReferrer;
    var companies = companiesState.executed ? companiesState.result : null;
    React.useEffect(function () {
        var company = companiesState.executed ? companiesState.result : null;
        if (company) {
            uStore.setCompany(company);
            navigate(aStore.appReferrer ? aStore.appReferrer : APP);
        }
    }, [companiesState.result, companiesState.executed]);
    //logger.info("companies", companies);
    return React.createElement(React.Fragment, null, companiesState.inProgress && React.createElement(LoadingBox, null));
});
export default wrapVerified(CompanyScreen);
